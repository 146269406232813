import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { IProductListingPageQuickLink, ProductListingPageQuickLink } from "./productListingPageQuickLink";
const urlPaths = require('../../config/urlPaths.json');
export interface IProductListingPagHeaderQuickLinks extends IInstanceComponentBase {
    productListingPageQuickLinks : IProductListingPageQuickLink[];
}

export class ProductListingPageHeaderQuickLinks extends InstanceComponentBase implements IProductListingPagHeaderQuickLinks {
    public productListingPageQuickLinks : IProductListingPageQuickLink[] = new Array();

    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);
        this.productListingPageHeaderQuickLinkFactory();
    }

    productListingPageHeaderQuickLinkFactory(){
        try {
            console.log('ProductListingPageHeaderQuickLinks: productListingPageHeaderQuickLinkFactory - Started');

            this.productListingPageQuickLinks = [];

            let quickLinkElements: NodeListOf<HTMLElement> | null = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageHeader.ProductListingPageHeaderQuickLink)) as NodeListOf<HTMLElement>;

            if(quickLinkElements !== null){
                for (let i = 0; i < quickLinkElements.length; i += 1) {
                    let quickLink: IProductListingPageQuickLink = new ProductListingPageQuickLink(quickLinkElements[i]);
                    this.productListingPageQuickLinks.push(quickLink);
                }
            }

            console.log('ProductListingPageHeaderQuickLinks: productListingPageHeaderQuickLinkFactory - Finished');
        } catch (error) {
            console.log("ProductListingPageHeaderQuickLinks: productListingPageHeaderQuickLinkFactory ERROR: ", error);
        }
    }

  
    dispose(): void {
        this.productListingPageQuickLinks.forEach((quickLink: IProductListingPageQuickLink) => quickLink.dispose());
    }
    
    delete(): void {

    }
}