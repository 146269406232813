export namespace OnScreenUtils {

    export function isVerticallyOnScreen(element: HTMLElement): boolean {

        let elementBoundingRect = element.getBoundingClientRect();
        let windowInnerHeight = window.innerHeight;
        let windowScrollY = window.scrollY;
        let midPointPosition = elementBoundingRect.top + (elementBoundingRect.height / 2);
        let midPointPositionWithScroll = (midPointPosition + windowScrollY);
        let pageBottomWithScroll = windowInnerHeight + windowScrollY;
        let isOnScreen: boolean = midPointPositionWithScroll < pageBottomWithScroll;

        if (isOnScreen) {
            return true;
        }
        else {
            return false;
        }
    }
}