import { HttpRequest } from "../ajax"
import { ProductDeliveryRequestDto } from "./productDeliveryRequestDto";
import { Convert, ProductDeliveryResponseDto } from "./productDeliveryResponseDto";
const urlPaths = require('../../config/urlPaths.json');

export namespace DeliveryServices {
    export async function getDeliveryInfoForAProduct(productDeliveryRequestDto: ProductDeliveryRequestDto): Promise<ProductDeliveryResponseDto> {



        let content = JSON.stringify(productDeliveryRequestDto);
        let url = urlPaths.websiteUrl + urlPaths.productDeliveryRoute;

        console.log('DeliveryServices: getDeliveryInfoForAProduct productDeliveryRequestDto', productDeliveryRequestDto);

        let responseContent = await HttpRequest.post(url, content);
        if (typeof responseContent === 'string') {
            try {
                let ProductDeliveryResponseDto: ProductDeliveryResponseDto = Convert.toProductDeliveryResponseDto(responseContent);
                return ProductDeliveryResponseDto
            } catch (error) {
                console.log('Error Converting JSON to ProductDeliveryResponseDto[]', error);
                throw new Error('Error Converting JSON to ProductDeliveryResponseDto[]');
            }
        }
    }
}