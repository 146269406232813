import { AsyncUtils } from "./utilities/asyncUtils";
import { CustomEventEnums } from "./utilities/enums/customEventEnums";
import { StringEnums } from "./utilities/enums/stringEnums";
import { UtilityEnums } from "./utilities/enums/utillityEnums";

export namespace ResponsiveState {

    export function getState(): string {
        return getStateAsEnum();
    }

    export function getStateAsEnum(): StringEnums.Selectors {
        if (window.innerWidth >= 1200) {
            return StringEnums.Selectors.Desktop;
        } else if (window.innerWidth >= 768) {
            return StringEnums.Selectors.Tablet;
        } else {
            return StringEnums.Selectors.Mobile;
        }
    }

    export function getOrientation(){
        const mediaQueryLandscape = window.matchMedia('(orientation:landscape)');

        if(mediaQueryLandscape.matches){
            return UtilityEnums.DeviceOrientation.Landscape
        } else{
            return UtilityEnums.DeviceOrientation.Portrait;
        }
    }

    export function responsiveStateInitialiser() {
        attachDispatchDeviceTypeChangeEventListeners();
        attachDispatchOrientationEventListeners();
        attachDispatchViewportDimensionsChangedEventListeners();
        //attachDispatchDocumentBodyScrollHeightChangedEventListeners();
    }

    /* ---------- EVENT Listeners ---------- */
    function attachDispatchDeviceTypeChangeEventListeners() {
        const mediaQueryMobile = window.matchMedia('(max-width:767px)');
        const mediaQueryTablet = window.matchMedia('(min-width:768px) and (max-width:1199px)');
        const mediaQueryDesktop = window.matchMedia('(min-width: 1200px)');
  
        mediaQueryMobile.addEventListener('change', function () {
            if(mediaQueryMobile.matches) {
                console.log('ResponsiveState: attachDispatchDeviceTypeChangeEventListeners - mediaQueryMobile');
                document.dispatchEvent(deviceTypeChangedEvent(UtilityEnums.DeviceType.Mobile));
            }
        }, false);
  
        mediaQueryTablet.addEventListener('change', function () {
            if(mediaQueryTablet.matches) {
                console.log('ResponsiveState: attachDispatchDeviceTypeChangeEventListeners - mediaQueryTablet');
                document.dispatchEvent(deviceTypeChangedEvent(UtilityEnums.DeviceType.Tablet));
            }
        }, false);
  
        mediaQueryDesktop.addEventListener('change', function () {
            if(mediaQueryDesktop.matches) {
                document.dispatchEvent(deviceTypeChangedEvent(UtilityEnums.DeviceType.Desktop));
                console.log('ResponsiveState: attachDispatchDeviceTypeChangeEventListeners - mediaQueryDesktop');
            }
        }, false);
    }

    function attachDispatchOrientationEventListeners() {
        const mediaQueryLandscape = window.matchMedia('(orientation:landscape)');
        const mediaQueryPortrait = window.matchMedia('(orientation:portrait)');
  
        mediaQueryLandscape.addEventListener('change', function () {
            if(mediaQueryLandscape.matches) {
                console.log('ResponsiveState: attachDispatchOrientationEventListeners - mediaQueryLandscape');
                document.dispatchEvent(deviceOrientationChangedEvent(UtilityEnums.DeviceOrientation.Landscape));
            }
        }, false);
  
        mediaQueryPortrait.addEventListener('change', function () {
            if(mediaQueryPortrait.matches) {
                console.log('ResponsiveState: attachDispatchOrientationEventListeners - mediaQueryPortrait');
                document.dispatchEvent(deviceOrientationChangedEvent(UtilityEnums.DeviceOrientation.Portrait));
            }
        }, false);

    }

    let VIEWPORT_DIMENSION_CHANGE_EVENT_FIRING: boolean = false;
    function attachDispatchViewportDimensionsChangedEventListeners() { 
        window.addEventListener('resize', async function () {
            const sample = async () => {
                VIEWPORT_DIMENSION_CHANGE_EVENT_FIRING = true;
                await AsyncUtils.delay(500);
                VIEWPORT_DIMENSION_CHANGE_EVENT_FIRING = false;
            }
            if(VIEWPORT_DIMENSION_CHANGE_EVENT_FIRING === false) {
                await sample();
                console.log('ResponsiveState: attachDispatchViewportDimensionsChangedEventListeners - dispatchEvent');
                const documentWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                const documentHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
                document.dispatchEvent(viewportDimensionsChangedEvent(documentWidth, documentHeight));
            }
        }, false);
    }



    function deviceTypeChangedEvent(deviceType: UtilityEnums.DeviceType ) {
        console.log('ResponsiveState: deviceTypeChangedEvent - '+ deviceType.toString());
        return new CustomEvent(CustomEventEnums.DeviceTypeChangeEvent, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                deviceType: deviceType
            }
        });
    }

    function deviceOrientationChangedEvent(deviceOrientation: UtilityEnums.DeviceOrientation ) {
        console.log('ResponsiveState: deviceOrientationChangedEvent - '+ deviceOrientation.toString());
        return new CustomEvent(CustomEventEnums.DeviceOrientationChangeEvent, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                deviceOrientation: deviceOrientation 
            }
        });
    }

    function viewportDimensionsChangedEvent(documentWidth:number, documentHeight:number) {
        console.log(`ResponsiveState: viewportDimensionsChangedEvent - documentWidth: ${documentWidth}, documentHeight ${documentHeight}`);
        return new CustomEvent(CustomEventEnums.ViewportDimensionsChangedEvent, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                documentWidth,
                documentHeight
            }
        });
    }
}