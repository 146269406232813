export enum CustomEventEnums {
    ZoomButtonPressed = 'gallery-zoom-button-pressed',
    CarouselGalleryMainDisplayAreaChanged = 'carousel-gallery-main-display-area-changed',
    DeviceTypeChangeEvent = 'device-type-change-event',
    DeviceOrientationChangeEvent = 'device-orientation-change-event',
    ViewportDimensionsChangedEvent = 'viewport-dimensions-changed-event',
    DocumentBodyScrollHeightChangedEvent = 'document-body-scroll-height-changed-event',
    SelectedCarouselCellSelected = 'selected-carousel-cell-selected',
    ProductSelectorChanged = 'product-selector-changed',
    AddProductStarted = 'add-product-started',
    AddProductCompleted = 'add-product-completed',
    ColourConfirmed = 'colour-confirmed',
    BasketLineDeleted = 'basket-line-deleted',
    BasketLineUpdated = 'basket-line-updated',
    BasketUpdateFailed = 'basket-update-failed',
    BasketAddRemoveCompleted = 'basket-add-remove-completed',
    ProductPriceUpdated = 'product-price-updated',
    ProductListingPageOptionSelected = "product-listing-page-option-selected",
    ProductListingPageSortChanged = "product-listing-page-sort-changed",
    ProductListingPageShowMore = 'product-listing-page-show-more',
    ProductListingPageHistoryBack = "product-listing-page-history-back",
    ProductListingPageHistoryShowMoreBack = "product-listing-page-history-show-more-back",
    ProductListingNoProductsReturned = "product-listing-page-no-products-returned",
    ProductListingPageHeaderQuickLink = "product-listing-page-header-quick-link",
    ButtonCarouselButtonClicked = 'button-carousel-button-clicked',
    ProductListingPageOptionDeselected = 'product-listing-page-option-deselected',
    FirstInteraction = 'first-interaction',
    FirstMovementInteraction = 'first-movement-interaction',
    OnScroll = 'on-scroll',
    ShowOverlay = 'show-overlay',
    HideOverlay = 'hide-overlay',
    CloseAllMenus = 'close-all-menus',
    NotificationPanelIsOpen = 'notification-panel-is-open',
    NotificationPanelIsClosed = 'notification-panel-is-closed',
    ShowLiveChat = 'show-live-chat',
    HideLiveChat = 'hide-live-chat',
    OpenLiveChat = 'open-live-chat',
    CloseLiveChat = 'close-live-chat',
    LiveChatLoadComplete = 'live-chat-load-complete'
}