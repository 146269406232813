
import { IProductGtmDataObject } from "../../shared/googleTagManager/gtmDataObject";
import { GoogleTagManager } from "../../shared/googleTagManager/gtmService";
import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AnalyticEnums } from "../../shared/utilities/enums/analyticsEnums";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { GoogleTagManagerEnums } from "../../shared/utilities/enums/googleTagManagerEnums";
import { SchemaDataEnums } from "../../shared/utilities/enums/schemaDataEnums";
import { UtilityEnums } from "../../shared/utilities/enums/utillityEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";

export interface IProductListingPageTile extends IInstanceComponentBase {
    id: string;
    url: string;
    name: string;
    price: string;
    brand: string;
    variant: string;
    category: string;
    position: number;
    impressionLogged: boolean;
    htmlElement: HTMLElement;
    updateImpressionLogged: (isLogged: boolean) => void;
    getProductGtmDataObject: () => IProductGtmDataObject;
}

export class ProductListingPageTile extends InstanceComponentBase implements IProductListingPageTile {
    private readonly _handleProductListingPageClickEvent;
    public id: string;
    public url: string;
    public name: string;
    public price: string;
    public brand: string;
    public variant: string;
    public category: string;
    public impressionLogged: boolean;

    constructor(public htmlElement: HTMLElement, public position: number) {
        super(htmlElement);
        this._handleProductListingPageClickEvent = this.handleProductListingPageClickEvent.bind(this);
        this.setId();
        this.setUrl();
        this.setName();
        this.setPrice();
        this.setBrand();
        this.setVariant();
        this.setCategory();
        this.setImpressionLogged();

        this.attachProductListingPageTileClickEventListener();
    }


    attachProductListingPageTileClickEventListener(): void {
        console.log('ProductListingPageTile: attachProductListingPageTileClickEventListener');
        this.htmlElement.addEventListener('click', this._handleProductListingPageClickEvent);
    }
    removeProductListingPageTileClickEventListener(): void {
        console.log('ProductListingPageTile: attachProductListingPageTileClickEventListener');
        this.htmlElement.removeEventListener('click', this._handleProductListingPageClickEvent);
    }

    setId(): void {
        try {
            let lineIdElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageTile.ProductListingPageTileLineId));
            this.id = lineIdElement.getAttribute(AttributeEnums.ProductListingPageTile.ProductListingPageTileLineId);
        } catch {
            console.log('ProductListingPageTile: setId Not Set');
            this.id = '';
        }
    }

    getItempropContentValue(itemListValue: SchemaDataEnums.ItemList) {
        try {
            let lineIdElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(SchemaDataEnums.ItemList.ItemProp, itemListValue));
            return lineIdElement.getAttribute(SchemaDataEnums.ItemList.Content);
        } catch {
            console.log('ProductListingPageTile: getItempropContentValue itemListValue Not Set');
            return '';
        }
    }

    setUrl(): void {
        try {
            let urlElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageTile.ProductListingPageTileUrl));
            this.url = urlElement.getAttribute(AttributeEnums.ProductListingPageTile.ProductListingPageTileUrl);
        } catch {
            console.log('ProductListingPageTile: setUrl Not Set');
            this.url = '';
        }
    }

    setName(): void {
        this.name = this.getItempropContentValue(SchemaDataEnums.ItemList.Name);
    }

    setPrice(): void {
        try {
            let priceElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageTile.ProductListingPageTilePrice));
            this.price = priceElement.getAttribute(AttributeEnums.ProductListingPageTile.ProductListingPageTilePrice);
        } catch {
            console.log('ProductListingPageTile: setPrice Not Set');
            this.price = '';
        }
    }

    setBrand(): void {
        this.brand = this.getItempropContentValue(SchemaDataEnums.ItemList.Brand);
    }

    setVariant(): void {
        this.variant = this.getItempropContentValue(SchemaDataEnums.ItemList.Size);
    }

    setCategory(): void {
        this.category = this.getItempropContentValue(SchemaDataEnums.ItemList.Category);
    }

    setImpressionLogged(): void {
        try {
            this.impressionLogged = (this.htmlElement.getAttribute(AnalyticEnums.InteractionState.ImpressionLogged) === 'true') ? true : false;
        } catch (error) {
            console.log('ProductListingPageTile: setImpressionLogged Not Set');
            this.impressionLogged = true;
        }
    }
    writeToImpressionLogged(isLogged: boolean): void {
        try {
            this.htmlElement.setAttribute(AnalyticEnums.InteractionState.ImpressionLogged, isLogged ? 'true' : 'false');
        } catch (error) {
            console.log('ProductListingPageTile: writeToImpressionLogged Not Set');
        }
    }

    updateImpressionLogged(isLogged: boolean): void {
        this.writeToImpressionLogged(isLogged);
        this.setImpressionLogged();
    }


    getList(): string {
        try {
            let plpElement = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPage.ProductListingPage));
            let headingElement = plpElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageHeader.ProductListingPageHeaderHeading)) as HTMLElement;
            let headingText = headingElement.innerText.replace(/,/g, '');
            return `${GoogleTagManagerEnums.ListSuffixes.ProductListingPage} - ${headingText}`;
        } catch {
            console.log('ProductListingPageTile: setList Not Set');
            return GoogleTagManagerEnums.ListSuffixes.ProductListingPage;
        }
    }

    handleProductListingPageClickEvent(): void {
        console.log('ProductListingPageTile: handleProductListingPageClickEvent');
        let productGtmDataObject: IProductGtmDataObject = this.getProductGtmDataObject();
        GoogleTagManager.sendProductGtmDataObjectAndNavigate(productGtmDataObject, this.url);
    }

    getProductGtmDataObject(): IProductGtmDataObject {
        let productGtmDataObject: IProductGtmDataObject = {
            id: this.id,
            url: this.url,
            name: this.name,
            price: this.price,
            brand: this.brand,
            variant: this.variant,
            category: this.category,
            position: this.position,
            list: this.getList()
        };
        return productGtmDataObject;
    }


    dispose(): void {
        this.removeProductListingPageTileClickEventListener();
    }

    delete(): void {

    }
}