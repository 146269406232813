import { InstanceComponentBase } from '../../shared/instanceComponentBase';
import { AttributeEnums } from '../../shared/utilities/enums/attributeEnums';
import { StringEnums } from '../../shared/utilities/enums/stringEnums';
import { HelperUtils } from '../../shared/utilities/helperUtils';

export interface IShowHide {
  htmlElement: Element;
  contentElements: NodeListOf<Element>;
  isHidden: boolean;
  value: string;
  dispose: () => void;
  delete: () => void;
}

export class ShowHide extends InstanceComponentBase implements IShowHide {

  private _minHeight: number;
  private _toggleElement: Element;
  private readonly _disableTransitionsSetHeight;
  private readonly _showHide;
  public isHidden: boolean;
  public value: string;
  constructor(public htmlElement: Element, public contentElements: NodeListOf<Element>) {
    super(htmlElement);
    this._toggleElement = htmlElement;
    this.contentElements = contentElements;
    this._minHeight = 0;

    this._disableTransitionsSetHeight = this.disableTransitionsSetHeight.bind(this);
    this.disableTransitionsSetHeight();

    this._showHide = this.showHide.bind(this);
    //----- Event Listener Bindings End -----//

    this.toggleText();
    //----- Initialise Event Listeners Start -----//
    this.attachResizeControllerEvent();
    this.attachClickEventListener();
    //----- Initialise Event Listeners End -----//
    this.setValue();
    this.setIsHidden();
  }

  set setMinHeight(minHeight: number) {
    console.log(`ShowHide: setMinHeight ${minHeight}`);
    this._minHeight = minHeight;
  }

  setHeight(contentElement: Element): void {
    //console.log("this need to happen after set min height");
    //console.log(`ShowHide: setHeight ${this._minHeight}`);
    if (contentElement.classList.contains(StringEnums.CssClass.LbHidden)) {
      contentElement.setAttribute("style", "max-height: " + this._minHeight + "px");
    } else {
      contentElement.setAttribute("style", "max-height: " + contentElement.scrollHeight + "px");
      this.zeroMaxHeight(contentElement);
    }
  }

  zeroMaxHeight(contentElement: Element): void {
    setTimeout(function () {
      contentElement.setAttribute("style", "max-height: initial");
    }, 500);
  }

  toggleText(): void {
    if ((this._toggleElement.innerHTML === "show") || (this._toggleElement.innerHTML === "hide")) {
      if (this._toggleElement.classList.contains(StringEnums.CssClass.LbHidden)) {
        this._toggleElement.innerHTML = "show";
      } else {
        this._toggleElement.innerHTML = "hide";
      }
    }

    if (this._toggleElement.getAttribute(AttributeEnums.ShowHide.HideToggleText)) {
      if (this._toggleElement.classList.contains(StringEnums.CssClass.LbHidden)) {
        this._toggleElement.innerHTML = this._toggleElement.getAttribute(AttributeEnums.ShowHide.ShowToggleText);
      } else {
        this._toggleElement.innerHTML = this._toggleElement.getAttribute(AttributeEnums.ShowHide.HideToggleText);
      }
    }
  }

  //------ Add Event Listeners Start -----//
  attachResizeControllerEvent(): void {
    window.addEventListener("resize", this._disableTransitionsSetHeight);
  }

  attachClickEventListener(): void {
    this._toggleElement.addEventListener('click', this._showHide);
  }
  //------ Add Event Listeners End -----//

  //------ Remove Event Listeners Start -----//
  removeResizeControllerEvent(): void {
    window.removeEventListener("resize", this._disableTransitionsSetHeight);
  }

  removeClickEventListener(): void {
    this._toggleElement.removeEventListener('click', this._showHide);
  }
  //------ Remove Event Listeners End -----//

  //------ Event Listener Methods Start -----//
  disableTransitionsSetHeight(): void {
    console.log('ShowHide: disableTransitionsSetHeight - initialise');

    for (let i = 0; i < this.contentElements.length; i++) {
      this.disableTransitions(<HTMLElement>this.contentElements[i]);
      this.setHeight(<HTMLElement>this.contentElements[i]);
    }
  }

  async showHide(): Promise<void> {
    console.log(`ShowHide: showHide - main loop`);
    this._toggleElement.classList.toggle(StringEnums.CssClass.LbHidden);
    for (let i = 0; i < this.contentElements.length; i++) {
      this.enableTransitions(<HTMLElement>this.contentElements[i]);
      this.toggleHiddenCss(<HTMLElement>this.contentElements[i]);
      this.resetMaxHeight(<HTMLElement>this.contentElements[i]);

      const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
        this.setHeight(<HTMLElement>this.contentElements[i]);
      }, 200);


      this.scrollToView(this._toggleElement);
    }
    this.toggleText();
    this.setIsHidden(); 
  }

  scrollToView(contentElement: Element): void {
    console.log(`ShowHide: scrollToView`);
    if (contentElement.classList.contains(StringEnums.CssClass.LbHidden)) {
      let readMoreToggleId = this._toggleElement.getAttribute(AttributeEnums.ReadMore.ReadMoreToggle);
      if (readMoreToggleId !== null) {
        let readMoreReturnElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ReadMore.ReadMoreReturnPosition, readMoreToggleId));
        if (readMoreReturnElement) {
          readMoreReturnElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }

  resetMaxHeight(contentElement: Element): void {
    console.log(`ShowHide: resetMaxHeight ${contentElement.scrollHeight}`);
    contentElement.setAttribute("style", "max-height: " + contentElement.scrollHeight + "px");
    console.log("--------------");
  }

  disableTransitions(contentElement: Element): void {
    contentElement.classList.add(StringEnums.CssClass.LbDisableCssTransitions);
  }

  enableTransitions(contentElement: Element): void {
    this._toggleElement.classList.remove(StringEnums.CssClass.LbDisableCssTransitions);
    contentElement.classList.remove(StringEnums.CssClass.LbDisableCssTransitions);
  }

  toggleHiddenCss(contentElement: Element): void {
    contentElement.classList.toggle(StringEnums.CssClass.LbHidden);
  }


  setIsHidden() {
    try{
    if(this.contentElements.length > 0){
    let hasHiddenClass = this.contentElements[0].classList.contains(StringEnums.CssClass.LbHidden);
    this.isHidden = hasHiddenClass;
    }
    } catch(error){
      console.log("ShowHide: setIsHidden Error:", error);
    }
  } 

  setValue() {
    try{
      this.value = this.htmlElement.getAttribute(AttributeEnums.ShowHide.ShowHideToggle);
    } catch(error){
      console.log("ShowHide: setValue Error:", error);
    }
  }
  //------ Event Listener Methods End -----//


  //------ Clean Up Processes Start -----//
  dispose(): void {
    this.removeResizeControllerEvent();
    this.removeClickEventListener();
    this.delete();
  }

  delete(): void {
    if ((<any>window)['sh' + this.htmlElement.getAttribute(AttributeEnums.ShowHide.ShowHideToggle) + 'ShowHide']) {
      console.log('ShowHide: Delete - Deleting ShowHide Reference');
      delete (<any>window)['sh' + this.htmlElement.getAttribute(AttributeEnums.ShowHide.ShowHideToggle) + 'ShowHide'];
    }
    else {
      console.log('ShowHide: Delete - ShowHide Reference Not Found');
    }
  }
  //------ Clean Up Processes End -----//
}