import { CookieConsent } from "./cookieConsent";
import { CookieUtils } from "./utilities/cookieUtils";
import { CookieEnums } from "./utilities/enums/cookieEnums";
import { OneTrustEnums } from "./utilities/enums/oneTrustEnums";
import { StringEnums } from "./utilities/enums/stringEnums";
import { GuidUtils } from "./utilities/guidUtils";
import { HelperUtils } from "./utilities/helperUtils";

export namespace LbCookies {

    export function getOrInitialiseBasketSession(basketSession?: string) {

        let sessionId: string | null = CookieUtils.getCookie(CookieEnums.BasketSessionId);
        if (!sessionId) {
            if (basketSession === undefined || basketSession.length === 0) {
                sessionId = GuidUtils.generateUUID().toUpperCase();
            } else {
                sessionId = basketSession;
            }
            CookieUtils.setCookie(CookieEnums.BasketSessionId, sessionId, 14);
        }

        return sessionId;
    }

    export function getOrInitialiseCookieConsent(): void {
        try {
            let consentStatus: string | null = CookieUtils.getCookie(CookieEnums.CookieConsent);
            if (consentStatus) {
                if (consentStatus === StringEnums.Cookie.ConsentEnable) {
                    CookieConsent.InitialiseCookieConsent();
                }
            } else {
                CookieUtils.setCookie(CookieEnums.CookieConsent, StringEnums.Cookie.ConsentEnable, 14);
                CookieConsent.InitialiseCookieConsent();
            }
        } catch {
            console.log('LbCookies: getOrInitialiseCookieConsent ERROR');
        }
    }

    export function setAffiliateClickRefCookie(targetingConsent: boolean): void {
        const urlParams = new URLSearchParams(window.location.search);
        try {
            console.log('LbCookies: setAffiliateClickRefCookie - ' + targetingConsent);
            if (targetingConsent) {
                let clickRefParam: string | null = urlParams.get(StringEnums.QueryStringParameters.AffiliateClickRef);

                if (clickRefParam != null) {
                    if (clickRefParam.length > 0) {
                        CookieUtils.setCookie(CookieEnums.AffiliateClickRef, clickRefParam, 30);
                    }
                }
            } else {
                CookieUtils.setCookie(CookieEnums.AffiliateClickRef, '', -1);
            }

        }
        catch {
            console.log('LbCookies: setAffiliateClickRefCookie ERROR');
        }
    }

    export function getAffiliateClickRefCookie(): string {
        try {
            let clickRefCookie: string | null = CookieUtils.getCookie(CookieEnums.AffiliateClickRef);
            let clickRef: string = '';

            if (clickRefCookie != null) {
                if (clickRefCookie.length > 0) {
                    clickRef = clickRefCookie;
                }
            }

            return clickRef;
        }
        catch {
            console.log('LbCookies: getAffiliateClickRefCookie ERROR');
            return "";
        }
    }
}