export interface IInstanceComponentBase {
    dispose:() => void;
}
export abstract class InstanceComponentBase implements IInstanceComponentBase{

    constructor(protected htmlElement: Element) {
        this.htmlElement = htmlElement;
    }

    getHtmlElement(): Element {
        return this.htmlElement;
    }

    abstract dispose(): void;

    //abstract delete(): void;
}