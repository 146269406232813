import { AjaxGetAndReplaceHtml } from "../../shared/ajaxGetAndReplaceHtml";
import { CookieUtils } from "../../shared/utilities/cookieUtils";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CookieEnums } from "../../shared/utilities/enums/cookieEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { Carousel, ICarousel } from "./carousel";

export interface ICarouselTileLoad extends ICarousel {

}

export class CarouselTileLoad extends Carousel implements ICarouselTileLoad {

    constructor(public htmlElement: Element) {
        super(htmlElement);
        this.getTiles();
    }

    async getTiles() {
        try {
            let tileElements: NodeListOf<Element> = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselTileLoad.Url));

            for (let currentElement = 0; currentElement < tileElements.length; currentElement += 1) {
                let tileElement: Element = tileElements[currentElement];
                let tileUrl: string = tileElement.getAttribute(AttributeEnums.CarouselTileLoad.Url);

                await AjaxGetAndReplaceHtml(tileElement, tileUrl);
              
                this.removeTileElementIfNoTileReturned(tileElement, tileUrl);
            }
            this.recallSetNextPreviousControls();
            this.setTileSize();
        } catch (error) {
            console.log('CarouselTileLoad: getTiles', error);
        }
    }

    removeTileElementIfNoTileReturned(tileElement: Element, tileUrl: string):void {
       try {
            if((tileElement as HTMLElement).innerHTML.length < 5){
                tileElement.remove();
                this.findAndRemoveLineSizeIdFromRecentlyViewedCookieAndUpdate(tileUrl);
            }
       } catch (error) {
            console.log('CarouselTileLoad: removeTileElementIfNoTileReturned', error);
       }
    }

    findAndRemoveLineSizeIdFromRecentlyViewedCookieAndUpdate(tileUrl: string): void {
        try {
            let lineSizeId = this.getLineSizeIdFromUrl(tileUrl);

            let recentlyViewedCookie: string = this.getRecentlyViewedCookie();

            if(recentlyViewedCookie !== ''){
                this.removeLineSizeIdAndUpdatedRecentlyViewedCookie(lineSizeId, recentlyViewedCookie);
            }
        }
        catch {
            console.log(`CarouselTileLoad: findAndRemoveLineSizeIdFromRecentlyViewedCookieAndUpdate ERROR`);
         }
    }

    removeLineSizeIdAndUpdatedRecentlyViewedCookie(lineSizeId: string, recentlyViewedCookie: string):void{
        let recentlyViewedIds: string [] = recentlyViewedCookie.split(',');

        recentlyViewedIds = recentlyViewedIds.filter(x => x !== lineSizeId); 

        let updatedRecentlyViewedIds: string = encodeURIComponent(recentlyViewedIds.toString());

        CookieUtils.setCookie(CookieEnums.RecentlyViewedIds, updatedRecentlyViewedIds, 1);
    }

    getRecentlyViewedCookie():string{
        let recentlyViewedCookie: string | null = CookieUtils.getCookie(CookieEnums.RecentlyViewedIds);

        if (recentlyViewedCookie != null) {
            if (recentlyViewedCookie.length > 0) {
                return recentlyViewedCookie;
            }
        }

        return '';
    }

    getLineSizeIdFromUrl(tileUrl:string):string {
        let lineIdSectionIndex: number = tileUrl.lastIndexOf('/');

        let lineSizeId: string = tileUrl.substring(lineIdSectionIndex + 1);

        if(!lineSizeId.includes('-')){
            lineSizeId = lineSizeId + '-0';
        }

        return lineSizeId;
    }


    dispose() {
        super.dispose();
    }
}