import { HttpRequest } from "../../shared/ajax";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { Carousel, ICarousel } from "./carousel";

export interface ICarouselInfiniteLoad extends ICarousel {

  //navigateCarouselToSelectedIndex: () => void;
}

export class CarouselInfiniteLoad extends Carousel implements ICarouselInfiniteLoad {
  private readonly _infiniteLoad;
  private page: number = this.getPageNumberFromUrl();
  private busy = false;
  private cellsLoadedPerUpdate = 12;

  constructor(public htmlElement: Element, private url: string, getCellsOnInitialise?: boolean) {
    super(htmlElement, getCellsOnInitialise);

    if (getCellsOnInitialise === undefined || getCellsOnInitialise === null || getCellsOnInitialise === true) {
      //this.loadCells();
      this.runLoadCells();
    } else {
      this.page++;
      this.slider.setAttribute('style', 'overflowX:scroll');
      this.setNextPreviousControls(0);
      this.busy = false;
    }

    //----- Event Listener Bindings Start -----//
    this._infiniteLoad = this.infiniteLoad.bind(this);
    //----- Event Listener Bindings End -----//

    //----- Initialise Event Listeners Start -----//
    this.attachLoadScrollEventListener();
    //----- Initialise Event Listeners End -----//

    this.setTileSize();
  }

  async runLoadCells() {
    await this.loadCells();
  }

  //------ Add Event Listeners Start -----//
  attachLoadScrollEventListener(): void {
    this.slider.addEventListener("scroll", this._infiniteLoad);
  }
  //------ Add Event Listeners End -----//


  //------ Remove Event Listeners Start -----//
  removeLoadScrollEventListener(): void {
    this.slider.removeEventListener("scroll", this._infiniteLoad);
  }
  //------ Remove Event Listeners End -----//

  async infiniteLoad() {
    //console.log('Carousel: infiniteLoad - Beginning');
    if (this.areMoreCellsNeeded) {
      await this.loadCells();
    }
  }

  get areMoreCellsNeeded() {
    if (this.slider.children[this.slider.children.length - 1].hasAttribute("data-last-tile")) {
      return false;
    } else {
      let sliderLeftBoundary = this.slider.getBoundingClientRect().left;
      let firstItemDisplayed = this.getIndexOfFirstElementDisplayed();
      if (firstItemDisplayed !== undefined) {
        let cellsFromEnd = this.slider.children.length - this.cellsLoadedPerUpdate;
        if (sliderLeftBoundary > this.getCellBoundaryLeft(cellsFromEnd + 1)) {
          return true;
        }
      } else {
        return false;
      }
    }
  }



  getPageNumberFromUrl(): number {
    let startingPageRefPoint: number = this.url.indexOf("page--") + "page--".length;
    let concludingUrl: string = this.url.substring(startingPageRefPoint);
    let endingPageRefPoint: number = concludingUrl.indexOf("--") + startingPageRefPoint;
    let pageValue: string = this.url.substring(startingPageRefPoint, endingPageRefPoint);

    return +pageValue;
  }

  getNewPageUrl(): string {
    let startingPageRefPoint: number = this.url.indexOf("page--") + "page--".length;
    let concludingUrl: string = this.url.substring(startingPageRefPoint);
    let endingPageRefPoint: number = concludingUrl.indexOf("--") + startingPageRefPoint;

    let newFrontString: string = this.url.substring(0, startingPageRefPoint);
    let newEndString: string = this.url.substring(endingPageRefPoint, this.url.length);
    let newFinalString: string = newFrontString + this.page + newEndString;

    return newFinalString;
  }

  getNewAjaxRoute(): string {
    let newPageUrl = this.getNewPageUrl();

    let tileName: string = this.htmlElement.getAttribute(AttributeEnums.Carousel.Tile);

    return newPageUrl + '/' + tileName;
  }

  async loadCells() {
    let route;

    route = this.getNewAjaxRoute();

    let self = this;

    function isBusy() {
      self.busy = true;
    }

    function isNotBusy() {
      self.page++;
      if (self.slider.children.length === 4) {
        self.slider.innerHTML = "";
        self.slider.setAttribute('style', 'overflowX:scroll');
        self.setNextPreviousControls(0);
      }
      self.busy = false;
    }
    function onFail() {
      self.hideContainer();
    }
    if (!self.busy) {
      let response = await HttpRequest.getBeforAfterFailed(route, isBusy, isNotBusy, onFail);

      self.slider.insertAdjacentHTML('beforeend', response as string);

      this.recallSetNextPreviousControls();
      this.setTileSize();
    }

  }



  dispose() {
    this.removeLoadScrollEventListener();
    super.dispose();
  }
}