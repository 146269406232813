export namespace InstantiationUtils {
  export async function importAndInstatiate(url: string, functionName: string) {
    let initualiseScript = await import(`../../${url}.ts`);
    initualiseScript[functionName]();
  }

  export function injectScript(src: string, parentElement?: HTMLElement, id?:string, runAsAsync?: boolean) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      if(id){
        script.id = id;
      }
      if(runAsAsync){
        script.async = true;
      }
      script.src = src;
      script.addEventListener('load', resolve);
      script.addEventListener('error', e => reject(e.error));
      if (parentElement) {
        parentElement.appendChild(script);
      } else {
        document.head.appendChild(script);
      }
    });
  }
}