import { IItemClickGtmDataObject } from "../../shared/googleTagManager/gtmDataObject";
import { GoogleTagManager } from "../../shared/googleTagManager/gtmService";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";

export interface IContentItem {
    id: string;
    url?: string;
    name: string;

    dispose: () => void;
}

export class ContentItem implements IContentItem {
    public id: string;
    public url?: string;
    public name: string;

    private readonly _handleClick;

    constructor(public htmlElement: HTMLElement) {
        this.setProperties();

        this._handleClick = this.handleClick.bind(this);

        this.attachClickEventListener();
    }

    setProperties(): void {
        this.id = this.htmlElement.getAttribute(AttributeEnums.ContentItem.ContentItemId);
        this.name = this.htmlElement.getAttribute(AttributeEnums.ContentItem.ContentItemName);
        this.url = this.htmlElement.getAttribute(AttributeEnums.ContentItem.ContentItemUrl);
    }

    attachClickEventListener(): void {
        this.htmlElement.addEventListener('click', this._handleClick);
    }
    removeClickEventListener(): void {
        this.htmlElement.removeEventListener('click', this._handleClick);
    }
    handleClick(): void {
        try {
            let dataObject = {
                id: this.id,
                name: this.name,
                url: this.url
            } as IItemClickGtmDataObject;

            if (this.url) {
                GoogleTagManager.sendItemClickGtmDataObjectAndNavigate(dataObject, this.url);
            } else {
                GoogleTagManager.sendItemClickGtmDataObject(dataObject);
            }

        } catch (error) {
            if (this.url) {
                document.location = this.url;
            }
        }
    }

    dispose(): void {
        try {
            this.removeClickEventListener();
        } catch (error) {

        }
    }

}