import { BaseMediaTile, IBaseMediaTile } from "./baseMediaTile";

export interface IImageMediaTile extends IBaseMediaTile {

}

export class ImageMediaTile extends BaseMediaTile implements IImageMediaTile {
    
    constructor(public htmlElement: Element) {

        super(htmlElement);
    }

    dispose(): void {
        
    }
    
}