import { Overlay } from './overlay';
import { StringEnums } from './utilities/enums/stringEnums';
import { HelperUtils } from './utilities/helperUtils';

export function initialiseOverlay() {

    let doesObjectExistOnWindow = () => { return typeof (<any>window).overlay }
    if (doesObjectExistOnWindow()) {

        let htmlElement = document.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbOverlay));
        createOverlay(htmlElement);

        function createOverlay(htmlElement: Element) {
            let overlay = new Overlay(htmlElement);
            (<any>window).overlay = overlay;
        }
    }
}