export namespace UtilityEnums {
    export enum DeviceOrientation {
        Landscape = 'landscape',
        Portrait = 'potrait'
    }
    export enum DeviceType {
        Mobile = 'mobile',
        Tablet = 'tablet',
        Desktop = 'desktop'
    }
    export enum ComponentOrientation {
        Vertical = 'vertical',
        Horizontal = 'horizontal'
    }
    export enum ProductTypeValue {
        Product = 'product',
        BasketPopOut = 'basketPopOut',
        Basket = 'basket'
    }
    //Product = 'Product' - is prefixed by data-product-parent value
    export enum WindowObjectName {
        Product = 'Product',
        BasketPopOut = 'basketPopOut',
        Basket = 'basket'
    }

    export enum SingleSelectType {
        Size = 'size',
        Category = 'category',
        Delivery = 'delivery',
        Campaign = 'campaign',
        Price = 'price',
        Sort = 'sort',
        Page = 'page'
    }

    export enum ProductCarouselNames {
        ProductCarouselOne = 'productCarouselOne',
        ProductCarouselTwo = 'productCarouselTwo',
        ProductCarouselThree = 'productCarouselThree',
        ProductCarouselFour = 'productCarouselFour',
        ProductCarouselFive = 'productCarouselFive',
        ProductCarouselSix = 'productCarouselSix',
        ProductCarouselSeven = 'productCarouselSeven'
    }

    export enum AnchorIds {
        CategoryCarousel = 'category-carousel'
    }

    export enum HistoryStateType {
        PopOut = 'history-state-pop-out',
        ProductListingPageUpdate = 'product-listing-page-update',
        BasketUpdate = 'basket-update',
        Other = ''
    }

    export enum YouTubeImageSizes {
        Default = 'Default',
        Medium = 'Medium',
        High = 'High',
        Standard = 'Standard',
        MaxRes = 'MaxRes'
    }
    export enum YouTubeImageFileNames {
        Default = 'default.jpg',
        Medium = 'mqdefault.jpg',
        High = 'hqdefault.jpg',
        Standard = 'sddefault.jpg',
        MaxRes = 'maxresdefault.jpg'
    }
    export enum YouTubeImageWidths {
        Default = 120,
        Medium = 320,
        High = 480,
        Standard = 640,
        MaxRes = 1280
    }
    export enum YouTubeImageHeights {
        Default = 90,
        Medium = 180,
        High = 360,
        Standard = 480,
        MaxRes = 720
    }

    export enum NotificationButtonTypes{
        Chat = 'chat',
        Phone = 'phone'
    }
}