import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { ButtonCarousel, IButtonCarousel } from "../buttonCarousel/buttonCarousel";
import { IProductListingPageOption } from "./productListingPageOption";

export interface IProductListingPageSelectedOptionsCarousel extends IButtonCarousel {
    
}

export class ProductListingPageSelectedOptionsCarousel extends ButtonCarousel implements IProductListingPageSelectedOptionsCarousel {
    private readonly _handleButtonCarouselButtonClicked;
    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);
        this._handleButtonCarouselButtonClicked = this.handleButtonCarouselButtonClicked.bind(this);
        this.attachButtonCarouselButtonClickedEventListener();
    }
    attachButtonCarouselButtonClickedEventListener(): void {
        console.log('ButtonCarouselButton: attachButtonCarouselButtonClickedEventListener');
        this.htmlElement.addEventListener(CustomEventEnums.ButtonCarouselButtonClicked, this._handleButtonCarouselButtonClicked);
    }

    removeButtonCarouselButtonClickedEventListener(): void {
        console.log('ButtonCarouselButton: removeButtonCarouselButtonClickedEventListener');
        this.htmlElement.removeEventListener(CustomEventEnums.ButtonCarouselButtonClicked, this._handleButtonCarouselButtonClicked);
    }

    handleButtonCarouselButtonClicked(e: CustomEvent) {
        console.log('ButtonCarouselButton: handleButtonCarouselButtonClicked');
        let querySelectorSegment: string = e.detail.value as string; 
        let type: string = this.getQuerySelectorSegmentToType(querySelectorSegment);
        let value: string = this.getQuerySelectorSegmentToValue(querySelectorSegment);
        this.htmlElement.dispatchEvent(this.productListingPageOptionDeselectedCustomEvent(type, value));
    }

    getQuerySelectorSegmentToType(querySelectorSegment: string) {
        return querySelectorSegment.split('--')[0];
    }

    getQuerySelectorSegmentToValue(querySelectorSegment: string) {
        return querySelectorSegment.split('--')[1];
    }

    public static replaceCarouselOptions(selectedOptionsCarouselElement: HTMLElement, options: IProductListingPageOption[]){
        selectedOptionsCarouselElement.innerHTML = ProductListingPageSelectedOptionsCarousel.createButtonHtml(options);
    }

    public static createButtonHtml(options: IProductListingPageOption[]) : string{
        let buttonHtml :string = '';
        options.forEach((option: IProductListingPageOption) => {
            let value = option.optionType + '--' + option.optionValue;
            buttonHtml += `<button class="lb_button lb_button-filter" data-button-carousel-button="true" data-button-carousel-button-value="${value}">${option.optionTitle}</button>`;
        });
        return buttonHtml;
    }

    productListingPageOptionDeselectedCustomEvent(type: string, value: string) {
        console.log(`ProductListingPageSelectedOptionsCarousel: productListingPageOptionDeselectedCustomEvent type: ${type}, value: ${value}`);
        return new CustomEvent(CustomEventEnums.ProductListingPageOptionDeselected, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                type: type,
                value: value
            }
        });
    }

    dispose(): void {
        this.removeButtonCarouselButtonClickedEventListener();
    }
    
    delete(): void {

    }
}