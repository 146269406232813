export namespace LoadingTemplates {

    export const productLoadingTemplate = `
    <div class="lb_popOutWrapper lb_spinnyWheel">
    </div>
    `;

    export const productAddingToBasketTemplate = `
    <div class="lb_popOutWrapper lb_spinnyWheel">
    </div>
    `;

}

