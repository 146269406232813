import { HttpRequest } from "../../shared/ajax";
import { AjaxGetAndReplaceHtml } from "../../shared/ajaxGetAndReplaceHtml";
import { IProductGtmDataObject } from "../../shared/googleTagManager/gtmDataObject";
import { GoogleTagManager } from "../../shared/googleTagManager/gtmService";
import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { UrlEnums } from "../../shared/utilities/enums/urlEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { OnScreenUtils } from "../../shared/utilities/onScreenUtils";
import { IProductListingPageTile, ProductListingPageTile } from "./productListingPageTile";
const urlPaths = require('../../config/urlPaths.json');
export interface IProductListingPageTiles extends IInstanceComponentBase {
    updateTiles: (querySelectorString: string) => Promise<void>;
    updateTilesUnpaged: (querySelectorString: string) => Promise<void>;
    addTilesPage: (querySelectorString: string) => void;
    countDisplayedPageTiles: () => number;
    displayProductListingTilesLoadingTiles: () => void;
    productListingPageTileList: IProductListingPageTile[];
}

export class ProductListingPageTiles extends InstanceComponentBase implements IProductListingPageTiles {
    public productListingPageTileList: IProductListingPageTile[];
    private readonly _handleOnScroll;

    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);
        this._handleOnScroll = this.handleOnScroll.bind(this);
        this.instantiateProductListingPageTiles();
        this.updateGTMImpressions();
        this.attachCustomOnScrollEventListener();
    }

    attachCustomOnScrollEventListener(): void {
        (<any>window).addEventListener(CustomEventEnums.OnScroll, this._handleOnScroll);
    }
    removeCustomOnScrollEventListener(): void {
        (<any>window).removeEventListener(CustomEventEnums.OnScroll, this._handleOnScroll);
    }
    handleOnScroll(): void {
        console.log('ProductListingPageTiles: handleOnScroll');
        this.updateGTMImpressions();
    }

    getAllUnLoggedTile(): Array<IProductListingPageTile> {
        return this.productListingPageTileList.filter((productListingPageTile: IProductListingPageTile) => productListingPageTile.impressionLogged === false);
    }

    updateGTMImpressions(): void {
        try {
            let tilesToLog = new Array<IProductListingPageTile>();
            let unloggedTiles = this.getAllUnLoggedTile();
            unloggedTiles.forEach((productListingPageTile: IProductListingPageTile) => {
                if (OnScreenUtils.isVerticallyOnScreen(productListingPageTile.htmlElement)) {
                    tilesToLog.push(productListingPageTile);
                }
            });

            let productGtmDataObjectList = new Array<IProductGtmDataObject>();
            tilesToLog.forEach((productListingPageTile: IProductListingPageTile) => {
                productGtmDataObjectList.push(productListingPageTile.getProductGtmDataObject());
            });

            if (productGtmDataObjectList.length > 0) {
                let impressionsLogged: boolean = GoogleTagManager.sendProductTileImpressionsGtmDataObject(productGtmDataObjectList);

                if (impressionsLogged) {
                    tilesToLog.forEach((productListingPageTile: IProductListingPageTile) => {
                        productListingPageTile.updateImpressionLogged(true);
                    });
                }
            }
        } catch (error) {
            console.log('ProductListingPageTiles: updateGTMImpressions ERROR', error);
        }
    }


    instantiateProductListingPageTiles(): void {
        try {
            console.log('ProductListingPageTiles: instantiateProductListingPageTiles - started');
            let productListingPageTileList = new Array<IProductListingPageTile>();
            let productListingPageTileElements: NodeListOf<HTMLElement> = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageTile.ProductListingPageTile));

            for (let i = 0; i < productListingPageTileElements.length; i += 1) {
                let currentProductListingTile = new ProductListingPageTile(productListingPageTileElements[i], i + 1);

                productListingPageTileList.push(currentProductListingTile);
            }

            this.productListingPageTileList = productListingPageTileList;
        } catch (error) {
            console.log('ProductListingPageTiles: instantiateProductListingPageTiles ERROR: ', error);
        }
    }

    async updateTiles(querySelectorString: string) {
        try {
            let url: string = urlPaths.websiteUrl + `${UrlEnums.PartialUrls.ProductListingPageTiles}${querySelectorString}`;
            await AjaxGetAndReplaceHtml(this.htmlElement, url);

            let productListingPageTileElements: NodeListOf<HTMLElement> = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageTile.ProductListingPageTile));

            if (productListingPageTileElements.length === 0) {           
                this.htmlElement.dispatchEvent(this.productListingNoProductsReturnedCustomEvent(querySelectorString));
            }
        } catch (error) {
            console.log('ProductListingPageTiles: updateTiles ERROR getting tiles', error);
        }
    }

    async updateTilesUnpaged(querySelectorString: string) {
        try {
            let url: string = urlPaths.websiteUrl + `${UrlEnums.PartialUrls.ProductListingPageTilesUnpaged}${querySelectorString}`;
            await AjaxGetAndReplaceHtml(this.htmlElement, url);

            let productListingPageTileElements: NodeListOf<HTMLElement> = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageTile.ProductListingPageTile));

            if (productListingPageTileElements.length === 0) {
                this.htmlElement.dispatchEvent(this.productListingNoProductsReturnedCustomEvent(querySelectorString));
            }
        } catch (error) {
            console.log('ProductListingPageTiles: updateTilesUnpaged ERROR getting tiles', error);
        }
    }

    async addTilesPage(querySelectorString: string) {
        let url: string = urlPaths.websiteUrl + `${UrlEnums.PartialUrls.ProductListingPageTiles}${querySelectorString}`;
        let response = await HttpRequest.get(url);
        this.htmlElement.insertAdjacentHTML('beforeend', response);
        this.reinitialiseTileList();
        this.updateGTMImpressions();
    }

    countDisplayedPageTiles() {
        let tileElements = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageTile.ProductListingPageTile));
        if (tileElements === null || tileElements === undefined) {
            return 0;
        } else {
            return tileElements.length;
        }
    }

    displayProductListingTilesLoadingTiles() {
        let tileCount = this.countDisplayedPageTiles();
        let loadingTilesHtmlString: string = '';
        for (let i = 0; i < tileCount; i += 1) {
            loadingTilesHtmlString += '<a href="/product/url/prevent/default" class="lb_productTile lb-loadingTile"></a>';
        }

        this.htmlElement.innerHTML = loadingTilesHtmlString;
    }

    reinitialiseTileList(): void {
        try {
            this.productListingPageTileList.forEach((productListingPageTile: IProductListingPageTile) => {
                productListingPageTile.dispose();
            });
        } catch (error) {
            console.log('reinitialiseTileList: failed to dispose ERROR: ', error);
        }
        this.productListingPageTileList = null;
        this.instantiateProductListingPageTiles();
    }
    productListingNoProductsReturnedCustomEvent(querySelectorString: string) {
        console.log(`ProductListingPageShowMore: productListingNoProductsReturnedCustomEvent`);
        return new CustomEvent(CustomEventEnums.ProductListingNoProductsReturned, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                querySelectorString: querySelectorString
            }
        });
    }
    dispose(): void {
        try {
            this.removeCustomOnScrollEventListener();
            this.productListingPageTileList.forEach((productListingPageTile: IProductListingPageTile) => {
                productListingPageTile.dispose();
            });
        } catch (error) {
            console.log('ProductListingPageTiles: failed to dispose ERROR: ', error);
        }
    }

    delete(): void {

    }
}