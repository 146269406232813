import { ResponsiveState } from "./responsiveState";
import { AttributeEnums } from "./utilities/enums/attributeEnums";
import { CustomEventEnums } from "./utilities/enums/customEventEnums";
import { StringEnums } from "./utilities/enums/stringEnums";
import { HelperUtils } from "./utilities/helperUtils";

export class Overlay {
  constructor(public htmlElement: Element) { }
  _scrollPosition: number;

  showOverlay() {
    console.log('Overlay: showOverlay');
    this._scrollPosition = window.scrollY;
    if (this.htmlElement.getAttribute(AttributeEnums.Overlay.OverlayHidden) === 'true') {

      this.htmlElement.setAttribute(AttributeEnums.Overlay.OverlayHidden, "false");
      let scrolbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.setAttribute("style", "padding-right:" + scrolbarWidth + "px");
      document.body.classList.add(StringEnums.CssClass.LbNoScroll);
      this.htmlElement.classList.remove(StringEnums.CssClass.LbHidden);
      (<any>window).dispatchEvent(this.showOverlayCustomEvent());
    }
  }

  hideOverlay() {
    console.log('Overlay: hideOverlay');
    if (this.htmlElement.getAttribute(AttributeEnums.Overlay.OverlayHidden) === 'false') {
      this.htmlElement.setAttribute(AttributeEnums.Overlay.OverlayHidden, "true");
      document.body.setAttribute("style", "padding-right:0");
      document.body.classList.remove(StringEnums.CssClass.LbNoScroll);
      this.htmlElement.classList.add(StringEnums.CssClass.LbHidden);
      if ((ResponsiveState.getState() === StringEnums.Selectors.Mobile) || (ResponsiveState.getState() === StringEnums.Selectors.Tablet)) {
        console.log('Overlay: Scroll back to position');
        window.scrollTo(0, this._scrollPosition);
      }
      (<any>window).dispatchEvent(this.hideOverlayCustomEvent());
    }
  }

    showOverlayCustomEvent() {
      console.log(`Overlay: showOverlayCustomEvent`);
      return new CustomEvent(CustomEventEnums.ShowOverlay, {
          bubbles: true,
          cancelable: true,
          composed: false,
          detail: {
              
          }
      });
  }
  hideOverlayCustomEvent() {
    console.log(`Overlay: hideOverlayCustomEvent`);
    return new CustomEvent(CustomEventEnums.HideOverlay, {
        bubbles: true,
        cancelable: true,
        composed: false,
        detail: {
           
        }
    });
  }
}