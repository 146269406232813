import { CustomEventEnums } from "./utilities/enums/customEventEnums";

export interface IScrollService {

}

export class ScrollService implements IScrollService {
    private readonly _handleScroll;

    constructor() {
        this._handleScroll = this.handleScroll.bind(this);
        this.attachScrollEventListener();
    }

    attachScrollEventListener(): void {
        (<any>window).addEventListener('scroll', this._handleScroll)
    }

    removeScrollEventListener(): void {
        (<any>window).removeEventListener('scroll', this._handleScroll)
    }

    handleScroll(event: Event): void {
        let windowInnerWidth = window.innerWidth;
        let windowInnerHeight = window.innerHeight;
        let windowScrollX = window.scrollX;
        let windowScrollY = window.scrollY;
        (<any>window).dispatchEvent(this.onScrollCustomEvent(windowInnerWidth, windowInnerHeight, windowScrollX, windowScrollY));
    }

    onScrollCustomEvent(windowInnerWidth: number, windowInnerHeight: number, windowScrollX: number, windowScrollY: number) {
        //console.log(`ScrollService: scrollCustomEvent windowInnerWidth: ${windowInnerWidth}  windowInnerHeight ${windowInnerHeight}  windowScrollX: ${windowScrollX}  windowScrollY ${windowScrollY}`);
        return new CustomEvent(CustomEventEnums.OnScroll, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                windowInnerWidth: windowInnerWidth,
                windowInnerHeight: windowInnerHeight,
                windowScrollX: windowScrollX,
                windowScrollY: windowScrollY
            }
        });
    }

    dispose(): void {
        this.removeScrollEventListener();
    }

    public static initialise(): void {
        (<any>window).scrollService = new ScrollService();
    }
}