import { IInstanceComponentBase, InstanceComponentBase } from "./instanceComponentBase";
import { IMegaMenuItem, MegaMenuItem } from "./megaMenuItem";
import { AttributeEnums } from "./utilities/enums/attributeEnums";
import { HelperUtils } from "./utilities/helperUtils";

export interface IMegaMenuDeviceBase extends IInstanceComponentBase {
    megaMenuItemList: IMegaMenuItem[];
}

export abstract class MegaMenuDeviceBase extends InstanceComponentBase implements IMegaMenuDeviceBase {

    public megaMenuItemList: IMegaMenuItem[];
    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);

        this.megaMenuItemListFactory();
    }

    megaMenuItemListFactory(): void {
        try {
            let megaMenuItemElements: NodeListOf<HTMLElement> = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.MegaMenuGtm.GtmMegaMenuName));
            this.megaMenuItemList = new Array<IMegaMenuItem>();

            for (let i = 0; i < megaMenuItemElements.length; i += 1) {
                let megaMenuItemElement: HTMLElement = megaMenuItemElements[i];
                let megaMenuItem: IMegaMenuItem = new MegaMenuItem(megaMenuItemElement);
                this.megaMenuItemList.push(megaMenuItem);
            }
        } catch (error) {
            console.log('MegaMenuDeviceBase: megaMenuItemListFactory ERROR', error);
        }

    }
    dispose(): void {

        try {
            this.megaMenuItemList.forEach((megaMenuItem: IMegaMenuItem) => megaMenuItem.dispose());
        } catch (error) {
            console.log('MegaMenuDeviceBase: dispose ERROR', error);
        }
    }
}