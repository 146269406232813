
import { HttpRequest } from "./ajax";

export async function AjaxGetAndReplaceHtml(htmlObj: any, url: string) {
    let route;

    route = url;

    let response = await HttpRequest.get(route);

    htmlObj.innerHTML = response;
}
export async function AjaxGetAndReplaceHtmlAndOuterElement(htmlObj: HTMLElement, url: string) {
    console.log('AjaxGetAndReplaceHtmlAndOuterElement - url ', url);
    console.log('AjaxGetAndReplaceHtmlAndOuterElement - htmlObj ', htmlObj);
    console.log('AjaxGetAndReplaceHtmlAndOuterElement - htmlObj outHTML ', htmlObj.outerHTML);
    
    let route;

    route = url;

    let response = await HttpRequest.get(route);

    if (typeof response === 'string') {
        console.log('AjaxGetAndReplaceHtmlAndOuterElement - response ', response);
        htmlObj.outerHTML = response;
    } 
}
// export async function AjaxGetAndAppendChildHtml(htmlObj: any, url: string) {
//     let route;

//     route = url;

//     let response = await HttpRequest.get(route);
//     console.log(`AjaxGetAndAppendChildHtml -  response type  = ${typeof response}`);
//     if(typeof response === 'string'){
//         var parser = new DOMParser();
//         var doc = parser.parseFromString(response, "text/xml");
//         htmlObj.appendChild(doc.documentElement);
//     }
   
// }