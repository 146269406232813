import { HttpRequest } from '../../shared/ajax';
import { IInstanceComponentBase, InstanceComponentBase } from '../../shared/instanceComponentBase';
import { ResponsiveState } from '../../shared/responsiveState';
import { AttributeEnums } from '../../shared/utilities/enums/attributeEnums';
import { CustomEventEnums } from '../../shared/utilities/enums/customEventEnums';
import { StringEnums } from '../../shared/utilities/enums/stringEnums';
import { HelperUtils } from '../../shared/utilities/helperUtils';

export interface ICarousel extends IInstanceComponentBase {

}

export class Carousel extends InstanceComponentBase implements ICarousel {

  private lastResponsiveState: string;
  private oldFirstCellOnDisplay: number;
  private timer = -1;
  protected slider: Element = this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselCells));
  private _cellsPerPage: number = parseInt(this.htmlElement.getAttribute(AttributeEnums.Carousel.CellsPerPage));
  private readonly _nextMoveSliderToPosition;
  private readonly _previousMoveSliderToPosition;
  private readonly _sliderScrolling;
  private readonly _pageResize;
  private readonly _setTileSize;

  constructor(htmlElement: Element, getCellsOnInitialise?: boolean) {
    super(htmlElement);
    this.lastResponsiveState = ResponsiveState.getState();

    //----- Event Listener Bindings Start -----//
    this._nextMoveSliderToPosition = this.nextMoveSliderToPosition.bind(this);
    this._previousMoveSliderToPosition = this.previousMoveSliderToPosition.bind(this);
    this._setTileSize = this.setTileSize.bind(this);
    this._sliderScrolling = this.sliderScrolling.bind(this);
    this._pageResize = this.pageResize.bind(this);
    //----- Event Listener Bindings End -----//

    //----- Initialise Event Listeners Start -----//
    this.attachNextClickEventListener();
    this.attachPreviousClickEventListener();
    this.attachScrollingScrollEventListener();
    this.attachResizeEventListener();
    this.attachViewportDimensionsChangedEventListener();
    //----- Initialise Event Listeners End -----//
    this.recallSetNextPreviousControls();
    this.setTileSize();
  }


  //------ Add Event Listeners Start -----//
  attachNextClickEventListener(): void {
    this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselNext)).addEventListener('click', this._nextMoveSliderToPosition);
  }

  attachPreviousClickEventListener(): void {
    this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselPrevious)).addEventListener('click', this._previousMoveSliderToPosition);
  }

  attachScrollingScrollEventListener(): void {
    this.slider.addEventListener("scroll", this._sliderScrolling);
  }

  attachResizeEventListener(): void {
    window.addEventListener("resize", this._pageResize);
  }

  attachViewportDimensionsChangedEventListener(): void {
    console.log('Carousel: attachViewportDimensionsChangedEventListener');
    document.addEventListener(CustomEventEnums.ViewportDimensionsChangedEvent, this._setTileSize);
  }
  //------ Add Event Listeners End -----//

  //------ Remove Event Listeners Start -----//
  removeNextClickEventListener(): void {
    this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselNext)).removeEventListener('click', this._nextMoveSliderToPosition);
  }

  removePreviousClickEventListener(): void {
    this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselPrevious)).removeEventListener('click', this._previousMoveSliderToPosition);
  }

  removeScrollingScrollEventListener(): void {
    this.slider.removeEventListener("scroll", this._sliderScrolling);
  }

  removeResizeEventListener(): void {
    window.removeEventListener("resize", this._pageResize);
  }
  //------ Remove Event Listeners End -----//

  //------ Event Listener Methods Start -----//
  nextMoveSliderToPosition(): void {
    //console.log('Carousel: nextMoveSliderToPosition - Beginning');
    let newPosition: number | undefined = this.getIndexOfFirstElementDisplayed();
    if (newPosition !== undefined) {
      this.moveSliderToPosition(newPosition + this._cellsPerPage);
    } else {
      console.log("Error");
    }
  }
  previousMoveSliderToPosition(): void {
    //console.log('Carousel: previousMoveSliderToPosition - Beginning');
    let newPosition: number | undefined = this.getIndexOfFirstElementDisplayed();
    if (newPosition !== undefined) {
      this.moveSliderToPosition(newPosition - this._cellsPerPage);
    } else {
      console.log("Error");
    }
  }

  sliderScrolling() {
    //console.log('Carousel: sliderScrolling - Beginning');
    if (this.timer !== -1) {
      clearTimeout(this.timer);
    }
    let newPosition: number | undefined = this.getIndexOfFirstElementDisplayed();
    let self = this;
    if (this.isCarouselAtEnd()) {
      self.timer = window.setTimeout(function () {

        self.moveSliderToPosition(newPosition);

      }, 250);
    }
  }

  pageResize() {
    if ((ResponsiveState.getState() !== this.lastResponsiveState) && (this.oldFirstCellOnDisplay)) {
      this.lastResponsiveState = ResponsiveState.getState();
      this.moveSliderToPosition(this.oldFirstCellOnDisplay);
    }
    this.oldFirstCellOnDisplay = this.getIndexOfFirstElementDisplayed();
  }

  moveSliderToPosition(cell: number) {
    if (cell < 0) { 
      cell = 0; 
    }
    if (cell > this.slider.children.length) { 
      cell = this.slider.children.length; 
    }
    let sliderLeftBoundary = this.slider.getBoundingClientRect().left;
    let cellLeftBoundary = this.getCellBoundaryLeft(cell) + this.slider.scrollLeft;
    let sliderTranslatePosition = cellLeftBoundary - sliderLeftBoundary;
    //this.slider.scrollLeft = Math.floor(sliderTranslatePosition);
    this.slider.scrollTo({
      left: Math.floor(sliderTranslatePosition),
      behavior: "smooth"
    });
    this.setNextPreviousControls(cell);

  }

  isCarouselAtEnd() {
    let cells = this.slider.children.length;
    let TileRight = this.slider.children[cells - 1].getBoundingClientRect().right;
    let sliderRight = this.slider.getBoundingClientRect().right;
    let lastTileWidth = this.slider.children[cells - 1].getBoundingClientRect().width * .5;
    if (TileRight >= sliderRight + lastTileWidth) {
      return true;
    }
    return false;
  }

  //------ Event Listener Methods End -----//

  setTileSize() {
    let cells = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.Carousel.Cells));
    if (this.lastResponsiveState === StringEnums.Selectors.Desktop) {
      for (let i = 0; i < cells.length; i++) {
        cells[i].setAttribute("style", "width:" + 100 / this._cellsPerPage + "%");
      }
    } else {
      for (let i = 0; i < cells.length; i++) {
        cells[i].removeAttribute("style");
      }
    }

  }

  setNextPreviousControls(cellCount: number) {
    if (ResponsiveState.getState() === StringEnums.Selectors.Desktop) {
      if (this.slider.children.length <= this._cellsPerPage) {
        this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselPrevious)).setAttribute("style", "display: none");
        this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselNext)).setAttribute("style", "display: none");
      } else if (cellCount <= 0) {
        this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselPrevious)).setAttribute("style", "display: none");
        this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselNext)).setAttribute("style", "display: block");
      } else if (cellCount + this._cellsPerPage >= this.slider.children.length) {
        this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselPrevious)).setAttribute("style", "display: block");
        this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselNext)).setAttribute("style", "display: none");
      }
      else {
        this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselPrevious)).setAttribute("style", "display: block");
        this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselNext)).setAttribute("style", "display: block");
      }
    } else {
      this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselPrevious)).setAttribute("style", "display: none");
      this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselNext)).setAttribute("style", "display: none");
    }
  }

  getIndexOfFirstElementDisplayed() {
    let sliderLeftBoundary = this.slider.getBoundingClientRect().left;
    let cells = this.slider.querySelectorAll(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselCell));
    for (let i = 0; i < cells.length; i++) {
      let cellLeftBoundary = cells[i].getBoundingClientRect().left;
      let offset = cells[i].getBoundingClientRect().width * .5;
      if (cellLeftBoundary >= sliderLeftBoundary - offset) {
        return i;
      }
    }
  }

  getCellBoundaryLeft(cell: number) {
    return this.slider.querySelectorAll(HelperUtils.addCssClassDot(StringEnums.CssClass.LbCarouselCell))[cell].getBoundingClientRect().left;
  }

  recallSetNextPreviousControls(): void {
    let newPosition: number | undefined = this.getIndexOfFirstElementDisplayed();
    let cell = newPosition;
    this.setNextPreviousControls(cell);
  }

  hideContainer() {
    let containerElement: HTMLElement = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Carousel.Container));
    if (containerElement) {
      containerElement.setAttribute("style", "display: none");
    }
  }

  showContainer() {
    let containerElement: HTMLElement = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Carousel.Container));
    if (containerElement) {
      containerElement.setAttribute("style", "display: block");
    }
  }
  //------ Custom Event Creation Start -----//
  //------ Custom Event Creation End -----//

  //------ Clean Up Processes Start -----//
  dispose(): void {
    this.removeNextClickEventListener();
    this.removePreviousClickEventListener();
    //this.removeLoadScrollEventListener();
    this.removeScrollingScrollEventListener();
    this.removeResizeEventListener();
    this.delete();
  }

  delete(): void {
    if ((<any>window)[this.htmlElement.getAttribute(AttributeEnums.Carousel.Carousel) + 'Carousel']) {
      console.log('CarouselController: Delete - Deleting Carousel Reference');
      delete (<any>window)[this.htmlElement.getAttribute(AttributeEnums.Carousel.Carousel) + 'Carousel'];
    }
    else {
      console.log('CarouselController: Delete - Carousel Reference Not Found');
    }
  }
  //------ Clean Up Processes End -----//
}