import { IAddToRemoveFromCartGtmDataObject } from "../../shared/googleTagManager/gtmDataObject";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";

export interface ICarouselGtm {
    id: number;
    name: string;
    price: number;
    brand: string;
    variant: string;
    category: string;
    url: string;
    groupSku: string;
    sku: string;
}

export class CarouselGtm implements ICarouselGtm {
    public id: number;
    public name: string;
    public price: number;
    public brand: string;
    public variant: string;
    public category: string;
    public url: string;
    public groupSku: string;
    public sku: string;

    public static getCarouselGtmFromCarouselGtmElement(carouselGtmElement: HTMLElement): ICarouselGtm {
        let id = +carouselGtmElement.getAttribute(AttributeEnums.CarouselGtm.GtmProductCarouselId);
        let name = carouselGtmElement.getAttribute(AttributeEnums.CarouselGtm.GtmProductCarouselName);
        let price = +carouselGtmElement.getAttribute(AttributeEnums.CarouselGtm.GtmProductCarouselPrice);
        let brand = carouselGtmElement.getAttribute(AttributeEnums.CarouselGtm.GtmProductCarouselBrand);
        let variant = carouselGtmElement.getAttribute(AttributeEnums.CarouselGtm.GtmProductCarouselSize);
        let category = carouselGtmElement.getAttribute(AttributeEnums.CarouselGtm.GtmProductCarouselCategory);
        let groupSku = carouselGtmElement.getAttribute(AttributeEnums.CarouselGtm.GtmProductCarouselGroupSku);
        let sku = carouselGtmElement.getAttribute(AttributeEnums.CarouselGtm.GtmProductCarouselSku);
        let url = carouselGtmElement.getAttribute(AttributeEnums.CarouselGtm.GtmProductCarouselUrl);

        let carouselGtm: ICarouselGtm = {
            id: id,
            name: name,
            price: price,
            brand: brand,
            variant: variant,
            category: category,
            groupSku: groupSku,
            sku: sku,
            url: url
        };

        return carouselGtm;
    }

    public static getAddToRemoveFromCartGtmDataObject(targetElement: HTMLElement): IAddToRemoveFromCartGtmDataObject {
        let carouselGtm = this.getCarouselGtmForAddToBasketElement(targetElement);

        let dataObject: IAddToRemoveFromCartGtmDataObject = {
            id: carouselGtm.id,
            name: carouselGtm.name,
            price: carouselGtm.price,
            brand: carouselGtm.brand,
            variant: carouselGtm.variant,
            category: carouselGtm.category,
            quantity: 1
        }
        return dataObject;
    }

    public static getCarouselGtmForAddToBasketElement(targetElement: HTMLElement): ICarouselGtm {
        try {
            let carouselGtmElement: HTMLElement = targetElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Carousel.Cells))
                .querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselGtm.GtmProductCarousel));

            return CarouselGtm.getCarouselGtmFromCarouselGtmElement(carouselGtmElement);
        } catch (error) {
            console.log('CarouselGtm: getCarouselGtmForAddToBasketElement ERROR', error);
            throw new error('Error mapping carouselGtm');
        }
    }
}

