import { initialise } from "./initialiser";
import { IInstanceComponentBase } from "./instanceComponentBase";
import { CustomEvents } from "./utilities/customEvents";
import { AttributeEnums } from "./utilities/enums/attributeEnums";
import { CustomEventEnums } from "./utilities/enums/customEventEnums";
import { SessionStorageEnums } from "./utilities/enums/sessionStorageEnums";
import { InstantiationUtils } from "./utilities/instantiationUtils";
const settings = require('../config/settings.json');
export interface ILiveChat {

}
export class LiveChat implements ILiveChat {
    private readonly _handleFirstMovementInteraction;
    private readonly _handleLiveChatLink;
    private readonly _handleHideOverlay;
    private readonly _handleShowOverlay;
    private readonly _handleShowLiveChatEvent;
    private readonly _handleHideLiveChatEvent;
    private readonly _handleOpenLiveChatEvent;
    private readonly _handleCloseLiveChatEvent;

    constructor() {
        this._handleFirstMovementInteraction = this.handleFirstMovementInteraction.bind(this);
        this._handleLiveChatLink = this.handleLiveChatLink.bind(this);
        this._handleHideOverlay = this.handleHideOverlay.bind(this);
        this._handleShowOverlay = this.handleShowOverlay.bind(this);
        this._handleShowLiveChatEvent = this.handleShowLiveChatEvent.bind(this);
        this._handleHideLiveChatEvent = this.handleHideLiveChatEvent.bind(this);
        this._handleOpenLiveChatEvent = this.handleOpenLiveChatEvent.bind(this);
        this._handleCloseLiveChatEvent = this.handleCloseLiveChatEvent.bind(this);

        let sessionStorageLiveChatCached: string | null = sessionStorage.getItem(SessionStorageEnums.LiveChatCached);
        if(sessionStorageLiveChatCached === 'true'){
            this.handleFirstMovementInteraction();
        }else{
            this.attachFirstMovementInteractionEventListener();
        }
    }

    attachFirstMovementInteractionEventListener(): void {
        console.log('LiveChat: attachFirstMovementInteractionEventListener');
        (<any>window).addEventListener(CustomEventEnums.FirstMovementInteraction, this._handleFirstMovementInteraction);
    }

    removeFirstMovementInteractionEventListener(): void {
        console.log('LiveChat: removeFirstMovementInteractionEventListener');
        (<any>window).removeEventListener(CustomEventEnums.FirstMovementInteraction, this._handleFirstMovementInteraction);
    }

    attachLiveChatClickEventListener(): void {
        console.log('LiveChat: attachLiveChatClickEventListener');
        (<any>window).addEventListener('click', this._handleLiveChatLink);
    }
    removeLiveChatClickEventListener(): void {
        console.log('LiveChat: removeLiveChatClickEventListener');
        (<any>window).removeEventListener('click', this._handleLiveChatLink);
    }

    
    attachShowOverlayEventListener(): void {
        console.log('LiveChat: attachShowOverlayEventListener');
        (<any>window).addEventListener(CustomEventEnums.ShowOverlay, this._handleShowOverlay);
    }
    removeShowOverlayEventListener(): void {
        console.log('LiveChat: removeShowOverlayEventListener');
        (<any>window).removeEventListener(CustomEventEnums.ShowOverlay, this._handleShowOverlay);
    }
    attachHideOverlayEventListener(): void {
        console.log('LiveChat: attachHideOverlayEventListener');
        (<any>window).addEventListener(CustomEventEnums.HideOverlay, this._handleHideOverlay);
    }
    removeHideOverlayEventListener(): void {
        console.log('LiveChat: removeHideOverlayEventListener');
        (<any>window).removeEventListener(CustomEventEnums.HideOverlay, this._handleHideOverlay);
    }

    attachShowLiveChatEventListener(): void {
        console.log('LiveChat: attachShowLiveChatEventListener');
        (<any>window).addEventListener(CustomEventEnums.ShowLiveChat, this._handleShowLiveChatEvent);
    }
    removeShowLiveChatEventListener(): void {
        console.log('LiveChat: removeShowLiveChatEventListener');
        (<any>window).removeEventListener(CustomEventEnums.ShowLiveChat, this._handleShowLiveChatEvent);
    }

    attachHideLiveChatEventListener(): void {
        console.log('LiveChat: attachHideLiveChatEventListener');
        (<any>window).addEventListener(CustomEventEnums.HideLiveChat, this._handleHideLiveChatEvent);
    }
    removeHideLiveChatEventListener(): void {
        console.log('LiveChat: removeHideLiveChatEventListener');
        (<any>window).removeEventListener(CustomEventEnums.HideLiveChat, this._handleHideLiveChatEvent);
    }

    attachOpenLiveChatEventListener(): void {
        console.log('LiveChat: attachOpenLiveChatEventListener');
        (<any>window).addEventListener(CustomEventEnums.OpenLiveChat, this._handleOpenLiveChatEvent);
    }
    removeOpenLiveChatEventListener(): void {
        console.log('LiveChat: removeOpenLiveChatEventListener');
        (<any>window).removeEventListener(CustomEventEnums.OpenLiveChat, this._handleOpenLiveChatEvent);
    }

    attachCloseLiveChatEventListener(): void {
        console.log('LiveChat: attachCloseLiveChatEventListener');
        (<any>window).addEventListener(CustomEventEnums.CloseLiveChat, this._handleCloseLiveChatEvent);
    }
    removeCloseLiveChatEventListener(): void {
        console.log('LiveChat: removeCloseLiveChatEventListener');
        (<any>window).removeEventListener(CustomEventEnums.CloseLiveChat, this._handleCloseLiveChatEvent);
    }


    async handleFirstMovementInteraction(): Promise<void> {
        try{
        console.log('LiveChat: handleFirstInteraction');
        await this.loadLiveChat();
        this.removeFirstMovementInteractionEventListener();
        this.attachLiveChatClickEventListener();
        this.attachHideOverlayEventListener();
        this.attachShowOverlayEventListener();
        this.attachShowLiveChatEventListener();
        this.attachHideLiveChatEventListener();
        this.attachOpenLiveChatEventListener();
        this.attachCloseLiveChatEventListener();

        this.customiseChatLabel();
        } catch(error) {
            console.log('LiveChat: handleFirstInteraction: Error', error);
        }
    }

    customiseChatLabel(){
       try{
            (<any>window).zESettings = {
                webWidget: {
                launcher: {
                    chatLabel: {
                    '*': settings.LiveChatButtonOnlineMessage
                    }
                }
                }
            };
            console.log(`LiveChat: customiseChatLabel chatLabel Online: ${settings.LiveChatButtonOnlineMessage} `);

            // (<any>window).zESettings = {
            //     webWidget: {
            //     contactOptions: {
            //         enabled: true,
            //         chatLabelOnline: { '*': settings.LiveChatButtonOnlineMessage },
            //         chatLabelOffline: { '*': settings.LiveChatButtonOfflineMessage }
            //     }
            //     }
            // };
            console.log(`LiveChat: customiseChatLabel contactOptions chatLabelOnline: ${settings.LiveChatButtonOnlineMessage} chatLabelOffline: ${settings.LiveChatButtonOfflineMessage}`);
            (<any>window).zESettings = {
                webWidget: {
                  launcher: {
                    mobile: {
                      labelVisible: true
                    }
                  }
                }
              };
              console.log(`LiveChat: customiseChatLabel - Label visible true`);

        } catch(error) {
            console.log('LiveChat: customiseChatLabel: Error', error);

        }
    }

    async loadLiveChat(): Promise<void> {
        //Requires Additional Cross Browser Testing
        await InstantiationUtils.injectScript('https://static.zdassets.com/ekr/snippet.js?key=026f3fa2-c554-400c-b1c9-d3eb7521baca', document.head, 'ze-snippet', true)
        .then(() => {
        console.log('LiveChat: loadLiveChat: Script loaded!');
        (<any>window).dispatchEvent(CustomEvents.liveChatLoadCompleteEvent());
        sessionStorage.setItem(SessionStorageEnums.LiveChatCached, 'true');
        }).catch(error => {
        console.error('LiveChat: LoadLiveChat: Error', error);
        });
               
    }
  
    handleLiveChatLink(event: Event): void {
        try{
            console.log('LiveChat: handleLiveChatLink');
            let targetElement = event.target as HTMLElement;
            if (targetElement.hasAttribute(AttributeEnums.LiveChat.LiveChatLink)) {
                event.preventDefault();
                this.openLiveChat();
            }
        } catch(error) {
            console.log('LiveChat: handleLiveChatLink: Error', error);
        }
    }

    openLiveChat(): void {
        console.log('LiveChat: openLiveChat');
       // (<any>window).$zopim.livechat.window.show();
        //this.showHideLiveChat(true);
        (<any>window).zE('webWidget', 'open');
        (<any>window).dispatchEvent(CustomEvents.closeAllMenusEvent());
    }
    handleHideOverlay(): void {
        this.showHideLiveChat(true);
    }

    handleShowOverlay(): void {
        this.showHideLiveChat(false);
    }

    handleShowLiveChatEvent(): void {
        this.showHideLiveChat(true);
    }
    handleHideLiveChatEvent(): void {
        this.showHideLiveChat(false);
    }

    handleOpenLiveChatEvent(): void {
       this.openLiveChat();
    }
    handleCloseLiveChatEvent(): void {
        //this.showHideLiveChat(false);
    }

    static initialise() {
        try{
            let doesObjectExistOnWindow = () => { return typeof (<any>window).liveChat };
            if (doesObjectExistOnWindow()) {
                let liveChat:ILiveChat  = new LiveChat();
                (<any>window).liveChat = liveChat;
            }
        } catch(error) {

            console.log('LiveChat: initialise: Error', error);

        }
    }

    showHideLiveChat(chatVisibility: boolean): void {
        try{
            if(chatVisibility){
                (<any>window).zE('webWidget', 'show');
            }else{
                (<any>window).zE('webWidget', 'hide');
            }
        } catch(error) {

            console.log('LiveChat: showHideLiveChat: Error', error);

        }
    }
    

    dispose() {
        try{
        this.removeLiveChatClickEventListener();
        this.removeShowOverlayEventListener();
        this.removeHideOverlayEventListener();
        this.removeShowLiveChatEventListener();
        this.removeHideLiveChatEventListener();
        this.removeOpenLiveChatEventListener();
        this.removeCloseLiveChatEventListener();
        } catch(error){
            console.log('LiveChat: dispose: Error disposing', error);
            
        }

    }

    delete(): void {

    }
}