import Swal from "sweetalert2";
import { IFilterClickGtmDataObject, IQuickLinkClickGtmDataObject, ISortClickGtmDataObject } from "../../shared/googleTagManager/gtmDataObject";
import { GoogleTagManager } from "../../shared/googleTagManager/gtmService";
import { HistoryController, IHistoryController } from "../../shared/historyController";
import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { IPopOutController } from "../../shared/popOutController";
import { ResponsiveState } from "../../shared/responsiveState";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { StringEnums } from "../../shared/utilities/enums/stringEnums";
import { UrlEnums } from "../../shared/utilities/enums/urlEnums";
import { UtilityEnums } from "../../shared/utilities/enums/utillityEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { ReadyUtils } from "../../shared/utilities/readyUtils";
import { GlobalComponents, IGlobalComponents } from "../globalComponents/globalComponents";
import { BannerPromotions, IBannerPromotions } from "../promotions/bannerPromotions";
import { CategoryPagingSelectableCarousel, ICategoryPagingSelectableCarousel } from "./categoryPagingSelectableCarousel";
import { IProductListingPageBreadcrumb, ProductListingPageBreadcrumb } from "./productListingPageBreadcrumb";
import { IProductListingPageHeader, ProductListingPageHeader } from "./productListingPageHeader";
import { IProductListingPageOption } from "./productListingPageOption";
import { IProductListingPageOptions, ProductListingPageOptions } from "./productListingPageOptions";
import { IProductListingPageSelectedOptionsCarousel, ProductListingPageSelectedOptionsCarousel } from "./productListingPageSelectedOptionsCarousel";
import { IProductListingPageShowMore, ProductListingPageShowMore } from "./productListingPageShowMore";
import { IProductListingPageTiles, ProductListingPageTiles } from "./productListingPageTiles";
const settings = require('../../config/settings.json');
export interface IProductListingPage extends IInstanceComponentBase {
    querySelectorArray: QuerySelector[];
}
export interface QuerySelector {
    type: string;
    value: string;
}
export interface OptionUpdateDto {
    filterQuery: string;
    optionDisplayStates: OptionDisplayState[];
}

export interface OptionDisplayState {
    value: string;
    showHide: boolean;
    optionalInnerShowHide: boolean | undefined;
    optionalInnerShowHideValue: string | undefined;
}

export interface CategoryOption {
    optionTitle: string;
    optionValue: string;
    optionSelected: boolean;
}

export interface Breadcrumb {
    url: string;
    name: string;
    position: number;
}

export class ProductListingPage extends InstanceComponentBase implements IProductListingPage {
    public productListingPageOptions: IProductListingPageOptions;
    public productListingPageTiles: IProductListingPageTiles;
    public productListingPageHeader: IProductListingPageHeader;
    public productListingPageShowMore: IProductListingPageShowMore;
    public productListingPageSelectedOptionsCarousel: IProductListingPageSelectedOptionsCarousel;
    public productListingPageSelectedOptionsBlock: IProductListingPageSelectedOptionsCarousel;
    public categoryPagingSelectableCarousel: ICategoryPagingSelectableCarousel;
    public productListingPageBreadcrumb: IProductListingPageBreadcrumb;
    public querySelectorString: string;
    public querySelectorArray: QuerySelector[];
    public globalComponents: IGlobalComponents
    public optionsLoaded: boolean;

    private readonly _handleProductListingPageOptionSelected;
    private readonly _handleProductListingPageSortChanged;
    private readonly _handleProductListingPageShowMore;
    private readonly _handleProductListingHistoryBack;
/*    private readonly _handleProductListingHistoryShowMoreBack;*/
    private readonly _handleNoProductsReturned;
    private readonly _handleProductListingPageHeaderQuickLink;
    private readonly popoutController: IPopOutController;

    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);
        this.popoutController = (<any>window).popOutController;
        this._handleProductListingPageOptionSelected = this.handleProductListingPageOptionSelected.bind(this);
        this._handleProductListingPageSortChanged = this.handleProductListingPageSortChanged.bind(this);
        this._handleProductListingPageShowMore = this.handleProductListingPageShowMore.bind(this);
        this._handleProductListingHistoryBack = this.handleProductListingHistoryBack.bind(this);
/*        this._handleProductListingHistoryShowMoreBack = this.handleProductListingHistoryShowMoreBack.bind(this);*/
        this._handleNoProductsReturned = this.handleNoProductsReturned.bind(this);
        this._handleProductListingPageHeaderQuickLink = this.handleProductListingPageHeaderQuickLink.bind(this);

        this.setQuerySelectorString();
        this.querySelectorArray = this.getQuerySelectorArray();
        this.productListingPageOptionsFactory();
        this.productListingPageTilesFactory();
        this.productListingPageHeaderFactory();
        this.productListingPageShowMoreFactory();
        this.productListingPageSelectedOptionsCarouselsFactory();
        //this.categoryPagingSelectableCarouselFactory();
        this.breadcrumbFactory();
        this.globalComponents = GlobalComponents.getGlobalComponents();

        this.attachProductListingPageOptionSelectedEventListener();
        this.attachProductListingPageSortChangedEventListener();
        this.attachProductListingPageShowMoreEventListener();
        this.attachProductListingPageOptionDeselectedEventListener();
        this.attachProductListingPageHistoryBackEventListener();
        this.attachProductListingPageNoProductsReturnedEventListener();
        this.attachProductListingPageHeaderQuickLinkEventListener();
        this.productListingPageHeader.disableSortDropdown();
        this.optionsLoaded = false;
        this.popoutController.active = true;

        this.showNoLongerAvailableDialogueIfTrueAndPushState();
    }

    attachProductListingPageOptionSelectedEventListener(): void {
        console.log('ProductListingPage: attachProductListingPageOptionSelectedEventListener');
        this.htmlElement.addEventListener(CustomEventEnums.ProductListingPageOptionSelected, this._handleProductListingPageOptionSelected);
    }

    removeProductListingPageOptionSelectedEventListener(): void {
        console.log('ProductListingPage: removeProductListingPageOptionSelectedEventListener');
        this.htmlElement.removeEventListener(CustomEventEnums.ProductListingPageOptionSelected, this._handleProductListingPageOptionSelected);
    }

    attachProductListingPageSortChangedEventListener(): void {
        console.log('ProductListingPage: attachProductListingPageSortChangedEventListener');
        this.htmlElement.addEventListener(CustomEventEnums.ProductListingPageSortChanged, this._handleProductListingPageSortChanged);
    }

    removeProductListingPageSortChangedEventListener(): void {
        console.log('ProductListingPage: removeProductListingPageSortChangedEventListener');
        this.htmlElement.removeEventListener(CustomEventEnums.ProductListingPageSortChanged, this._handleProductListingPageSortChanged);
    }

    attachProductListingPageShowMoreEventListener(): void {
        console.log('ProductListingPage: attachProductListingPageShowMoreEventListener');
        this.htmlElement.addEventListener(CustomEventEnums.ProductListingPageShowMore, this._handleProductListingPageShowMore);
    }

    removeProductListingPageShowMoreEventListener(): void {
        console.log('ProductListingPage: removeProductListingPageShowMoreEventListener');
        this.htmlElement.removeEventListener(CustomEventEnums.ProductListingPageShowMore, this._handleProductListingPageShowMore);
    }

    attachProductListingPageOptionDeselectedEventListener(): void {
        console.log('ProductListingPage: attachProductListingPageOptionDeselectedEventListener');
        this.htmlElement.addEventListener(CustomEventEnums.ProductListingPageOptionDeselected, this._handleProductListingPageOptionSelected);
    }

    removeProductListingPageOptionDeselectedEventListener(): void {
        console.log('ProductListingPage: removeProductListingPageOptionDeselectedEventListener');
        this.htmlElement.removeEventListener(CustomEventEnums.ProductListingPageOptionDeselected, this._handleProductListingPageOptionSelected);
    }

    attachProductListingPageHistoryBackEventListener(): void {
        console.log('ProductListingPage: attachProductListingPageHistoryBackEventListener');
        document.addEventListener(CustomEventEnums.ProductListingPageHistoryBack, this._handleProductListingHistoryBack);

    }

    removeProductListingPageHistoryBackEventListener(): void {
        console.log('ProductListingPage: removeProductListingPageHistoryBackEventListener');
        document.removeEventListener(CustomEventEnums.ProductListingPageHistoryBack, this._handleProductListingHistoryBack);
    }

    //attachProductListingPageHistoryShowMoreBackEventListener(): void {
    //    console.log('ProductListingPage: attachProductListingPageHistoryShowMoreBackEventListener');
    //    document.addEventListener(CustomEventEnums.ProductListingPageHistoryShowMoreBack, this._handleProductListingHistoryBack);

    //}

    //removeProductListingPageHistoryShowMoreBackEventListener(): void {
    //    console.log('ProductListingPage: removeProductListingPageHistoryShowMoreBackEventListener');
    //    document.removeEventListener(CustomEventEnums.ProductListingPageHistoryShowMoreBack, this._handleProductListingHistoryBack);
    //}

    attachProductListingPageNoProductsReturnedEventListener(): void {
        console.log('ProductListingPage: attachProductListingPageNoProductsReturnedEventListener');
        this.htmlElement.addEventListener(CustomEventEnums.ProductListingNoProductsReturned, this._handleNoProductsReturned);

    }

    removeProductListingPageNoProductsReturnedEventListener(): void {
        console.log('ProductListingPage: removeProductListingPageNoProductsReturnedEventListener');
        this.htmlElement.removeEventListener(CustomEventEnums.ProductListingNoProductsReturned, this._handleNoProductsReturned);
    }

    attachProductListingPageHeaderQuickLinkEventListener(): void {
        console.log('ProductListingPage: attachProductListingPageHeaderQuickLinkEventListener');
        this.htmlElement.addEventListener(CustomEventEnums.ProductListingPageHeaderQuickLink, this._handleProductListingPageHeaderQuickLink);
    }

    removeProductListingPageHeaderQuickLinkEventListener(): void {
        console.log('ProductListingPage: removeProductListingPageHeaderQuickLinkEventListener');
        this.htmlElement.removeEventListener(CustomEventEnums.ProductListingPageHeaderQuickLink, this._handleProductListingPageHeaderQuickLink);
    }

    async productListingPageOptionsFactory() {
        try {
            console.log('ProductListingPage: productListingPageOptionsFactory - Started');

            let optionsElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageOptions.ProductListingPageOptions)) as HTMLElement;
            this.productListingPageOptions = new ProductListingPageOptions(optionsElement, this.querySelectorString);
            await this.productListingPageOptions.updateOptions(this.querySelectorString);
            this.productListingPageOptions.removeLoading();
            this.productListingPageHeader.enableSortDropdown();
            this.optionsLoaded = true;
            console.log('ProductListingPage: productListingPageOptionsFactory - Finished');
        } catch (error) {
            console.log("ProductListingPage: productListingPageOptionsFactory ERROR: ", error);
        }
    }

    async productListingPageOptionsFactoryForUpdate(optionsUpdateDto: OptionUpdateDto) {
        try {
            console.log('ProductListingPage: productListingPageOptionsFactoryForUpdate - Started');

            let optionsElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageOptions.ProductListingPageOptions)) as HTMLElement;
            this.productListingPageOptions = new ProductListingPageOptions(optionsElement, this.querySelectorString);
            await this.productListingPageOptions.updateOptionsForOptionUpdateDto(optionsUpdateDto);
            this.productListingPageOptions.removeLoading();
            this.productListingPageHeader.enableSortDropdown();
            this.optionsLoaded = true;
            
            console.log('ProductListingPage: productListingPageOptionsFactory - Finished');
        } catch (error) {
            console.log("ProductListingPage: productListingPageOptionsFactoryForUpdate ERROR: ", error);
        }
    }

    async productListingPageShowMoreFactory() {
        try {
            console.log('ProductListingPage: productListingPageShowMoreFactory - Started');

            let showMoreElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowMore)) as HTMLElement;
            this.productListingPageShowMore = new ProductListingPageShowMore(showMoreElement);

            console.log('ProductListingPage: productListingPageShowMoreFactory - Finished');
        } catch (error) {
            console.log("ProductListingPage: productListingPageShowMoreFactory ERROR: ", error);
        }
    }
    productListingPageTilesFactory() {
        try {
            let tilesElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageTiles.ProductListingPageTiles)) as HTMLElement;
            this.productListingPageTiles = new ProductListingPageTiles(tilesElement);
        } catch (error) {
            console.log('ProductListingPage: productListingPageTilesFactory ERROR: ', error);
        }
    }

    productListingPageHeaderFactory() {
        try {
            let headerElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageHeader.ProductListingPageHeader)) as HTMLElement;
            console.log(`ProductListingPage: productListingPageTilesFactory headerElement exists ${(headerElement!= null)}` );
            this.productListingPageHeader = new ProductListingPageHeader(headerElement);
            if (this.optionsLoaded) {
                this.productListingPageHeader.enableSortDropdown();
            }

        } catch (error) {
            console.log('ProductListingPage: productListingPageHeaderFactory ERROR: ', error);
        }
    }
    productListingPageSelectedOptionsCarouselsFactory() {
        try {
            let selectedOptionsCarouselElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageSelectedOptionsCarousel.ProductListingPageSelectedOptionsCarousel, UtilityEnums.DeviceType.Desktop)) as HTMLElement;
            this.productListingPageSelectedOptionsCarousel = new ProductListingPageSelectedOptionsCarousel(selectedOptionsCarouselElement);

            let selectedOptionsBlockElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageSelectedOptionsCarousel.ProductListingPageSelectedOptionsCarousel, UtilityEnums.DeviceType.Mobile)) as HTMLElement;
            this.productListingPageSelectedOptionsBlock = new ProductListingPageSelectedOptionsCarousel(selectedOptionsBlockElement);
        } catch (error) {
            console.log('ProductListingPage: productListingPageSelectedOptionsCarouselsFactory ERROR: ', error);
        }
    }

    categoryPagingSelectableCarouselFactory(): void {
        try {
            let carouselElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CategoryPagingSelectableCarousel.CategoryPagingSelectableCarousel));

            let componentOrientation = carouselElement.getAttribute(AttributeEnums.SelectableCarousel.ComponentOrientation);
            let componentOrientationEnum = (UtilityEnums.ComponentOrientation.Horizontal === componentOrientation) ? UtilityEnums.ComponentOrientation.Horizontal : UtilityEnums.ComponentOrientation.Vertical;
            let categoryPagingSelectableCarousel = new CategoryPagingSelectableCarousel(carouselElement, componentOrientationEnum);
            this.categoryPagingSelectableCarousel = categoryPagingSelectableCarousel;
        } catch (error) {
            console.log('ProductListingPage: categoryPagingSelectableCarouselFactory ERROR: ', error);
        }
    }

    breadcrumbFactory() {
        var breadcrumbContainerElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Breadcrumb.BreadcrumbContainer)) as HTMLElement;
        this.productListingPageBreadcrumb = new ProductListingPageBreadcrumb(breadcrumbContainerElement);
    }
    async reinitialiseTiles() {
        await this.productListingPageTiles.updateTilesUnpaged(this.querySelectorString);
        this.productListingPageTiles.dispose();
        this.productListingPageTiles = null;
        this.productListingPageTilesFactory();

    }

    async reinitialiseHeader() {
        console.log('ProductListingPage: reinitialiseHeader Started');

        let optionUpdateDto: OptionUpdateDto = this.getOptionUpdateDto();
        console.log("ProductListingPage: getOptionUpdateDto Completed");
        console.log("ProductListingPage: updateHeaderForOptions Started");

        await this.productListingPageHeader.updateHeaderForOptions(optionUpdateDto);
        //await this.productListingPageHeader.updateHeader(this.querySelectorString);
        console.log("ProductListingPage: dispose Started");

        this.productListingPageHeader.dispose();
        this.productListingPageHeader = null;
        console.log("ProductListingPage: productListingPageHeaderFactory Started");
        this.productListingPageHeaderFactory();

    }

    async reinitialiseProductListingPageSelectedOptionsCarousels() {

        let selectedOptions = this.productListingPageOptions.getAllSelectedOptions();
        // Desktop
        let selectedOptionsCarouselElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageSelectedOptionsCarousel.ProductListingPageSelectedOptionsCarousel, UtilityEnums.DeviceType.Desktop)) as HTMLElement;
        ProductListingPageSelectedOptionsCarousel.replaceCarouselOptions(selectedOptionsCarouselElement, selectedOptions);

        // Mobile
        let selectedOptionsBlockElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageSelectedOptionsCarousel.ProductListingPageSelectedOptionsCarousel, UtilityEnums.DeviceType.Mobile)) as HTMLElement;
        ProductListingPageSelectedOptionsCarousel.replaceCarouselOptions(selectedOptionsBlockElement, selectedOptions);

        this.productListingPageSelectedOptionsCarouselsFactory();
    }

    async reinitialiseCategoryPagingSelectableCarousel() {
        try {
            this.categoryPagingSelectableCarousel.dispose();
            let categoryOpions: IProductListingPageOption[] = this.productListingPageOptions.getCategoryOptions();
            let carouselElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CategoryPagingSelectableCarousel.CategoryPagingSelectableCarousel)) as HTMLElement;

            CategoryPagingSelectableCarousel.reinitializeCategoryCarouselHtml(categoryOpions, carouselElement);
            this.categoryPagingSelectableCarousel = null;
            this.categoryPagingSelectableCarouselFactory();
            let categoryQuerySelector = this.querySelectorArray.filter((querySelector: QuerySelector) => querySelector.type == UtilityEnums.SingleSelectType.Category)[0] as QuerySelector | null;
            if (categoryQuerySelector) {
                let selectedIndex = +this.categoryPagingSelectableCarousel.getCellIdFromValue(categoryQuerySelector.value);
                this.categoryPagingSelectableCarousel.updateCarouselCurrentSelectedIndex(selectedIndex);
            } else {
                this.categoryPagingSelectableCarousel.setCurrentSelectedIndexAndStyleToNone();
            }
        } catch (error) {
            console.log("ProductListingPage: reinitialiseCategoryPagingSelectableCarousel ERROR: ", error);
        }

    }

    updateBreadcrumb() {
        try {
            this.productListingPageBreadcrumb.updateBreadcrumb(this.productListingPageHeader.hiddenBreadcrumb);
        } catch (error) {
            console.log('ProductListingPage: updateBreadcrumb Error', error);
        }
    }

    getOptionUpdateDto(): OptionUpdateDto {
        let groupOptions = this.productListingPageOptions.productListingPageGroupOptions;
        let optionUpdateDto: OptionUpdateDto = { filterQuery: this.querySelectorString, optionDisplayStates: new Array<OptionDisplayState>() } as OptionUpdateDto;
        try {
            for (let groupOption in groupOptions) {
                let optionDisplayStates = {} as OptionDisplayState;
                optionDisplayStates.showHide = groupOptions[groupOption].showHide.isHidden;
                if (groupOptions[groupOption].optionalInnerShowHide) {
                    optionDisplayStates.optionalInnerShowHide = groupOptions[groupOption].optionalInnerShowHide.isHidden;
                    optionDisplayStates.optionalInnerShowHideValue = groupOptions[groupOption].optionalInnerShowHide.value;
                } else {
                    optionDisplayStates.optionalInnerShowHide = undefined;
                    optionDisplayStates.optionalInnerShowHideValue = undefined;
                }
                optionDisplayStates.value = groupOptions[groupOption].showHide.value;
                optionUpdateDto.optionDisplayStates.push(optionDisplayStates);
            }
        } catch (error) {
            console.log("ProductListingPage: getOptionUpdateDto ERROR: ", error);
        }
        return optionUpdateDto;

    }
    resetPageQuerySelectorToPageOne() {
        this.querySelectorArray.forEach((querySelector: QuerySelector) => { if (querySelector.type === UtilityEnums.SingleSelectType.Page) { querySelector.value = "1" } });
        let updatedQuerySelectorString: string = this.getQuerySelectorStringFromQuerySelectorArray(this.querySelectorArray);
        this.updateQuerySelectorString(updatedQuerySelectorString);
    }

    resetPageQuerySelectorNoPage() {
        let updatedQuerySelectorArray: Array<QuerySelector> = ProductListingPage.removePageNumberInQuerySelectorArray(this.querySelectorArray);
        this.querySelectorArray = updatedQuerySelectorArray;
        let updatedQuerySelectorString: string = this.getQuerySelectorStringFromQuerySelectorArray(this.querySelectorArray);
        this.updateQuerySelectorString(updatedQuerySelectorString);
    }

    async handleProductListingPageSortChanged(e: CustomEvent) {
        if (this.optionsLoaded) {
            let value: string = e.detail.value;
            console.log(`ProductListingPage: handleProductListingPageSortChanged value: ${value}`);
            this.productListingPageShowMore.setIsLoadingTrue();
            // // remove page number before sort unless only page--1 without any other filters       
            if (this.querySelectorString !== 'page--1') {

                this.removePageNumberInQuerySelectorStringAndArray();
            }
            // update sort filter
            let updatedQuerySelector: string = this.addRemoveSelectedDeselectedOption(UtilityEnums.SingleSelectType.Sort, value);

            this.updateQuerySelectorString(updatedQuerySelector);
            this.querySelectorArray = this.getQuerySelectorArray();
            this.productListingPageTiles.displayProductListingTilesLoadingTiles();
            await this.reinitialiseHeader();
            await this.reinitialiseTiles();
            this.htmlElement.scrollIntoView();

            this.productListingPageShowMore.updateProductListingPageShowingStartProductAndProductCount(this.productListingPageHeader.startProduct, this.productListingPageHeader.productCount);
            this.productListingPageShowMore.updateProductListingPageShowingTotalProducts(this.productListingPageHeader.totalProducts);
            this.productListingPageShowMore.updateShowMoreButtonEnabledDisabledStyle();
            this.productListingPageShowMore.setIsLoadingFalse();

            await this.setHistoryAndTitle();
            this.sendSortClickGtm(value);
        }
    }

    async handleNoProductsReturned(e: CustomEvent) { 
        let querySelectorString: string = e.detail.querySelectorString;
        console.log(`ProductListingPage: handleNoProductsReturned - ${querySelectorString}`);
        window.location.href = `${UrlEnums.PageUrls.ProductListingPageNoProducts}/${querySelectorString}`;  
       
    }

    sendSortClickGtm(sortValue: string): void {
        try {
            let dataObject = {
                url: '/browse/' + this.querySelectorString,
                name: this.productListingPageHeader.getHeadingText().replace(/,/g, ''),
                sort: sortValue,
                aggregateList: this.getAggregateList()

            } as ISortClickGtmDataObject;

            GoogleTagManager.sendSortClickGtmDataObject(dataObject);
        } catch (error) {
            console.log('ProductListingPage: sendSortClickGtm ERROR', error);
        }
    }

    getAggregateList(): string {
        try {
            let keyList: string[] = this.querySelectorArray.map((querySelector: QuerySelector) => querySelector.type).filter((type: string) => type !== 'sort' && type !== 'page');
            let distinctKeyList: string[] = keyList.filter((value: string, index: number, array: Array<string>) => array.indexOf(value) === index);
            let aggregateList: string = distinctKeyList.flatMap((value: string) => value + ' ').toString().trim().replace(/,/g, '');
            return aggregateList;
        } catch (error) {
            console.log('ProductListingPage: getAggregateList ERROR', error);
            return '';
        }
    }

    async setHistoryAndTitle() {
        let headingText = this.productListingPageHeader.getHeadingText();
        let historyController = HistoryController.getHistoryControllerClassReference();
        await historyController.pushProductListingPageHistoryToStack(this.querySelectorString, headingText + ' | Land of Beds');
    }

    async handleProductListingPageOptionSelected(e: CustomEvent) {
        let currentQuerySelector: string = this.querySelectorString;
        let optionType: string = e.detail.type;
        let optionValue: string = e.detail.value;
        console.log(`ProductListingPage: handleProductListingPageOptionSelected optionType: ${optionType} optionValue: ${optionValue}`);
        let newQuerySelectorString = this.addRemoveSelectedDeselectedOption(optionType, optionValue);

        //this.removePageNumberInQuerySelectorStringAndArray();
        if (newQuerySelectorString.length === 0) {
            newQuerySelectorString = 'page--1';
        }
        this.updateQuerySelectorUrlAndArrayForNewQuerySelectorString(newQuerySelectorString);

        newQuerySelectorString = this.querySelectorString;

        if (newQuerySelectorString.length === 0) {
            newQuerySelectorString = 'page--1';
            this.updateQuerySelectorUrlAndArrayForNewQuerySelectorString(newQuerySelectorString);

        }
        try{
        await this.reinitialiseProductListingPageWith(newQuerySelectorString);      
        this.sendFilterClickGtm(optionType, optionValue, currentQuerySelector);
        this.removeUnrequiredContent();
        } catch(error){
            console.log(`ProductListingPage: handleProductListingPageOptionSelected reinitialiseProductListingPageWith ERROR`,error);
        }   
    }

    removeUnrequiredContent():void{
        this.removePageElement(AttributeEnums.ProductListingPageDescription.ProductListingPageDescription);
        this.removePageElement(AttributeEnums.ProductListingPageQuestionsAnswers.ProductListingPageQuestionsAnswers);
    }
    removePageElement(elementName:string):void{
        try {
            let pageElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(elementName)) as HTMLElement;
            if(pageElement !== null){
                pageElement.remove();
            }
        } catch (error) {
            console.log('ProductListingPage: removePageElement ERROR', error);
        }
    }

    showNoLongerAvailableDialogueIfTrueAndPushState() {
        let showNoProductsDialogue = this.htmlElement.getAttribute(AttributeEnums.ProductListingPage.ProductListingPageShowNoProductsDialogue);
        if(showNoProductsDialogue === "true")
        {
        Swal.fire(StringEnums.Content.SorryThisSelectionIsNoLongerAvailable);
        window.history.replaceState({"pageTitle":StringEnums.Content.SorryThisSelectionIsNoLongerAvailableTitle},StringEnums.Content.SorryThisSelectionIsNoLongerAvailableTitle, "/browse/page--1");
        document.title = StringEnums.Content.SorryThisSelectionIsNoLongerAvailableTitle;
        }
    }
    sendFilterClickGtm(optionType: string, optionValue: string, currentQuerySelector: string): void {
        try {
            let dataObject = {
                id: `${optionType}--${optionValue}`,
                url: `/browse/${currentQuerySelector}`,
                targetUrl: `/browse/${this.querySelectorString}`,
                name: this.productListingPageHeader.getHeadingText().replace(/,/g, ''),
                aggregateList: this.getAggregateList(),
                group: optionType,
                option: optionValue
            } as IFilterClickGtmDataObject;

            GoogleTagManager.sendFilterClickGtmDataObject(dataObject);
        } catch (error) {
            console.log('ProductListingPage: sendFilterClickGtm ERROR', error);
        }
    }

    async handleProductListingHistoryBack(e: CustomEvent) {
        let newQuerySelectorString: string = e.detail.querySelector;

        console.log(`ProductListingPage: handleProductListingHistoryBack newQuerySelectorString: ${newQuerySelectorString}`);
        this.updateQuerySelectorUrlAndArrayForNewQuerySelectorStringNoPageRemoval(newQuerySelectorString);
        this.reinitialiseProductListingPageWithNoHistory(newQuerySelectorString);
    }

    //async handleProductListingHistoryShowMoreBack(e: CustomEvent) {
    //    let newQuerySelectorString: string = e.detail.querySelector;

    //    console.log(`ProductListingPage: handleProductListingHistoryBack newQuerySelectorString: ${newQuerySelectorString}`);
    //    this.updateQuerySelectorUrlAndArrayForNewQuerySelectorString(newQuerySelectorString);
    //    this.reinitialiseProductListingPageWithNoHistory(newQuerySelectorString);
    //}

    updateQuerySelectorUrlAndArrayForNewQuerySelectorString(newQuerySelectorString: string) {
        this.updateQuerySelectorString(newQuerySelectorString);
        this.querySelectorArray = this.getQuerySelectorArray();
        this.resetPageQuerySelectorNoPage();
        if (this.querySelectorArray.length === 0) {
            //Add page if no query selectors for default plp home page
            this.updateQuerySelectorString("page--1");
            this.querySelectorArray = this.getQuerySelectorArray();
        }
        this.ifQueryArrayHasNoCategoryRemoveExtrasFromQueryArrayAndQueryString();
    }
    updateQuerySelectorUrlAndArrayForNewQuerySelectorStringNoPageRemoval(newQuerySelectorString: string) {
        this.updateQuerySelectorString(newQuerySelectorString);
        this.querySelectorArray = this.getQuerySelectorArray();
        this.ifQueryArrayHasNoCategoryRemoveExtrasFromQueryArrayAndQueryString();
    }


    async handleProductListingPageShowMore() {
        console.log(`ProductListingPage: handleProductListingPageShowMore`);

        this.incrementPageNumberInQuerySelectorStringAndArray();
        await this.productListingPageTiles.addTilesPage(this.querySelectorString);
        let newProductCount:number = this.getNewProductCount();
        let startProductCount:number = this.getStartProductCount();
        
        this.productListingPageHeader.updateProductListingPageShowingStartProductAndProductCount(startProductCount, newProductCount);
        this.productListingPageShowMore.updateProductListingPageShowingStartProductAndProductCount(startProductCount, newProductCount);
        this.productListingPageShowMore.updateShowMoreButtonEnabledDisabledStyle();
        this.productListingPageShowMore.setIsLoadingFalse();
        await this.setHistoryAndTitle();
    
        this.writeToQuerySelectorString(this.querySelectorString);
          
    }
    getNewProductCount() {
        let tileCount = this.productListingPageTiles.countDisplayedPageTiles();
        let startPage: number = this.getStartPageCountFromQuerySelectorArray();
        let pageCount: number = this.getPageCountFromQuerySelectorArray();
        let productsPerPage: number = +settings.PlpProductsPerPage;
        let newProductCount = ((startPage-1) * productsPerPage) + tileCount;
        return newProductCount;
    }

    getStartProductCount() {      
        let startPage: number = this.getStartPageCountFromQuerySelectorArray();       
        let productsPerPage: number = +settings.PlpProductsPerPage;
        let startProductCount = (productsPerPage * (startPage -1)) + 1;
        return startProductCount;
    }
    incrementPageNumberInQuerySelectorStringAndArray() {
        let pageIndex: number = this.querySelectorArray.findIndex((querySelector: QuerySelector) => querySelector.type === UtilityEnums.SingleSelectType.Page);
        if (pageIndex === -1) {
            let incrementedPage: QuerySelector = { type: UtilityEnums.SingleSelectType.Page, value: "2" };
            this.querySelectorArray.push(incrementedPage);
        } else {
            let oldPageValue = this.querySelectorArray[pageIndex].value;
            if (oldPageValue.includes('-')) {
                let startPageCount: number = this.getStartPageCountFromQuerySelectorArray();
                let oldPageCount: number = this.getPageCountFromQuerySelectorArray();
                this.querySelectorArray = this.querySelectorArray.filter((querySelector: QuerySelector) => querySelector.type !== UtilityEnums.SingleSelectType.Page);
                let incrementedPage: QuerySelector = { type: UtilityEnums.SingleSelectType.Page, value: `${startPageCount}-${(oldPageCount + 1)}` };
                this.querySelectorArray.push(incrementedPage);

            } else {
                let oldPageCount: number = this.getPageCountFromQuerySelectorArray();
                this.querySelectorArray = this.querySelectorArray.filter((querySelector: QuerySelector) => querySelector.type !== UtilityEnums.SingleSelectType.Page);
                let incrementedPage: QuerySelector = { type: UtilityEnums.SingleSelectType.Page, value: (oldPageCount + 1).toString() };
                this.querySelectorArray.push(incrementedPage);
            }
        }
        //this.querySelectorString = this.getQuerySelectorStringFromQuerySelectorArray(this.querySelectorArray);
        //this.writeToQuerySelectorString(this.querySelectorString);
        this.updateQuerySelectorString(this.getQuerySelectorStringFromQuerySelectorArray(this.querySelectorArray));
    }
    private getStartPageCountFromQuerySelectorArray(): number {
        let pageIndex: number = this.querySelectorArray.findIndex((querySelector: QuerySelector) => querySelector.type === UtilityEnums.SingleSelectType.Page);
        if (pageIndex !== -1) {
            let pageValue = this.querySelectorArray[pageIndex].value;
            if (pageValue.includes('-')) {
                let startPageCount: number = +pageValue.split('-')[0];
                return startPageCount;
            } else {
                return 1;
            }
        } else {
            return 1;
        }
    }

    private getPageCountFromQuerySelectorArray(): number {

        let pageIndex: number = this.querySelectorArray.findIndex((querySelector: QuerySelector) => querySelector.type === UtilityEnums.SingleSelectType.Page);
        if (pageIndex !== -1) {
            let pageValue = this.querySelectorArray[pageIndex].value;
            if (pageValue.includes('-')) {
                let pageCount: number = +pageValue.split('-')[1];
                return pageCount;
            } else {
                return +pageValue;
            }
        } else {
            return 1;
        }
    }

    async handleProductListingPageHeaderQuickLink(e: CustomEvent) {
        let currentQuerySelector: string = this.querySelectorString;
        let optionType: string = e.detail.type;
        let optionValue: string = e.detail.value;
        console.log(`ProductListingPage: handleProductListingPageHeaderQuickLink`);
      
        try{
            this.sendQuickLinkClickGtm(optionType, optionValue, currentQuerySelector);
        } catch(error){
            console.log(`ProductListingPage: handleProductListingPageHeaderQuickLink reinitialiseProductListingPageWith ERROR`,error);
        }   
    }

    sendQuickLinkClickGtm(optionType: string, optionValue: string, currentQuerySelector: string): void {
        try {
            let dataObject = {
                id: `${optionType}`,
                url: `/browse/${currentQuerySelector}`,
                targetUrl: `${optionValue}`,
                name: this.productListingPageHeader.getHeadingText().replace(/,/g, ''),
                aggregateList: this.getAggregateList()
            } as IQuickLinkClickGtmDataObject;

            GoogleTagManager.sendQuickLinkClickGtmDataObjectAndNavigate(dataObject, optionValue);
        } catch (error) {
            console.log('ProductListingPageQuickLink: sendQuickLinkClickGtm ERROR', error);
        }
    }


    removePageNumberInQuerySelectorStringAndArray() {
        let pageIndex: number = this.querySelectorArray.findIndex((querySelector: QuerySelector) => querySelector.type === UtilityEnums.SingleSelectType.Page);
        if (pageIndex > -1) {
            // Remove page if page is present
            this.querySelectorArray = this.querySelectorArray.filter((querySelector: QuerySelector) => querySelector.type !== UtilityEnums.SingleSelectType.Page);
        }
        this.querySelectorString = this.getQuerySelectorStringFromQuerySelectorArray(this.querySelectorArray);
    }
    static removePageNumberInQuerySelectorArray(querySelectorArray: Array<QuerySelector>) {
        let pageIndex: number = querySelectorArray.findIndex((querySelector: QuerySelector) => querySelector.type === UtilityEnums.SingleSelectType.Page);
        
        if (pageIndex > -1) {
            // Remove page if page is present
            querySelectorArray = querySelectorArray.filter((querySelector: QuerySelector) => querySelector.type !== UtilityEnums.SingleSelectType.Page);
        }
        return querySelectorArray;
    }

    static removePageNumberInQuerySelectorString(querySelectorString: string): string {
        let querySelectorArray = ProductListingPage.createQuerySelectorArrayFromString(querySelectorString);
        let querySelectorArrayWithoutPage = ProductListingPage.removePageNumberInQuerySelectorArray(querySelectorArray);
        let querySelectorStringWithoutPage = ProductListingPage.createQuerySelectorStringFromQuerySelectorArray(querySelectorArrayWithoutPage);
        return querySelectorStringWithoutPage;
    }

    setQuerySelectorString() {
        this.querySelectorString = this.getQuerySelectorString();
    }

    getQuerySelectorString() {
        let querySelectorString: string = this.htmlElement.getAttribute(AttributeEnums.ProductListingPage.ProductListingPage);
        return querySelectorString;
    }

    static getQuerySelectorString() {
        let querySelectorElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPage.ProductListingPage));
        let querySelectorString = querySelectorElement.getAttribute(AttributeEnums.ProductListingPage.ProductListingPage);
        return querySelectorString;
    }

    writeToPreviousQuerySelectorString(querySelectorString: string) {
        this.htmlElement.setAttribute(AttributeEnums.ProductListingPage.ProductListingPage, querySelectorString);
    }

    static hasDifferentQuerySelector(querySelector: string): boolean {
        if (querySelector === ProductListingPage.getQuerySelectorString()) {
            return false;
        } else {
            return true;
        }
    }

    static hasDifferentQuerySelectorWhenPageIsRemoved(currentQuerySelectorString: string, newQuerySelectorString: string) {
        var currentQuerySelectorStringWithoutPage = ProductListingPage.removePageNumberInQuerySelectorString(currentQuerySelectorString);
        var newQuerySelectorStringWithoutPage = ProductListingPage.removePageNumberInQuerySelectorString(newQuerySelectorString);

        if (currentQuerySelectorStringWithoutPage === newQuerySelectorStringWithoutPage) {
            return false;
        } else {
            return true;
        }
    }

    writeToQuerySelectorString(querySelectorString: string) {
        this.htmlElement.setAttribute(AttributeEnums.ProductListingPage.ProductListingPage, querySelectorString);
    }


    updateQuerySelectorString(querySelectorString: string) {
        this.writeToQuerySelectorString(querySelectorString);
        this.setQuerySelectorString();
        console.log(`ProductListingPage: updateQuerySelectorString ${this.querySelectorString}`);

    }

    ifQueryArrayHasNoCategoryRemoveExtrasFromQueryArrayAndQueryString() {
        if (!this.querySelectorArrayHasCategory()) {
            this.removeExtraQuerySelectorsFromArrayAndUrl();
        }
    }

    querySelectorArrayHasCategory() {
        let catagoryIndex = this.querySelectorArray.findIndex((querySelector: QuerySelector) => querySelector.type == UtilityEnums.SingleSelectType.Category);
        if (catagoryIndex !== -1) {
            return true;
        } else {
            return false;
        }
    }
    removeExtraQuerySelectorsFromArrayAndUrl() {
        let updatedQuerySelectorArray: QuerySelector[] = new Array<QuerySelector>();
        for (let querySelector in this.querySelectorArray) {
            if (!this.isQuerySelectorAnExtra(this.querySelectorArray[querySelector])) {
                updatedQuerySelectorArray.push(this.querySelectorArray[querySelector]);
            }
        }
        this.querySelectorArray = updatedQuerySelectorArray;
        this.querySelectorString = this.getQuerySelectorStringFromQuerySelectorArray(updatedQuerySelectorArray);
    }

    isQuerySelectorAnExtra(querySelector: QuerySelector): boolean {

        if (querySelector.type.includes('_')) {
            return true;
        } else {
            return false;
        }
    }

    async reinitialiseProductListingPageWith(newQuerySelectorString: string) {
        console.log(`ProductListingPage: reinitialiseProductListingPageWith - Started`);
        this.popoutController.active = false;

        await this.reinitialiseProductListingPageWithNoHistory(newQuerySelectorString);
        await this.setHistoryAndTitle();

        console.log(`ProductListingPage: reinitialiseProductListingPageWith - End`);
    }

    async reinitialiseProductListingPageWithNoHistory(newQuerySelectorString: string) {

        let optionsUpdateDto: OptionUpdateDto = this.getOptionUpdateDto();
        this.displayOptionSelectedLoadingOverlays();
        console.log(`ProductListingPage: reinitialiseProductListingPageWithNoHistory - reinitialiseTiles Started`);
        await this.reinitialiseTiles();
        console.log(`ProductListingPage: reinitialiseProductListingPageWithNoHistory - reinitialiseHeader Started`);
        await this.reinitialiseHeader();
        console.log(`ProductListingPage: reinitialiseProductListingPageWithNoHistory - updateBreadcrumb Started`);
        this.updateBreadcrumb();
        this.disposePageOptionsForReinitialise();

        console.log(`ProductListingPage: reinitialiseProductListingPageWithNoHistory - productListingPageOptionsFactory Started`);
        this.removeNoScrollIfMobileMenuOpen();
        await this.productListingPageOptionsFactoryForUpdate(optionsUpdateDto);
        console.log(`ProductListingPage: reinitialiseProductListingPageWithNoHistory - productListingPageOptionsFactory Finished`);
       
        this.setProductListingPageShowMore();

        console.log(`ProductListingPage: reinitialiseProductListingPageWithNoHistory - reinitialiseProductListingPageSelectedOptionsCarousels Started`);
        this.reinitialiseProductListingPageSelectedOptionsCarousels();
        //this.reinitialiseCategoryPagingSelectableCarousel();
        //location.href = "#"+ UtilityEnums.AnchorIds.CategoryCarousel; 
        this.htmlElement.scrollIntoView();
        this.addNoScrollIfMobileMenuOpen();
       

    }
    removeNoScrollIfMobileMenuOpen() {
        let url: string = window.location.href;
        let plpMobileOptionsMenuOpen: boolean = url.includes(`#${AttributeEnums.ProductListingPageOptions.ProductListingPageOptionsPopOutValue}`);

        if (ResponsiveState.getStateAsEnum() !== StringEnums.Selectors.Desktop && plpMobileOptionsMenuOpen === true) {
            ReadyUtils.removeBodyNoScroll();
        }
    }

    addNoScrollIfMobileMenuOpen() {
        let url: string = window.location.href;
        let plpMobileOptionsMenuOpen: boolean = url.includes(`#${AttributeEnums.ProductListingPageOptions.ProductListingPageOptionsPopOutValue}`);

        if (ResponsiveState.getStateAsEnum() !== StringEnums.Selectors.Desktop && plpMobileOptionsMenuOpen === true) {
            ReadyUtils.addBodyNoScroll();
        }
    }

    setProductListingPageShowMore(){
        try{
        this.productListingPageShowMore.updateProductListingPageShowingStartProductAndProductCount(this.productListingPageHeader.startProduct, this.productListingPageHeader.productCount);
        this.productListingPageShowMore.updateProductListingPageShowingTotalProducts(this.productListingPageHeader.totalProducts);
        this.productListingPageShowMore.updateShowMoreButtonEnabledDisabledStyle();
        this.productListingPageShowMore.setIsLoadingFalse();
        this.productListingPageOptions.updateProductListingPageShowingTotalProducts(this.productListingPageHeader.totalProducts);
        } catch(error){
            console.log(`ProductListingPage: setProductListingPageShowMore - ERROR setting show more values`, error);

        }
    }

    displayOptionSelectedLoadingOverlays() {
        this.productListingPageTiles.displayProductListingTilesLoadingTiles();
        this.productListingPageShowMore.setIsLoadingTrue();
        this.productListingPageSelectedOptionsCarousel.displayLoading();
        this.productListingPageSelectedOptionsBlock.displayLoading();
        //this.categoryPagingSelectableCarousel.displayLoading();
        this.productListingPageOptions.displayLoading();
        this.productListingPageHeader.disableSortDropdown();
        this.optionsLoaded = false;
    }

    disposePageOptionsForReinitialise() {
        this.productListingPageOptions.dispose();
        this.productListingPageOptions = null;
        this.productListingPageSelectedOptionsCarousel.dispose();
        this.productListingPageSelectedOptionsBlock.dispose();
    }

    addRemoveSelectedDeselectedOption(type: string, value: string): string {

        this.removeAndUpdateSelectedOptionsWithNoProducts();

        let updatedQuerySelectorString: string;
        let querySelectorSegment: string = `${type}--${value}`;
        let hasSelectorSegment: boolean = this.querySelectorString.includes(querySelectorSegment);
        let hasType = this.querySelectorString.includes(type);

        if (hasSelectorSegment) {
            updatedQuerySelectorString = this.removeQuerySelectorSegmentFromQuerySelectorString(this.querySelectorString, querySelectorSegment);
        }
        else if (hasType && this.isSingleSelect(type)) {
            let querySelectorArrayWithTypeRemoved = this.removeQuerySelectorSegmentsFromQuerySelectorArrayByType(this.querySelectorArray, type);
            let updatedQuerySelectorArray: QuerySelector[] = this.insertQuerySelectorSegmentIntoQuerySelectorArray(querySelectorArrayWithTypeRemoved, type, value);
            updatedQuerySelectorString = this.getQuerySelectorStringFromQuerySelectorArray(updatedQuerySelectorArray);
        }
        else {
            let updatedQuerySelectorArray: QuerySelector[] = this.insertQuerySelectorSegmentIntoQuerySelectorArray(this.querySelectorArray, type, value);
            updatedQuerySelectorString = this.getQuerySelectorStringFromQuerySelectorArray(updatedQuerySelectorArray);
        }

        

        return updatedQuerySelectorString;
    }

    removeAndUpdateSelectedOptionsWithNoProducts() {
        try{
        
        let updatedQuerySelectorString: string = this.removeSelectedOptionsWithNoProducts();
        this.updateQuerySelectorUrlAndArrayForNewQuerySelectorString(updatedQuerySelectorString);
        } catch(error) {
            console.log(`ProductListingPage: removeAndUpdateSelectedOptionsWithNoProducts Error`, error);  
        } 
    }

    removeSelectedOptionsWithNoProducts(){
        try{
        
        let allSelectedOptions = this.productListingPageOptions.getAllSelectedOptions();
        let querySelectorArrayWithoutSortAndPage = this.querySelectorArray.filter((querySelector: QuerySelector) => { return (querySelector.type !== UtilityEnums.SingleSelectType.Page) && (querySelector.type !== UtilityEnums.SingleSelectType.Sort)} );
        let selectedQuerySelectorStringList = allSelectedOptions.map((option: IProductListingPageOption) => {return `${option.optionType}--${option.optionValue}`});
        const filtersToRemove = querySelectorArrayWithoutSortAndPage.filter((querySelector: QuerySelector) => !selectedQuerySelectorStringList.includes(`${querySelector.type}--${querySelector.value}`));
            
        let updatedQuerySelectorString: string = this.querySelectorString;
        filtersToRemove.forEach((querySelector: QuerySelector) => {
            updatedQuerySelectorString = updatedQuerySelectorString.replace(`--${querySelector.type}--${querySelector.value}`, '');
            updatedQuerySelectorString = updatedQuerySelectorString.replace(`${querySelector.type}--${querySelector.value}`, '');
            updatedQuerySelectorString = updatedQuerySelectorString.replace(/--/y, '');
        });
        return updatedQuerySelectorString;
        
        } catch(error) {
            console.log(`ProductListingPage: removeSelectedOptionsWithNoProducts Error`, error);
            return this.querySelectorString;
        } 
    }

    isSingleSelect(type: string): boolean {
        if (Object.values(UtilityEnums.SingleSelectType).includes(type as UtilityEnums.SingleSelectType)) {
            return true;
        } else {
            return false;
        }
    }


    removeQuerySelectorSegmentFromQuerySelectorString(querySelectorString: string, querySelectorSegment: string): string {
        let segmentIndex = querySelectorString.indexOf(querySelectorSegment);

        if (segmentIndex == 0) {
            if (querySelectorSegment === querySelectorString) {
                // Last Query to be removed
                console.log(`ProductListingPage: removeQuerySelectorSegmentFromQuerySelectorString querySelectorString ${querySelectorString} querySelectorSegment: ${querySelectorSegment} remove final query returning an empty string`);
                return '';
            } else {
                let stringToReplace: string = `${querySelectorSegment}--`;

                let updatedQuerySelectorString = querySelectorString.replace(stringToReplace, '');
                console.log(`ProductListingPage: removeQuerySelectorSegmentFromQuerySelectorString querySelectorString ${querySelectorString} querySelectorSegment: ${querySelectorSegment} updatedQuerySelectorString: ${updatedQuerySelectorString}`);
                return updatedQuerySelectorString;
            }
        } else if (segmentIndex > 0) {
            let updatedQuerySelectorString = querySelectorString.replace(`--${querySelectorSegment}`, '');
            console.log(`ProductListingPage: removeQuerySelectorSegmentFromQuerySelectorString querySelectorString ${querySelectorString} querySelectorSegment: ${querySelectorSegment} updatedQuerySelectorString: ${updatedQuerySelectorString}`);
            return updatedQuerySelectorString;
        } else {
            console.log(`ProductListingPage: removeQuerySelectorSegmentFromQuerySelectorString querySelectorString ${querySelectorString} querySelectorSegment: ${querySelectorSegment} updatedQuerySelectorString: NOT UPDATED!!!`);
            return querySelectorString;
        }
    }


    removeQuerySelectorSegmentFromQuerySelectorArray(querySelectorArray: QuerySelector[], type: string, value: string): QuerySelector[] {
        let filteredQuerySelectorArray = querySelectorArray.filter((querySelector: QuerySelector) => !(querySelector.type === type && querySelector.value === value));
        return filteredQuerySelectorArray;
    }
    removeQuerySelectorSegmentsFromQuerySelectorArrayByType(querySelectorArray: QuerySelector[], type: string): QuerySelector[] {
        let filteredQuerySelectorArray = querySelectorArray.filter((querySelector: QuerySelector) => !(querySelector.type === type));
        return filteredQuerySelectorArray;
    }


    insertQuerySelectorSegmentIntoQuerySelectorArray(querySelectorArray: QuerySelector[], type: string, value: string) {
        let updatedQuerySelectorArray = querySelectorArray;
        let newQuerySelector: QuerySelector = { type: type, value: value };
        updatedQuerySelectorArray.push(newQuerySelector);
        return this.sortQuerySelectorArray(updatedQuerySelectorArray);

    }

    //getQuerySelectorStringFromQuerySelectorArray(querySelectorArray: QuerySelector[]): string {
    //    let sortedArray = this.sortQuerySelectorArray(querySelectorArray);
    //    let querySelectorString: string = '';
    //    sortedArray.forEach((querySelector: QuerySelector) => { querySelectorString += `${querySelector.type}--${querySelector.value}--` });
    //    querySelectorString = querySelectorString.replace(/-+$/, '');
    //    return querySelectorString;
    //}

    getQuerySelectorStringFromQuerySelectorArray(querySelectorArray: QuerySelector[]): string {
        
        return ProductListingPage.createQuerySelectorStringFromQuerySelectorArray(querySelectorArray);
    }

    static createQuerySelectorStringFromQuerySelectorArray(querySelectorArray: QuerySelector[]): string {
        let sortedArray = ProductListingPage.staticSortQuerySelectorArray(querySelectorArray);
        let querySelectorString: string = '';
        sortedArray.forEach((querySelector: QuerySelector) => { querySelectorString += `${querySelector.type}--${querySelector.value}--` });
        querySelectorString = querySelectorString.replace(/-+$/, '');
        return querySelectorString;
    }

    sortQuerySelectorArray(querySelectorArray: QuerySelector[]): QuerySelector[] {
        return ProductListingPage.staticSortQuerySelectorArray(querySelectorArray);
    }

    static staticSortQuerySelectorArray(querySelectorArray: QuerySelector[]): QuerySelector[] {
        return querySelectorArray.sort(function (a, b) {
            if (a.type === b.type) {
                if (a.value > b.value) {
                    return 1;
                } else if (a.value < b.value) {
                    return -1;
                }
            } else if (a.type > b.type) {
                return 1;
            } else if (a.type < b.type) {
                return -1;
            }
        });
    }

    //getQuerySelectorArray(): QuerySelector[] {
    //    let queryPiecesStringArray = this.querySelectorString.split('--');
    //    let querySelectorArray: QuerySelector[] = new Array<QuerySelector>();
    //    for (let i = 0; i < queryPiecesStringArray.length; i += 2) {
    //        let currentQuerySelector: QuerySelector = { type: queryPiecesStringArray[i], value: queryPiecesStringArray[i + 1] };
    //        querySelectorArray.push(currentQuerySelector);
    //    }
    //    return querySelectorArray;

    //}

    getQuerySelectorArray(): QuerySelector[] {
        return ProductListingPage.createQuerySelectorArrayFromString(this.querySelectorString);
    }

    static createQuerySelectorArrayFromString(querySelectorString: string): QuerySelector[] {
        if (querySelectorString === '') {
            querySelectorString = 'page--1';
        }
        let queryPiecesStringArray = querySelectorString.split('--');
        let querySelectorArray: QuerySelector[] = new Array<QuerySelector>();
        for (let i = 0; i < queryPiecesStringArray.length; i += 2) {
            let currentQuerySelector: QuerySelector = { type: queryPiecesStringArray[i], value: queryPiecesStringArray[i + 1] };
            querySelectorArray.push(currentQuerySelector);
        }
        return querySelectorArray;

    }

    dispose(): void {
        try {
            this.removeProductListingPageOptionSelectedEventListener();
            this.removeProductListingPageSortChangedEventListener();
            this.removeProductListingPageShowMoreEventListener();
            this.removeProductListingPageOptionDeselectedEventListener();
            this.removeProductListingPageHistoryBackEventListener();
            this.removeProductListingPageNoProductsReturnedEventListener();
            this.removeProductListingPageHeaderQuickLinkEventListener();
            this.productListingPageOptions.dispose();
            this.productListingPageHeader.dispose();
            this.productListingPageTiles.dispose();
            this.productListingPageShowMore.dispose();
        } catch (error) {
            console.log('ProductListingPage: dispose ERROR', error);
        }

    }



    delete(): void {

    }
}