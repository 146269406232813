import Swal from 'sweetalert2';
import { InstanceComponentBase } from '../../shared/instanceComponentBase';
import { AttributeEnums } from '../../shared/utilities/enums/attributeEnums';
import { CustomEventEnums } from '../../shared/utilities/enums/customEventEnums';
import { StringEnums } from '../../shared/utilities/enums/stringEnums';
import { HelperUtils } from '../../shared/utilities/helperUtils';
import { TypeUtils } from '../../shared/utilities/typeUtils';
import { Gallery } from '../gallery/galleryController';

export interface IColourPickerState {
  selectedPrice?: string;
  selectedLineId: number;
  selectedExtraId?: number;
  selectedColourPickerFor: string;
  selectedSwatchSrc?: string;
  selectedSwatchPreviewSrc?: string;
  selectedGroupId: number;
  selectedOptionName?: string;
}
export class ColourPicker extends InstanceComponentBase {

  private readonly gallery: Gallery;
  private readonly _confirmColourButton: Element;
  private readonly _setColourPickerSelectedStateFromDropdown;
  private readonly _setDropdownWithEvent;
  private readonly _setDropdownWithGallery;
  private readonly _setColourPickerSelectedStateFromGalleryCellAndUpdateAllWithEvent;
  private readonly _confirmColourEvent;

  private colourPickerState: IColourPickerState;
  constructor(public htmlElement: Element) {
    super(htmlElement);

    let familyName = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ColourPicker.ColourPickerGallery)).getAttribute(AttributeEnums.ColourPicker.ColourPickerGallery);
    let gallery = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ColourPicker.ColourPickerGallery, familyName));

    this.gallery = new Gallery(gallery);

    //----- Event Listener Bindings Start -----//
    this._setColourPickerSelectedStateFromDropdown = this.setColourPickerSelectedStateFromDropdown.bind(this);
    this._setDropdownWithEvent = this.setDropdownWithEvent.bind(this);
    this._setDropdownWithGallery = this.setDropdownWithGallery.bind(this);
    this._setColourPickerSelectedStateFromGalleryCellAndUpdateAllWithEvent = this.setColourPickerSelectedStateFromGalleryCellAndUpdateAllWithEvent.bind(this);
    this._confirmColourEvent = this.confirmColourEvent.bind(this);
    //----- Event Listener Bindings End -----//

    //----- Initialise Event Listeners Start -----//
    this.attachDropdownEventListener();
    this.attachCellEventListener();
    this._confirmColourButton = htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ColourPicker.ConfirmColourFor));
    this.attachClickEventListenerForConfirm(this._confirmColourButton);
    //----- Initialise Event Listeners End -----//

    this.colourPickerState = this.initialiseColourPickerSelectedState();
    this.updateAll();
  }

  //------ Add Event Listeners Start -----//
  attachDropdownEventListener() {
    this.htmlElement.addEventListener('change', this._setColourPickerSelectedStateFromDropdown);
  }

  attachCellEventListener() {
    let cells = this.gallery.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.Gallery.GallerySelectableCell));
    for (let i = 0; i < cells.length; i++) {
      this.attachHoverEventListener(cells[i]);
      this.attachLeaveEventListener(cells[i]);
      this.attachClickEventListener(cells[i]);
    }
  }

  attachHoverEventListener(cell: Element) {
    cell.addEventListener('mouseenter', this._setDropdownWithEvent);
  }

  attachLeaveEventListener(cell: Element) {
    cell.addEventListener('mouseleave', this._setDropdownWithGallery);
  }

  attachClickEventListener(cell: Element) {
    cell.addEventListener('click', this._setColourPickerSelectedStateFromGalleryCellAndUpdateAllWithEvent);
  }

  attachClickEventListenerForConfirm(element: Element) {
    element.addEventListener('click', this._confirmColourEvent);
  }
  //------ Add Event Listeners End -----//

  //------ Remove Event Listeners Start -----//
  removeDropdownEventListener() {
    this.htmlElement.removeEventListener('change', this._setColourPickerSelectedStateFromDropdown);
  }

  removeCellEventListener() {
    let cells = this.gallery.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.Gallery.GallerySelectableCell));
    for (let i = 0; i < cells.length; i++) {
      this.removeHoverEventListener(cells[i]);
      this.removeLeaveEventListener(cells[i]);
      this.removeClickEventListener(cells[i]);
    }
  }

  removeHoverEventListener(cell: Element) {
    cell.removeEventListener('mouseenter', this._setDropdownWithEvent);
  }

  removeLeaveEventListener(cell: Element) {
    cell.removeEventListener('mouseleave', this._setDropdownWithGallery);
  }

  removeClickEventListener(cell: Element) {
    cell.removeEventListener('click', this._setColourPickerSelectedStateFromGalleryCellAndUpdateAllWithEvent);
  }

  removeClickEventListenerForConfirm(element: Element) {
    element.removeEventListener('click', this._confirmColourEvent);
  }
  //------ Remove Event Listeners End -----//

  initialiseColourPickerSelectedState(): IColourPickerState {
    let hiddenInput = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ColourPicker.ColourPickerFor)) as HTMLElement;
    return ColourPicker.mapSelectedColourHiddenInputToIColourPicker(hiddenInput);

  }

  updateAll() {
    if ((this.colourPickerState.selectedExtraId !== null) && (this.colourPickerState.selectedExtraId !== undefined)) {
      this.gallery.setMainBackgroundAreaById(this.colourPickerState.selectedExtraId);
      this.gallery.setTileToSelectedById(this.colourPickerState.selectedExtraId);
      this.gallery.selectedElement = this.gallery.getSelectedCellById(this.colourPickerState.selectedExtraId);
      this.setDropdownById(this.colourPickerState.selectedExtraId);
      ColourPicker.updateDataAttributesForProductColourChange(this.colourPickerState, this.htmlElement);
      this.hideNoColourSelectedValidation();
    }
  }

  //------ Event Listener Methods Start -----//
  setColourPickerSelectedStateFromDropdown(e: Event) {
    console.log('ColourPicker: setColourPickerSelectedStateFromDropdown - Beginning');

    let targetElement = e.target as HTMLSelectElement;
    let id = targetElement.options[targetElement.selectedIndex].value;
    if (id === "0") {
      this.reset();
    } else {

      let selectedCell = this.getSelectedCellById(id) as HTMLElement;
      this.setColourPickerSelectedStateFromGalleryCellAndUpdateAll(selectedCell.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelectorItem)));
    }
  }

  setDropdownWithEvent(e: Event): void {
    console.log('ColourPicker: setDropdownWithEvent - Beginning');
    let targetElement = e.target as HTMLElement;
    this.setDropdown(targetElement);
  }

  setDropdownWithGallery(): void {
    console.log('ColourPicker: setDropdownWithGallery - Beginning');
    this.setDropdown(this.gallery.selectedElement);
  }

  setColourPickerSelectedStateFromGalleryCellAndUpdateAllWithEvent(e: Event): void {
    console.log('ColourPicker: setColourPickerSelectedStateFromGalleryCellAndUpdateAllWithEvent - Beginning');
    let targetElement = e.target as HTMLElement;
    this.setColourPickerSelectedStateFromGalleryCellAndUpdateAll(targetElement);
  }

  confirmColourEvent(e: Event) {
    let targetElement = e.target as HTMLElement;
    if (!this.colourPickerState.selectedExtraId) {
      this.showNoColourSelectedValidation();
    } else {
      let colourConfirmedType: string = targetElement.getAttribute(AttributeEnums.ColourPicker.ConfirmColourFor);
      targetElement.dispatchEvent(this.colourConfirmedEventFactory(colourConfirmedType));
    }
  }

  setColourPickerSelectedStateFromGalleryCellAndUpdateAll(selectedCell: HTMLElement) {
    this.setColourPickerSelectedStateFromGalleryCell(selectedCell);
    this.updateAll();
  }
  setColourPickerSelectedStateFromGalleryCell(colourPickerTile: HTMLElement) {
    this.colourPickerState = ColourPicker.mapColourPickerSelectedStateFromGalleryCell(colourPickerTile, this.colourPickerState);
  }

  setDropdown(cell: Element) {
    if (cell != null) {
      let id = cell.getAttribute(AttributeEnums.Gallery.GalleryId);
      let element;
      if (id) {
        element = <HTMLOptionElement>this.htmlElement.querySelector('option[value="' + id + '"]');
        element.selected = true;
      } else {
        this.reset();
      }
    }
  }

  setDropdownById(id: number) {
    let element;
    if (id) {
      element = <HTMLOptionElement>this.htmlElement.querySelector('option[value="' + id + '"]');
      element.selected = true;
    } else {
      this.reset();
    }
  }

  setSelected(id: number) {
    let cell = this.getSelectedCellById(id.toString());
    this.setDropdown(cell);
    this.gallery.selectedElement = cell;
    this.gallery.setTileToSelected(cell);
    this.gallery.setMainAreaBackground(cell);
  }

  getSelectedCellById(id: string) {
    return this.gallery.htmlElement.querySelector(
      HelperUtils.addDataAttributeTags(AttributeEnums.Gallery.GalleryId, id));
  }

  showNoColourSelectedValidation() {
    Swal.fire(StringEnums.Content.PleaseSelectAColour);

    let colourPickerDropDown = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ColourPicker.ColourPickerDropDown)).closest(HelperUtils.addCssClassDot(StringEnums.CssClass.LbFormElementDropDown)) as HTMLElement;
    colourPickerDropDown.classList.add(StringEnums.CssClass.LbWarning);
    let colourPickerGallery = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ColourPicker.ColourPickerGallery)) as HTMLElement;
    colourPickerGallery.classList.add(StringEnums.CssClass.LbWarning);
  }
  //------ Event Listener Methods End -----//

  //------ Custom Event Creation Start -----//
  colourConfirmedEventFactory(type: string) {
    return new CustomEvent(CustomEventEnums.ColourConfirmed, {
      bubbles: true,
      cancelable: true,
      composed: false,
      detail: {
        'type': type
      }
    });
  }
  //------ Custom Event Creation End -----//

  static mapColourPickerSelectedStateFromGalleryCell(selectedCell: HTMLElement, existingColoutPickerState: IColourPickerState) {
    let colourPickerState: IColourPickerState = {
      ...existingColoutPickerState,
      selectedGroupId: +selectedCell.getAttribute(AttributeEnums.ProductSelector.ProductSelectorGroupId),
      selectedExtraId: TypeUtils.castStringToNumberOrUndefined(selectedCell.getAttribute(AttributeEnums.ProductSelector.ProductSelectorExtraId)),
      selectedOptionName: selectedCell.getAttribute(AttributeEnums.ProductSelector.ProductSelectorName),
      selectedSwatchSrc: selectedCell.getAttribute(AttributeEnums.ProductSelector.ProductSelectorSwatchSrc),
      selectedSwatchPreviewSrc: ColourPicker.getSelectedSwatchPreviewSrc(selectedCell.getAttribute(AttributeEnums.ProductSelector.ProductSelectorSwatchSrc)),
      selectedPrice: selectedCell.getAttribute(AttributeEnums.ProductSelector.ProductSelectorPrice),
    };
    return colourPickerState;
  }
  setSelectedSwatchPreviewSrcFromSelectedSwatchSrc() {
    if(this.colourPickerState.selectedSwatchSrc !== null && this.colourPickerState.selectedSwatchSrc !== undefined) {
      ColourPicker.getSelectedSwatchPreviewSrc(this.colourPickerState.selectedSwatchSrc);
    }
  }

  static getSelectedSwatchPreviewSrc(selectedSwatchSrc: string) {
    if(selectedSwatchSrc !== null && selectedSwatchSrc !== undefined) {
      return selectedSwatchSrc.replace('extrasmallsquarecropped', 'mediumletterboxcropped');
    }
  }

  static mapSelectedColourHiddenInputToIColourPicker(colourPickerNewSelection: HTMLElement): IColourPickerState {

    let colourPickerState: IColourPickerState = {

      selectedGroupId: +colourPickerNewSelection.getAttribute(AttributeEnums.ColourPicker.ColourPickerGroupId),
      selectedExtraId: TypeUtils.castStringToNumberOrUndefined(colourPickerNewSelection.getAttribute(AttributeEnums.ColourPicker.ColourPickerValue)),
      selectedOptionName: colourPickerNewSelection.getAttribute(AttributeEnums.ColourPicker.ColourPickerName),
      selectedSwatchSrc: colourPickerNewSelection.getAttribute(AttributeEnums.ColourPicker.ColourPickerSwatchSrc),
      selectedSwatchPreviewSrc: ColourPicker.getSelectedSwatchPreviewSrc(colourPickerNewSelection.getAttribute(AttributeEnums.ProductSelector.ProductSelectorSwatchSrc)),
      selectedPrice: colourPickerNewSelection.getAttribute(AttributeEnums.ColourPicker.ColourPickerAdditionPrice),
      selectedLineId: +colourPickerNewSelection.getAttribute(AttributeEnums.ColourPicker.ColourPickerLineId),
      selectedColourPickerFor: colourPickerNewSelection.getAttribute(AttributeEnums.ColourPicker.ColourPickerFor)
    };
    return colourPickerState;
  }

  static updateDataAttributesForProductColourChange(colourPickerState: IColourPickerState, htmlElement: Element): void {
    let dataProductColourHiddenElement = htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ColourPicker.ColourPickerValue));
    dataProductColourHiddenElement.setAttribute(AttributeEnums.ColourPicker.ColourPickerValue, colourPickerState.selectedExtraId.toString());
    dataProductColourHiddenElement.setAttribute(AttributeEnums.ColourPicker.ColourPickerName, colourPickerState.selectedOptionName);
    dataProductColourHiddenElement.setAttribute(AttributeEnums.ColourPicker.ColourPickerSwatchSrc, colourPickerState.selectedSwatchSrc);
    dataProductColourHiddenElement.setAttribute(AttributeEnums.ColourPicker.ColourPickerAdditionPrice, colourPickerState.selectedPrice);
  }

  static selectedTextFormatter(name: string): string {
    let prefixText = 'Selected ';
    let postfixText = ':';
    return prefixText + name + postfixText;
  }

  hideNoColourSelectedValidation() {
    let colourPickerDropDown = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ColourPicker.ColourPickerDropDown)).closest(HelperUtils.addCssClassDot(StringEnums.CssClass.LbFormElementDropDown)) as HTMLElement;
    colourPickerDropDown.classList.remove(StringEnums.CssClass.LbWarning);
    let colourPickerGallery = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ColourPicker.ColourPickerGallery)) as HTMLElement;
    colourPickerGallery.classList.remove(StringEnums.CssClass.LbWarning);
  }

  reset() {
    this.gallery.reset();
    let optionElement = <HTMLOptionElement>this.htmlElement.querySelector('option[value="' + 0 + '"]');
    optionElement.selected = true;
    this.gallery.setMainAreaBackground(this.gallery.placeholder);
  }

  //------ Clean Up Processes Start -----//
  dispose(): void {
    this.gallery.dispose();
    this.removeDropdownEventListener();
    this.removeCellEventListener();
    this.removeClickEventListenerForConfirm(this._confirmColourButton);
    this.delete();
  }

  delete(): void {
    if ((<any>window)[this.htmlElement.getAttribute(AttributeEnums.ColourPicker.ColourPicker) + 'ColourPicker']) {
      console.log('ColourPickerController: Delete - Deleting ColourPicker Reference');
      delete (<any>window)[this.htmlElement.getAttribute(AttributeEnums.ColourPicker.ColourPicker) + 'ColourPicker'];
    }
    else {
      console.log('ColourPickerController: Delete - ColourPicker Reference Not Found');
    }
  }
  //------ Clean Up Processes End -----//
}