export namespace UrlEnums {
    export enum ApiUrls {
        BasketUpdateAssembly = '/api/basketapi/update-assembly',
        BasketUpdateRemoval = '/api/basketapi/update-removal',
        BasketUpdateLineQuantity = '/api/basketapi/update-line-quantity',
        BasketDeleteLine = '/api/basketapi/delete-line',
        BasketLine = '/api/basket/line'
    }
    export enum PartialUrls {
        Basket = '/basket',
        BasketEmpty = '/basket/empty',
        BasketSimple = '/basket/simple/',
        BasketSummary = '/basket/basket-with-summary-only/',
        BasketRecommendationsTemplate = '/basket/recommendations-template/',
        ProductGalleryPopOut = '/product/gallery-pop-out',
        ProductFinancePanel = '/product/finance-panel/',
        ProductSelectionPanel = '/product/selection-panel/',
        ProductListingPageTiles = '/product-listing-page/product-tiles/',
        ProductListingPageTilesUnpaged = '/product-listing-page/product-tiles-unpaged/',
        ProductListingPageOptions = '/product-listing-page/product-options/',
        ProductListingPageHeaders = '/product-listing-page/page-header/',
        ProductListingPageTilesUpdate = '/product-listing-page/product-tiles/update',
        ProductListingPageOptionsUpdate = '/product-listing-page/product-options/update',
        ProductListingPageHeadersUpdate = '/product-listing-page/page-header/update',
        Search = '/search/',
        TrendingSearches = 'search/placeholders/trending-searches',
        MegaMenu = '/mega-menu',
        MegaMenuDesktop = '/mega-menu/desktop',
        MegaMenuMobile = '/mega-menu/handheld'
    }
    export enum PageUrls{
        ProductListingPageNoProducts = '/browse/no-products',
    }
    export enum ImageUrls {
        LbActive = 'lb-active'
    }

    export enum NotificationUrls{
        Notification = '/notification'
    }
}