import { PopOutController } from "../../shared/popOutController";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { StringEnums } from "../../shared/utilities/enums/stringEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { TypeUtils } from "../../shared/utilities/typeUtils";

export interface IGroupPickerState {
    selectedPrice?: string;
    selectedExtraId?: number;
    selectedGroupPickerFor: string;
    groupId: number;
    selectedOptionName?: string;
    groupName: string;
    selectorType: string;
}
export namespace GroupPicker {
    export function mapSelectedGroupHiddenInputToIGroupPicker(groupPickerNewSelection: HTMLElement): IGroupPickerState {
        try{
        console.log('GroupPicker: mapSelectedGroupHiddenInputToIGroupPicker Element', groupPickerNewSelection);
        let groupPickerState: IGroupPickerState = {

            groupId: +groupPickerNewSelection.getAttribute(AttributeEnums.ProductSelector.ProductSelectorGroupId),
            groupName: groupPickerNewSelection.getAttribute(AttributeEnums.ProductSelector.ProductSelectorGroupName),
            selectedExtraId: +groupPickerNewSelection.getAttribute(AttributeEnums.ProductSelector.ProductSelectorExtraId),
            selectedOptionName: groupPickerNewSelection.getAttribute(AttributeEnums.ProductSelector.ProductSelectorName),
            selectedPrice: groupPickerNewSelection.getAttribute(AttributeEnums.ProductSelector.ProductSelectorPrice),
            selectorType: groupPickerNewSelection.getAttribute(AttributeEnums.ProductSelector.ProductSelectorType),
            selectedGroupPickerFor: groupPickerNewSelection.getAttribute(AttributeEnums.ProductSelector.ProductSelectorFor),
        };
        return groupPickerState;
        } catch(error){
            console.log('GroupPicker: mapSelectedGroupHiddenInputToIGroupPicker Element ERROR', error);
            return null;
        }
    }

    export function updateSelectedElementOnClick(clickElement: HTMLElement) {
        let dataProductSelectorGroupAll = clickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.GroupPicker.GroupPicker)).querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelectorItem));
        deselectProductSelector(dataProductSelectorGroupAll);
        selectProductSelector(clickElement);
    }

    function deselectProductSelector(productSelectorGroup: NodeListOf<Element>): void {
        console.log('SelectorGlobal: deselectProductSelector');
        for (let i = 0; i < productSelectorGroup.length; i += 1) {
            const currentElement = productSelectorGroup[i];
            currentElement.classList.remove(StringEnums.CssClass.LbSelected);
            currentElement.setAttribute(AttributeEnums.ProductSelector.ProductSelectorIsSelected, 'false');
        }
    }

    function selectProductSelector(clickElement: HTMLElement): void {
        console.log('SelectorGlobal: selectProductSelector');
        clickElement.classList.add(StringEnums.CssClass.LbSelected);
        clickElement.setAttribute(AttributeEnums.ProductSelector.ProductSelectorIsSelected, 'true');
        let popOutElement: Element = clickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.GroupPicker.GroupPicker) + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutId));
        if (PopOutController.hasIdLowerLevel(popOutElement.getAttribute(AttributeEnums.PopOut.PopOutId))) {

            let levelUpPopOutId: string = PopOutController.getIdLowerLevel(popOutElement.getAttribute(AttributeEnums.PopOut.PopOutId));
            ((<any>window).popOutController as PopOutController).openByIdAndFamily(levelUpPopOutId, popOutElement.getAttribute(AttributeEnums.PopOut.PopOutFamily));
        } else {
            ((<any>window).popOutController as PopOutController).closeAllMenus();
        }
    }


}
