import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { ColourPicker, IColourPickerState } from "../colourPicker/colourPickerController";
import { Product } from "./product";
import { IProductDropDown, IProductSelector, ProductDropDown } from "./productDropDown";
import { IProductGroupDropDown, IProductGroupSelector, ProductGroupDropDown } from "./productGroupDropDown";
import { getProductWithTypeName } from "./productInitialiser";

type IProductGroupDropDownOmit = Omit<IProductGroupDropDown, 'updateProductDropDown'>;

export interface IProductColourDropDown extends IProductGroupDropDownOmit {
    productSwatchTitle: string | undefined;
    productSwatchStyle: string | undefined;
    updateProductDropDown: (newDisplayText: string, newDisplayName: string, newValue: number | undefined, newAdditionPrice?: number, newSwatchStyle?: string, newSwatchTitle?: string) => void;
}


export class ProductColourDropDown extends ProductGroupDropDown implements IProductColourDropDown {
    productSwatchTitle: string | undefined;
    productSwatchStyle: string | undefined;
    private readonly _updateAfterColourConfirmed;
    private readonly _correctColourPickerElement: HTMLElement;
    colourPicker: ColourPicker;

    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);
        this._correctColourPickerElement = this.getCorrectColourPickerElement();

        this.initialiseColourProperties();
        this.colourPickerFactory();  
        //----- Event Listener Bindings Start -----//
        this._updateAfterColourConfirmed = this.updateAfterColourConfirmed.bind(this);

        //----- Event Listener Bindings End -----//

        //----- Initialise Event Listeners Start -----//
        this.attachColourConfirmedEventListener();
        //----- Initialise Event Listeners End -----//
    }

    //------ Add Event Listeners Start -----//
    attachColourConfirmedEventListener() {
        console.log('Product: removeColourConfirmedEventListener');
        this._correctColourPickerElement.addEventListener(CustomEventEnums.ColourConfirmed, this._updateAfterColourConfirmed);
    }
    //------ Add Event Listeners End -----//

    //------ Remove Event Listeners Start -----//
    removeColourConfirmedEventListener() {
        console.log('Product: removeColourConfirmedEventListener');
        this._correctColourPickerElement.removeEventListener(CustomEventEnums.ColourConfirmed, this._updateAfterColourConfirmed);
    }
    //------ Remove Event Listeners End -----//

    //------ Event Listener Methods Start -----//
    updateAfterColourConfirmed(event: CustomEvent) {
        console.log('Product: updateAfterColourConfirmed - ColourConfirmed Event Fired');
        console.log('Product: updateAfterColourConfirmed - ColourConfirmed Type: ', event.detail);
        //let element = event.currentTarget as HTMLElement;
        let eventDetail = event.detail;
        let productType: string = eventDetail.type;
        if(productType === this.getProductContainerElement().getAttribute(AttributeEnums.Product.Product)) {
        let correctProduct: Product = getProductWithTypeName(productType);
        console.log(`Product: updateAfterColourConfirmed productType: ${productType} dataProductType: ${correctProduct.dataProductType}`);
        if (correctProduct != null) {
            let colourPickerGalleryElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ColourPicker.ColourPickerFor, correctProduct.dataProductType)) as HTMLElement;

            if (colourPickerGalleryElement) {
                this.updateDataAttributesForProductColourConfirm(colourPickerGalleryElement, correctProduct);
                //(<any>window).popOutController.openByIdAndFamily('1:1', correctProduct.dataProductType);

                this.closeOptionsPickerPopout();
            }
        }
        }
    }

    updateDataAttributesForProductColourConfirm(colourPickerNewSelection: HTMLElement, correctProduct: Product): void {
        let colourPickerState: IColourPickerState = ColourPicker.mapSelectedColourHiddenInputToIColourPicker(colourPickerNewSelection);
        console.log("updateDataAttributesForProductColourConfirm");
        let colourDropDown: ProductColourDropDown = correctProduct.productOptionsPanel.getColourDropdown();
        let selectedText = 'Selected Colour: ';
        colourDropDown.updateProductDropDown(selectedText, colourPickerState.selectedOptionName,
            colourPickerState.selectedExtraId, +colourPickerState.selectedPrice,
            "background-image: url('" + colourPickerState.selectedSwatchSrc + "')",
            colourPickerState.selectedOptionName
        );
        this.updateSwatchImage(colourPickerState.selectedSwatchSrc, colourPickerState.selectedOptionName);
        correctProduct.productPrice.updateProductPrice(correctProduct.productOptionsPanel.getTotalProductAdditionsPrice());

        this.htmlElement.dispatchEvent(correctProduct.productSelectorChangedEvent(correctProduct.dataProductType));
    }
    //------ Event Listener Methods End -----//

    updateSwatchImage(selectedSwatchSrc: string, selectedOptionName: string){
        let swatchImageElements : NodeListOf<HTMLElement> = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.SwatchImage.SwatchImage));
        
        for(let i =0; swatchImageElements[i]; i+=1){
            let swatchImageElement : HTMLElement =  swatchImageElements[i];
            ProductColourDropDown.setSwatchImageInfo(swatchImageElement, selectedOptionName, selectedSwatchSrc);
        }
    }

    public static setSwatchImageInfo(swatchImageElement : HTMLElement, selectedOptionName: string, selectedSwatchSrc: string) {
        if(swatchImageElement !== null) {
            swatchImageElement.setAttribute('style',  "background-image: url('" + selectedSwatchSrc.replace('extrasmallsquarecropped', 'smallsquarecropped') + "')");
            swatchImageElement.setAttribute('alt',  selectedOptionName);
            swatchImageElement.setAttribute(AttributeEnums.SwatchImage.IsHidden, 'false');
        }
    }
    public static clearSwatchImageInfo(swatchImageElement : HTMLElement) {
        if(swatchImageElement !== null) {
            swatchImageElement.setAttribute('style',  "");
            swatchImageElement.setAttribute('alt',  '');
            swatchImageElement.setAttribute(AttributeEnums.SwatchImage.IsHidden, 'true');
        }
    }

    setProductSwatchTitle(): string {
        let swatchTitleElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.DropDowns.ProductDisplaySwatch)) as HTMLElement;
        return this.setPropertyFromDataAttributeValidUndefined(swatchTitleElement, 'title');

    }

    setProductSwatchStyle(): string {
        let swatchStyleElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.DropDowns.ProductDisplaySwatch)) as HTMLElement;
        return this.setPropertyFromDataAttributeValidUndefined(swatchStyleElement, 'style');

    }

    writeToProductSwatchTitle(newSwatchTitle: string): void {
        let swatchTitleElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.DropDowns.ProductDisplaySwatch)) as HTMLElement;
        this.writeToDataAttributeValue(swatchTitleElement, 'title', newSwatchTitle);
    }

    writeToProductSwatchStyle(newSwatchStyle: string): void {
        let swatchStyleElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.DropDowns.ProductDisplaySwatch)) as HTMLElement;
        this.writeToDataAttributeValue(swatchStyleElement, 'style', newSwatchStyle);
    }

    updateProductSwatchTitle(newSwatchTitle: string): void {
        this.writeToProductSwatchTitle(newSwatchTitle);
        this.productSwatchTitle = this.setProductSwatchTitle();
    }

    updateProductSwatchStyle(newSwatchStyle: string): void {
        this.writeToProductSwatchStyle(newSwatchStyle);
        this.productSwatchStyle = this.setProductSwatchStyle();
    }

    updateProductDropDown(newDisplayText: string, newDisplayName: string, newValue: number | undefined, newAdditionPrice: number = 0, newSwatchStyle: string = '', newSwatchTitle: string = ''): void {
        this.updateProductSwatchTitle(newSwatchTitle);
        this.updateProductSwatchStyle(newSwatchStyle);
        super.updateProductDropDown(newDisplayText, newDisplayName, newValue, newAdditionPrice);
    }

    refreshProperties(): void {
        this.productSwatchTitle = this.setProductSwatchTitle();
        this.productSwatchStyle = this.setProductSwatchStyle();
        super.refreshProperties();
    }
    initialiseColourProperties(): void {
        this.productSwatchTitle = this.setProductSwatchTitle();
        this.productSwatchStyle = this.setProductSwatchStyle();           
    }
    //------ Custom Event Creation Start -----//
    //------ Custom Event Creation End -----//
    colourPickerFactory():void{
        let colourPickerElement = this.getProductContainerElement().querySelector(`[${AttributeEnums.ColourPicker.ColourPicker}]`) as HTMLElement;
        this.colourPicker = new ColourPicker(colourPickerElement);
    }

    getCorrectColourPickerElement(): HTMLElement{
        return this.getProductContainerElement().querySelector(`[${AttributeEnums.ColourPicker.ColourPicker}][data-pop-out-id="${this.popOutIdOpen}"][data-pop-out-family="${this.popOutFamilyOpen}"]`);
    }
    //------ Clean Up Processes Start -----//
    dispose(): void {
        this.removeColourConfirmedEventListener();
        this.colourPicker.dispose();
        super.dispose();
    }
    //------ Clean Up Processes End -----//
}