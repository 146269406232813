import { AjaxGetAndReplaceHtml } from '../../shared/ajaxGetAndReplaceHtml';
import { addProductCarouselClickEvent, openBasketPopOutWindowWithOverlay, viewDetailsClickEvent, viewDetailsClickEventRemove } from './basketPopOutInitialiser';
import { LbCookies } from '../../shared/lbCookies';
import { initialise } from '../../shared/initialiser';
import { HttpRequest } from '../../shared/ajax';
import { PopOutController } from '../../shared/popOutController';
import { LoadingTemplates } from '../loading/loadingTemplates';
import { Product } from '../product/product';
import { AttributeEnums } from '../../shared/utilities/enums/attributeEnums';
import { Carousel } from '../carousel/carousel';
import { HelperUtils } from '../../shared/utilities/helperUtils';
import { StringEnums } from '../../shared/utilities/enums/stringEnums';
import { CustomEventEnums } from '../../shared/utilities/enums/customEventEnums';
import { CarouselInfiniteLoad } from '../carousel/carouselInfiniteLoad';
import { IInstanceComponentBase, InstanceComponentBase } from '../../shared/instanceComponentBase';
import { UtilityEnums } from '../../shared/utilities/enums/utillityEnums';
import { BasketService } from '../basket/basketService';
import { CarouselGtm, ICarouselGtm } from '../carousel/carouselGtm';
import { IAddToRemoveFromCartGtmDataObject } from '../../shared/googleTagManager/gtmDataObject';

const urlPaths = require('../../config/urlPaths.json');

export class BasketPopOut extends InstanceComponentBase {

    private readonly _addProductStarted;
    private readonly _addProductCompleted;
    public carouselList: CarouselInfiniteLoad[];
    public product: Product;
    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);
        this._addProductStarted = this.addProductStarted.bind(this);
        this._addProductCompleted = this.addProductCompleted.bind(this);
        this.attachAddProductEventListener();
    }

    async instantiateAllCarousels() {
        let carouselElements: NodeListOf<HTMLElement> = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselInfiniteLoad.CarouselInfiniteLoad));

        let carouselList: CarouselInfiniteLoad[] = [];

        for (let i = 0; i < carouselElements.length; i += 1) {
            try {
                carouselList.push(this.carouselFactory(carouselElements[i]));
            }
            catch (error) {
                console.log('BasketPopOut: instantiateAllCarousels', error);
            }
        }
        this.carouselList = carouselList;

    }
    carouselFactory(carouselElement: HTMLElement) {
        let carouselInitialise: boolean | undefined = undefined;
        if (carouselElement.hasAttribute(AttributeEnums.CarouselInfiniteLoad.GetCellsOnInitialise)) {
            carouselInitialise = (carouselElement.getAttribute(AttributeEnums.CarouselInfiniteLoad.GetCellsOnInitialise) === 'true');
        }
        let carousel = new CarouselInfiniteLoad(carouselElement, carouselElement.getAttribute(AttributeEnums.CarouselInfiniteLoad.Url), carouselInitialise);
        return carousel;
    }
    async initialiseBasketPopoutTemplate() {
        let popOutWrapperElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutWrapper));
        let basketSessionCookie: string = LbCookies.getOrInitialiseBasketSession();

        const uriBasketPopOutTemplate: string = urlPaths.websiteUrl + '/basket/basket-pop-out/template/' + basketSessionCookie;
        let response = await HttpRequest.get(uriBasketPopOutTemplate);
        if (typeof response === 'string') {

            popOutWrapperElement.insertAdjacentHTML('beforeend', response);
            this.initializeAfterTemplateLoads();
        }
    }

    async reitialiseBasketPopoutTemplate() {
        let basketPopOutElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.BasketPopOut.BasketPopOut));
        let basketSessionCookie: string = LbCookies.getOrInitialiseBasketSession();

        const uriBasketPopOutTemplate: string = urlPaths.websiteUrl + '/basket/basket-pop-out/initial-state/' + basketSessionCookie;
        if (((<any>window).basketPopOutProduct)) {
            ((<any>window).basketPopOutProduct as Product).dispose();
        }
        let response = await HttpRequest.get(uriBasketPopOutTemplate);
        await AjaxGetAndReplaceHtml(basketPopOutElement, uriBasketPopOutTemplate);
        (<any>window).popOutController.openByIdAndFamily('1', UtilityEnums.ProductTypeValue.BasketPopOut);
        await this.initialiseBasketPopOutRecommendations();
        await initialise();
    }

    async initialiseBasketPopoutItemSummary() {
        document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.BasketPopOut.BasketPopOutItemSummary)).innerHTML = '<p class="lb-loading">loading</p>';
        let basketPopOutItemSummaryElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.BasketPopOut.BasketPopOutItemSummary));
        const uriItemSummary = basketPopOutItemSummaryElement.getAttribute(AttributeEnums.BasketPopOut.BasketPopOutItemSummary);
        await AjaxGetAndReplaceHtml(basketPopOutItemSummaryElement, uriItemSummary);
    }

    async initialiseBasketPopoutSummary() {
        document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.BasketPopOut.BasketPopOutSummary)).innerHTML = '<p class="lb-loading"loading</p>';
        let basketPopOutSummaryElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.BasketPopOut.BasketPopOutSummary));
        const uriSummary = basketPopOutSummaryElement.getAttribute(AttributeEnums.BasketPopOut.BasketPopOutSummary);
        await AjaxGetAndReplaceHtml(basketPopOutSummaryElement, uriSummary);

    }

    async initialiseBasketPopOutRecommendations() {
        console.log("initialiseBasketPopoutRecommendations start");
        let basketPopOutRecommendationsElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.BasketPopOut.BasketPopOutRecommendations));
        const uriRecommendations = basketPopOutRecommendationsElement.getAttribute(AttributeEnums.BasketPopOut.BasketPopOutRecommendations);
        await AjaxGetAndReplaceHtml(basketPopOutRecommendationsElement, uriRecommendations);
        console.log("initialiseBasketPopoutRecommendations end");
        this.instantiateAllCarousels();
    }

    showProductAddingToBasketTemplate() {
        let basketPopOutProductElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.BasketPopOut.BasketPopOutProduct));
        basketPopOutProductElement.innerHTML = LoadingTemplates.productAddingToBasketTemplate;
    }

    attachAddProductEventListener() {
        //Maybe Change to [data-basket-pop-out] querySelector to distinquish from main product page product:-
        console.log('BasketPopOut: addProductEventListener');
        document.addEventListener("CustomEventEnums.AddProductStarted", this._addProductStarted);

        document.addEventListener(CustomEventEnums.AddProductCompleted, this._addProductCompleted);

    }

    addProductStarted(event: CustomEvent) {
        if (event.detail.type == UtilityEnums.ProductTypeValue.BasketPopOut) {
            console.log("BasketPopOut: AddProductStarted Event Fired");
            this.showProductAddingToBasketTemplate();
        }
    }
    addProductCompleted(event: CustomEvent) {
        if (event.detail.type == UtilityEnums.ProductTypeValue.BasketPopOut) {
            console.log("BasketPopOut: AddProductCompleted Event Fired");
            this.updateBasketPopoutAfterAdditionalProductAdded();
        }
    }
    removeAddProductEventListener() {
        document.removeEventListener(CustomEventEnums.AddProductStarted, this._addProductStarted);

        document.removeEventListener(CustomEventEnums.AddProductCompleted, this._addProductCompleted);
    }
    async initialiseBasketPopOut() {
        this.initialiseBasketPopoutTemplate();
    }

    async initializeAfterTemplateLoads() {
        openBasketPopOutWindowWithOverlay((<any>window).popOutController);
        console.log('BasketPopOut: initializeAfterTemplateLoads - openBasketPopOutWindowWithOverlay');
        await this.initialiseBasketPopOutRecommendations();
        viewDetailsClickEvent();
        addProductCarouselClickEvent();

        await initialise();
    }

    async initialiseBasketPopOutProduct(productUrl: string) {

        console.log('BasketPopOut: initialiseBasketPopOutProduct Started');
        let basketPopOutProductElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.BasketPopOut.BasketPopOutProduct));
        basketPopOutProductElement.setAttribute(AttributeEnums.Product.Product, UtilityEnums.ProductTypeValue.BasketPopOut);
        basketPopOutProductElement.setAttribute(AttributeEnums.InstanceComponentInitialiser.Ignore, 'true');
        if (this.product) {
            this.product.dispose();
        }
        basketPopOutProductElement.innerHTML = LoadingTemplates.productLoadingTemplate;
        await AjaxGetAndReplaceHtml(basketPopOutProductElement, productUrl);
        console.log('BasketPopOut: initialiseBasketPopOutProduct AjaxGetAndReplaceHtml Finished');
        this.product = new Product(basketPopOutProductElement);
        await initialise();
        console.log('BasketPopOut: initialiseBasketPopOutProduct - executed initialise');
        //((<any>window).basketPopOutProduct as Product).viewProductDetailsFieldsSetUp();
        console.log('BasketPopOut: initialiseBasketPopOutProduct - Finished', this.product);
        console.log('BasketPopOut: initialiseBasketPopOutProduct Finished');
    }

    async handleAddToBasketCarouselClick(targetElement: HTMLElement) {

        document.dispatchEvent(this.addProductStartedEvent(UtilityEnums.ProductTypeValue.BasketPopOut));

        let response: string | void = await this.addToBasket(targetElement);

        document.dispatchEvent(this.addProductCompletedEvent(UtilityEnums.ProductTypeValue.BasketPopOut));
    }

    async addToBasket(targetElement: HTMLElement): Promise<string> {
        let lineId = targetElement.getAttribute(AttributeEnums.Carousel.LineId);
        try {
            let dataObject: IAddToRemoveFromCartGtmDataObject = CarouselGtm.getAddToRemoveFromCartGtmDataObject(targetElement);
            let response: string | void = await BasketService.addProductNoSize(lineId, dataObject);
            return response;
        } catch (error) {
            let response = await BasketService.addProductNoSizeWithoutGtmPost(lineId);
            return response;
        }
    }

    addProductStartedEvent(type: string) {
        return new CustomEvent(CustomEventEnums.AddProductStarted, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                'type': type
            }

        });
    }

    addProductCompletedEvent(type: string) {
        return new CustomEvent(CustomEventEnums.AddProductCompleted, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                'type': type
            }

        });
    }
    async updateBasketPopoutAfterAdditionalProductAdded() {


        await this.reitialiseBasketPopoutTemplate();

    }


    dispose() {
        if ((this.carouselList !== undefined) && (this.carouselList.length > 0)) {
            this.carouselList.forEach((carousel: CarouselInfiniteLoad) => {
                carousel.dispose();
            });
        }
        if ((<any>window).basketPopOutProduct) {
            (<any>window).basketPopOutProduct.dispose();
        }
        viewDetailsClickEventRemove();
        this.removeAddProductEventListener();
        delete (<any>window).basketPopOut;
    }

}