import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";

export interface IProductListingPageShowMore extends IInstanceComponentBase {
    updateProductListingPageShowingStartProduct:(value: number) => void;
    updateProductListingPageShowingProductCount: (value: number) => void;
    updateProductListingPageShowingTotalProducts: (value: number) => void;
    updateProductListingPageShowingStartProductAndProductCount: (startProductValue: number, productCountValue: number) => void;
    canShowMore: () => boolean;
    updateShowMoreButtonEnabledDisabledStyle: () => void;
    startProduct: number;
    productCount: number;
    totalProducts: number;
    isLoading: boolean;
    setIsLoadingTrue: () => void;
    setIsLoadingFalse: () => void;
}

export class ProductListingPageShowMore extends InstanceComponentBase implements IProductListingPageShowMore {
    private readonly _handleProductListingPageShowMoreButtonClicked;
    public startProduct: number;
    public productCount: number;
    public totalProducts: number;
    public isLoading: boolean;
    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);
        this.setProductListingPageShowingStartProduct();
        this.setProductListingPageShowingProductCount();
        this.setProductListingPageShowingTotalProducts();
        this.updateShowMoreButtonEnabledDisabledStyle();
        this._handleProductListingPageShowMoreButtonClicked = this.handleProductListingPageShowMoreButtonClicked.bind(this);
        this.isLoading = false;
        this.attachProductListingPageShowMoreButtonClickedEventListener();
    }

    attachProductListingPageShowMoreButtonClickedEventListener(): void {
        console.log('ProductListingPageShowMore: attachProductListingPageShowMoreButtonClickedEventListener');
        this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowMoreButton)).addEventListener('click', this._handleProductListingPageShowMoreButtonClicked);
    }

    removeProductListingPageShowMoreButtonClickedEventListener(): void {
        console.log('ProductListingPageShowMore: removeProductListingPageShowMoreButtonClickedEventListener');
        this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowMoreButton)).removeEventListener('click', this._handleProductListingPageShowMoreButtonClicked);
    }

    handleProductListingPageShowMoreButtonClicked(e: Event){
        console.log('ProductListingPageShowMore: handleProductListingPageShowMoreButtonClicked');
        this.clearShowMoreHref();
        if(this.canShowMore() && this.isLoading === false){
        this.setIsLoadingTrue();
        let targetElement = e.target as HTMLElement;        
        this.htmlElement.dispatchEvent(this.productListingPageShowMoreCustomEvent());
        }        
    }

    clearShowMoreHref() {
        this.writeToProductListingPageShowMoreHref("");
    }

    writeToProductListingPageShowMoreHref(value: string) {
        try {
            let buttonElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowMoreButton));
            buttonElement.setAttribute("href", value);
        } catch(error) {
            console.log('ProductListingPageShowMore: writeToProductListingPageShowMoreHref', error);
        }
    }

    writeToProductListingPageShowingProductCount(value: number) {
        HelperUtils.setValueForElementAndAttribute(value.toString(), this.htmlElement, AttributeEnums.ProductListingPageShowMore.ProductListingPageShowingProductCount);
    }

    setProductListingPageShowingProductCount() {
            let productCountElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowingProductCount));            
            this.productCount = +productCountElement.getAttribute(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowingProductCount);
    }

    updateProductListingPageShowingProductCount(value: number) {
        this.writeToProductListingPageShowingProductCount(value);
        this.setProductListingPageShowingProductCount();
        this.updateProductListingPageShowingProductCountDisplayValue();
    }

    writeToProductListingPageShowingStartProduct(value: number) {
        HelperUtils.setValueForElementAndAttribute(value.toString(), this.htmlElement, AttributeEnums.ProductListingPageShowMore.ProductListingPageShowingStartProduct);
    }

    setProductListingPageShowingStartProduct() {
        let productCountElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowingStartProduct));
        this.startProduct = +productCountElement.getAttribute(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowingStartProduct);
    }

    updateProductListingPageShowingStartProduct(value: number) {
        this.writeToProductListingPageShowingStartProduct(value);
        this.setProductListingPageShowingStartProduct();
        this.updateProductListingPageShowingProductCountDisplayValue();
    }

    updateProductListingPageShowingStartProductAndProductCount(startProductValue: number, productCountValue: number) {
        this.updateProductListingPageShowingStartProduct(startProductValue);
        this.updateProductListingPageShowingProductCount(productCountValue);
    }

    updateProductListingPageShowingProductCountDisplayValue() {
        let productCountElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowingProductCount)) as HTMLElement;                   
        productCountElement.innerText = `${this.startProduct} to ${this.productCount}`;
    }

    writeToProductListingPageShowingTotalProducts(value: number) {
        HelperUtils.setValueForElementAndAttribute(value.toString(), this.htmlElement as HTMLElement, AttributeEnums.ProductListingPageShowMore.ProductListingPageShowingTotalProducts,AttributeEnums.ProductListingPageShowMore.ProductListingPageShowingTotalProducts);
    }

    setProductListingPageShowingTotalProducts() {
            let productCountElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowingTotalProducts));            
            this.totalProducts = +productCountElement.getAttribute(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowingTotalProducts);
    }
    updateProductListingPageShowingTotalProductsDisplayValue() {
        let productCountElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowingTotalProducts)) as HTMLElement;                   
        productCountElement.innerText = `${this.totalProducts}`;
    }
    updateProductListingPageShowingTotalProducts(value: number) {
        this.writeToProductListingPageShowingTotalProducts(value);
        this.setProductListingPageShowingTotalProducts();
        this.updateProductListingPageShowingTotalProductsDisplayValue();
    }

   

    canShowMore(): boolean {
        
        if (this.productCount  <  this.totalProducts) {
            console.log('ProductListingPageShowMore: canShowMore => true');
            return true;
        } else {
            console.log('ProductListingPageShowMore: canShowMore => false');
            return false;
        }
    }

    addDisabledStyleToShowMore(){
        let showMoreButtonElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowMoreButton));
        showMoreButtonElement.classList.add('lb_button-unavailable');
    }
    removeDisabledStyleToShowMore(){
        let showMoreButtonElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowMoreButton));
        showMoreButtonElement.classList.remove('lb_button-unavailable');
    }

    addLoadingStyleToShowMore(){
        let showMoreButtonElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowMoreButton));
        showMoreButtonElement.classList.add('lb_spinnyWheel');
    }
    removeLoadingStyleToShowMore(){
        let showMoreButtonElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageShowMore.ProductListingPageShowMoreButton));
        showMoreButtonElement.classList.remove('lb_spinnyWheel');
    }

    setIsLoadingTrue(){
        this.isLoading = true;
        this.addLoadingStyleToShowMore();
    }

    setIsLoadingFalse(){
        this.isLoading = false;
        this.removeLoadingStyleToShowMore();
    }

    updateShowMoreButtonEnabledDisabledStyle() {
        if(this.canShowMore()){
            this.removeDisabledStyleToShowMore();
        } else{
            this.addDisabledStyleToShowMore();
        }
    }
    productListingPageShowMoreCustomEvent() {
        console.log(`ProductListingPageShowMore: productListingPageShowMoreCustomEvent `);
        return new CustomEvent(CustomEventEnums.ProductListingPageShowMore, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {           
            }
        });
    }

    

    dispose(): void {
        this.removeProductListingPageShowMoreButtonClickedEventListener();
    }
    
    delete(): void {

    }
}