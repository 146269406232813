import { AttributeEnums } from "./enums/attributeEnums";

export namespace HelperUtils {
    export function addDataAttributeTags(dataAttribute: string, dataAttributeValue?: string): string {
        if (dataAttributeValue) {
            return '[' + dataAttribute + '="' + dataAttributeValue + '"]';
        } else {
            return '[' + dataAttribute + ']';
        }
    }

    export function addCssClassDot(cssClass: string): string {
        return '.' + cssClass;
    }

    export function getValueForElementAndAttribute(htmlElementForSelector: HTMLElement, elementAttribute: string, attributeToGetIfDifferent?: string): string|undefined{
        let element = htmlElementForSelector.querySelector(HelperUtils.addDataAttributeTags(elementAttribute));
        if(element) {
            if(attributeToGetIfDifferent){
                return element.getAttribute(attributeToGetIfDifferent);
            } else {
                return element.getAttribute(elementAttribute);
            }
        }
        return undefined;
    }

    export function setValueForElementAndAttribute(value: string, htmlElementForSelector: HTMLElement, elementAttribute: string, attributeToGetIfDifferent?: string): void{
        let element = htmlElementForSelector.querySelector(HelperUtils.addDataAttributeTags(elementAttribute));
        if(element) {
            if(attributeToGetIfDifferent){
                element.setAttribute(attributeToGetIfDifferent, value);
            } else {
                element.setAttribute(elementAttribute, value);
            }
        }
    }
}