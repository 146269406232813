import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";

export interface IBaseMediaTile extends IInstanceComponentBase {
    disableSwipe : boolean;
    active: boolean;
}

export abstract class BaseMediaTile extends InstanceComponentBase implements IBaseMediaTile {
    
    public disableSwipe: boolean = false;

    private _active: boolean = false;
    public get active() : boolean {
        return this._active;
    }

    setTileActiveState(activeState : boolean) {
        if(activeState !== this._active){
            console.log(`BaseMediaTile: setTileActiveState - active state changed ${activeState}`);
            this._active = activeState;
            if (this.active) {
                this.onActivated();
            } else {
                this.onDeactivated();
            }
        }
    }

    onActivated() : void{
    }

    onDeactivated() : void{
    }

    constructor(public htmlElement: Element) {
        super(htmlElement);
    }

    dispose(): void {
        
    }

}