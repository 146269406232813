import { CookieUtils } from "./utilities/cookieUtils";
import { AttributeEnums } from "./utilities/enums/attributeEnums";
import { CookieEnums } from "./utilities/enums/cookieEnums";
import { StringEnums } from "./utilities/enums/stringEnums";
import { HelperUtils } from "./utilities/helperUtils";

export namespace CookieConsent {

    function attachCookieConsentClickEvent() {
        console.log('LbCookies: attachCookieConsentClickEvent');
        document.addEventListener('click', cookieConsentClick);
    }
    function removeCookieConsentClickEvent() {
        console.log('LbCookies: removeCookieConsentClickEvent');
        document.removeEventListener('click', cookieConsentClick);
    }

    function cookieConsentClick(event: Event): void {
        console.log('LbCookies: cookieConsentClick');
        let targetElement = event.target as HTMLElement;
        if (targetElement.hasAttribute(AttributeEnums.CookieConsent.ConsentButton)) {
            event.preventDefault();
            setDismissCookieConsent();
            removeCookieConsentClickEvent();
            removeCookieConsentElement();
        }
    }

    function setDismissCookieConsent(): void {
        CookieUtils.setCookie(CookieEnums.CookieConsent, StringEnums.Cookie.ConsentDismiss, 14);
    }

    function removeCookieConsentElement(): void {
        console.log('LbCookies: removeCookieConsent');
        let cookieConsentElement: HTMLElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CookieConsent.CookieConsent));
        cookieConsentElement.remove();
    }

    export function InitialiseCookieConsent(): void {
        console.log('LbCookies: InitialiseCookieConsent');
        const cookieConsent = cookieConsentHolder();
        consentText(cookieConsent);
        consentButton(cookieConsent);
        document.body.appendChild(cookieConsent);
        attachCookieConsentClickEvent();
    }

    function cookieConsentHolder(): Element {
        const cookieConsentElement = document.createElement('div');
        cookieConsentElement.setAttribute(AttributeEnums.CookieConsent.CookieConsent, 'true');
        cookieConsentElement.classList.add(StringEnums.CssClass.LbCookieConsent);
        return cookieConsentElement;
    }

    function consentText(cookieConsentElement: Element): void {
        const consentText = document.createElement('p');
        consentText.classList.add(StringEnums.CssClass.LbCookieConsentText);
        consentText.innerText = StringEnums.Cookie.ConsentText;
        consentPrivacy(consentText);
        cookieConsentElement.append(consentText);
    }

    function consentPrivacy(consentText: Element): void {
        const consentPrivacy = document.createElement('a');
        consentPrivacy.href = '/privacy-policy';
        consentPrivacy.classList.add(StringEnums.CssClass.LbLink, StringEnums.CssClass.LbLinkWordWrap);
        consentPrivacy.innerText = StringEnums.Cookie.ConsentPrivacy;
        consentText.append(consentPrivacy);
    }

    function consentButton(cookieConsentElement: Element): void {
        const consentButton = document.createElement('a');
        consentButton.setAttribute(AttributeEnums.CookieConsent.ConsentButton, 'true');
        consentButton.href = '#';
        consentButton.classList.add(StringEnums.CssClass.LbCookieConsentBtn);
        consentButton.innerText = StringEnums.Cookie.ConsentButton;
        cookieConsentElement.append(consentButton);
    }

}