const urlPaths = require('../../config/urlPaths.json');
export namespace CookieUtils {
    // Given a cookie key `name`, returns the value of
    // the cookie or `null`, if the key is not found.
    export function getCookie(name: string): string | null {
        try {
            const nameLenPlus = (name.length + 1);
            return document.cookie
                .split(';')
                .map(c => c.trim())
                .filter(cookie => {
                    return cookie.substring(0, nameLenPlus) === `${name}=`;
                })
                .map(cookie => {
                    return decodeURIComponent(cookie.substring(nameLenPlus));
                })[0] || null;
        } catch (error) {
            console.log('CookieUtils: getCookie ERROR', error);
            throw new Error();
        }
    }

    export function setCookie(name: string, val: string, daysTillExpires?: number, hoursTillExpires?: number) {
        try {
            const date = new Date();
            const value = val;

            daysTillExpires = daysTillExpires ?? 7;
            // Set it expire in 7 days or daysTillExpires

            hoursTillExpires = hoursTillExpires ?? 0;
            //set it to expire in 0 hours or hoursTillExpires

            date.setTime(date.getTime() + (daysTillExpires * 24 * 60 * 60 * 1000) + (hoursTillExpires * 60 * 60 * 1000));

            // Set it
            // document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; domain=.example.com; path=/";
            document.cookie = `${name}=${value}; expires=${date.toUTCString()}; domain=${urlPaths.cookieDomain}; path=/`;
        } catch (error) {
            console.log('CookieUtils: setCookie ERROR', error);
        }
    }

    export function deleteCookie(name: string) {
        try {
             const date = new Date();

            // Set it expire in -1 days
            date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

            // Set it
            // document.cookie = name+"=; expires="+date.toUTCString()+"; path=/";
            document.cookie = `${name}=; expires=${+date.toUTCString()}; domain=${urlPaths.cookieDomain}; path=/`;
        } catch (error) {
            console.log('CookieUtils: deleteCookie ERROR', error);
        }
    }
}