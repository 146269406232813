import { PopOutController } from '../../shared/popOutController';
import { AttributeEnums } from '../../shared/utilities/enums/attributeEnums';
import { CustomEventEnums } from '../../shared/utilities/enums/customEventEnums';
import { UtilityEnums } from '../../shared/utilities/enums/utillityEnums';
import { HelperUtils } from '../../shared/utilities/helperUtils';
import { BasketPopOut } from './basketPopOut';


export async function initialiseBasketPopOut() {
    (<any>window).openBasketPopOut = async function () {
        console.log('initialiseBasketPopOut: openBasketPopOut');
        console.log('initialiseBasketPopOut: openBasketPopOut has [data-basket-pop-out]');
        //remove old basket popout if it exists
        disposeOfBasketPopOut();
        let basketPopOutElement: HTMLElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.BasketPopOut.BasketPopOutWrapper));
        basketPopOutElement.innerHTML = '';
        let basketPopOut: BasketPopOut = new BasketPopOut(basketPopOutElement);

        (<any>window).basketPopOut = basketPopOut;
        let result = await (<any>window).basketPopOut.initialiseBasketPopOut();

    }
    document.addEventListener(CustomEventEnums.AddProductCompleted, (event: CustomEvent) => {
        if (event.detail.type == UtilityEnums.ProductTypeValue.Product) {
            console.log('initialiseBasketPopOut: addEventListener - AddProductCompleted started');
            (<any>window).openBasketPopOut();
        }
    });
}
export function disposeOfBasketPopOut() {
    if (((<any>window).basketPopOut as BasketPopOut)) {
        ((<any>window).basketPopOut as BasketPopOut).dispose();
    }
}
export function openBasketPopOutWindowWithOverlay(popOutController: PopOutController) {
    popOutController.openByIdAndFamily('1', UtilityEnums.ProductTypeValue.BasketPopOut);
}
export function ifClickElementIsOfProductUrlOpenBasketPopOutProduct(event: MouseEvent): void {
    let clickElement = event.target;

    if (clickElement instanceof HTMLElement && clickElement.hasAttribute(AttributeEnums.Product.ProductUrl)) {
        event.preventDefault();
        let productUrl = clickElement.getAttribute(AttributeEnums.Product.ProductUrl);
        openBasketPopOutProduct(productUrl);
    } else if (clickElement instanceof HTMLElement && clickElement.hasAttribute(AttributeEnums.ProductSelector.ProductSelectorItem) && clickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductUrl))) {
        event.preventDefault();
        let productUrl = clickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductUrl)).getAttribute(AttributeEnums.Product.ProductUrl);
        openBasketPopOutProduct(productUrl);
    }

    if (clickElement instanceof HTMLElement && clickElement.hasAttribute(AttributeEnums.Carousel.CarouselTileAddButton)) {
        let lineId = clickElement.getAttribute(AttributeEnums.Carousel.LineId);
    }
}

export function ifClickElementHasDataCarouselTileAddButtonAttribute(event: MouseEvent): void {
    let clickElement = event.target;
    if (clickElement instanceof HTMLElement && clickElement.hasAttribute(AttributeEnums.Carousel.CarouselTileAddButton)) {
        event.preventDefault();
        let basketPopOut: BasketPopOut = (<any>window).basketPopOut;
        basketPopOut.handleAddToBasketCarouselClick(clickElement);
    }

    if (clickElement instanceof HTMLElement && clickElement.hasAttribute(AttributeEnums.Carousel.CarouselTileAddButton)) {
        let lineId = clickElement.getAttribute(AttributeEnums.Carousel.LineId);
    }
}

export async function openBasketPopOutProduct(productUrl: string) {
    console.log('BasketPopOutInitialiser: openBasketPopOutProduct');

    (<any>window).popOutController.openByIdAndFamily('1:1', UtilityEnums.ProductTypeValue.BasketPopOut);
    //addClosePopOutControls((<any>window).popOutController);
    let basketPopOut: BasketPopOut = (<any>window).basketPopOut;
    await basketPopOut.initialiseBasketPopOutProduct(productUrl);
}


// export function addClosePopOutControls(popOutController: PopOutController) {
//     console.log('BasketPopOutInitialiser: addClosePopOutControls');
//     document.addEventListener('click', event => {
//         const closestPopOutElement = event.target as HTMLElement;
//         if (closestPopOutElement.hasAttribute(AttributeEnums.PopOut.PopOutBacklink)) {
//             event.preventDefault();
//             const popOutLayerId = closestPopOutElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutId)).getAttribute(AttributeEnums.PopOut.PopOutId);
//             const popOutLayerFamily = closestPopOutElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutId)).getAttribute(AttributeEnums.PopOut.PopOutFamily);
//             if (popOutLayerId.indexOf(":") === -1) {
//                 popOutController.closeAllMenus();
//             } else {
//                 console.log('BasketPopOutInitialiser: addClosePopOutControls - openByIdAndFamily');
//                 popOutController.openByIdAndFamily(popOutLayerId.substring(0, popOutLayerId.lastIndexOf(":")), popOutLayerFamily);
//             }
//         }
//     });
// }

export function viewDetailsClickEvent() {
    document.addEventListener('click', ifClickElementIsOfProductUrlOpenBasketPopOutProduct);
    //Maybe Change to [data-basket-pop-out] to distinquish from main product page product:-
    //document.querySelector('[data-basket-pop-out]').addEventListener('click', ifClickElementIsOfProductUrlOpenBasketPopOutProduct);
}
export function viewDetailsClickEventRemove() {
    document.removeEventListener('click', ifClickElementIsOfProductUrlOpenBasketPopOutProduct);

    //Maybe Change to [data-basket-pop-out] to distinquish from main product page product:-
    //document.querySelector('[data-basket-pop-out]').removeEventListener('click', ifClickElementIsOfProductUrlOpenBasketPopOutProduct);

}

export function addProductCarouselClickEvent() {
    document.addEventListener('click', ifClickElementHasDataCarouselTileAddButtonAttribute);
    //Maybe Change to [data-basket-pop-out] to distinquish from main product page product:-
    //document.querySelector('[data-basket-pop-out]').addEventListener('click', ifClickElementIsOfProductUrlOpenBasketPopOutProduct);
}
export function addProductCarouselClickEventRemove() {
    document.removeEventListener('click', ifClickElementHasDataCarouselTileAddButtonAttribute);

    //Maybe Change to [data-basket-pop-out] to distinquish from main product page product:-
    //document.querySelector('[data-basket-pop-out]').removeEventListener('click', ifClickElementIsOfProductUrlOpenBasketPopOutProduct);

}




