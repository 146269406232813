import { HistoryController } from "./historyController";
import { AttributeEnums } from "./utilities/enums/attributeEnums";
import { CustomEventEnums } from "./utilities/enums/customEventEnums";
import { StringEnums } from "./utilities/enums/stringEnums";
import { HelperUtils } from "./utilities/helperUtils";
export interface IPopOutController {
  closeAllMenus: () => void;
  openByIdAndFamily: (id: string, family: string) => void;
  openByIdAndFamilyNoHistoryPush: (id: string, family: string) => void;
  active: boolean;
}

export interface IPopOutState {
  popOutFamily: string;
  popOutId: string;
  popOutHistoryLength: number;
}
export class PopOutController {
  private readonly _popOutClickEventHandler;
  private readonly _backLinkClosePopOutControlsEventHandler;
  private readonly _handleCloseAllMenus;

  public active: boolean;

  constructor() {
    this._popOutClickEventHandler = this.popOutClickEventHandler.bind(this);
    this._backLinkClosePopOutControlsEventHandler = this.backLinkClosePopOutControlsEventHandler.bind(this);
    this._handleCloseAllMenus = this.handleCloseAllMenus.bind(this);
    //this.createPopOutEventListener();
    this.attachPopOutClickEventListener();
    this.attachEventForCloseAllAside();

    this.attachBackLinkClosePopOutControlsEventListener();
    this.attachCloseAllMenusPopOutControlsEventListener();
    this.active = true;
  }

  // createPopOutEventListener() {
  //   console.log('PopOutController: createPopOutEventListener');
  //   document.addEventListener('click', event => {

  //     let clickElement = event.target;

  //     if (clickElement instanceof HTMLElement && this.hasPopOutOpenAsParentOrSelf(clickElement)) {
  //       event.preventDefault();
  //       let popOutId = clickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutIdOpen)).getAttribute(AttributeEnums.PopOut.PopOutIdOpen);
  //       let popOutFamily = clickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamilyOpen)).getAttribute(AttributeEnums.PopOut.PopOutFamilyOpen);
  //       this.openByIdAndFamily(popOutId, popOutFamily);

  //     }
  //   });
  // }

  attachPopOutClickEventListener() {
    document.addEventListener('click', this._popOutClickEventHandler);
  }
  removePopOutClickEventListener() {
    document.removeEventListener('click', this._popOutClickEventHandler);
  }

  attachEventForCloseAllAside() {
    console.log('PopOutController: addEventForCloseAllAside');
    document.addEventListener('click', e => { this.handleOverlayClick(e) });
  }

  removeEventForCloseAllAside() {
    console.log('PopOutController: removeEventForCloseAllAside');
    document.removeEventListener('click', this.handleOverlayClick);
  }

  attachBackLinkClosePopOutControlsEventListener() {
    document.addEventListener('click', this._backLinkClosePopOutControlsEventHandler);
  }

  removeBackLinkClosePopOutControlsEventListener() {
    document.removeEventListener('click', this._backLinkClosePopOutControlsEventHandler);
  }

  attachCloseAllMenusPopOutControlsEventListener() {
    (<any>window).addEventListener(CustomEventEnums.CloseAllMenus, this._handleCloseAllMenus);
  }

  removeCloseAllMenusPopOutControlsEventListener() {
    (<any>window).removeEventListener(CustomEventEnums.CloseAllMenus, this._handleCloseAllMenus);
  }

  popOutClickEventHandler(event: Event) {
    let clickElement = event.target;

    if (clickElement instanceof HTMLElement && this.hasPopOutOpenAsParentOrSelf(clickElement)) {
      event.preventDefault();
      let popOutId = clickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutIdOpen)).getAttribute(AttributeEnums.PopOut.PopOutIdOpen);
      let popOutFamily = clickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamilyOpen)).getAttribute(AttributeEnums.PopOut.PopOutFamilyOpen);
      this.openByIdAndFamily(popOutId, popOutFamily);

    }
  }
 
  backLinkClosePopOutControlsEventHandler() {
      console.log('PopOutController: addBackLinkClosePopOutControlsEventListener - click event fired');
      const closestPopOutElement = event.target as HTMLElement;

      this.backLinkPopOutCloseCorrectPopoutLayer(closestPopOutElement);
      console.log('PopOutController: addBackLinkClosePopOutControlsEventListener - end of method');
  }

  backLinkPopOutCloseCorrectPopoutLayer(backLickElement: HTMLElement) {
    if (backLickElement.hasAttribute(AttributeEnums.PopOut.PopOutBacklink) && this.active) {
      const popOutLayerId = backLickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutId)).getAttribute(AttributeEnums.PopOut.PopOutId);
      const popOutLayerFamily = backLickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutId)).getAttribute(AttributeEnums.PopOut.PopOutFamily);
      if (popOutLayerId.indexOf(":") === -1) {
        console.log('PopOutController: backLinkPopOutCloseCorrectPopoutLayer - closeAllMenus');
        this.closeAllMenus();
      } else {
        console.log('PopOutInitialiser: backLinkPopOutCloseCorrectPopoutLayer - openByIdAndFamily');
        HistoryController.historyBackOneIfPopOut();
        this.openByIdAndFamilyNoHistoryPush(popOutLayerId.substring(0, popOutLayerId.lastIndexOf(":")), popOutLayerFamily);

      }
    }
  }

  attachAllPopOutEventListners() {
    this.attachPopOutClickEventListener();
    this.attachEventForCloseAllAside();
    this.attachBackLinkClosePopOutControlsEventListener();
  }

  closeAllLevel1NoneFamilyMembers(popOutFamily: string) {
    console.log('PopOutController: closeAllLevel1NoneFamilyMembers');
    let popOutElements = document.querySelectorAll('div' + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily)
      + ':not(' + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily, popOutFamily)
      + ')' + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutId, '1'));
    popOutElements.forEach(item => {
      item.classList.remove(StringEnums.CssClass.LbActive);
    });
  }

  closeAllLevel1() {
    console.log('PopOutController: closeAllLevel1');
    let popOutElements = document.querySelectorAll('div' + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily)
      + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutId, '1'));
    popOutElements.forEach(item => {
      item.classList.remove(StringEnums.CssClass.LbActive);
    });
  }

  popOutOpen() {
    let popOutOpen: boolean = false;
    let popOutElements = document.querySelectorAll('div' + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily)
      + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutId, '1'));
    popOutElements.forEach(item => {
      if (item.classList.contains(StringEnums.CssClass.LbActive)) {
        popOutOpen = true;
      }
    });
    return popOutOpen;
  }


  noneFamilyMemberIsOpen(popOutFamily: string) {
    console.log('PopOutController: noneFamilyMemberIsOpen');
    let popOutElements = document.querySelectorAll('div' + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily)
      + ':not(' + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily, popOutFamily)
      + ')' + HelperUtils.addCssClassDot(StringEnums.CssClass.LbActive));
    if (popOutElements.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  closeAllNoneFamilyMembers(popOutFamily: string) {
    console.log('PopOutController: closeAllNoneFamilyMembers');
    let popOutElements = document.querySelectorAll('div' + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily) + ':not(' + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily, popOutFamily) + ')');
    this.removeRelevantActiveAndTransitionCss(popOutElements);
  }

  closeAllFamilyMembers(popOutFamily: string) {
    console.log('PopOutController: closeAllFamilyMembers');
    let popOutElements = document.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily, popOutFamily));
    this.removeRelevantActiveAndTransitionCss(popOutElements);
  }

  removeRelevantActiveAndTransitionCss(elements: NodeListOf<Element>) {
    console.log('PopOutController: removeRelevantActiveAndTransitionCss');
    elements.forEach(element => {
      element.classList.remove(StringEnums.CssClass.LbActive);
      if (this.getDataPopOutIdLevel(element) !== 1) {
        element.classList.remove(StringEnums.CssClass.LbPopOutTransition);
      }
    });
  }

  initializePopOut(popOutFamily: string) {
    console.log('PopOutController: initializePopOut');
    this.closeAllFamilyMembers(popOutFamily);
  }

  closeAllNoneLinealSiblingsAndTheirDescendantsByIdAndFamily(id: string, family: string) {
    console.log('PopOutController: closeAllNoneLinealSiblingsAndTheirDescendantsByIdAndFamily');
    let element = this.getElementByIdAndFamily(id, family);
    this.closeAllNoneLinealSiblingsAndTheirDescendants(element);
  }

  closeAllNoneLinealSiblingsAndTheirDescendants(selectedElement: Element) {
    console.log('PopOutController: closeAllNoneLinealSiblingsAndTheirDescendants');
    let family = selectedElement.getAttribute(AttributeEnums.PopOut.PopOutFamily);
    let level: number = this.getDataPopOutIdLevel(selectedElement);
    let popOutElements = document.querySelectorAll('div' + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily, family));

    for (let currentElement = 0; currentElement < popOutElements.length; currentElement += 1) {
      let currentElementLevel = this.getDataPopOutIdLevel(popOutElements[currentElement]);
      if ((currentElementLevel > level) ||
        !this.hasSameLineage(popOutElements[currentElement], selectedElement)) {
        popOutElements[currentElement].classList.remove(StringEnums.CssClass.LbActive);
      }
    }
  }



  sleep(ms: number) {
    console.log('PopOutController: sleep');
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getElementByIdAndFamily(id: string, family: string) {
    return document.querySelector('div' + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily, family) + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutId, id));
  }

  getAllPopOutElements() {
    return document.querySelectorAll('div' + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily));
  }

  getElementsByFamily(family: string) {
    return document.querySelectorAll('div' + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily, family));
  }

  getPopOutTransitionDelayTimeInMs() {
    let popOutElement = document.querySelector('div' + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily) + HelperUtils.addCssClassDot(StringEnums.CssClass.LbPopOutTransition));
    if (popOutElement) {
      let transitionDuration = parseFloat(window.getComputedStyle(popOutElement).transitionDuration) * 1000;
      let transitionDelay = parseFloat(window.getComputedStyle(popOutElement).transitionDelay) * 1000;
      return transitionDuration + transitionDelay;
    } else {
      return 0;
    }
  }

  closeUnneededMenusReadyForNewSelection(id: string, family: string, callback: (id: string, family: string, that: this) => void) {
    console.log('PopOutController: closeUnneededMenusReadyForNewSelection');
    if (this.noneFamilyMemberIsOpen(family)) {
      this.closeAllLevel1NoneFamilyMembers(family);
      this.sleep(this.getPopOutTransitionDelayTimeInMs() + 100).then(() => {
        this.closeAllNoneLinealSiblingsAndTheirDescendantsByIdAndFamily(id, family)
        callback(id, family, this);
      });
    } else {
      this.closeAllNoneLinealSiblingsAndTheirDescendantsByIdAndFamily(id, family);
      callback(id, family, this);
    }
  }

  openByIdAndFamily(id: string, family: string) {
    console.log('PopOutController: openByIdAndFamily');
    if ((id !== null && id !== undefined) && (family !== null && family !== undefined) && this.active) {
      this.closeUnneededMenusReadyForNewSelection(id, family, this.openCorrectElementsForOpenByIdAndFamily);

      HistoryController.pushPopOutHistoryToStack(family, id, location.href);
    }
  }
  openByIdAndFamilyNoHistoryPush(id: string, family: string) {
    console.log('PopOutController: openByIdAndFamilyNoHistoryPush');
    if ((id !== null && id !== undefined) && (family !== null && family !== undefined) && this.active) {
      this.closeUnneededMenusReadyForNewSelection(id, family, this.openCorrectElementsForOpenByIdAndFamily);

    }
  }

  openCorrectElementsForOpenByIdAndFamily(id: string, family: string, that: this) {
    console.log('PopOutController: openCorrectElementsForOpenByIdAndFamily');
    let popOutFamily = that.getElementsByFamily(family);
    let selectedElement = that.getElementByIdAndFamily(id, family);

    that.openLevel1Elements(popOutFamily);

    that.sleep(that.getPopOutTransitionDelayTimeInMs() + 100).then(() => {
      that.openAllRelevantNoneLevel1Levels(selectedElement, popOutFamily);
    });

  }

  openLevel1Elements(popOutFamily: NodeListOf<Element>) {
    console.log('PopOutController: openLevel1Elements');
    for (let i = 0; i < popOutFamily.length; i += 1) {
      const currentElement = popOutFamily[i];
      if (this.getDataPopOutIdLevel(currentElement) === 1) {
        (<any>window).overlay.showOverlay();
        //this.addEventForCloseAllAside();
        currentElement.classList.add(StringEnums.CssClass.LbActive);
        currentElement.classList.add(StringEnums.CssClass.LbPopOutTransition);
      }
    }

  }

  openAllRelevantNoneLevel1Levels(selectedElement: Element, popOutFamily: NodeListOf<Element>) {
    console.log('PopOutController: openAllRelevantNoneLevel1Levels');
    selectedElement.classList.add(StringEnums.CssClass.LbActive);
    selectedElement.classList.add(StringEnums.CssClass.LbPopOutTransition);

    for (let i = 0; i < popOutFamily.length; i += 1) {
      const currentElement = popOutFamily[i];
      if (this.hasSameLineage(currentElement, selectedElement) && this.getDataPopOutIdLevel(currentElement) < this.getDataPopOutIdLevel(selectedElement)) {
        currentElement.classList.add(StringEnums.CssClass.LbActive);
        currentElement.classList.add(StringEnums.CssClass.LbPopOutTransition);
      }
    }
  }

  getDataPopOutIdLevel(element: Element) {
    let id: string = element.getAttribute(AttributeEnums.PopOut.PopOutId);
    return id.split(':').length;
  }

  hasSameLineage(element1: Element, element2: Element) {
    let lowerIdCount: number;
    let element1Id: string = element1.getAttribute(AttributeEnums.PopOut.PopOutId);
    let element2Id: string = element2.getAttribute(AttributeEnums.PopOut.PopOutId);
    let element1IdParts = (element1Id).split(":");
    let element2IdParts = (element2Id).split(":");

    if (element1IdParts.length > element2IdParts.length) {
      lowerIdCount = element2IdParts.length;
    } else {
      lowerIdCount = element1IdParts.length;
    }

    for (let i = 0; i < lowerIdCount; i += 1) {
      if (element1IdParts[i] !== element2IdParts[i]) {
        return false;
      }
    }
    return true;
  }

  closeAllPopOutMenus() {
    console.log('PopOutController: closeAllPopOutMenus');
    this.closeAllLevel1();
    this.sleep(this.getPopOutTransitionDelayTimeInMs() + 100).then(() => {
      let popOutElements = this.getAllPopOutElements();
      popOutElements.forEach(item => {
        item.classList.remove(StringEnums.CssClass.LbActive);
        item.classList.remove(StringEnums.CssClass.LbPopOutTransition);
      });
    });
  }

  getTopLevelActiveMenu(activeMenuElements: NodeListOf<Element>) {
    console.log('PopOutController: getTopLevelActiveMenu');
    let maxLevel: number = -1;
    for (let i = 0; i < activeMenuElements.length; i += 1) {

      const currentElement = activeMenuElements[i] as HTMLElement;

      let levelNumber = this.getDataPopOutIdLevel(currentElement);
      if (levelNumber > maxLevel) {
        maxLevel = levelNumber;
      }
    }
    return maxLevel;
  }



  handleOverlayClick(e: MouseEvent) {

    console.log('PopOutController: handleOverlayClick');
    let htmlElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily) + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutId, '1') + HelperUtils.addCssClassDot(StringEnums.CssClass.LbActive));

    if (htmlElement && this.active) {

      let htmlElementEdge = htmlElement.getBoundingClientRect();

      let target = e.target;

      if (target instanceof HTMLElement) {
        if (target.hasAttribute(AttributeEnums.PopOut.PopOutFamily)) {
          if ((((e as MouseEvent).clientX < htmlElementEdge.left) || ((e as MouseEvent).clientX > htmlElementEdge.right))) {
            e.preventDefault();
            console.log('PopOutController: handleOverlayClick - closeAllMenus');
            this.handleOverlayCloseCorrectPopout(target); 
              //this.closeAllMenus();
            
            return;
          }
          if ((((e as MouseEvent).clientY < htmlElementEdge.bottom))) {
            e.preventDefault();
            console.log('PopOutController: handleOverlayClick - closeAllMenus');
            //this.closeAllMenus();
            this.handleOverlayCloseCorrectPopout(target); 
          }
        }
      }
    }
  }

  handleOverlayCloseCorrectPopout(target: HTMLElement) {
    let popOutElement = target.hasAttribute(AttributeEnums.PopOut.PopOutOverlayClickIsBack);
    if (target.hasAttribute(AttributeEnums.PopOut.PopOutOverlayClickIsBack) && target.getAttribute(AttributeEnums.PopOut.PopOutOverlayClickIsBack) === "true") {
      let backLinkElement: HTMLElement = target.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutBacklink));
      if(backLinkElement){
      console.log('PopOutController: handleOverlayCloseCorrectPopout - back');
      this.backLinkPopOutCloseCorrectPopoutLayer(backLinkElement);
      } else{
        console.log('PopOutController: handleOverlayCloseCorrectPopout - closeAllMenus');
        this.closeAllMenus();
      }
    } else {
      console.log('PopOutController: handleOverlayCloseCorrectPopout - closeAllMenus');
      this.closeAllMenus();
    }
  }
  handleCloseAllMenus() {
    this.closeAllMenus();
  }

  closeAllMenus() {
    console.log('PopOutController: closeAllMenus');

    (<any>window).popOutController.closeAllPopOutMenus();
    (<any>window).overlay.hideOverlay();
    this.removeEventForCloseAllAside();
    HistoryController.removeAllPopOutHistory();
  }

  hasPopOutOpenAsParentOrSelf(target: HTMLElement) {
    try {
      return (target.closest(HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutIdOpen)).hasAttribute(AttributeEnums.PopOut.PopOutIdOpen) && target.closest(HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamilyOpen)).hasAttribute(AttributeEnums.PopOut.PopOutFamilyOpen));
    } catch (error) {
      return false;
    }
  }

  static hasIdLowerLevel(popoutId: string) {
    if (popoutId.indexOf(':') >= 0) {
      return true;
    } else {
      return false;
    }
  }

  static getIdLowerLevel(popoutId: string): string | undefined {
    let lastIndexOfColon = popoutId.lastIndexOf(':');
    if (lastIndexOfColon >= 0) {
      return popoutId.substring(0, lastIndexOfColon);
    } else {
      return undefined;
    }
  }
  
}