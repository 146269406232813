import { BaseMediaTile, IBaseMediaTile } from "./baseMediaTile";

export interface IYouTubeMediaTile extends IBaseMediaTile {
    youTubeParameters: IYouTubeParameters;
}

export interface IYouTubeParameters {
    displayControl?: boolean;
    disableKeyboardControls?: boolean;
    autoPlay?: boolean;
    closedCaptionOnLoad?: boolean;
    disableFullScreen?: boolean;
    isPlaying?: boolean;
    showRelatedVideos?: boolean;
}

export class YouTubeMediaTile extends BaseMediaTile implements IYouTubeMediaTile {
    
    public youTubeParameters: IYouTubeParameters = {
        displayControl: true,
        disableKeyboardControls: false,
        autoPlay: false,
        closedCaptionOnLoad: false,
        disableFullScreen: false,
        isPlaying: false,
        showRelatedVideos: false
    };

    constructor(public htmlElement: Element, youTubeParameters: IYouTubeParameters) {

        super(htmlElement);
        this.youTubeParameters = {...this.youTubeParameters, ...youTubeParameters};
    }

    dispose(): void {
        
    }
    
}