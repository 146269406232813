export namespace GoogleTagManagerEnums {

    export enum ListSuffixes {
        ProductListingPage = 'PLP',
        ProductDisplayPage = 'PDP',
        Basket = 'BSK',
        Home = 'HME',
        Brand = 'BRN',
        Finance = 'FIN',
        Static = 'STA'
    }
    export enum EventTypes {
        ProductImpressions = 'productImpressions',
        PromotionImpressions = 'promotionImpressions',
        ProductClick = 'productClick',
        PromotionClick = 'promotionClick',
        ProductView = 'productView',
        AddToCart = 'addToCart',
        RemoveFromCart = 'removeFromCart',
        ViewCart = 'view_cart',
        SearchPageClick = 'searchPageClick',
        SearchProductClick = 'searchProductClick',
        MenuClick = 'menuClick',
        SortClick = 'sortClick',
        FilterClick = 'filterClick',
        ItemClick = 'itemClick',
        QuickLinkClick = 'quickLinkClick',
    }

    export enum SearchClickListValues {
        Page = 'Page',
        Product = 'Product'
    }
} 