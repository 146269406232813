import { HttpRequest } from "../../shared/ajax";
import { AjaxGetAndReplaceHtml } from "../../shared/ajaxGetAndReplaceHtml";
import { initialise } from "../../shared/initialiser";
import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { UrlEnums } from "../../shared/utilities/enums/urlEnums";
import { UtilityEnums } from "../../shared/utilities/enums/utillityEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { OptionUpdateDto } from "./productListingPage";
import { IProductListingPageGroupOptions, ProductListingPageGroupOptions } from "./productListingPageGroupOptions";
import { IProductListingPageOption } from "./productListingPageOption";
const urlPaths = require('../../config/urlPaths.json');
export interface IProductListingPageOptions extends IInstanceComponentBase {
    htmlElement: HTMLElement; 
    productListingPageGroupOptions: IProductListingPageGroupOptions[];
    totalProducts: number;
    updateOptions: (querySelectorString: string) => Promise<void>;
    updateOptionsForOptionUpdateDto(optionUpdateDto: OptionUpdateDto): Promise<void>;
    getAllSelectedOptions:() => IProductListingPageOption[];
    getCategoryOptions:() => IProductListingPageOption[];
    updateProductListingPageShowingTotalProducts:(value: number) => void;
    displayLoading: () => void;
    removeLoading:() => void;
    
}

export class ProductListingPageOptions extends InstanceComponentBase implements IProductListingPageOptions {
    public productListingPageGroupOptions: IProductListingPageGroupOptions[] = new Array();
    public productCount: number;
    public totalProducts: number;
    constructor(public htmlElement: HTMLElement, querySelectorString: string) {
        super(htmlElement);
        this.setProductListingPageShowingTotalProducts();
    }

    async updateOptions(querySelectorString: string): Promise<void> {       
        let url: string = urlPaths.websiteUrl + `${UrlEnums.PartialUrls.ProductListingPageOptions}${querySelectorString}`;
        await AjaxGetAndReplaceHtml(this.htmlElement, url);
        await initialise();
        this.groupOptionsFactory();
    }

    async updateOptionsForOptionUpdateDto(optionUpdateDto: OptionUpdateDto): Promise<void> {       
        let url: string = urlPaths.websiteUrl + `${UrlEnums.PartialUrls.ProductListingPageOptionsUpdate}`;
        let bodyJson = JSON.stringify(optionUpdateDto);
        let response = await HttpRequest.post(url, bodyJson);
        this.htmlElement.innerHTML = response;
        await initialise();
        this.groupOptionsFactory();
    }
    
    groupOptionsFactory() {
        let groupOptionElements = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageGroupOptions.ProductListingPageGroupOptions)) as NodeListOf<HTMLElement>;
        Array.from(groupOptionElements).forEach((element: HTMLElement) => { this.productListingPageGroupOptions.push(new ProductListingPageGroupOptions(element))});
    }
    
    getAllSelectedOptions(): IProductListingPageOption[] {
        let selectedProductListingPageOptions = new Array<IProductListingPageOption>();
        for(let groupOption in this.productListingPageGroupOptions) {
            let selectedOptions = this.productListingPageGroupOptions[groupOption].productListingPageOptionList.filter((option: IProductListingPageOption)=> option.optionSelected);
            selectedProductListingPageOptions = selectedProductListingPageOptions.concat(selectedOptions);
        }
        return selectedProductListingPageOptions;
    }

    getCategoryOptions(): IProductListingPageOption[] {
        var categoryGroupOptions = this.productListingPageGroupOptions.filter((groupOptions: IProductListingPageGroupOptions) => groupOptions.groupType == UtilityEnums.SingleSelectType.Category)[0];
        return categoryGroupOptions.productListingPageOptionList;   
    }
    displayLoading(){
       this.htmlElement.classList.add('lb-loadingTile');
    }
    removeLoading(){
        this.htmlElement.classList.remove('lb-loadingTile');
     }

    writeToProductListingPageShowingTotalProducts(value: number) {
        var productListingPageElement = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPage.ProductListingPage)) as HTMLElement;
        HelperUtils.setValueForElementAndAttribute(value.toString(), productListingPageElement, AttributeEnums.ProductListingPageOptions.ProductTotal, AttributeEnums.ProductListingPageOptions.ProductTotal);
    }

    setProductListingPageShowingTotalProducts() {
            let productCountElement = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPage.ProductListingPage)).querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageOptions.ProductTotal)) as HTMLElement;            
            this.totalProducts = +productCountElement.getAttribute(AttributeEnums.ProductListingPageOptions.ProductTotal);
    }

    updateProductListingPageShowingTotalProducts(value: number) {
        this.writeToProductListingPageShowingTotalProducts(value);
        this.setProductListingPageShowingTotalProducts();
        this.updateProductListingPageShowingTotalProductsDisplayValue();
    }

    updateProductListingPageShowingTotalProductsDisplayValue() {
        let productCountElement = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPage.ProductListingPage)).querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageOptions.ProductTotal)) as HTMLElement;            
        productCountElement.innerText = `${this.totalProducts}`;
    }

    dispose(): void {
        this.productListingPageGroupOptions.forEach((groupOptions: IProductListingPageGroupOptions) => groupOptions.dispose());
    }
    
    delete(): void {

    }
}