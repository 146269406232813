import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { IShowHide, ShowHide } from "../showHide/showHideController";
import { IProductListingPageOption, ProductListingPageOption } from "./productListingPageOption";
const urlPaths = require('../../config/urlPaths.json');
export interface IProductListingPageGroupOptions extends IInstanceComponentBase {
    
    groupType: string;  
    productListingPageOptionList : IProductListingPageOption[];
    htmlElement: HTMLElement;
    showHide: IShowHide;
    optionalInnerShowHide: IShowHide | undefined;
}

export class ProductListingPageGroupOptions extends InstanceComponentBase implements IProductListingPageGroupOptions {
    public productListingPageOptionList : IProductListingPageOption[] = new Array();

    public groupType: string;    
    public showHide: IShowHide;
    public optionalInnerShowHide: IShowHide | undefined;
    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);

        this.setProductListingPageGroupOptions();
        this.productListingPageOptionListFactory();
        this.showHideFactory();
        this.optionalInnerShowHideFactory();
       
    }
   
    showHideFactory() {
        try{
        let showHideElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ShowHide.ShowHideToggle)) as HTMLElement;
        let showHideToggleId:string = showHideElement.getAttribute(AttributeEnums.ShowHide.ShowHideToggle);
        let showHideContentElement = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ShowHide.ShowHideContent, showHideToggleId));       
        this.showHide = new ShowHide(showHideElement, showHideContentElement);             
        } catch(error) {
            console.log("ProductListingPageGroupOptions: showHideFactory Error", error);
        }
    }

    optionalInnerShowHideFactory() {
        try{
        let showHideElements = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ShowHide.ShowHideToggle)) as NodeListOf<HTMLElement>;
        if(showHideElements.length > 1) {
            let innerShowHideElement = showHideElements[1];
            let innerShowHideElementId:string = innerShowHideElement.getAttribute(AttributeEnums.ShowHide.ShowHideToggle);
            let showHideContentElements = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ShowHide.ShowHideContent, innerShowHideElementId));       
            this.optionalInnerShowHide = new ShowHide(innerShowHideElement, showHideContentElements);

        }
        
        } catch(error) {
            console.log("ProductListingPageGroupOptions: optionalInnerShowHideFactory Error", error);
        }
    }
    
    writeToProductListingPageGroupOptions(value: string | undefined){
        HelperUtils.setValueForElementAndAttribute(value.toString(), this.htmlElement as HTMLElement, AttributeEnums.ProductListingPageGroupOptions.ProductListingPageGroupOptions);
    }

    setProductListingPageGroupOptions(){

            this.groupType = this.htmlElement.getAttribute(AttributeEnums.ProductListingPageGroupOptions.ProductListingPageGroupOptions);
    }

    updateProductListingPageGroupOptions(value: string | undefined){
        this.writeToProductListingPageGroupOptions(value);
        this.setProductListingPageGroupOptions();
    } 

    productListingPageOptionListFactory() {
        let optionElements = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageOption.ProductListingPageOption)) as NodeListOf<HTMLElement>;
        optionElements.forEach((element: HTMLElement) =>{this.productListingPageOptionList.push(new ProductListingPageOption(element, this.groupType))});
    }

    dispose(): void {
        this.productListingPageOptionList.forEach((option: IProductListingPageOption) => option.dispose());

        this.showHide.dispose();

        if(this.optionalInnerShowHide){
            this.optionalInnerShowHide.dispose();
        }
    }
    
    delete(): void {

    }
}