import { AsyncUtils } from "../../shared/utilities/asyncUtils";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { StringEnums } from "../../shared/utilities/enums/stringEnums";
import { SwipedEventEnums } from "../../shared/utilities/enums/swipedEventEnums";
import { UtilityEnums } from "../../shared/utilities/enums/utillityEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { BaseMediaTile } from "../mediaTile/baseMediaTile";
import { SelectableCarousel, ISelectableCarousel } from "./selectableCarousel";


export interface IBasePagingSelectableCarousel extends ISelectableCarousel {

    reinitialiseCarousel: () => void;
    isSwipeable: () => boolean;
    cellsPerPage: number;
}

export abstract class BasePagingSelectableCarousel extends SelectableCarousel implements IBasePagingSelectableCarousel {

    protected carouselPrevElement: HTMLElement;
    protected carouselNextElement: HTMLElement;
    protected sliderElement: HTMLElement;

    protected occupiedCellsElements: NodeListOf<HTMLElement> = this.htmlElement.querySelectorAll(`${HelperUtils.addDataAttributeTags(AttributeEnums.SelectableCarousel.Cell)}:not(${HelperUtils.addDataAttributeTags(AttributeEnums.SelectableCarousel.FillerCell)})`);

    public cellsPerPage: number;
    public currentPage: number;

    private readonly _handleSwipedUp;
    private readonly _handleSwipedDown;
    private readonly _handleSwipedLeft;
    private readonly _handleSwipedRight;

    constructor(public htmlElement: Element, carouselOrientation: UtilityEnums.ComponentOrientation, topLevelHtmlElement?: HTMLElement) {
        super(htmlElement, carouselOrientation, topLevelHtmlElement);

        this.carouselPrevElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.PagingSelectableCarousel.Prev));
        this.carouselNextElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.PagingSelectableCarousel.Next));
        this.sliderElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.PagingSelectableCarousel.Slider));
        //.totalOccupiedCells = this.occupiedCellsElements.length;
        this.cellsPerPage = this.getCellsPerPage();
        this.currentPage = this.getInitialPage();

        this._handleSwipedUp = this.handleSwipedUp.bind(this);
        this._handleSwipedDown = this.handleSwipedDown.bind(this);
        this._handleSwipedLeft = this.handleSwipedLeft.bind(this);
        this._handleSwipedRight = this.handleSwipedRight.bind(this);

        this.setCarouselShowHideArrows();
        this.attachSwipedUpEventListener();
        this.attachSwipedDownEventListener();
        this.attachSwipedLeftEventListener();
        this.attachSwipedRightEventListener();
    }

    //Carousel Events  
    abstract attachCarouselPagePrevClickEventListener(): void;
    abstract removeCarouselPagePrevClickEventListener(): void;

    abstract attachCarouselPageNextClickEventListener(): void;
    abstract removeCarouselPageNextClickEventListener(): void;

    abstract attachViewportDimensionsChangedEventListener(): void;
    abstract removeViewportDimensionsChangedEventListener(): void;

    //Touch Events
    attachSwipedUpEventListener(): void {
        this.htmlElement.addEventListener(SwipedEventEnums.SwipedUp, this._handleSwipedUp);
    }
    removeSwipedUpEventListener(): void {
        this.htmlElement.removeEventListener(SwipedEventEnums.SwipedUp, this._handleSwipedUp);
    }

    attachSwipedDownEventListener(): void {
        this.htmlElement.addEventListener(SwipedEventEnums.SwipedDown, this._handleSwipedDown);
    }
    removeSwipedDownEventListener(): void {
        this.htmlElement.addEventListener(SwipedEventEnums.SwipedDown, this._handleSwipedDown);
    }

    attachSwipedLeftEventListener(): void {
        this.htmlElement.addEventListener(SwipedEventEnums.SwipedLeft, this._handleSwipedLeft);
    }
    removeSwipedLeftEventListener(): void {
        this.htmlElement.addEventListener(SwipedEventEnums.SwipedLeft, this._handleSwipedLeft);
    }

    attachSwipedRightEventListener(): void {
        this.htmlElement.addEventListener(SwipedEventEnums.SwipedRight, this._handleSwipedRight);
    }
    removeSwipedRightEventListener(): void {
        this.htmlElement.addEventListener(SwipedEventEnums.SwipedRight, this._handleSwipedRight);
    }

    handleSwipedUp(e: CustomEvent) {
        if (this.carouselOrientation == UtilityEnums.ComponentOrientation.Vertical && this.isSwipeable()) {
            AsyncUtils.delay(100).then(() => { this.moveCarouselToNextPosition(); });
        }
    }
    handleSwipedDown(e: CustomEvent) {
        if (this.carouselOrientation == UtilityEnums.ComponentOrientation.Vertical && this.isSwipeable()) {
            AsyncUtils.delay(100).then(() => { this.moveCarouselToPrevPosition(); });
        }
    }
    handleSwipedLeft(e: CustomEvent) {
        if (this.carouselOrientation == UtilityEnums.ComponentOrientation.Horizontal && this.isSwipeable()) {
            AsyncUtils.delay(100).then(() => { this.moveCarouselToNextPosition(); });
        }
    }
    handleSwipedRight(e: CustomEvent) {
        if (this.carouselOrientation == UtilityEnums.ComponentOrientation.Horizontal && this.isSwipeable()) {
            AsyncUtils.delay(100).then(() => { this.moveCarouselToPrevPosition(); });
        }
    }

    abstract moveCarouselToPrevPosition(): void;
    abstract moveCarouselToNextPosition(): void;
    abstract reinitialiseCarousel(): void;

    abstract getCellsPerPage(): number;
    abstract getInitialPage(): number;

    moveCarouselToPosition(position: number): void {

        console.log('BasePagingSelectableCarousel: moveCarouselToPosition' + position);
        let elemetToMoveToFirstPosition = this.carouselSliderElement.children[position];
        if (elemetToMoveToFirstPosition !== undefined) {
            let scrollPositionOfCarousel = this.carouselSliderElement;
            let offSetPositionOfCarousel = scrollPositionOfCarousel.getBoundingClientRect();
            let positionOfElemetToMoveToFirstPosition = elemetToMoveToFirstPosition.getBoundingClientRect();
            let posLeft = scrollPositionOfCarousel.scrollLeft + positionOfElemetToMoveToFirstPosition.left - offSetPositionOfCarousel.left;
            //let posTop = scrollPositionOfCarousel.scrollTop + positionOfElemetToMoveToFirstPosition.top - offSetPositionOfCarousel.top;
            //this.carouselSliderElement.scrollLeft = posLeft;
            this.carouselSliderElement.scrollTo({
                left: posLeft,
                //top: posTop,
                behavior: 'smooth'
            });
        } else {
            console.log(`BasePagingSelectableCarousel: moveCarouselToPosition, Element undefined error - position: ${position}, children ${this.carouselSliderElement.children.length}`);
        }
    }

    moveCarouselToPage(pageNumber: number) {
        try{
        console.log('BasePagingSelectableCarousel: moveCarouselToPage');
        let pageFirstPosition: number = ((pageNumber - 1) * this.cellsPerPage ) + 1;
        let pageFirstPositionZeroIndexed = pageFirstPosition - 1;
        this.moveCarouselToPosition(pageFirstPositionZeroIndexed);
        } catch(error){
            console.log('BasePagingSelectableCarousel: moveCarouselToPage - Error', error);
        }
    }

    getTotalPageCount(): number {
        let getTotalPageCountNumber = Math.ceil(this.totalOccupiedCells / this.cellsPerPage);
        return getTotalPageCountNumber;
    }

    hasPrevButton(): boolean {
        console.log("BasePagingSelectableCarousel: hasPrevButton", this.currentPage);
        return (this.currentPage !== 1);
    }

    hasNextButton(): boolean {
        console.log("BasePagingSelectableCarousel: hasNextButton", this.currentPage);
        return (this.currentPage !== this.getTotalPageCount());
    }

    isSwipeable() {
        return this.mediaTiles.every((tile: BaseMediaTile) => { return !tile.disableSwipe; });
    }

    disableCarouselShowHideArrowsWhenScrolling() {
        console.log('BasePagingSelectableCarousel: disableCarouselShowHideArrowsWhenScrolling');

        let that = this;
        let isScrolling: number;
        this.sliderElement.addEventListener('scroll', function (e) {
            that.carouselPrevElement.classList.add(StringEnums.CssClass.LbDisabled);
            that.carouselNextElement.classList.add(StringEnums.CssClass.LbDisabled);
            clearTimeout( isScrolling );
            isScrolling = window.setTimeout(function() {
                console.log('BasePagingSelectableCarousel: disableCarouselShowHideArrowsWhenScrolling - clear timeout after scroll ends');
                that.carouselPrevElement.classList.remove(StringEnums.CssClass.LbDisabled);
                that.carouselNextElement.classList.remove(StringEnums.CssClass.LbDisabled);
            }, 100);
        }, false);

        setTimeout(function () {
            console.log('BasePagingSelectableCarousel: disableCarouselShowHideArrowsWhenScrolling - clear timeout after 1 seconds');
            clearTimeout( isScrolling ); 
            that.carouselPrevElement.classList.remove(StringEnums.CssClass.LbDisabled);
            that.carouselNextElement.classList.remove(StringEnums.CssClass.LbDisabled);
        }, 1000);
    }

    setCarouselShowHideArrows() {
        console.log('BasePagingSelectableCarousel: setCarouselShowHideArrows', this.carouselCurrentSelectedIndex);
        if (this.sliderElement !== undefined) {
            this.disableCarouselShowHideArrowsWhenScrolling();
        }
        this.carouselPrevElement.classList.remove("lb-hidden");
        this.carouselNextElement.classList.remove("lb-hidden");
        if (!this.hasPrevButton()) {
            this.carouselPrevElement.classList.add("lb-hidden");
        }
        if (!this.hasNextButton()) {
            this.carouselNextElement.classList.add("lb-hidden");
        }
        this.centerCarosuel();
    }

    centerCarosuel() {
        console.log('BasePagingSelectableCarousel: centerCarosuel');
        if (!this.hasPrevButton() && !this.hasNextButton()) {
            this.htmlElement.classList.add("lb_pagingSelectableCarousel-centerAlign");
        } else {
            this.htmlElement.classList.remove("lb_pagingSelectableCarousel-centerAlign");
        }
    }

    dispose(): void {
        this.removeCarouselPagePrevClickEventListener();
        this.removeCarouselPageNextClickEventListener();
        this.removeViewportDimensionsChangedEventListener();

        this.removeSwipedUpEventListener();
        this.removeSwipedDownEventListener();
        this.removeSwipedLeftEventListener();
        this.removeSwipedRightEventListener();
        super.dispose();
    }
}