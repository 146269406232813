import { BasketProduct } from "../product/basketProduct";
import { HttpRequest } from "../../shared/ajax";
import { LbCookies } from "../../shared/lbCookies";
import { UrlEnums } from "../../shared/utilities/enums/urlEnums";
import { IAddToRemoveFromCartGtmDataObject } from "../../shared/googleTagManager/gtmDataObject";
import { GoogleTagManager } from "../../shared/googleTagManager/gtmService";

const urlPaths = require('../../config/urlPaths.json');

export namespace BasketService {


    export async function addProductNoSize(lineId: string, addToRemoveFromCartGtmDataObject: IAddToRemoveFromCartGtmDataObject): Promise<string> {
        let url = urlPaths.websiteUrl + urlPaths.addToBasketbyLineIdApiRoute + lineId + '/' + LbCookies.getOrInitialiseBasketSession();
        let response: string | void = await HttpRequest.post(url, "");
        //GTM Add
        GoogleTagManager.sendAddToCartGtmDataObject(addToRemoveFromCartGtmDataObject);
        return response;
    }

    export async function addProductNoSizeWithoutGtmPost(lineId: string): Promise<string> {
        let url = urlPaths.websiteUrl + urlPaths.addToBasketbyLineIdApiRoute + lineId + '/' + LbCookies.getOrInitialiseBasketSession();
        let response: string | void = await HttpRequest.post(url, "");
        return response;
    }

    export async function addProduct(basketProduct: BasketProduct, addToRemoveFromCartGtmDataObject: IAddToRemoveFromCartGtmDataObject): Promise<string> {
        let response = await HttpRequest.post(urlPaths.basketDomain + UrlEnums.ApiUrls.BasketLine, JSON.stringify(basketProduct));
        //GTM Add
        GoogleTagManager.sendAddToCartGtmDataObject(addToRemoveFromCartGtmDataObject);
        return response;
    }

    export function mapAddToRemoveFromCartGtmDataObject(id: number, name: string, price: number, brand: string, variant: string, category: string, quantity: number): IAddToRemoveFromCartGtmDataObject {
        let dataObject: IAddToRemoveFromCartGtmDataObject = {
            id: id,
            name: name,
            price: price,
            brand: brand,
            variant: variant,
            category: category,
            quantity: quantity
        };

        return dataObject;
    }

    export async function updateProductQty(basketSessionId: string, basketLineId: number, oldQty: number, newQty: number, itemPrice: number, addToRemoveFromCartGtmDataObject: IAddToRemoveFromCartGtmDataObject): Promise<void> {

        let url: string = `${urlPaths.websiteUrl}${UrlEnums.ApiUrls.BasketUpdateLineQuantity}/${basketSessionId}/${basketLineId}/${newQty}`;


        await HttpRequest.put(url, '');

        if (oldQty > newQty) {
            let numberOfItemsRemoved: number = oldQty - newQty;
            let updatedDataObject = { ...addToRemoveFromCartGtmDataObject, quantity: numberOfItemsRemoved, price: (numberOfItemsRemoved * itemPrice) } as IAddToRemoveFromCartGtmDataObject;
            GoogleTagManager.sendRemoveFromCartGtmDataObject(updatedDataObject);
        }
        else {
            let numberOfItemsAdded: number = newQty - oldQty;
            let updatedDataObject = { ...addToRemoveFromCartGtmDataObject, quantity: numberOfItemsAdded, price: (numberOfItemsAdded * itemPrice) } as IAddToRemoveFromCartGtmDataObject;
            GoogleTagManager.sendAddToCartGtmDataObject(updatedDataObject);
        }
    }

    export async function updateProductQtyWithoutGtmPost(basketSessionId: string, basketLineId: number, newQty: number): Promise<void> {
        let url: string = `${urlPaths.websiteUrl}${UrlEnums.ApiUrls.BasketUpdateLineQuantity}/${basketSessionId}/${basketLineId}/${newQty}`;
        await HttpRequest.put(url, '');
    }

    export async function deleteProduct(basketSessionId: string, basketLineId: number, qtyToDelete: number, addToRemoveFromCartGtmDataObject: IAddToRemoveFromCartGtmDataObject) {
        let url: string = `${urlPaths.websiteUrl}${UrlEnums.ApiUrls.BasketDeleteLine}/${basketSessionId}/${basketLineId}`;
        await HttpRequest.deleteCall(url, '');
        //GTM remove
        let updatedDataObject = { ...addToRemoveFromCartGtmDataObject, quantity: qtyToDelete } as IAddToRemoveFromCartGtmDataObject;
        GoogleTagManager.sendRemoveFromCartGtmDataObject(updatedDataObject);
    }

    export async function deleteProductWithoutGtmPost(basketSessionId: string, basketLineId: number) {
        let url: string = `${urlPaths.websiteUrl}${UrlEnums.ApiUrls.BasketDeleteLine}/${basketSessionId}/${basketLineId}`;
        await HttpRequest.deleteCall(url, '');
    }
}