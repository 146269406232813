import { HttpRequest } from "../../shared/ajax";
import { AjaxGetAndReplaceHtml } from "../../shared/ajaxGetAndReplaceHtml";
import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { ResponsiveState } from "../../shared/responsiveState";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { StringEnums } from "../../shared/utilities/enums/stringEnums";
import { UrlEnums } from "../../shared/utilities/enums/urlEnums";
import { UtilityEnums } from "../../shared/utilities/enums/utillityEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { ReadMoreInterface } from "../readMore/interfaces";
import { ReadMore } from "../readMore/readMoreController";
import { Breadcrumb, OptionUpdateDto } from "./productListingPage";
import { IProductListingPagHeaderQuickLinks, ProductListingPageHeaderQuickLinks } from "./productListingPageHeaderQuickLinks";
const urlPaths = require('../../config/urlPaths.json');
export interface IProductListingPageHeader extends IInstanceComponentBase {
    updateHeaderForOptions: (optionUpdateDto: OptionUpdateDto) => Promise<void>;
    updateHeader: (querySelectorString: string) => Promise<void>;
    updateProductListingPageShowingProductCount: (value: number) => void;
    updateProductListingPageShowingStartProduct: (value: number) => void;
    updateProductListingPageShowingProductTotalProducts: (value: number) => void;
    updateProductListingPageShowingStartProductAndProductCount: (startProductValue: number, productCountValue: number) => void;
    getHeadingText: () => string;
    disableSortDropdown: () => void;
    enableSortDropdown: () => void;
    startProduct: number;
    productCount: number;
    totalProducts: number;
    hiddenBreadcrumb: Breadcrumb[];
}

export class ProductListingPageHeader extends InstanceComponentBase implements IProductListingPageHeader {
    private readonly _handleSortChanged;
    public startProduct: number;
    public productCount: number;
    public totalProducts: number;
    public hiddenBreadcrumb: Breadcrumb[] = new Array<Breadcrumb>();
    public productListingPageHeaderQuickLinks: IProductListingPagHeaderQuickLinks;
    public headerReadMore: ReadMoreInterface;
    private resizeObserver: ResizeObserver;
    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);
        console.log(`PLPHeader - ${htmlElement}`);
        this._handleSortChanged = this.handleSortChanged.bind(this);
        this.attachProductListingPageHeaderSortChangedEventListener();

        this.startHeaderResizeObserver();

        this.setProductListingPageShowingStartProduct();
        this.setProductListingPageShowingProductCount();
        this.setProductListingPageShowingProductTotalProducts();
        this.setBreadcrumbHiddenFields();
        this.productListingPageHeaderQuickLinksFactory();
        this.initialiseReadMore();
    }

    attachProductListingPageHeaderSortChangedEventListener(): void {
        try {
            console.log('ProductListingPageHeader: attachProductListingPageHeaderSortChangedEventListener');
            let sortElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageHeader.ProductListingPageSort));
            sortElement.addEventListener("change", this._handleSortChanged);
        } catch (error) {
            console.log('ProductListingPageHeader: attachProductListingPageHeaderSortChangedEventListener Error', error);

        }
    }

    removeProductListingPageHeaderSortChangedEventListener(): void {
        try {
            console.log('ProductListingPageHeader: removeProductListingPageHeaderSortChangedEventListener');
            let sortElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageHeader.ProductListingPageSort));
            sortElement.removeEventListener("change", this._handleSortChanged);
        } catch (error) {
            console.log('ProductListingPageHeader: removeProductListingPageHeaderSortChangedEventListener Error', error);

        }
    }

    startHeaderResizeObserver(): void {
        try {
            this.resizeObserver = new ResizeObserver(entries => {
                let headerHeight = entries[0].target.scrollHeight
                console.log('Document Body Scroll Height changed:', headerHeight);
                this.handleHeaderHeightChanged(headerHeight);
            }
            );

            // start observing a DOM node
            this.resizeObserver.observe(this.htmlElement);
        } catch (error) {
            console.log('ProductListingPageHeader: attachViewportDimensionsChangedEventEventListener Error', error);

        }
    }

    initialiseReadMore() {
        try {
            let readMoreToggleElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ReadMore.ReadMoreToggle, AttributeEnums.ProductListingPageHeader.ProductListingPageHeaderReadMoreValue)) as HTMLElement;
            let readMoreContentElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ReadMore.ReadMoreContent, AttributeEnums.ProductListingPageHeader.ProductListingPageHeaderReadMoreValue)) as HTMLElement;
            if (readMoreToggleElement != null && readMoreContentElement != null) {
                this.headerReadMore = new ReadMore(readMoreToggleElement, readMoreContentElement);
            }
        } catch (error) {
            console.log('ProductListingPageHeader: initialiseReadMore Error', error);
        }
    }

    async updateHeaderForOptions(optionUpdateDto: OptionUpdateDto) {
        try {
            let url: string = urlPaths.websiteUrl + `${UrlEnums.PartialUrls.ProductListingPageHeadersUpdate}`;
            console.log('ProductListingPageHeader: updateHeaderForOptions HttpRequest.post Started');

            let response = await HttpRequest.post(url, JSON.stringify(optionUpdateDto));
            if (response.length === 0) {
                console.log('ProductListingPageHeader: updateHeaderForOptions response.length === 0');

                location.href = '/browse/page--1';
            }
            this.htmlElement.innerHTML = response;
        } catch (error) {
            console.log('ProductListingPageHeader: updateHeaderForOptions Error', error);

        }
    }


    async updateHeader(querySelectorString: string) {
        let url: string = urlPaths.websiteUrl + `${UrlEnums.PartialUrls.ProductListingPageHeaders}${querySelectorString}`;
        await AjaxGetAndReplaceHtml(this.htmlElement, url);
    }

    disableSortDropdown(): void {
        let sortElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageHeader.ProductListingPageSort)) as HTMLSelectElement;
        sortElement.disabled = true;
    }
    enableSortDropdown(): void {
        let sortElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageHeader.ProductListingPageSort)) as HTMLSelectElement;
        sortElement.disabled = false;
    }

    productListingPageHeaderQuickLinksFactory() {
        try {
            console.log('ProductListingPageHeader: productListingPageHeaderQuickLinksFactory - Started');

            let quickLinksElement: HTMLElement | null = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageHeader.ProductListingPageHeaderQuickLinks)) as HTMLElement;
            if (quickLinksElement !== null) {
                this.productListingPageHeaderQuickLinks = new ProductListingPageHeaderQuickLinks(quickLinksElement);
            }


            console.log('ProductListingPageHeader: productListingPageHeaderQuickLinksFactory - Finished');
        } catch (error) {
            console.log('ProductListingPageHeader: productListingPageHeaderQuickLinksFactory ERROR: ', error);
        }
    }

    handleSortChanged(e: Event) {
        try {
            console.log('ProductListingPageHeader: handleSortChanged');
            let targetElement = e.target as HTMLSelectElement;
            let selectedOption = targetElement.options[targetElement.selectedIndex];
            let value: string = selectedOption.getAttribute('value');
            this.htmlElement.dispatchEvent(this.productListingPageSortChangedCustomEvent(value));
        } catch {
            console.log('ProductListingPageHeader: handleSortChanged ERROR');
        }
    }
    handleHeaderHeightChanged(headerHeight: number) {
        let responsiveState = ResponsiveState.getStateAsEnum() as StringEnums.Selectors;
        if (responsiveState == StringEnums.Selectors.Desktop) {
            this.htmlElement.style.top = null;

        } else {
            console.log(`ProductListingPageHeader: handleHeaderHeightChanged headerHeight ${headerHeight}`);
            this.htmlElement.style.top = '-' + (headerHeight - 100) + 'px';
        }
    }

    writeToProductListingPageShowingProductCount(value: number) {
        HelperUtils.setValueForElementAndAttribute(value.toString(), this.htmlElement as HTMLElement, AttributeEnums.ProductListingPageHeader.ProductListingPageFilterBarResultsProductCount);
    }

    setProductListingPageShowingProductCount() {

        let productCountElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageHeader.ProductListingPageFilterBarResultsProductCount));
        this.productCount = +productCountElement.getAttribute(AttributeEnums.ProductListingPageHeader.ProductListingPageFilterBarResultsProductCount);
    }

    updateProductListingPageShowingProductCount(value: number) {
        try {
            this.writeToProductListingPageShowingProductCount(value);
            this.setProductListingPageShowingProductCount();
            this.updateProductListingPageShowingProductCountDisplayValue();
        } catch (error) {
            console.log('ProductListingPageHeader: updateProductListingPageShowingProductCount Error', error);

        }
    }

    writeToProductListingPageShowingStartProduct(value: number) {
        HelperUtils.setValueForElementAndAttribute(value.toString(), this.htmlElement as HTMLElement, AttributeEnums.ProductListingPageHeader.ProductListingPageFilterBarResultsStartProduct);
    }

    setProductListingPageShowingStartProduct() {

        let productCountElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageHeader.ProductListingPageFilterBarResultsStartProduct));
        this.startProduct = +productCountElement.getAttribute(AttributeEnums.ProductListingPageHeader.ProductListingPageFilterBarResultsStartProduct);
    }

    updateProductListingPageShowingStartProduct(value: number) {
        try {
            this.writeToProductListingPageShowingStartProduct(value);
            this.setProductListingPageShowingStartProduct();
            this.updateProductListingPageShowingProductCountDisplayValue();
        } catch (error) {
            console.log('ProductListingPageHeader: updateProductListingPageShowingStartProduct Error', error);

        }
    }
    updateProductListingPageShowingStartProductAndProductCount(startProductValue: number, productCountValue: number): void {
        this.updateProductListingPageShowingStartProduct(startProductValue);
        this.updateProductListingPageShowingProductCount(productCountValue);
    }

    updateProductListingPageShowingProductCountDisplayValue() {
        let productCountElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageHeader.ProductListingPageFilterBarResultsProductCount)) as HTMLElement;
        productCountElement.innerText = `${this.startProduct} to ${this.productCount}`;
    }

    writeToProductListingPageShowingTotalProducts(value: number) {
        HelperUtils.setValueForElementAndAttribute(value.toString(), this.htmlElement as HTMLElement, AttributeEnums.ProductListingPageHeader.ProductListingPageFilterBarResultsTotalProducts);
    }

    setProductListingPageShowingProductTotalProducts() {
        try {
            let productCountElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageHeader.ProductListingPageFilterBarResultsTotalProducts));
            this.totalProducts = +productCountElement.getAttribute(AttributeEnums.ProductListingPageHeader.ProductListingPageFilterBarResultsTotalProducts);
        } catch (error) {
            console.log(`ProductListingPageHeader: setProductListingPageShowingProductTotalProducts ERROR:`, error);
            throw new Error();
        }
    }

    updateProductListingPageShowingProductTotalProducts(value: number) {
        try {
            this.writeToProductListingPageShowingTotalProducts(value);
            this.setProductListingPageShowingProductTotalProducts();
            this.updateProductListingPageShowingTotalProductsDisplayValue();
        } catch (error) {
            console.log(`ProductListingPageHeader: updateProductListingPageShowingProductTotalProducts ERROR:`, error);
            throw new Error();
        }
    }

    updateProductListingPageShowingTotalProductsDisplayValue() {
        let productCountElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageHeader.ProductListingPageFilterBarResultsTotalProducts)) as HTMLElement;
        productCountElement.innerText = `${this.totalProducts}`;
    }

    setBreadcrumbHiddenFields() {
        try {
            this.hiddenBreadcrumb = [];
            let breadcrumbHiddenFieldElements: NodeListOf<HTMLElement> = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.BreadcrumbHiddenField.BreadcrumbHiddenField)) as NodeListOf<HTMLElement>;
            breadcrumbHiddenFieldElements.forEach((breadcrumbHiddenFieldElement: HTMLElement) => {
                let currentBreadcrumb = {
                    url: breadcrumbHiddenFieldElement.getAttribute(AttributeEnums.BreadcrumbHiddenField.BreadcrumbHiddenFieldUrl),
                    name: breadcrumbHiddenFieldElement.getAttribute(AttributeEnums.BreadcrumbHiddenField.BreadcrumbHiddenFieldName),
                    position: +breadcrumbHiddenFieldElement.getAttribute(AttributeEnums.BreadcrumbHiddenField.BreadcrumbHiddenFieldPosition)
                } as Breadcrumb;
                //breadcrumbHiddenFieldElement 

                this.hiddenBreadcrumb.push(currentBreadcrumb);
            });
        } catch (error) {
            console.log('ProductListingPageHeader: setBreadcrumbHiddenFields Error Setting Breadcrumb', error);
          
        }

    }

    getHeadingText(): string {
        try {
            let headerElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductListingPageHeader.ProductListingPageHeaderHeading)) as HTMLElement;
            let headingText = headerElement.innerText;
            return headingText;
        } catch (error) {
            console.log('ProductListingPageHeader: getHeadingText Error', error);

            return '';
        }
    }
    productListingPageSortChangedCustomEvent(value: string) {
        console.log(`ProductListingPageOption: productListingPageSortChangedCustomEvent value: ${value}`);
        return new CustomEvent(CustomEventEnums.ProductListingPageSortChanged, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                value: value
            }
        });
    }

    dispose(): void {
        try {
            this.removeProductListingPageHeaderSortChangedEventListener();
            if (this.productListingPageHeaderQuickLinks != null) {
                this.productListingPageHeaderQuickLinks.dispose();
            }
            if (this.resizeObserver != null) {
                this.resizeObserver.disconnect();
            }
            if (this.headerReadMore != null) {
                this.headerReadMore.dispose();
            }
        } catch (error) {
            console.log(`ProductListingPageOption: dispose Error:`, error);
        }
    }

    delete(): void {

    }
}