import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { ContentItem, IContentItem } from "./contentItem";

export interface IContentItems {
    contentItemList: Array<IContentItem>;
    dispose: () => void;
}
export class ContentItems implements IContentItems {
    public contentItemList = new Array<IContentItem>();

    constructor() {
        this.contentItemListFactory();
    }

    contentItemListFactory(): void {
        try {
            let contentItemsElements: NodeListOf<Element> = document.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ContentItem.ContentItem));

            for (let i = 0; i < contentItemsElements.length; i += 1) {
                try {
                    this.contentItemList.push(new ContentItem(contentItemsElements[i] as HTMLElement));
                } catch (error) {
                    console.log(`ContentItems: contentItemListFactory ERROR on creation`, error);
                }
            }
        } catch (error) {
            console.log('ContentItems: contentItemListFactory ERROR:', error);
        }

    }


    dispose(): void {
        try {
            this.contentItemList.forEach((contentItem: IContentItem) => contentItem.dispose());
        } catch (error) {

        }
    }

    public static contentItemsFactory(): ContentItems {
        try {
            return new ContentItems();
        } catch (error) {
            console.log('ContentItems.contentItemsFactory ERROR', error);
        }
    }

}