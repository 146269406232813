import { InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { GroupEnums } from "../../shared/utilities/enums/groupEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { GroupPicker } from "../groupPicker/groupPicker";
import { ProductColourDropDown } from "./productColourDropDown";
import { IProductSelector } from "./productDropDown";
import { IProductGroupDropDown, IProductGroupSelector, ProductGroupDropDown } from "./productGroupDropDown";
import { IProductSizeSelector, ProductSizeDropDown } from "./productSizeDropDown";

export interface IProductOptionsPanel {
    productSizeDropDown?: ProductSizeDropDown;
    productGroupDropDownList: ProductGroupDropDown[];
    fixedValueProductSelectorList: IProductSelector[];
    refreshAllDropdowns: () => void;
    getGroupDropdownById: (groupId: number) => ProductGroupDropDown;
    getColourDropdown: () => ProductColourDropDown;
    hasSizeDropdown: () => boolean;
    hasColourDropdown: () => boolean;
    setProductOptionsValidation: () => void;
    getTotalProductAdditionsPrice: () => number;
    setUpOptionsSelectedFields: () => void;
    dispose: () => Promise<void>;
}

export class ProductOptionsPanel extends InstanceComponentBase implements IProductOptionsPanel {
    productSizeDropDown?: ProductSizeDropDown;
    productGroupDropDownList: ProductGroupDropDown[] = new Array();
    fixedValueProductSelectorList: IProductSelector[] = new Array();

    constructor(public productOptionsPanelElement: HTMLElement) {
        super(productOptionsPanelElement);
        this.productSizeDropDownFactory();
        this.productGroupDropDownListFactory();
        this.productFixedValueProductSelectorListFactory();
    }

    productSizeDropDownFactory(): void {
        let sizeHiddenField: Element = this.productOptionsPanelElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductSizeId) + HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelector));
        let productSizeDropDownElement: Element | null = sizeHiddenField.closest(HelperUtils.addDataAttributeTags(AttributeEnums.DropDowns.ProductDropDown));
        if (productSizeDropDownElement !== null) {
            this.productSizeDropDown = new ProductSizeDropDown(productSizeDropDownElement as HTMLElement);
        }
    }

    productGroupDropDownFactory(productGroupDropDownElement: HTMLElement): ProductGroupDropDown {
        console.log('ProductOptionsPanel: productGroupDropDownFactory - Started');
        return new ProductGroupDropDown(productGroupDropDownElement as HTMLElement);
    }

    productColourDropDownFactory(productColourDropDownElement: HTMLElement): ProductColourDropDown {
        return new ProductColourDropDown(productColourDropDownElement as HTMLElement);
    }

    productGroupDropDownListFactory(): void {
        let allProductGroupSelectorElements: NodeListOf<Element> = this.productOptionsPanelElement.querySelectorAll(
            HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductGroupId) + HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelector));
        for (let i = 0; i < allProductGroupSelectorElements.length; i += 1) {
            let productGroupDropDownElement: Element | null = allProductGroupSelectorElements[i].closest(HelperUtils.addDataAttributeTags(AttributeEnums.DropDowns.ProductDropDown));

            if (productGroupDropDownElement !== null) {

                this.productGroupOrColourDropDownFactory(productGroupDropDownElement);
            }
        }
    }

    productGroupOrColourDropDownFactory(productGroupDropDownElement: Element): void {
        let productSelector = productGroupDropDownElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelector));
        if (productGroupDropDownElement !== null) {
            if (productSelector.getAttribute(AttributeEnums.ProductSelector.ProductSelector) === 'colour') {
                this.productGroupDropDownList.push(this.productColourDropDownFactory(productGroupDropDownElement as HTMLElement));
            } else if (productSelector.getAttribute(AttributeEnums.ProductSelector.ProductSelector) === 'group') {
                let returnProductGroupDropDownFactory = this.productGroupDropDownFactory(productGroupDropDownElement as HTMLElement);
                console.log('ProductOptionsPanel: productGroupDropDownListFactory - ', returnProductGroupDropDownFactory);
                this.productGroupDropDownList.push(returnProductGroupDropDownFactory);
            }
            else {
                console.log('ProductOptionsPanel: productGroupDropDownListFactory - Drop Down Type is of invalid type', productGroupDropDownElement);
                throw new Error('ProductOptionsPanel: productGroupDropDownListFactory - Drop Down Type is of invalid type');
            }
        } else {
            console.log('ProductOptionsPanel: productGroupOrColourDropDownFactory - data-product-selector is missing', productGroupDropDownElement);
            throw new Error('ProductOptionsPanel: productGroupOrColourDropDownFactory - data-product-selector is missing');
        }
    }

    productFixedValueProductSelectorListFactory(): void {
        let allFixedValueProductSelectorElements: NodeListOf<Element> = this.productOptionsPanelElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelector));
        for (let i = 0; i < allFixedValueProductSelectorElements.length; i += 1) {
            let productDropdownElement: Element | null = allFixedValueProductSelectorElements[i].closest(HelperUtils.addDataAttributeTags(AttributeEnums.DropDowns.ProductDropDown));

            if (productDropdownElement === null) {

                this.productFixedValueProductSelectorFactory(allFixedValueProductSelectorElements[i]);
            }
        }
    }
    productFixedValueProductSelectorFactory(productFixedValueProductSelectorElement: Element): void {
        if (productFixedValueProductSelectorElement.getAttribute(AttributeEnums.ProductSelector.ProductSelector) === 'colour') {
            this.fixedValueProductSelectorList.push(this.productGroupSelectorFactory(productFixedValueProductSelectorElement as HTMLElement));
        } else if (productFixedValueProductSelectorElement.getAttribute(AttributeEnums.ProductSelector.ProductSelector) === 'group') {
            this.fixedValueProductSelectorList.push(this.productGroupSelectorFactory(productFixedValueProductSelectorElement as HTMLElement));
        } else if (productFixedValueProductSelectorElement.getAttribute(AttributeEnums.ProductSelector.ProductSelector) === 'size') {
            this.fixedValueProductSelectorList.push(this.productSizeSelectorFactory(productFixedValueProductSelectorElement as HTMLElement));
        }
        else {
            console.log('ProductOptionsPanel: productFixedValueProductSelectorFactory - Drop Down Type is of invalid type', productFixedValueProductSelectorElement);
            throw new Error('ProductOptionsPanel: productFixedValueProductSelectorFactory - Drop Down Type is of invalid type');
        }
    }
    productGroupSelectorFactory(productFixedValueProductSelectorElement: HTMLElement): IProductGroupSelector {
        let productGroupId: number = +productFixedValueProductSelectorElement.getAttribute(AttributeEnums.Product.ProductGroupId);
        let productAdditionPrice: number = +productFixedValueProductSelectorElement.getAttribute(AttributeEnums.Product.ProductAdditionPrice);
        let productSelectorElement: HTMLElement = productFixedValueProductSelectorElement;
        let productLineId: number = +productFixedValueProductSelectorElement.getAttribute(AttributeEnums.Product.ProductLineId);
        let value: number | undefined = +productFixedValueProductSelectorElement.getAttribute('value');

        return {
            productGroupId: productGroupId,
            productAdditionPrice: productAdditionPrice,
            productSelectorElement: productSelectorElement,
            productLineId: productLineId,
            value: value
        }
    }

    productSizeSelectorFactory(productFixedValueProductSelectorElement: HTMLElement): IProductSizeSelector {
        let productSizeId: number | undefined = +productFixedValueProductSelectorElement.getAttribute(AttributeEnums.Product.ProductSizeId);
        let productSelectorElement: HTMLElement = productFixedValueProductSelectorElement;
        let productLineId: number = +productFixedValueProductSelectorElement.getAttribute(AttributeEnums.Product.ProductLineId);
        let value: number | undefined = +productFixedValueProductSelectorElement.getAttribute('value');

        return {
            productSizeId: productSizeId,
            productSelectorElement: productSelectorElement,
            productLineId: productLineId,
            value: value
        }
    }

    getTotalProductAdditionsPrice(): number {
        let totalAdditionsPrice: number = 0;
        this.productGroupDropDownList.forEach((productGroup: ProductGroupDropDown) => {
            totalAdditionsPrice += productGroup.productAdditionPrice;
        });
        return totalAdditionsPrice;
    }

    refreshAllDropdowns() {
        if (this.productSizeDropDown !== undefined) {
            this.productSizeDropDown.refreshProperties();
        }
        for (let i in this.productGroupDropDownList) {
            this.productGroupDropDownList[i].refreshProperties();
        }
    }

    getGroupDropdownById(groupId: number): ProductGroupDropDown | undefined {

        let selectedGroupDropdowns = this.productGroupDropDownList.filter((groupDropDown: ProductGroupDropDown) => groupDropDown.productGroupId === groupId);
        if (selectedGroupDropdowns.length === 1) {
            return selectedGroupDropdowns[0];
        } else if (selectedGroupDropdowns.length === 0) {
            console.log('ProductOptionsPanel: getGroupDropdownById - has no groups for id: ' + groupId, selectedGroupDropdowns);
            return undefined;
        }
        else {
            console.log('ProductOptionsPanel: getGroupDropdownById - has incorrect groups for id: ' + groupId, selectedGroupDropdowns);
            throw Error('ProductOptionsPanel: getGroupDropdownById - has incorrect groups for id: ' + groupId);
        }
    }

    getColourDropdown(): ProductColourDropDown | undefined {
        return this.getGroupDropdownById(GroupEnums.GroupIds.Colour) as ProductColourDropDown;
    }

    hasSizeDropdown(): boolean {
        if (this.productSizeDropDown !== undefined && this.productSizeDropDown !== null) {
            return true;
        } else {
            return false;
        }
    }

    hasColourDropdown(): boolean {
        if (this.getColourDropdown()) {
            return true;
        } else {
            return false;
        }
    }

    setProductOptionsValidation() {
        let groupDropdowns: ProductGroupDropDown[] = this.productGroupDropDownList;
        for (let i in groupDropdowns) {
            groupDropdowns[i].updateValidationStateUsingValue();
        }
        if (this.hasSizeDropdown()) {
            this.productSizeDropDown.updateValidationStateUsingValue();
        }
    }

    setUpOptionsSelectedFields(): void {
        if (this.productSizeDropDown) {
            this.checkIfProductSizeSelectorHasOptionsThenSetsSelector();
        }

        for (let i in this.productGroupDropDownList) {
            let currentDropDownValue: number | undefined = this.productGroupDropDownList[i].value;
            if (currentDropDownValue) {

                this.productGroupDropDownList[i].updateProductGroupPanelSelected();
            }

        }
    }

    checkIfProductSizeSelectorHasOptionsThenSetsSelector(): void {
        if (this.hasSizeDropdown()) {
            this.productSizeDropDown.productSizeSelectorSetSelected();
        }
    }



    async dispose(): Promise<void> {
       if(this.hasSizeDropdown()){
        this.productSizeDropDown.dispose();
       }
       this.productGroupDropDownList.forEach((dropDown: IProductGroupDropDown) => {
        dropDown.dispose();
       });
    }
    delete(): void {

    }

}