import { ShowHide } from '../showHide/showHideController';
import { InstanceComponentBase } from '../../shared/instanceComponentBase';
import { ReadMoreInterface } from './interfaces';
import { AttributeEnums } from '../../shared/utilities/enums/attributeEnums';
import { HelperUtils } from '../../shared/utilities/helperUtils';
import { StringEnums } from '../../shared/utilities/enums/stringEnums';
export class ReadMore extends InstanceComponentBase implements ReadMoreInterface {

  private readonly showHide: ShowHide;
  private readonly _setReadMore;
  private _staticMinHeight: number;
  private _toggleElement: Element;
  private _contentElement: Element;
  private _buttingElement: Element;

  constructor(public htmlElement: Element, public contentElement: Element) {
    super(htmlElement);
    //----- Event Listener Bindings Start -----//
    this._setReadMore = this.setReadMore.bind(this);
    //----- Event Listener Bindings End -----//

    this._toggleElement = htmlElement;
    this._contentElement = contentElement;
    let buttingElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ReadMore.ReadMoreButting, this._toggleElement.getAttribute(AttributeEnums.ReadMore.ReadMoreToggle)));
    if (typeof (buttingElement) != 'undefined' && buttingElement != null) {
      this._buttingElement = buttingElement;
    } else {
      this._buttingElement = undefined;
    }
    let dynamicHeight: any = this._contentElement.getAttribute(AttributeEnums.ReadMore.ReadMoreHeight);
    if (dynamicHeight) {
      this._staticMinHeight = dynamicHeight;
    } else {
      this._staticMinHeight = 50;
    }
    var contentElements = document.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ReadMore.ReadMoreContent, this._toggleElement.getAttribute(AttributeEnums.ReadMore.ReadMoreToggle)));
    this.showHide = new ShowHide(htmlElement, contentElements);
    this.setReadMore();

    //----- Initialise Event Listeners Start -----//
    this.attachResizeControllerEvent();
    this.attachClickEventListener();
    //----- Initialise Event Listeners End -----//
  }

  get contentElementMinHeight() {
    if (!this._buttingElement || this.isContentBelowButtingElement) {
      return this._staticMinHeight;
    } else {
      let buttingElementBottom = this._buttingElement.getBoundingClientRect().bottom;
      let contentElementTop = this._contentElement.getBoundingClientRect().top;
      let toggleElementHeight = this._toggleElement.getBoundingClientRect().height;
      if (this._buttingElement.getAttribute(AttributeEnums.ReadMore.ReadMoreButtingOffset)) {
        let buttingOffset:number = +this._buttingElement.getAttribute(AttributeEnums.ReadMore.ReadMoreButtingOffset);
        console.log("offset", buttingOffset);
        return buttingElementBottom - (contentElementTop + toggleElementHeight) + buttingOffset;
      } else{
        return buttingElementBottom - (contentElementTop + toggleElementHeight);
      }
    }
  }

  get isContentBelowButtingElement() {
    if (this._contentElement.getBoundingClientRect().top > this._buttingElement.getBoundingClientRect().bottom) {
      return true;
    } else {
      return false;
    }
  }

  setReadMore() {
    console.log('ReadMore: setReadMore');
    this.showHide.setMinHeight = this.contentElementMinHeight;
    this.displayLink();
    this.toggleText();
    this.showHide.setHeight(this.contentElement);
  }

  //------ Add Event Listeners Start -----//
  attachResizeControllerEvent(): void {
    //window.addEventListener("resize", this._setReadMore);
  }

  attachClickEventListener(): void {
    this._toggleElement.addEventListener('click', this._setReadMore);
  }
  //------ Add Event Listeners End -----//

  //------ Remove Event Listeners Start -----//
  removeResizeControllerEvent(): void {
    window.removeEventListener("resize", this._setReadMore);
  }

  removeClickEventListener(): void {
    this._toggleElement.removeEventListener('click', this._setReadMore);
  }
  //------ Remove Event Listeners End -----//

  //------ Event Listener Methods Start -----//
  //------ Event Listener Methods End -----//

  displayLink(): void {
    let contentElementHeight = this._contentElement.getBoundingClientRect().height;
    if (contentElementHeight < this._staticMinHeight) {
      this._toggleElement.setAttribute("style", "display: none");
      this._contentElement.classList.add(StringEnums.CssClass.LbReadMoreFade);
    } else {
      this._toggleElement.setAttribute("style", "display: block");
      this._contentElement.classList.remove(StringEnums.CssClass.LbReadMoreFade);
    }

    if (this.contentElementMinHeight > this._contentElement.scrollHeight) {
      this._toggleElement.setAttribute("style", "display: none");
      this._contentElement.classList.add(StringEnums.CssClass.LbReadMoreFade);
    } else {
      this._toggleElement.setAttribute("style", "display: block");
      this._contentElement.classList.remove(StringEnums.CssClass.LbReadMoreFade);
    }
  }

  toggleText(): void {
    if (this._toggleElement.classList.contains(StringEnums.CssClass.LbHidden)) {
      this._toggleElement.innerHTML = StringEnums.Content.ReadMore;
    } else {
      this._toggleElement.innerHTML = StringEnums.Content.ReadLess;
    }
  }

  //------ Custom Event Creation Start -----//
  //------ Custom Event Creation End -----//

  //------ Clean Up Processes Start -----//
  dispose(): void {
    console.log('ReadMore: dispose - Beginning');
    this.showHide.dispose();
    this.removeResizeControllerEvent();
    this.removeClickEventListener();
    this.delete();
  }

  delete(): void {
    if ((<any>window)['rm' + this.htmlElement.getAttribute(AttributeEnums.ReadMore.ReadMoreToggle) + 'ReadMore']) {
      console.log('ReadMore: Delete - Deleting ReadMore Reference');
      delete (<any>window)['rm' + this.htmlElement.getAttribute(AttributeEnums.ReadMore.ReadMoreToggle) + 'ReadMore'];
    }
    else {
      console.log('ReadMore: Delete - ReadMore Reference Not Found');
    }
  }
  //------ Clean Up Processes End -----//
}