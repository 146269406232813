import { ResponsiveState } from "./responsiveState";
import { CustomEventEnums } from "./utilities/enums/customEventEnums";
import { StringEnums } from "./utilities/enums/stringEnums";

export interface IFirstInteraction {
    firstInteractionFired: boolean;
    firstMovementInteractionFired: boolean;
}
export class FirstInteraction {
    private readonly _handleInteraction;

    public firstInteractionFired: boolean = false;
    public firstMovementInteractionFired: boolean = false;
    constructor() {
        this._handleInteraction = this.handleInteraction.bind(this);

        this.attachDocumentScrollEventListener();
        this.attachDocumentMouseDownEventListener();
        this.attachDocumentMouseMoveEventListener();
        this.attachDocumentTouchStartEventListener();
        this.attachDocumentKeyDownEventListener();
    }

    attachDocumentScrollEventListener(): void {
        console.log('FirstInteraction: attachDocumentScrollEventListener');
        document.addEventListener('scroll', this._handleInteraction);
    }

    removeDocumentScrollEventListener(): void {
        console.log('FirstInteraction: removeDocumentScrollEventListener');
        document.removeEventListener('scroll', this._handleInteraction);
    }

    attachDocumentMouseDownEventListener(): void {
        console.log('FirstInteraction: attachDocumentMouseDownEventListener');
        document.addEventListener('mousedown', this._handleInteraction);
    }

    removeDocumentMouseDownEventListener(): void {
        console.log('FirstInteraction: removeDocumentMouseDownEventListener');
        document.removeEventListener('mousedown', this._handleInteraction);
    }

    attachDocumentMouseMoveEventListener(): void {
        console.log('FirstInteraction: attachDocumentMouseMoveEventListener');
        document.addEventListener('mousemove', this._handleInteraction);
    }

    removeDocumentMouseMoveEventListener(): void {
        console.log('FirstInteraction: removeDocumentMouseMoveEventListener');
        document.removeEventListener('mousemove', this._handleInteraction);
    }

    attachDocumentTouchStartEventListener(): void {
        console.log('FirstInteraction: attachDocumentTouchStartEventListener');
        document.addEventListener('touchstart', this._handleInteraction);
    }

    removeDocumentTouchStartEventListener(): void {
        console.log('FirstInteraction: removeDocumentTouchStartEventListener');
        document.removeEventListener('touchstart', this._handleInteraction);
    }

    attachDocumentKeyDownEventListener(): void {
        console.log('FirstInteraction: attachDocumentKeyDownEventListener');
        document.addEventListener('keydown', this._handleInteraction);
    }

    removeDocumentKeyDownEventListener(): void {
        console.log('FirstInteraction: removeDocumentKeyDownEventListener');
        document.removeEventListener('keydown', this._handleInteraction);
    }

    static initialise() {
        let firstInteraction = new FirstInteraction();
        (<any>window).firstInteraction = firstInteraction;
    }

    handleInteraction(e: Event) {
        try{
        let type = e.type;
        if(!this.firstInteractionFired){
            console.log(`FirstInteraction: handleInteraction firstInteractionFired Type: ${type} Started`);
            document.dispatchEvent(this.firstInteractionCustomEvent(type));
            console.log(`FirstInteraction: handleInteraction firstInteractionFired Type: ${type} Fired`);
            this.firstInteractionFired = true;
            this.disposeNonMovementEventListners();
            
        }
        if(type === 'scroll' || (type === 'mousemove' && ResponsiveState.getState() === StringEnums.Selectors.Desktop))
        {
            console.log(`FirstInteraction: handleInteraction firstMovementInteractionFired Type: ${type} Started`);
           document.dispatchEvent(this.firstMovementInteractionCustomEvent(type)); 
           console.log(`FirstInteraction: handleInteraction firstMovementInteractionFired Type: ${type} Fired`);
            this.firstMovementInteractionFired = true;
           this.disposeMovementEventListners();

        }
            
        if(this.firstInteractionFired && this.firstMovementInteractionFired){
            console.log(`FirstInteraction: handleInteraction Delete Started`);
            this.delete();
        }
        }catch(error){
            console.log('FirstInteraction: handleInteraction error', error);
        }
    }

    firstInteractionCustomEvent(type: string) {
        console.log(`FirstInteraction: firstInteractionCustomEvent type: ${type}`);
        return new CustomEvent(CustomEventEnums.FirstInteraction, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                type: type,
            }
        });
    }

    firstMovementInteractionCustomEvent(type: string) {
        console.log(`FirstInteraction: firstMovementInteractionCustomEvent type: ${type}`);
        return new CustomEvent(CustomEventEnums.FirstMovementInteraction, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                type: type,
            }
        });
    }


    disposeNonMovementEventListners(){
        this.removeDocumentMouseDownEventListener();
        this.removeDocumentTouchStartEventListener();
        this.removeDocumentKeyDownEventListener();
    }
    disposeMovementEventListners(){
        this.removeDocumentMouseMoveEventListener();
        this.removeDocumentScrollEventListener();
    }

   

    delete() {
        console.log('FirstInteraction: delete');
        if ((<any>window)['firstInteraction']) {
            console.log('FirstInteraction: delete - has a firstInteraction');
            delete (<any>window)['firstInteraction'];
            console.log('FirstInteraction: delete - reference firstInteraction');
        }
        else {
            console.log('FirstInteraction: delete - firstInteraction Reference Not Found');
        }
    }
}