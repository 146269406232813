import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";

export interface IButtonCarouselButton extends IInstanceComponentBase {
    value: string|undefined;
}

export class ButtonCarouselButton extends InstanceComponentBase implements IButtonCarouselButton {
    public value: string|undefined;
    private readonly _handleButtonCarouselButtonClicked;

    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);
        this._handleButtonCarouselButtonClicked = this.handleButtonCarouselButtonClicked.bind(this);
        this.setButtonCarouselButtonValue();
        this.attachButtonCarouselButtonClickedEventListener();
    }

    attachButtonCarouselButtonClickedEventListener(): void {
        console.log('ButtonCarouselButton: attachButtonCarouselButtonClickedEventListener');
        this.htmlElement.addEventListener('click', this._handleButtonCarouselButtonClicked);
    }

    removeButtonCarouselButtonClickedEventListener(): void {
        console.log('ButtonCarouselButton: removeButtonCarouselButtonClickedEventListener');
        this.htmlElement.removeEventListener('click', this._handleButtonCarouselButtonClicked);
    }

    handleButtonCarouselButtonClicked(e: Event) {
        console.log('ButtonCarouselButton: handleButtonCarouselButtonClicked');
        let targetElement = e.target as HTMLElement;        
        this.htmlElement.dispatchEvent(this.productButtonCarouselButtonClickedCustomEvent(this.value));
    }

    writeToButtonCarouselButtonValue(value: string | undefined){
        HelperUtils.setValueForElementAndAttribute(value.toString(), this.htmlElement as HTMLElement, AttributeEnums.ButtonCarouselButton.ButtonCarouselButtonValue);
    }

    setButtonCarouselButtonValue(){

            this.value = this.htmlElement.getAttribute(AttributeEnums.ButtonCarouselButton.ButtonCarouselButtonValue);

    }

    updateButtonCarouselButtonValue(value: string | undefined){
        this.writeToButtonCarouselButtonValue(value);
        this.setButtonCarouselButtonValue();
    } 

    productButtonCarouselButtonClickedCustomEvent(value: string) {
        console.log(`ButtonCarouselButton: productButtonCarouselButtonClickedCustomEvent value: ${value}`);
        return new CustomEvent(CustomEventEnums.ButtonCarouselButtonClicked, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                value: value
            }
        });
    }

    dispose(): void {
        this.removeButtonCarouselButtonClickedEventListener(); 
    }
    
    delete(): void {

    }
}