import { IPromotionGtmDataObject } from "../../shared/googleTagManager/gtmDataObject";
import { GoogleTagManager } from "../../shared/googleTagManager/gtmService";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { OnScreenUtils } from "../../shared/utilities/onScreenUtils";
import { BannerPromotion, IBannerPromotion } from "./bannerPromotion";

export interface IBannerPromotions {
    dispose: () => void;
    bannerPromotionList: Array<IBannerPromotion>;
}

export class BannerPromotions implements IBannerPromotions {
    public bannerPromotionList = new Array<IBannerPromotion>();
    private readonly _handlePromotionImpressionsOnScroll;

    constructor() {
        this._handlePromotionImpressionsOnScroll = this.handlePromotionImpressionsOnScroll.bind(this);
        this.bannerPromotionListFactory();
        this.updateGTMImpressions();
        this.attachCustomOnScrollEventListener();
    }

    attachCustomOnScrollEventListener(): void {
        (<any>window).addEventListener(CustomEventEnums.OnScroll, this._handlePromotionImpressionsOnScroll);
    }
    removeCustomOnScrollEventListener(): void {
        (<any>window).removeEventListener(CustomEventEnums.OnScroll, this._handlePromotionImpressionsOnScroll);
    }
    handlePromotionImpressionsOnScroll(): void {
        console.log('BannerPromotions: handlePromotionImpressionsOnScroll');
        this.updateGTMImpressions();
    }

    getAllUnLoggedPromotions(): Array<IBannerPromotion> {
        return this.bannerPromotionList.filter((bannerPromotion: IBannerPromotion) => bannerPromotion.impressionLogged === false);
    }

    updateGTMImpressions(): void {
        try {
            let promotionsToLog = new Array<IBannerPromotion>();
            let unloggedPromotions = this.getAllUnLoggedPromotions();
            unloggedPromotions.forEach((bannerPromotion: IBannerPromotion) => {
                if (OnScreenUtils.isVerticallyOnScreen(bannerPromotion.htmlElement)) {
                    promotionsToLog.push(bannerPromotion);
                }
            });

            let promotionGtmDataObjectList = new Array<IPromotionGtmDataObject>();
            promotionsToLog.forEach((bannerPromotion: IBannerPromotion) => {
                promotionGtmDataObjectList.push(bannerPromotion.getPromotionGtmDataObject());
            });

            if (promotionGtmDataObjectList.length > 0) {
                let impressionsLogged: boolean = GoogleTagManager.sendPromotionImpressionsGtmDataObject(promotionGtmDataObjectList);

                if (impressionsLogged) {
                    promotionsToLog.forEach((bannerPromotion: IBannerPromotion) => {
                        bannerPromotion.updateImpressionLogged(true);
                    });
                }
            }
        } catch (error) {
            console.log('BannerPromotions: updateGTMImpressions ERROR', error);
        }
    }


    bannerPromotionListFactory(): void {
        try {
            let bannerPromotionElements: NodeListOf<Element> = document.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.BannerPromotion.BannerPromotion));

            for (let i = 0; i < bannerPromotionElements.length; i += 1) {
                try {
                    this.bannerPromotionList.push(new BannerPromotion(bannerPromotionElements[i] as HTMLElement, i + 1));
                } catch (error) {
                    console.log(`BannerPromotions: bannerPromotionsFactory ERROR on creation position ${i + 1}`, error);
                }
            }
        } catch (error) {
            console.log('BannerPromotions: bannerPromotionsFactory ERROR:', error);
        }

    }

    dispose(): void {
        try {
            this.removeCustomOnScrollEventListener();
            this.bannerPromotionList.forEach((bannerPromotion: BannerPromotion) => this.dispose());
        } catch (error) {
            console.log('BannerPromotions: dispose ERROR:', error);
        }

    }

    public static bannerPromotionsFactory(): BannerPromotions {
        try {
            return new BannerPromotions();
        } catch (error) {
            console.log('BannerPromotions.bannerPromotionsFactory ERROR', error);
        }
    }

}