import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";

export interface IProductListingPageOption extends IInstanceComponentBase {
    
    optionValue: string;
    optionType: string;
    optionTitle: string;
    optionSelected: boolean;
    htmlElement: HTMLElement;
}

export class ProductListingPageOption extends InstanceComponentBase implements IProductListingPageOption {
    
    public optionValue: string;
    public optionType: string;
    public optionTitle: string;
    public optionSelected: boolean;
    private readonly _handleProductListingPageOptionSelected;
    
    constructor(public htmlElement: HTMLElement, optionType: string) {
        super(htmlElement);
        this.optionType = optionType;
        this._handleProductListingPageOptionSelected = this.handleProductListingPageOptionSelected.bind(this);
        this.setProductListingPageOption();
        this.setProductListingPageOptionSelected();
        this.setProductListingPageOptionTitle();
        this.attachProductListingPageOptionClickedEventListener();
    }

    attachProductListingPageOptionClickedEventListener(): void {
        console.log('ProductListingPageOption: attachProductListingPageOptionClickedEventListener');
        this.htmlElement.addEventListener("click", this._handleProductListingPageOptionSelected);
    }

    removeProductListingPageOptionClickedEventListener(): void {
        console.log('ProductListingPageOption: removeProductListingPageOptionClickedEventListener');
        this.htmlElement.removeEventListener("click", this._handleProductListingPageOptionSelected);
    }

    handleProductListingPageOptionSelected(e: Event) {
        console.log('ProductListingPageOption: handleProductListingPageOptionSelected');       
        this.htmlElement.dispatchEvent(this.productListingPageOptionSelectedCustomEvent(this.optionType, this.optionValue));
          
    }
    writeToProductListingPageOption(value: string | undefined){
        HelperUtils.setValueForElementAndAttribute(value.toString(), this.htmlElement as HTMLElement, AttributeEnums.ProductListingPageOption.ProductListingPageOption);
    }

    setProductListingPageOption(){

            this.optionValue = this.htmlElement.getAttribute(AttributeEnums.ProductListingPageOption.ProductListingPageOption);

    }

    updateProductListingPageOption(value: string | undefined){
        this.writeToProductListingPageOption(value);
        this.setProductListingPageOption();
    } 
   
    setProductListingPageOptionSelected() {

        this.optionSelected = (this.htmlElement.getAttribute(AttributeEnums.ProductListingPageOption.ProductListingPageOptionSelected) === 'true');
    }

    setProductListingPageOptionTitle() {

        this.optionTitle = this.htmlElement.getAttribute(AttributeEnums.ProductListingPageOption.ProductListingPageOptionTitle);
    }

    productListingPageOptionSelectedCustomEvent(type: string, value: string) {
        console.log(`ProductListingPageOption: productListingPageOptionSelectedCustomEvent type: ${type}  value: ${value}`);
        return new CustomEvent(CustomEventEnums.ProductListingPageOptionSelected, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                type: type,
                value: value
            }
        });
    }

    dispose(): void {
        this.removeProductListingPageOptionClickedEventListener();
    }
    
    delete(): void {

    }
}