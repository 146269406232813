import { AsyncUtils } from "../../shared/utilities/asyncUtils";
import { SwipedEventEnums } from "../../shared/utilities/enums/swipedEventEnums";
import { IImageMediaTile, ImageMediaTile } from "./imageMediaTile";

export interface IZoomedImageMediaTile extends IImageMediaTile { }
interface XYCoordinates {
    x: number;
    y: number;
}

export class ZoomedImageMediaTile extends ImageMediaTile implements IZoomedImageMediaTile {

    private readonly _handleMouseMove;

    constructor(public htmlElement: Element) {

        super(htmlElement);

        this._handleMouseMove = this.handleMouseOver.bind(this);
        this.disableSwipe = true;
        this.resetImageScrollToCenter();
    }

    attachMouseMoveEventListener(): void {
        console.log(`ZoomedImageMediaTile: attachMouseMoveEventListener`);
        this.htmlElement.addEventListener('mousemove', this._handleMouseMove);
    }
    removeMouseMoveEventListener(): void {
        console.log(`ZoomedImageMediaTile: removeMouseMoveEventListener`);
        this.htmlElement.removeEventListener('mousemove', this._handleMouseMove);
    }

    resetImageScrollToCenter(): void {
        console.log(`ZoomedImageMediaTile: resetImageScrollToCenter`);
        const left = (this.htmlElement.scrollWidth - this.htmlElement.clientWidth) / 2;
        const top = (this.htmlElement.scrollHeight - this.htmlElement.clientHeight) / 2;
        this.htmlElement.scrollTo({ left: left, top: top } as ScrollToOptions);
    }

    relativeCoords(e: MouseEvent): XYCoordinates {
        const bounds = this.htmlElement.getBoundingClientRect();
        const x: number = e.pageX - bounds.left;
        const y: number = (e.pageY - bounds.top) - Math.round(window.scrollY);
        return { x: x, y: y } as XYCoordinates;
    }

    handleMouseOver(e: MouseEvent) {
        const mouse: XYCoordinates = this.relativeCoords(e);
        const clientWidthExponent = mouse.x / this.htmlElement.clientWidth;
        const xScrollTarget = (this.htmlElement.scrollWidth - this.htmlElement.clientWidth) * clientWidthExponent;
        const clientHeightExponent = mouse.y / this.htmlElement.clientHeight;
        const yScrollTarget = (this.htmlElement.scrollHeight - this.htmlElement.clientHeight) * clientHeightExponent;

        this.htmlElement.scrollTo({ left: xScrollTarget, top: yScrollTarget } as ScrollToOptions);
    }

    onActivated(): void {
        console.log(`ZoomedImageMediaTile: onActivated`);
        AsyncUtils.delay(1000).then(() => {
            this.attachMouseMoveEventListener();
            this.resetImageScrollToCenter();
        });
    }

    onDeactivated(): void {
        console.log(`ZoomedImageMediaTile: onDeactivated`);
        this.removeMouseMoveEventListener();
        AsyncUtils.delay(1000).then(() => { this.resetImageScrollToCenter(); });
    }

    dispose(): void {
        this.removeMouseMoveEventListener();
    }

}