import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { TypeUtils } from "../../shared/utilities/typeUtils";
import { ButtonImageMediaTile } from "./buttonImageMediaTile";
import { ImageMediaTile } from "./imageMediaTile";
import { LazyYouTubeMediaTile } from "./lazyYouTubeMediaTile";
import { IYouTubeParameters, YouTubeMediaTile } from "./youTubeMediaTile";
import { ZoomedImageMediaTile } from "./zoomedImageMediaTile";


function imageMediaTileFactory(htmlElement: HTMLElement): ImageMediaTile {
    if (htmlElement.hasAttribute(AttributeEnums.ImageMediaTile.ImageMediaTile)) {
        return new ImageMediaTile(htmlElement);
    } else {
        throw new Error('Expected image media tile but got wrong type');
    }
}

function buttonImageMediaTileFactory(htmlElement: HTMLElement): ButtonImageMediaTile {
    if (htmlElement.hasAttribute(AttributeEnums.ButtonImageMediaTile.ButtonImageMediaTile)) {
        return new ButtonImageMediaTile(htmlElement);
    } else {
        throw new Error('Expected button image media tile but got wrong type');
    }
}

function zoomedImageMediaTileFactory(htmlElement: HTMLElement): ZoomedImageMediaTile {
    if (htmlElement.hasAttribute(AttributeEnums.ZoomedImageMediaTile.ZoomedImageMediaTile)) {

        return new ZoomedImageMediaTile(htmlElement);
    } else {
        throw new Error('Expected zoomed image media tile but got wrong type');
    }
}

function youTubeMediaTileFactory(htmlElement: HTMLElement): YouTubeMediaTile {
    if (htmlElement.hasAttribute(AttributeEnums.YouTubeMediaTile.YouTubeMediaTile)) {

        let youTubeParameters = {} as IYouTubeParameters;
        if (htmlElement.hasAttribute(AttributeEnums.YouTubeMediaTile.AutoPlay)) {
            youTubeParameters.autoPlay = TypeUtils.convertToBoolean(htmlElement.getAttribute(AttributeEnums.YouTubeMediaTile.AutoPlay));
        }
        if (htmlElement.hasAttribute(AttributeEnums.YouTubeMediaTile.ClosedCaptionOnLoad)) {
            youTubeParameters.closedCaptionOnLoad = TypeUtils.convertToBoolean(htmlElement.getAttribute(AttributeEnums.YouTubeMediaTile.ClosedCaptionOnLoad));
        }
        if (htmlElement.hasAttribute(AttributeEnums.YouTubeMediaTile.DisableFullScreen)) {
            youTubeParameters.disableFullScreen = TypeUtils.convertToBoolean(htmlElement.getAttribute(AttributeEnums.YouTubeMediaTile.DisableFullScreen));
        }
        if (htmlElement.hasAttribute(AttributeEnums.YouTubeMediaTile.DisableKeyboardControls)) {
            youTubeParameters.disableKeyboardControls = TypeUtils.convertToBoolean(htmlElement.getAttribute(AttributeEnums.YouTubeMediaTile.DisableKeyboardControls));
        }
        if (htmlElement.hasAttribute(AttributeEnums.YouTubeMediaTile.DisplayControl)) {
            youTubeParameters.displayControl = TypeUtils.convertToBoolean(htmlElement.getAttribute(AttributeEnums.YouTubeMediaTile.DisplayControl));
        }
        return new YouTubeMediaTile(htmlElement, youTubeParameters);

    } else {
        throw new Error('Expected You Tube media tile but got wrong type');
    }
}

function lazyYouTubeMediaTileFactory(htmlElement: HTMLElement): LazyYouTubeMediaTile {
    if (htmlElement.hasAttribute(AttributeEnums.LazyYouTubeMediaTile.LazyYouTubeMediaTile)) {
        return new LazyYouTubeMediaTile(htmlElement);

    } else {
        throw new Error('Expected You Tube media tile but got wrong type');
    }
}

export function mediaTileFactory(htmlElement: HTMLElement) {
    if (htmlElement.hasAttribute(AttributeEnums.ImageMediaTile.ImageMediaTile)) {
        return imageMediaTileFactory(htmlElement);
    } else if (htmlElement.hasAttribute(AttributeEnums.ZoomedImageMediaTile.ZoomedImageMediaTile)) {
        return zoomedImageMediaTileFactory(htmlElement);
    } else if (htmlElement.hasAttribute(AttributeEnums.YouTubeMediaTile.YouTubeMediaTile)) {
        return youTubeMediaTileFactory(htmlElement);
    } else if (htmlElement.hasAttribute(AttributeEnums.LazyYouTubeMediaTile.LazyYouTubeMediaTile)) {
        return lazyYouTubeMediaTileFactory(htmlElement);
    } else if (htmlElement.hasAttribute(AttributeEnums.ButtonImageMediaTile.ButtonImageMediaTile)) {
        return buttonImageMediaTileFactory(htmlElement);
    } else {
        console.log(`MediaTileFactory: ERROR media tile type not valid`);
    }
}