import { IInstanceComponentBase, InstanceComponentBase } from "./instanceComponentBase";
import { IMegaMenuDeviceBase, MegaMenuDeviceBase } from "./megaMenuDeviceBase";
import { IMegaMenuItem, MegaMenuItem } from "./megaMenuItem";
import { AttributeEnums } from "./utilities/enums/attributeEnums";
import { HelperUtils } from "./utilities/helperUtils";

export interface IMegaMenuHandheld extends IMegaMenuDeviceBase {
}

export class MegaMenuHandheld extends MegaMenuDeviceBase implements IMegaMenuHandheld {

    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);
    }

    dispose(): void {
        super.dispose();
    }
}