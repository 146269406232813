declare global {
    interface HTMLElement {
      removeChildren():void;
    }

    interface Number {
        currency(decimals: number, changeZeroToFree:boolean):string;
      }
  }
  
  HTMLElement.prototype.removeChildren = function() {
    while (this.firstChild) {
        this.removeChild(this.firstChild);
    }
  }

  Number.prototype.currency = function(decimals: number, changeZeroToFree:boolean = false): string {

    const comma = ",";

    const stop = ".";

    const commaGroup = 3;
    if (parseInt(this) === 0 && changeZeroToFree) return 'Free';

    let re = '\\d(?=(\\d{' + (commaGroup || 3) + '})+' + (decimals > 0 ? '\\D' : '$') + ')', num = this.toFixed(Math.max(0, ~~decimals));

    return "£" + (stop ? num.replace('.', stop) : num).replace(new RegExp(re, 'g'), '$&' + (comma || ','));
};

export {}