export namespace TypeUtils {
  export function castStringToNumberOrUndefined(potentialNumber: string | undefined,) {
    let nullableNumber = +potentialNumber;
    return (!Number.isNaN(nullableNumber)) ? nullableNumber : undefined;
  }

  export class Holder<T> {
    value: T;
    constructor(value: T) {
      this.value = value;
    }
    typeof(): string {
      return typeof this.value;
    }
  }

  export function convertToBoolean(input: string): boolean | undefined {
      try {
          return JSON.parse(input.toLowerCase());
      }
      catch (e) {
          return undefined;
      }
  }

}