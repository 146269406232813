import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { ButtonCarouselButton } from "./buttonCarouselButton";

export interface IButtonCarousel extends IInstanceComponentBase {
    buttonCarouselButtons: ButtonCarouselButton[];
    displayLoading: () => void;
}

export class ButtonCarousel extends InstanceComponentBase implements IButtonCarousel {
    public buttonCarouselButtons: ButtonCarouselButton[] = new Array<ButtonCarouselButton>();
    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);
        this.buttonCarouselButtonsFactory();
    }

    buttonCarouselButtonsFactory(): void {
        let buttonCarouselButtonElements = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ButtonCarouselButton.ButtonCarouselButton));
        buttonCarouselButtonElements.forEach((buttonCarouselButtonElement: HTMLElement) => this.buttonCarouselButtons.push(new ButtonCarouselButton(buttonCarouselButtonElement)));
    }

    displayLoading() {
        for(let i=0; i < this.buttonCarouselButtons.length; i++) {
            this.buttonCarouselButtons[i].htmlElement.classList.add('lb-loadingTile');  
            this.buttonCarouselButtons[i].removeButtonCarouselButtonClickedEventListener();        
        }        
    }

    dispose(): void {
        this.buttonCarouselButtons.forEach((buttonCarouselButton: ButtonCarouselButton) => buttonCarouselButton.dispose());
    }
    
    delete(): void {

    }
}