import { CustomEventEnums } from "./enums/customEventEnums";
export namespace CustomEvents {
    export const closeAllMenusEvent = () => {
    console.log(`CustomEvents: closeAllMenusEvent`);
    return new CustomEvent(CustomEventEnums.CloseAllMenus, {
        bubbles: true,
        cancelable: true,
        composed: false,
        detail: {         
        }
    });
  }

  export const liveChatLoadCompleteEvent = () => {
    console.log(`CustomEvents: liveChatLoadComplete`);
    return new CustomEvent(CustomEventEnums.LiveChatLoadComplete, {
        bubbles: true,
        cancelable: true,
        composed: false,
        detail: {         
        }
    });
  }

  export const notificationPanelIsOpenEvent = () => {
    console.log(`CustomEvents: notificationPanelIsOpen`);
    return new CustomEvent(CustomEventEnums.NotificationPanelIsOpen, {
        bubbles: true,
        cancelable: true,
        composed: false,
        detail: {         
        }
    });
  }

  export const notificationPanelIsClosedEvent = () => {
    console.log(`CustomEvents: notificationPanelIsClosed`);
    return new CustomEvent(CustomEventEnums.NotificationPanelIsClosed, {
        bubbles: true,
        cancelable: true,
        composed: false,
        detail: {         
        }
    });
  }

  export const showLiveChatEvent = () => {
    console.log(`CustomEvents: showLiveChatEvent`);
    return new CustomEvent(CustomEventEnums.ShowLiveChat, {
        bubbles: true,
        cancelable: true,
        composed: false,
        detail: {         
        }
    });
  }

  export const hideLiveChatEvent = () => {
    console.log(`CustomEvents: hideLiveChatEvent`);
    return new CustomEvent(CustomEventEnums.HideLiveChat, {
        bubbles: true,
        cancelable: true,
        composed: false,
        detail: {         
        }
    });
  }


  export const openLiveChatEvent = () => {
    console.log(`CustomEvents: openLiveChatEvent`);
    return new CustomEvent(CustomEventEnums.OpenLiveChat, {
        bubbles: true,
        cancelable: true,
        composed: false,
        detail: {         
        }
    });
  }

  export const closeLiveChatEvent = () => {
    console.log(`CustomEvents: closeLiveChatEvent`);
    return new CustomEvent(CustomEventEnums.CloseLiveChat, {
        bubbles: true,
        cancelable: true,
        composed: false,
        detail: {         
        }
    });
  }
}