import { HttpRequest } from "../../shared/ajax";
import { LbCookies } from "../../shared/lbCookies";
import { BasketProduct } from "./basketProduct";
import Swal from "sweetalert2";
import { InstanceComponentBase } from "../../shared/instanceComponentBase";
import { ProductDeliveryPanel } from "./productDeliveryPanel";
import { getProductDeliveryPanelViaOuterElement, initialiseProductDeliveryPanel } from "./productDeliveryPanelInitialiser";
import { IProductOptionsPanel, ProductOptionsPanel } from "./productOptionsPanel";
import { IProductPrice, ProductPrice } from "./productPrice";
import { getProductWithTypeName, reitialiseMainProductAndProductOptionsPanel } from "./productInitialiser";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { initialise } from "../../shared/initialiser";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { StringEnums } from "../../shared/utilities/enums/stringEnums";
import { UrlEnums } from "../../shared/utilities/enums/urlEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";

import { ProductColourDropDown } from "./productColourDropDown";
import { AjaxGetAndReplaceHtml } from "../../shared/ajaxGetAndReplaceHtml";
import { CarouselTileLoad, ICarouselTileLoad } from "../carousel/carouselTileLoad";
import { LoadingTemplates } from "../loading/loadingTemplates";
import { IProductFinancePanel, ProductFinancePanel } from "./productFinancePanel";
import { UtilityEnums } from "../../shared/utilities/enums/utillityEnums";
import { IProductCarouselOrchestrator, ProductCarouselOrchestrator } from "./productCarouselOrchestrator";
import { YouTubeVideo } from "../video/youTubeVideo";
import { IPopOutController } from "../../shared/popOutController";
import { BannerPromotions, IBannerPromotions } from "../promotions/bannerPromotions";
import { IProductGtm, ProductGtm } from "./productGtm";
import { BasketService } from "../basket/basketService";
import { GlobalComponents, IGlobalComponents } from "../globalComponents/globalComponents";
const urlPaths = require('../../config/urlPaths.json');

export class Product extends InstanceComponentBase {

    productDeliveryPanel: ProductDeliveryPanel;
    productOptionsPanel: IProductOptionsPanel;
    productPrice: IProductPrice;
    productFinancePanel: IProductFinancePanel;
    productCarouselOrchestrator: IProductCarouselOrchestrator;
    productGtm: IProductGtm;
    public dataProductType: string;
    public lineId: number;
    public sizeId: number | undefined;
    public youTubeVideos: YouTubeVideo[];
    private readonly _productSelectorClick;
    private readonly _productAddToBasketClick;
    private readonly _setProductInvalidMessagesAndValidationWithEvent;
    public globalComponents: IGlobalComponents;
    //private readonly _recalculateFinancePanelFromCustomEvent;
    private readonly popoutController: IPopOutController;
    constructor(public htmlElement: Element) {
        super(htmlElement);
        this.dataProductType = htmlElement.getAttribute(AttributeEnums.Product.Product);
        this.popoutController = (<any>window).popOutController;
        //----- Event Listener Bindings Start -----//
        this._productSelectorClick = this.productSelectorClick.bind(this);
        this._productAddToBasketClick = this.productAddToBasketClick.bind(this);
        this._setProductInvalidMessagesAndValidationWithEvent = this.setProductInvalidMessagesAndValidationWithEvent.bind(this);
        //this._recalculateFinancePanelFromCustomEvent = this.recalculateFinancePanelFromCustomEvent.bind(this);
        //----- Event Listener Bindings End -----//

        //----- Initialise Event Listeners Start -----//
        //this.attachProductSelectorClickEvent();
        this.attachAddToBasketClickEvent();
        this.attachProductSelectorChangedEventListener();
        //this.attachProductPriceUpdatedEvent();
        //----- Initialise Event Listeners End -----//
        this.viewProductDetailsFieldsSetUp();

        //Initialise ProductCarouselOrchestrator
        this.productCarouselOrchestrator = new ProductCarouselOrchestrator(htmlElement as HTMLElement);

        //Initialise productFinancePanel
        let productFinancePanelElement: HTMLElement = htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductFinancePanel.ProductFinancePanel));
        if (productFinancePanelElement) {
            this.productFinancePanel = new ProductFinancePanel(productFinancePanelElement);
        }

        this.createProductGtmFactory();
        //Create YouTube Videos
        this.createYouTubeVideoFactory();
        this.globalComponents = GlobalComponents.getGlobalComponents();

        this.setProductLineId();
        this.setProductSizeId();
        this.popoutController.active = true;
    }

    setProductLineId(): void {
        this.lineId = +this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductLineId)).getAttribute(AttributeEnums.Product.ProductLineId);

    }
    setProductSizeId(): void {
        this.sizeId = +this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductSizeId)).getAttribute(AttributeEnums.Product.ProductSizeId);
    }

    createProductGtmFactory(): void {
        try {
            let productGtmElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductGtm.GtmProduct));
            if (productGtmElement) {
                this.productGtm = new ProductGtm(productGtmElement);
            }
        } catch (error) {

        }
    }

    createYouTubeVideoFactory(): void {
        let youTubeElements: NodeListOf<Element> = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.Video.Video));
        this.youTubeVideos = new Array<YouTubeVideo>();

        for (let i = 0; i < youTubeElements.length; i += 1) {
            let youTubeElement: Element = youTubeElements[i] as HTMLElement;
            let youTubeVideo = new YouTubeVideo(youTubeElement);
            this.youTubeVideos.push(youTubeVideo);
        }
    }

    //------ Add Event Listeners Start -----//

    attachAddToBasketClickEvent() {
        console.log('Product: attachAddToBasketClickEvent');
        this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductAddToCart)).addEventListener('click', this._productAddToBasketClick);
    }

    attachProductSelectorChangedEventListener() {
        console.log('Product: productSelectorChangedEventListener');
        this.htmlElement.addEventListener(CustomEventEnums.ProductSelectorChanged, this._setProductInvalidMessagesAndValidationWithEvent);
    }
    // async attachProductPriceUpdatedEvent() {
    //     document.addEventListener('product-price-updated', this._recalculateFinancePanelFromCustomEvent);
    // }
    //------ Add Event Listeners End -----//

    //------ Remove Event Listeners Start -----//
    // removeProductSelectorClickEvent() {
    //     console.log('Product: removeProductSelectorClickEvent');
    //     this.htmlElement.removeEventListener('click', this._productSelectorClick);
    // }
    removeAddToBasketClickEvent() {
        console.log('Product: removeAddToBasketClickEvent');

        this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductAddToCart)).removeEventListener('click', this._productAddToBasketClick);
    }

    removeProductSelectorChangedEventListener() {
        console.log('Product: removeProductSelectorChangedEventListener');
        this.htmlElement.removeEventListener(CustomEventEnums.ProductSelectorChanged, this._setProductInvalidMessagesAndValidationWithEvent);
    }

    // removeProductPriceUpdatedEvent() {
    //     document.removeEventListener('product-price-updated', this._recalculateFinancePanelFromCustomEvent);
    // }
    //------ Remove Event Listeners End -----//

    //------ Event Listener Methods Start -----//
    setProductInvalidMessagesAndValidationWithEvent(event: CustomEvent) {

        console.log("Product: ProductSelectorChanged Event Fired");
        console.log("Product: ProductSelectorChanged Type: ", event.detail);
        //let element = event.currentTarget as HTMLElement;
        let eventDetail = event.detail;

        this.setProductInvalidMassagesAndValidation(eventDetail.type);
    }

    productSelectorClick(event: MouseEvent) {
        console.log('Product: productSelectorClick');
        let clickElement = event.target;
        this.ifClickElementIsOfAddToCart(clickElement);
    }

    productAddToBasketClick(event: MouseEvent) {
        console.log('Product: productAddToBasketClick');
        let clickElement = event.target;
        this.ifClickElementIsOfAddToCart(clickElement);
    }

    async ifClickElementIsOfAddToCart(clickElement: EventTarget): Promise<void> {
        if (clickElement instanceof HTMLElement && clickElement.hasAttribute(AttributeEnums.Product.ProductAddToCart)) {
            let currentProductType: string = clickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).getAttribute(AttributeEnums.Product.Product);
            console.log(`Product ifClickElementIsOfAddToCart - currentProductType: ${currentProductType} this.dataProductType ${this.dataProductType}`);
            if (currentProductType === this.dataProductType) {
                console.log('Product: ifClickElementIsOfAddToCart - started ');
                if (this.isProductValid()) {
                    console.log('Product: ifClickElementIsOfAddToCart - isProductValid true');
                    let basketProduct: BasketProduct = this.mapSelectorsToBasketProduct();
                    document.dispatchEvent(this.addProductStartedEvent(this.dataProductType));


                    let result = await BasketService.addProduct(basketProduct, this.productGtm.getAddToRemoveFromCartGtmDataObject());

                    document.dispatchEvent(this.addProductCompletedEvent(this.dataProductType));

                    if (this.dataProductType === UtilityEnums.ProductTypeValue.Product) {

                        ProductColourDropDown.clearSwatchImageInfo(this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.SwatchImage.GallerySwatchImage)));
                        await reitialiseMainProductAndProductOptionsPanel(this);

                    }
                } else {
                    console.log('Product: ifClickElementIsOfAddToCart - isProductValid false');
                    let productType = clickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).getAttribute(AttributeEnums.Product.Product);
                    clickElement.setAttribute(AttributeEnums.Product.ProductAddToCartAttempted, 'true');
                    this.setProductInvalidMassagesAndValidation(productType);
                    Swal.fire(StringEnums.Content.PleaseSelectAllProductOptions);
                }
            }
        }
    }


    // async recalculateProductFinancePanel(price: number) {
    //     try {
    //         console.log('Product: recalculateProductFinancePanel Started');
    //         let dataProductFinancePanelElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductFinancePanel)) as HTMLElement;
    //         console.log('Product: recalculateProductFinancePanel - dataProductFinancePanelElement ', dataProductFinancePanelElement);
    //         if (dataProductFinancePanelElement !== null) {
    //             dataProductFinancePanelElement.innerHTML = LoadingTemplates.productLoadingTemplate;
    //             let url: string = urlPaths.websiteUrl + `${UrlEnums.PartialUrls.ProductFinancePanel}${price}`;
    //             console.log('Product: recalculateProductFinancePanel URL: ' + url);
    //             await AjaxGetAndReplaceHtmlAndOuterElement(dataProductFinancePanelElement, url);
    //             console.log('Product: recalculateProductFinancePanel AjaxGetAndReplaceHtml Finished');
    //         }
    //     } catch (error) {
    //         console.log('Product: recalculateProductFinancePanel', error);
    //     }
    // }

    // recalculateFinancePanelFromCustomEvent(event: CustomEvent): void {
    //     console.log('Product: recalculateFinancePanelFromCustomEvent - Beginning');
    //     let eventDetail = event.detail;
    //     let type = eventDetail.type as string;
    //     if (type === this.htmlElement.getAttribute(AttributeEnums.Product.Product)) {
    //         console.log('Product: recalculateFinancePanelFromCustomEvent - totalPrice' + this.productPrice.totalPrice);
    //         this.recalculateProductFinancePanel(this.productPrice.totalPrice);
    //     }
    // }
    //------ Custom Event Creation Start -----//
    productSelectorChangedEvent(type: string) {
        return new CustomEvent(CustomEventEnums.ProductSelectorChanged, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                'type': type
            }

        });
    }

    addProductStartedEvent(type: string) {
        return new CustomEvent(CustomEventEnums.AddProductStarted, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                'type': type
            }

        });
    }

    addProductCompletedEvent(type: string) {
        return new CustomEvent(CustomEventEnums.AddProductCompleted, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                'type': type
            }

        });
    }

    //------ Custom Event Creation End -----//

    getAdditionPriceTotalFromHiddenInput(): number {
        let additionPriceTotal: number = 0;

        let dataProductSelectedAddtionPriceElements: NodeListOf<Element> = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelector) + HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductAdditionPrice));

        for (let currentElement = 0; currentElement < dataProductSelectedAddtionPriceElements.length; currentElement += 1) {
            let currentAdditionPriceElement: Element = dataProductSelectedAddtionPriceElements[currentElement];
            additionPriceTotal += parseFloat(currentAdditionPriceElement.getAttribute(AttributeEnums.Product.ProductAdditionPrice).replace(',', ''));
        }

        return additionPriceTotal;
    }

    isProductValid(): boolean {
        let dataProductSelectorElements: NodeListOf<Element> = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelector));

        let valid: boolean = true;
        for (let currentElement = 0; currentElement < dataProductSelectorElements.length; currentElement += 1) {
            let currentSelectorElement: Element = dataProductSelectorElements[currentElement];

            if (currentSelectorElement && currentSelectorElement.getAttribute('value')) {
                //alert("My input has a value!");
            } else {
                valid = false
            }
        }

        return valid;
    }

    setProductInvalidMassagesAndValidation(type: string) {
        try {
            let product: Product = getProductWithTypeName(type);
            product.productOptionsPanel.setProductOptionsValidation();
        } catch (error) {
            console.log('Product: setProductInvalidMassagesAndValidation', error);
        }
    }

    removeProductInvalidMassagesAndValidation() {
        let productDropdowns = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.DropDowns.ProductDropDown));

        for (let i = 0; i < productDropdowns.length; i += 1) {
            productDropdowns[i].classList.remove(StringEnums.CssClass.LbWarning);
        }
    }

    mapSelectorsToBasketProduct = (): BasketProduct => {
        let sizeSelector = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelector) + HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductSizeId));
        let extraSelectors = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelector) + HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductGroupId));

        let sizeId: number = +sizeSelector.getAttribute('value');
        let lineId: number = +sizeSelector.getAttribute(AttributeEnums.Product.ProductLineId);
        let extraIds: number[] = [];
        for (let i = 0; i < extraSelectors.length; i += 1) {
            let extraId: number = +extraSelectors[i].getAttribute('value');
            extraIds.push(extraId);
        }
        let sessionId = LbCookies.getOrInitialiseBasketSession();
        let datetime: Date = new Date();

        return {
            "id": 0,
            "lineId": lineId,
            "sizeId": sizeId,
            "sessionId": sessionId,
            "quantity": 1,
            "removal": 0,
            "assembly": 0,
            "sku": "",
            "datetime": datetime.toISOString(),
            "extraIds": extraIds


        };
    };


    viewProductDetailsFieldsSetUp() {
        console.log('Product: viewProductDetailsFieldsSetUp');

        this.initialiseDelivery();
        this.initialiseProductOptionsPanel();
        this.initialiseProductPrice();
        if (this.productOptionsPanel) {
            this.productOptionsPanel.setUpOptionsSelectedFields();
        }
        if (this.productPrice) {
            this.productPrice.updateProductPrice(this.productOptionsPanel.getTotalProductAdditionsPrice());
        }
    }

    initialiseDelivery(): void {
        try {
            let deliveryPanelElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductDelivery.ProductDeliveryPanel));
            if (deliveryPanelElement) {
                if (this.productDeliveryPanel === undefined || this.productDeliveryPanel === null) {
                    console.log('Product: initialiseDelivery');
                    this.productDeliveryPanel = new ProductDeliveryPanel(deliveryPanelElement);
                }
            }
        }
        catch (error) {
            console.log('Product: initialiseDelivery', error);
        }
    }

    initialiseProductOptionsPanel(): void {
        try {
            if (this.productOptionsPanel === undefined || this.productOptionsPanel === null) {
                console.log('Product: initialiseProductOptionsPanel', this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductOptionsPanel)));

                this.productOptionsPanel = new ProductOptionsPanel((this.htmlElement as HTMLElement).querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductOptionsPanel)));
            }
        }
        catch (error) {
            console.log('Product: initialiseProductOptionsPanel', error);
        }
    }

    initialiseProductPrice(): void {
        try {
            this.productPrice = new ProductPrice((this.htmlElement as HTMLElement).querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductPrice)));
        }
        catch (error) {
            console.log('Product: initialiseProductPrice', error);
        }
    }

    //selectorGlobal.ts
    static selectedTextFormatter(name: string): string {
        console.log('SelectorGlobal: selectedTextFormatter');
        let prefixText = 'Selected ';
        let postfixText = ':';
        return prefixText + name + postfixText;
    }

    //------ Clean Up Processes Start -----//
    dispose(): void {
        try {
            console.log(`Product: dispose - ${this.dataProductType}`);
            //this.removeProductSelectorClickEvent();
            this.removeAddToBasketClickEvent();
            this.removeProductSelectorChangedEventListener();
            if (this.productOptionsPanel) {
                this.productOptionsPanel.dispose();
            }
            if (this.productDeliveryPanel) {
                this.productDeliveryPanel.dispose();
            }

            if (this.productPrice) {
                this.productPrice.dispose();
            }
            if (this.productFinancePanel) {
                this.productFinancePanel.dispose();
            }
        } catch (error) {
            console.log('Product: dispose ERROR', error);
        }

        this.delete();
    }

    delete(): void {
        console.log('Product: delete');
        if ((<any>window)[this.htmlElement.getAttribute(AttributeEnums.Product.Product) + UtilityEnums.WindowObjectName.Product]) {
            console.log('Product: delete - has a product');
            delete (<any>window)[this.htmlElement.getAttribute(AttributeEnums.Product.Product) + UtilityEnums.WindowObjectName.Product];
            console.log('Product: delete - reference deleted');
        }
        else {
            console.log('Product: delete - Product Reference Not Found');
        }
    }
    //------ Clean Up Processes End -----//

}
