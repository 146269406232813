import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { UtilityEnums } from "../../shared/utilities/enums/utillityEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { BasePagingSelectableCarousel, IBasePagingSelectableCarousel } from "./basePagingSelectableCarousel";
import { SelectableCarousel, ISelectableCarousel } from "./selectableCarousel";


export interface IPagingSelectableCarousel extends IBasePagingSelectableCarousel {

    navigateCarouselToFirstCellInCurrentPage: () => void;
}

export class PagingSelectableCarousel extends BasePagingSelectableCarousel implements IPagingSelectableCarousel {

    private readonly _moveCarouselToNextPosition;
    private readonly _moveCarouselToPrevPosition;
    private readonly _reinitialiseCarousel;

    constructor(public htmlElement: Element, carouselOrientation: UtilityEnums.ComponentOrientation, topLevelHtmlElement?: HTMLElement) {
        super(htmlElement, carouselOrientation, topLevelHtmlElement);
        //this.totalOccupiedCells = this.occupiedCellsElements.length;
        //this.cellsPerPage = this.getCellsPerPage();
        //this.currentPage = this.getInitialPage();
        this.updateSizeOfSlider();
        this.addNonOccupiedCells();

        this._moveCarouselToNextPosition = this.moveCarouselToNextPosition.bind(this);
        this._moveCarouselToPrevPosition = this.moveCarouselToPrevPosition.bind(this);
        this._reinitialiseCarousel = this.reinitialiseCarousel.bind(this);


        this.attachCarouselPageNextClickEventListener();
        this.attachCarouselPagePrevClickEventListener();
        this.attachViewportDimensionsChangedEventListener();

        this.navigateCarouselToFirstCellInCurrentPage();
    }

    //Carousel Events  
    attachCarouselPagePrevClickEventListener(): void {
        console.log('PagingSelectableCarousel: attachCarouselPagePrevClickEventListener');
        this.carouselPrevElement.addEventListener('click', this._moveCarouselToPrevPosition);
    }

    removeCarouselPagePrevClickEventListener(): void {
        this.carouselPrevElement.removeEventListener('click', this._moveCarouselToPrevPosition);
    }

    attachCarouselPageNextClickEventListener(): void {
        console.log('PagingSelectableCarousel: attachCarouselPageNextClickEventListener');
        this.carouselNextElement.addEventListener('click', this._moveCarouselToNextPosition);
    }

    removeCarouselPageNextClickEventListener(): void {
        this.carouselNextElement.removeEventListener('click', this._moveCarouselToNextPosition);
    }

    attachViewportDimensionsChangedEventListener(): void {
        console.log('PagingSelectableCarousel: attachViewportDimensionsChangedEventListener');
        document.addEventListener(CustomEventEnums.ViewportDimensionsChangedEvent, this._reinitialiseCarousel);
    }

    removeViewportDimensionsChangedEventListener(): void {
        document.removeEventListener(CustomEventEnums.ViewportDimensionsChangedEvent, this._reinitialiseCarousel);
    }

    reinitialiseCarousel(): void {
        console.log('PagingSelectableCarousel: reinitialiseCarousel');
        let firstCellOfPreResizeState = this.indexOfFirstCellInCurrentPage();
        console.log(`PagingSelectableCarousel: reinitialiseCarousel - firstCellOfPreResizeState ${firstCellOfPreResizeState}`);
        this.writeToSliderRemovingInlineStyles();
        this.removeNonOccupiedCells();
        this.totalOccupiedCells = this.occupiedCellsElements.length;
        this.cellsPerPage = this.getCellsPerPage();
        console.log(`PagingSelectableCarousel: reinitialiseCarousel - cellsPerPage ${this.cellsPerPage}`);
        this.currentPage = this.getPageForCell(firstCellOfPreResizeState);

        this.updateSizeOfSlider();
        this.addNonOccupiedCells();

        this.navigateCarouselToFirstCellInCurrentPage();
    };

    indexOfFirstCellInCurrentPage(): number {
        let firstCellInCurrentPage = this.indexOfLastCellInCurrentPage() - (this.cellsPerPage - 1);
        return firstCellInCurrentPage;
    }

    indexOfLastCellInCurrentPage(): number {
        let currentPage = this.currentPage;
        let lastCellInCurrentPage = (currentPage * this.cellsPerPage) - 1;
        console.log(`PagingSelectableCarousel: indexOfLastCellInCurrentPage - currentPage ${currentPage}, cellsPerPage ${this.cellsPerPage}, lastCellInCurrentPage ${lastCellInCurrentPage}`);
        return lastCellInCurrentPage;
    }

    navigateCarouselToFirstCellInCurrentPage(): void {
        let position = this.indexOfFirstCellInCurrentPage();
        console.log(`PagingSelectableCarousel: navigateCarouselToFirstCellInCurrentPage - position ${position}`);
        this.moveCarouselToPosition(position);
        this.setCarouselShowHideArrows();
    }

    getFirstElementOnDisplay() {
        console.log(`PagingSelectableCarousel: ComponentOrientation ${UtilityEnums.ComponentOrientation.Horizontal}`);
        let chooserTileWidth = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.PagingSelectableCarousel.Cell)).getBoundingClientRect().width;
        if (this.carouselOrientation === UtilityEnums.ComponentOrientation.Horizontal) {
            let scrollPosition = Math.floor(this.carouselSliderElement.scrollLeft);
            return Math.ceil(scrollPosition / (chooserTileWidth+5));
        } else {
            let scrollPosition = Math.floor(this.carouselSliderElement.scrollTop);
            return Math.ceil(scrollPosition / 80);
        }
    }

    moveCarouselToPrevPosition(): void {
        console.log('PagingSelectableCarousel: moveCarouselToPrevPosition');
        if (this.hasPrevButton()) {
            let firstElementOnDisplayIndex: number = this.getFirstElementOnDisplay();
            let elementForFirstPositionIndex = firstElementOnDisplayIndex - this.cellsPerPage;
            this.moveCarouselToPosition(Math.max(0, elementForFirstPositionIndex));
            this.currentPage -= 1;
            this.setCarouselShowHideArrows();
        }
    }

    moveCarouselToNextPosition(): void {
        console.log('PagingSelectableCarousel: moveCarouselToNextPosition');
        if (this.hasNextButton()) {
            let firstElementOnDisplayIndex: number = this.getFirstElementOnDisplay();
            let elementForFirstPositionIndex = firstElementOnDisplayIndex + this.cellsPerPage;
            this.moveCarouselToPosition(elementForFirstPositionIndex);
            this.currentPage += 1;
            this.setCarouselShowHideArrows();
        }
    }

    getInitialPage(): number {
        let getInitialPageNumber = this.getPageForCell(this.carouselCurrentSelectedIndex);
        return getInitialPageNumber;
    }

    getPageForCell(cellIndex: number): number {
        let getPageForCellNumber = Math.ceil(((cellIndex ?? 0) + 1) / this.cellsPerPage);
        return getPageForCellNumber;
    }

    getCellsPerPage(): number {
        if (this.carouselOrientation === UtilityEnums.ComponentOrientation.Horizontal) {
            return this.getTileSlotsCountHorizontal();
        } else {
            return this.getTileSlotsCountVertical();
        }
    }
    
    setCurrentPage(): void {
        this.currentPage = this.getPageForCell(this.carouselCurrentSelectedIndex);
    }

    getTileSlotsCountVertical(): number {
        let chooser = this.htmlElement.getBoundingClientRect();
        let chooserButtonPrev = this.carouselPrevElement.getBoundingClientRect();
        let chooserButtonNext = this.carouselNextElement.getBoundingClientRect();
        let sliderHeight = chooser.height - (chooserButtonPrev.height + chooserButtonNext.height);
        let tileSlotCountHeight = Math.floor(sliderHeight / 75);
        console.log(`PagingSelectableCarousel: getTileSlotsCountVertical: ${tileSlotCountHeight}`);
        return tileSlotCountHeight;
    }

    getTileSlotsCountHorizontal(): number {
        let chooser = this.htmlElement.getBoundingClientRect();
        let chooserButtonPrev = this.carouselPrevElement.getBoundingClientRect();
        let chooserButtonNext = this.carouselNextElement.getBoundingClientRect();
        let chooserTileWidth = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.PagingSelectableCarousel.Cell)).getBoundingClientRect().width;
        let sliderWidth = chooser.width - (chooserButtonPrev.width + chooserButtonNext.width);
        let tileSlotCountWidth = Math.floor(sliderWidth / chooserTileWidth);
        return tileSlotCountWidth;
    }

    updateSizeOfSlider(): void {
        console.log(`PagingSelectableCarousel: updateSizeOfSlider`);
        let chooserTileWidth = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.PagingSelectableCarousel.Cell)).getBoundingClientRect().width;
        let totalTilesSize = this.cellsPerPage * ((this.carouselOrientation === UtilityEnums.ComponentOrientation.Horizontal) ? chooserTileWidth : 75);
        let totalGaps = (this.cellsPerPage - 1) * 5;
        if (this.carouselOrientation === UtilityEnums.ComponentOrientation.Horizontal) {
            let totalSize = totalTilesSize + totalGaps;
            this.writeToSliderWithStyleDimensions(totalSize);
        } else {
            let totalSize = totalTilesSize + totalGaps;
            this.writeToSliderWithStyleDimensions(totalSize);
        }
    }

    writeToSliderWithStyleDimensions(totalSize: number) {
        this.carouselSliderElement.setAttribute('style', 'flex:0 0 ' + totalSize + 'px');
    }

    writeToSliderRemovingInlineStyles() {
        this.carouselSliderElement.removeAttribute('style');
    }

    calculateNonOccupiedCellsRequired(): number {
        console.log(`PagingSelectableCarousel: calculateNonOccupiedCellsRequired totalpagecount ${this.getTotalPageCount()}  cellsperpage ${this.cellsPerPage} totaloccupiedcells ${this.totalOccupiedCells}`);
        let calculateNonOccupiedCellsRequiredNumber = (this.getTotalPageCount() * this.cellsPerPage) - this.totalOccupiedCells;
        return calculateNonOccupiedCellsRequiredNumber;
    }

    addNonOccupiedCells(): void {
        console.log(`PagingSelectableCarousel: addNonOccupiedCells: ${this.calculateNonOccupiedCellsRequired()}`);
        for (let item = 0; item < this.calculateNonOccupiedCellsRequired(); item++) {
            this.carouselSliderElement.appendChild(this.createCell());
        }
    }

    removeNonOccupiedCells(): void {
        console.log('PagingSelectableCarousel: addNonOccupiedCells');
        console.log(`PagingSelectableCarousel: addNonOccupiedCells - nodelist`, this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.SelectableCarousel.FillerCell)));
        this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.SelectableCarousel.FillerCell)).forEach((fillerCellElement: HTMLElement) => fillerCellElement.remove());
    }

    createCell(): HTMLElement {
        let newCell = document.createElement("div");
        newCell.classList.add('lb_selectableCarousel_cells_cell-filler', 'lb_selectableCarousel_cells_cell');
        newCell.setAttribute('data-selectable-carousel-filler-cell', '');
        return newCell;
    }

    dispose(): void {
        super.dispose();
    }
}