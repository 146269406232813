/*!
 * swiped-events.js - v@version@
 * Pure JavaScript swipe events
 * https://github.com/john-doherty/swiped-events
 * @inspiration https://stackoverflow.com/questions/16348031/disable-scrolling-when-touch-moving-certain-element
 * @author John Doherty <www.johndoherty.info>
 * @license MIT
 */
interface CustomParams {
    bubbles?: boolean;
    cancelable?: boolean;
    detail: any | undefined;
}

export function swipedEvents(window:any, document: Document) {

    'use strict';

    // patch CustomEvent to allow constructor creation (IE/Chrome)
    if (typeof window.CustomEvent !== 'function') {

        (<any>window).CustomEvent = function (event: string, params: CustomParams): CustomEvent {
            console.log(`swipedEvents: CustomEvent Wrapper event: ${event}`);

            params = params || ({ bubbles: false, cancelable: false, detail: undefined } as CustomParams);

            var evt = document.createEvent('CustomEvent');
            evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
            return evt;
        };

        (<any>window).CustomEvent.prototype = window.Event.prototype;
    }

    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchmove', handleTouchMove, false);
    document.addEventListener('touchend', handleTouchEnd, false);

    var xDown:number| null = null;
    var yDown:number| null = null;
    var xDiff:number| null = null;
    var yDiff:number| null = null;
    var timeDown:number | null = null;
    var startEl:HTMLElement | null = null;


    function handleTouchEnd(e: TouchEvent) {

        console.log('handleTouchEnd: Started');
        // if the user released on a different target, cancel!
        if (startEl !== e.target) return;

        var swipeThreshold = parseInt(getNearestAttribute(startEl, 'data-swipe-threshold', '20'), 10); // default 20px
        var swipeTimeout = parseInt(getNearestAttribute(startEl, 'data-swipe-timeout', '500'), 10);    // default 500ms
        var timeDiff = Date.now() - timeDown;
        var eventType = '';
        var changedTouches: TouchList = e.changedTouches || e.touches || new TouchList();

        if (Math.abs(xDiff) > Math.abs(yDiff)) { // most significant
            if (Math.abs(xDiff) > swipeThreshold && timeDiff < swipeTimeout) {
                if (xDiff > 0) {
                    eventType = 'swiped-left';
                    console.log('handleTouchEnd: swiped-left');
                }
                else {
                    eventType = 'swiped-right';
                    console.log('handleTouchEnd: swiped-right');
                }
            }
        }
        else if (Math.abs(yDiff) > swipeThreshold && timeDiff < swipeTimeout) {
            if (yDiff > 0) {
                eventType = 'swiped-up';
                console.log('handleTouchEnd: swiped-up');
            }
            else {
                eventType = 'swiped-down';
                console.log('handleTouchEnd: swiped-down');
            }
        }
        
        console.log(`handleTouchEnd: eventType ${eventType}`);
        if (eventType !== '') {
            
            var eventData = {
                dir: eventType.replace(/swiped-/, ''),
                touchType: 'direct',
                xStart: xDown,
                xEnd: changedTouches[0].clientX,
                yStart: yDown,
                yEnd: changedTouches[0].clientY
            };

            // fire `swiped` event event on the element that started the swipe
            startEl.dispatchEvent(new CustomEvent('swiped', { bubbles: true, cancelable: true, detail: eventData }));
            console.log('handleTouchEnd: dispatchEvent swiped');
            // fire `swiped-dir` event on the element that started the swipe
            startEl.dispatchEvent(new CustomEvent(eventType, { bubbles: true, cancelable: true, detail: eventData }));
            console.log(`handleTouchEnd: dispatchEvent ${eventType}`);
        }

        // reset values
        xDown = null;
        yDown = null;
        timeDown = null;
        console.log(`handleTouchEnd: Ended`);
    }


    function handleTouchStart(e: TouchEvent) {
        console.log('handleTouchStart');
        // if the element has data-swipe-ignore="true" we stop listening for swipe events
        if ((e.target as HTMLElement).getAttribute('data-swipe-ignore') === 'true') return;

        startEl = e.target as HTMLElement;

        timeDown = Date.now();
        xDown = e.touches[0].clientX;
        yDown = e.touches[0].clientY;
        xDiff = 0;
        yDiff = 0;
    }


    function handleTouchMove(e: TouchEvent) {
        console.log('handleTouchMove');
        if (!xDown || !yDown) return;

        var xUp = e.touches[0].clientX;
        var yUp = e.touches[0].clientY;

        xDiff = xDown - xUp;
        yDiff = yDown - yUp;
    }


    function getNearestAttribute(el:HTMLElement, attributeName:string, defaultValue: string) {
        console.log('swipedEvents: getNearestAttribute: Started');

        // walk up the dom tree looking for attributeName
        while (el && el !== document.documentElement) {

            var attributeValue = el.getAttribute(attributeName);

            if (attributeValue) {
                return attributeValue;
            }

            el = (el.parentNode as HTMLElement);
        }
        console.log('swipedEvents: getNearestAttribute: Ended');
        return defaultValue;
    }
    console.log(`swipedEvents: Ended`);
}