import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { StringEnums } from "../../shared/utilities/enums/stringEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { GroupPicker, IGroupPickerState } from "../groupPicker/groupPicker";
import { Product } from "./product";
import { IProductDropDown, IProductSelector, ProductDropDown } from "./productDropDown";
import { getProductWithTypeName } from "./productInitialiser";

type IProductDropDownOmit = Omit<IProductDropDown, 'updateProductDropDown'>;

export interface IProductGroupDropDown extends IProductDropDownOmit, IProductGroupSelector {
    updateProductDropDown: (newDisplayText: string, newDisplayName: string, newValue: number | undefined, newAdditionPrice?: number) => void;
    updateProductAdditionPrice: (newAdditionPrice: number) => void;
    updateProductGroupPanelSelected: () => void;
}

export interface IProductGroupSelector extends IProductSelector {
    productGroupId: number;
    productAdditionPrice: number;
}

export class ProductGroupDropDown extends ProductDropDown implements IProductGroupDropDown {

    productGroupId: number;
    productAdditionPrice: number;
    private readonly _groupSelectorClick;
    private readonly _correctGroupPickerElement: HTMLElement;
    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);

        this._correctGroupPickerElement = this.getCorrectGroupPickerElement()

        this.initialiseGroupProperties();

        //----- Event Listener Bindings Start -----//
        this._groupSelectorClick = this.groupSelectorClick.bind(this);
        //----- Event Listener Bindings End -----//

        //----- Initialise Event Listeners Start -----//
        this.attachGroupSelectorClickEvent();
        //----- Initialise Event Listeners End -----//
    }

    //------ Add Event Listeners Start -----//
    attachGroupSelectorClickEvent() {

        if (this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelector)).getAttribute(AttributeEnums.ProductSelector.ProductSelector) === 'group') {
            this._correctGroupPickerElement.addEventListener('click', this._groupSelectorClick);

        }

    }
    //------ Add Event Listeners End -----//

    //------ Remove Event Listeners Start -----//
    removeGroupSelectorClickEvent() {
        if (this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelector)).getAttribute(AttributeEnums.ProductSelector.ProductSelector) === 'group') {
            this._correctGroupPickerElement.removeEventListener('click', this._groupSelectorClick);
        }
    }
    //------ Remove Event Listeners End -----//

    //------ Event Listener Methods Start -----//

    getSelectedGroupOptionSelector(clickElement: HTMLElement): HTMLElement {
        try {
            let mainOptionElement: HTMLElement;
            if (clickElement.hasAttribute(AttributeEnums.ProductSelector.ProductSelectorType)) {
                mainOptionElement = clickElement;
            } else {
                //go up the dom to find the data attributes on the parent
                mainOptionElement = clickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelectorType));
            }
            console.log('ProductGroupDropDown: getSelectedGroupOptionSelector', mainOptionElement);
            return mainOptionElement;
        } catch {
            console.log('ProductGroupDropDown: getSelectedGroupOptionSelector catch not ProductSelectorType null exception');
            return null;
        }
    }
    groupSelectorClick(event: Event): void {
        try {
            console.log('ProductGroupDropDown: groupSelectorClick', event.target);

            let groupSelectorElement: HTMLElement = this.getSelectedGroupOptionSelector(event.target as HTMLElement);
            if (groupSelectorElement !== null) {
                let productType: string = this.getProductContainerElement().getAttribute(AttributeEnums.Product.Product);
                let product: Product = getProductWithTypeName(productType);

                let groupPickerState: IGroupPickerState = GroupPicker.mapSelectedGroupHiddenInputToIGroupPicker(groupSelectorElement);
                if (groupPickerState !== null) {
                    this.updateProductDropDown(Product.selectedTextFormatter(groupPickerState.groupName), groupPickerState.selectedOptionName, groupPickerState.selectedExtraId, +(groupPickerState.selectedPrice ?? 0));

                    GroupPicker.updateSelectedElementOnClick(groupSelectorElement);
                    product.productPrice.updateProductPrice(product.productOptionsPanel.getTotalProductAdditionsPrice());
                    this.htmlElement.dispatchEvent(product.productSelectorChangedEvent(productType));
                } else{
                    console.log('ProductGroupDropDown: groupSelectorClick - groupPickerState Null');
                }
            } else{
                console.log('ProductGroupDropDown: groupSelectorClick - groupSelectorElement Null');

            }
        } catch (error) {
            console.log('ProductGroupDropDown: groupSelectorClick ERROR', error);

        }
    }
    //------ Event Listener Methods End -----//

    setProductGroupId(): number {
        let groupIdElement: HTMLElement;
        try {
            groupIdElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductGroupId)) as HTMLElement;

            return parseInt(this.setPropertyFromDataAttribute(groupIdElement, AttributeEnums.Product.ProductGroupId));
        } catch (error) {
            console.log('Critical Error: setProductGroupId Type Missmatch Error', groupIdElement);
            throw 'Critical Error: setProductGroupId Type Missmatch Error';
        }
    }

    setProductAdditionPrice(): number {
        let additionPriceElement: HTMLElement;
        try {
            additionPriceElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductAdditionPrice)) as HTMLElement;

            return parseInt(this.setPropertyFromDataAttribute(additionPriceElement, AttributeEnums.Product.ProductAdditionPrice));
        } catch (error) {
            console.log('Critical Error: setProductAdditionPrice Type Missmatch Error', additionPriceElement);
            throw 'Critical Error: setProductAdditionPrice Type Missmatch Error';
        }
    }

    setDisplayNamePrice(newDisplayName: string, newAdditionPrice: number): string {
        if ((!Number.isNaN(newAdditionPrice)) && (newAdditionPrice > 0)) {
            return newDisplayName + ' (£' + newAdditionPrice + ')';
        }
        else {
            return newDisplayName;
        }
    }

    updateProductDropDown(newDisplayText: string, newDisplayName: string, newValue: number | undefined, newAdditionPrice: number = 0): void {
        this.updateProductAdditionPrice(newAdditionPrice);
        super.updateProductDropDown(newDisplayText, this.setDisplayNamePrice(newDisplayName, newAdditionPrice), newValue);
    }

    writeToProductAdditionPrice(newAdditionPrice: number): void {
        try {
            let displayTextElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductAdditionPrice)) as HTMLElement;
            this.writeToDataAttributeValue(displayTextElement, AttributeEnums.Product.ProductAdditionPrice, newAdditionPrice.toString());
        } catch (error) {
            console.log('SelectorGroups writeToProductAdditionPrice ERROR', error);

        }
    }

    updateProductAdditionPrice(newAdditionPrice: number): void {
        this.writeToProductAdditionPrice(newAdditionPrice);
        this.productAdditionPrice = this.setProductAdditionPrice();
    }

    updateProductGroupPanelSelected(): void {
        try {
            console.log('SelectorGroups: checkIfCurrentProductGroupSelectorHasOptionsThenSetsSelector');

            let productGroupElement = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelectorGroupId, this.productGroupId.toString()));

            if (productGroupElement) {
                this.productGroupSelectorSetSelected();
            }
        } catch (error) {
            console.log('SelectorGroups: checkIfCurrentProductGroupSelectorHasOptionsThenSetsSelector ERROR', error);

        }
    }

    productGroupSelectorSetSelected(): void {
        try {
            console.log('SelectorGroups: productGroupSelectorSetSelected');
            //selects the relevant element of type productGroupIdVal with a matching extra id to that of the inputVal
            let productSelectorElement = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelectorGroupId, this.productGroupId.toString()) + HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelectorExtraId, this.value.toString()));
            //sets the data attr for selected to true
            productSelectorElement.setAttribute(AttributeEnums.ProductSelector.ProductSelectorIsSelected, 'true');

            if (productSelectorElement.classList.contains(StringEnums.CssClass.LbColourPickerTile)) {
                productSelectorElement.classList.add(StringEnums.CssClass.LbGalleryChooserTilesCellSelected);
            }
        } catch (error) {
            console.log('SelectorGroups: productGroupSelectorSetSelected ERROR', error);
        }
    }

    getCorrectGroupPickerElement(): HTMLElement {
        return this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.GroupPicker.GroupPicker) + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutId, this.popOutIdOpen) + HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily, this.popOutFamilyOpen));
    }
    refreshProperties(): void {
        this.productGroupId = this.setProductGroupId();
        this.productAdditionPrice = this.setProductAdditionPrice();
        super.refreshProperties();
    }

    initialiseGroupProperties(): void {
        this.productGroupId = this.setProductGroupId();
        this.productAdditionPrice = this.setProductAdditionPrice();

    }

    //------ Custom Event Creation Start -----//
    //------ Custom Event Creation End -----//

    //------ Clean Up Processes Start -----//
    dispose(): void {
        this.removeGroupSelectorClickEvent();
        super.dispose();
    }
    //------ Clean Up Processes End -----//
}