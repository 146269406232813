import { DeliveryServices } from "../../shared/delivery/deliveryServices";
import { ProductDeliveryRequestDto } from "../../shared/delivery/productDeliveryRequestDto";
import { DeliveryOption, ProductDeliveryResponseDto } from "../../shared/delivery/productDeliveryResponseDto";
import { InstanceComponentBase } from "../../shared/instanceComponentBase";
import { CookieUtils } from "../../shared/utilities/cookieUtils";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { StringEnums } from "../../shared/utilities/enums/stringEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { ValidationUtilities } from "../../shared/utilities/validationUtilities";
require('../../shared/utilities/extensionUtilities');

export interface IProductDeliveryPanel {
}

export interface ProductDeliveryPanelState {
    productDeliveryRequestDto: ProductDeliveryRequestDto;
    productDeliveryResponseDto: ProductDeliveryResponseDto;
}
export class ProductDeliveryPanel extends InstanceComponentBase implements IProductDeliveryPanel {

    private productDeliveryPanelState: ProductDeliveryPanelState;
    private productHtmlElement: Element;
    private readonly _setDeliveryPostcodeWithEvent;
    private readonly _setDeliveryPostcodeAndInitialiseDelivery;
    private readonly _initialiseDeliveryPanelFromCustomEvent;

    constructor(public htmlElement: Element) {
        console.log('ProductDeliveryPanel instantiate')
        super(htmlElement);
        this.setProductHtmlElement();
        this.productDeliveryPanelState = { productDeliveryRequestDto: {}, productDeliveryResponseDto: {} } as ProductDeliveryPanelState;
        this.initialiseDeliveryPanel();

        //----- Event Listener Bindings Start -----//
        this._setDeliveryPostcodeWithEvent = this.setDeliveryPostcodeWithEvent.bind(this);
        this._setDeliveryPostcodeAndInitialiseDelivery = this.setDeliveryPostcodeAndInitialiseDelivery.bind(this);
        this._initialiseDeliveryPanelFromCustomEvent = this.initialiseDeliveryPanelFromCustomEvent.bind(this);
        //----- Event Listener Bindings End -----//

        //----- Initialise Event Listeners Start -----//
        this.attachPostcodeOnKeyUpEvent();
        this.attachPostcodeChangeOnClickEvent();
        this.attachProductPriceUpdatedEvent();
        //----- Initialise Event Listeners End -----//
    }

    setProductHtmlElement() {
        this.productHtmlElement = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product));
    }

    async initialiseDeliveryPanel() {
        this.initialiseProductDeliveryRequestDto();
        let requestDto = this.productDeliveryPanelState.productDeliveryRequestDto;
        if (requestDto.sizeId === undefined) {
            console.log('ProductDeliveryPanel: initialiseDeliveryPanel undefined size');
            this.htmlElement.innerHTML = this.productDeliveryTemplateForNoSizeSelected();
        } else {
            if ((requestDto.postcode !== '') && (requestDto.postcode !== null) && (requestDto.postcode !== undefined) && this.validatePostcode(requestDto.postcode)) {
                console.log('ProductDeliveryPanel: initialiseDeliveryPanel populated postcode');
                await this.productDeliveryTemplateForSizeSelectedAndPostcodeAdded();
            } else {
                console.log('ProductDeliveryPanel: initialiseDeliveryPanel empty postcode');
                //this.htmlElement.innerHTML = this.productDeliveryTemplateForSizeSelectedNoPostcode()
                await this.productDeliveryTemplateForSizeSelectedNoPostcode();
            }
        }
    }

    //------ Add Event Listeners Start -----//
    async attachPostcodeOnKeyUpEvent() {
        this.htmlElement.addEventListener('keyup', this._setDeliveryPostcodeWithEvent);
    }

    async attachPostcodeChangeOnClickEvent() {
        this.htmlElement.addEventListener('click', this._setDeliveryPostcodeAndInitialiseDelivery);
    }

    async attachProductPriceUpdatedEvent() {
        this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).addEventListener(CustomEventEnums.ProductPriceUpdated, this._initialiseDeliveryPanelFromCustomEvent);
    }
    //------ Add Event Listeners End -----//

    //------ Remove Event Listeners Start -----//
    removePostcodeOnKeyUpEvent() {
        this.htmlElement.removeEventListener('keyup', this._setDeliveryPostcodeWithEvent);
    }

    removePostcodeChangeOnClickEvent() {
        this.htmlElement.removeEventListener('click', this._setDeliveryPostcodeAndInitialiseDelivery);
    }

    removeProductPriceUpdatedEvent() {
        let productElement: HTMLElement = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product));
        productElement.removeEventListener(CustomEventEnums.ProductPriceUpdated, this._initialiseDeliveryPanelFromCustomEvent);
    }
    //------ Remove Event Listeners End -----//

    //------ Event Listener Methods Start -----//
    setDeliveryPostcodeWithEvent(event: Event): void {
        console.log('ProductDeliveryPanel: setDeliveryPostcodeWithEvent - Beginning');

        const element = event.target as HTMLInputElement;

        if (element.hasAttribute(AttributeEnums.ProductDelivery.ProductDeliveryPostcode)) {
            let value: string = element.value.toUpperCase().trim();
            let postcodeFormDiv = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductDelivery.ProductDeliveryPostcode)).closest('div');
            postcodeFormDiv.classList.add(StringEnums.CssClass.LbWarning);
            this.setDeliveryPostcodeCookie(value);
            console.log('Delivery Postcode Value:', value);
            if (this.validatePostcode(value)) {
                postcodeFormDiv.classList.remove(StringEnums.CssClass.LbWarning);
                postcodeFormDiv.classList.add(StringEnums.CssClass.LbSuccess);
                this.productDeliveryPanelState.productDeliveryRequestDto.postcode = value;
                this.productDeliveryTemplateForSizeSelectedAndPostcodeAdded();
            }
        }
    }

    setDeliveryPostcodeAndInitialiseDelivery(event: Event): void {
        console.log('ProductDeliveryPanel: setDeliveryPostcodeAndInitialiseDelivery - Beginning');

        const element = event.target as HTMLInputElement;

        if (element.hasAttribute(AttributeEnums.ProductDelivery.ProductDeliveryPostcodeChange)) {

            this.productDeliveryPanelState.productDeliveryRequestDto.postcode = '';
            this.setDeliveryPostcodeCookie('');
            this.initialiseDeliveryPanel();
        }
    }

    initialiseDeliveryPanelFromCustomEvent(event: CustomEvent): void {
        console.log('ProductDeliveryPanel: initialiseDeliveryPanelFromCustomEvent - Beginning');
        //console.log('ProductDeliveryPanel: addProductPriceUpdatedEvent product-price-updated event fired')
        let eventDetail = event.detail;
        let type = eventDetail.type as string;
        if (type === this.productHtmlElement.getAttribute(AttributeEnums.Product.Product)) {
            this.initialiseDeliveryPanel();
        }
    }

    setDeliveryPostcodeCookie(postcode: string) {
        CookieUtils.setCookie('delivery-postcode', postcode, 14);
    }
    //------ Event Listener Methods End -----//

    //------ Custom Event Creation Start -----//
    //------ Custom Event Creation End -----//


    getProductHtmlElement(): Element {
        return this.productHtmlElement;
    }


    getRequestTotal() {

        let priceElement = this.productHtmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductPrice.ProductNowPrice));
        if (priceElement !== null) {
            return parseInt(priceElement.getAttribute(AttributeEnums.ProductPrice.ProductNowPrice));
        } else {
            return 0;
        }
    }

    getRequestLineId() {
        console.log('ProductDeliveryPanel: getRequestLineId');

        let lineId = parseInt(this.htmlElement.getAttribute(AttributeEnums.ProductDelivery.ProductDeliveryPanelLineId));
        if (!isNaN(lineId)) {
            return lineId;
        } else {
            console.log('Product LineId Error. LineId not set for product!');
            throw ('Product LineId Error. LineId not set for product!');
        }
    }

    setRequestTotal() {

        this.productDeliveryPanelState.productDeliveryRequestDto.total = this.getRequestTotal();
    }

    initialiseProductDeliveryRequestDto() {

        let requestDto = { lineId: 0, sizeId: undefined, total: 0, postcode: '' } as ProductDeliveryRequestDto;
        //this.setDeliveryPostcodeCookie('LS18 4SF');
        let postcode = CookieUtils.getCookie('delivery-postcode');
        if (postcode !== null) {
            requestDto.postcode = postcode;
        }
        requestDto.lineId = this.getRequestLineId();
        let sizeId = parseInt(this.productHtmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductSizeId)).getAttribute(AttributeEnums.Product.ProductSizeId));
        if (isNaN(sizeId)) {
            requestDto.sizeId = undefined;
        } else {
            requestDto.sizeId = sizeId;
        }
        requestDto.total = this.getRequestTotal();

        this.productDeliveryPanelState = { ...this.productDeliveryPanelState, productDeliveryRequestDto: requestDto } as ProductDeliveryPanelState;
    }

    productDeliveryTemplateForNoSizeSelected() {
        return '<p class="lb_heading lb_icon lb_icon-small lb_icon-button lb_icon-delivery">Please <span class="lb_text-strong">select size</span> for delivery options</p>';
    }

    async productDeliveryTemplateForSizeSelectedNoPostcode(): Promise<void> {
        let DefaultPostCode = StringEnums.Delivery.DefaultPostCode;
        this.productDeliveryPanelState.productDeliveryRequestDto.postcode = DefaultPostCode;
        if (this.validatePostcode(DefaultPostCode)) {
            await this.productDeliveryTemplateForSizeSelectedAndDefaultPostcode();
        }
        this.productDeliveryPanelState.productDeliveryRequestDto.postcode = '';
    }

    async productDeliveryTemplateForSizeSelectedAndDefaultPostcode() {
        this.setRequestTotal();
        try {
            let response = await DeliveryServices.getDeliveryInfoForAProduct(this.productDeliveryPanelState.productDeliveryRequestDto);
            this.productDeliveryPanelState.productDeliveryResponseDto = response;
            let responseDto = this.productDeliveryPanelState.productDeliveryResponseDto;
            console.log('ProductDeliveryPanel: productDeliveryTemplateForSizeSelectedAndDefaultPostcode: got productDeliveryResponseDto', responseDto);

            (this.htmlElement as HTMLElement).removeChildren();
            this.defaultDeliveryOptionSectionElements(responseDto.deliveryOptions, this.htmlElement);
        } catch (error) {
            console.log('ProductDeliveryPanel: productDeliveryTemplateForSizeSelectedAndDefaultPostcode: ERROR', this.productDeliveryPanelState.productDeliveryRequestDto);
            this.htmlElement.innerHTML = this.productDeliveryTemplateError();
        }
    }

    async productDeliveryTemplateForSizeSelectedAndPostcodeAdded() {
        this.setRequestTotal();
        try {
            let response = await DeliveryServices.getDeliveryInfoForAProduct(this.productDeliveryPanelState.productDeliveryRequestDto);
            this.productDeliveryPanelState.productDeliveryResponseDto = response;
            let responseDto = this.productDeliveryPanelState.productDeliveryResponseDto;
            console.log('ProductDeliveryPanel: productDeliveryTemplateForSizeSelectedAndPostcodeAdded: got productDeliveryResponseDto', responseDto);

            (this.htmlElement as HTMLElement).removeChildren();
            this.deliveryOptionSectionElements(responseDto.deliveryOptions, responseDto.dispatchedFrom, this.htmlElement);
        } catch (error) {
            console.log('ProductDeliveryPanel: productDeliveryTemplateForSizeSelectedAndPostcodeAdded: ERROR', this.productDeliveryPanelState.productDeliveryRequestDto);
            this.productDeliveryTemplateErrorReset();
        }
    }

    productDeliveryTemplateError() {
        // icon should change to error
        return '<p class="lb_heading lb_icon lb_icon-small lb_icon-button lb_icon-info">Sorry. Delivery information is currently unavailable for this product.</p>';
    }
    
    productDeliveryTemplateErrorReset():void {
        this.productDeliveryPanelState.productDeliveryRequestDto.postcode = '';
        this.setDeliveryPostcodeCookie('');
        this.initialiseDeliveryPanel();
    }

    defaultDeliveryOptionSectionElements(deliveryOptions: DeliveryOption[], deliveryOptionsElement: Element) {
        this.defaultDeliveryIconHeading(deliveryOptionsElement);
        this.defaultDeliveryInput(deliveryOptionsElement);
        this.deliveryOptionsHeadingElements(deliveryOptionsElement);
        this.deliveryOptionArrayMapToElements(deliveryOptions, deliveryOptionsElement);
    }
    defaultDeliveryIconHeading(deliveryOptionsElement: Element): void {
        const deliveryIconHeading = document.createElement('p');
        deliveryIconHeading.innerText = 'Check delivery options in your area';
        deliveryIconHeading.classList.add(StringEnums.CssClass.LbHeading, StringEnums.CssClass.LbIcon, StringEnums.CssClass.LbIconSmall, StringEnums.CssClass.LbIconButton, StringEnums.CssClass.LbIconDelivery);
        deliveryOptionsElement.append(deliveryIconHeading);
    }
    defaultDeliveryInput(deliveryOptionsElement: Element): void {
        const defaultDeliveryOuter = document.createElement('div');

        let defaultDeliveryInput = document.createElement('input');
        defaultDeliveryInput.classList.add(StringEnums.CssClass.LbFormElementInput);
        defaultDeliveryInput.setAttribute('type', 'text');
        defaultDeliveryInput.setAttribute('placeholder', StringEnums.Delivery.InputPlaceholderPostCode);
        defaultDeliveryInput.setAttribute(AttributeEnums.ProductDelivery.ProductDeliveryPostcode, '');

        defaultDeliveryOuter.append(defaultDeliveryInput);
        defaultDeliveryOuter.classList.add(StringEnums.CssClass.LbFormElement, StringEnums.CssClass.LbFormElementTextBox);

        deliveryOptionsElement.append(defaultDeliveryOuter);
    }

    deliveryOptionSectionElements(deliveryOptions: DeliveryOption[], dispatchedFrom: string, deliveryOptionsElement: Element) {
        let deliveryOptionIsSelectable = this.checkIfDeliveryOptionIsSelectable(deliveryOptions);
        
        this.deliveryIconHeading(deliveryOptionsElement);
        this.deliveryCurrentPostcodeAndReset(deliveryOptionsElement);
        this.deliveryOptionsHeadingElements(deliveryOptionsElement);

        if(deliveryOptionIsSelectable){
            this.deliveryOptionArrayMapToElements(deliveryOptions, deliveryOptionsElement);
            this.deliveryOptionDispatch(dispatchedFrom, deliveryOptionsElement);
        }else{
            this.deliveryOptionNotAvailable(deliveryOptions, deliveryOptionsElement);
        }
    }

    deliveryIconHeading(deliveryOptionsElement: Element) {
        const deliveryIconHeading = document.createElement('p');
        deliveryIconHeading.innerText = 'Delivery';
        deliveryIconHeading.classList.add(StringEnums.CssClass.LbHeading, StringEnums.CssClass.LbIcon, StringEnums.CssClass.LbIconSmall, StringEnums.CssClass.LbIconButton, StringEnums.CssClass.LbIconDelivery);
        deliveryOptionsElement.append(deliveryIconHeading);
    }

    deliveryCurrentPostcodeAndReset(deliveryOptionsElement: Element) {
        const currentPostcode = document.createElement('p');
        currentPostcode.innerText = 'Postcode: ' + this.productDeliveryPanelState.productDeliveryRequestDto.postcode + ' ';
        let changePostcodeAnchor = document.createElement('a');
        changePostcodeAnchor.innerText = 'Change Postcode';
        changePostcodeAnchor.classList.add(StringEnums.CssClass.LbLink);
        changePostcodeAnchor.setAttribute(AttributeEnums.ProductDelivery.ProductDeliveryPostcodeChange, '');
        currentPostcode.append(changePostcodeAnchor);
        currentPostcode.classList.add(StringEnums.CssClass.LbText);
        deliveryOptionsElement.append(currentPostcode);
    }

    deliveryOptionsHeadingElements(deliveryOptionsElement: Element) {
        const deliveryOptionHeaderElement = document.createElement('h2');
        deliveryOptionHeaderElement.innerHTML = 'Delivery Options <span data-pop-out-id-open="1" data-pop-out-family-open="delivery" class="lb_icon lb_icon-vSmall lb_icon-surface lb_icon-button lb_icon-info lb_link"></span>';
        deliveryOptionHeaderElement.classList.add(StringEnums.CssClass.LbHeading, StringEnums.CssClass.LbHeadingDelivery);
        deliveryOptionsElement.append(deliveryOptionHeaderElement);
    }

    deliveryOptionArrayMapToElements(deliveryOptions: DeliveryOption[], deliveryOptionsElement: Element) {
        const deliveryOptionGridElement = document.createElement('div');
        deliveryOptionGridElement.classList.add(StringEnums.CssClass.LbOptionsGrid);
        deliveryOptionsElement.append(deliveryOptionGridElement);
        for (let x in deliveryOptions) {
            this.deliveryOptionMapToElements(deliveryOptions[x], deliveryOptionGridElement);
        }
    }

    deliveryOptionMapToElements(deliveryOption: DeliveryOption, deliveryOptionGrid: Element) {
        let from = (deliveryOption.isPickable) ? ' from ' : '';
        let subNote = (deliveryOption.subNote.length) ? '<br/><em>(' + deliveryOption.subNote + ')</em>' : '';

        const deliveryOptionsGridOptionElement = document.createElement('div');
        deliveryOptionsGridOptionElement.classList.add(StringEnums.CssClass.LbOptionsGridOption);
        deliveryOptionsGridOptionElement.innerHTML = deliveryOption.name + ' <span class="lb_text-strong">' + from + deliveryOption.leadTimeText + '</span>' + subNote;

        const deliveryOptionGridPriceElement = document.createElement('div');
        deliveryOptionGridPriceElement.classList.add(StringEnums.CssClass.LbOptionsGridPrice);
        deliveryOptionGridPriceElement.innerText = deliveryOption.charge.currency(0, true); //needs to display 'FREE' for 0 cost

        deliveryOptionGrid.appendChild(deliveryOptionsGridOptionElement);
        deliveryOptionGrid.appendChild(deliveryOptionGridPriceElement);
    }

    deliveryOptionDispatch(dispatchedFrom: string, deliveryOptionsElement: Element) {
        const deliveryOptionDispatch = document.createElement('div');
        deliveryOptionDispatch.innerText = 'Dispatched direct from ' + dispatchedFrom + '. You will be contacted to arrange delivery.';
        deliveryOptionDispatch.classList.add(StringEnums.CssClass.LbText, StringEnums.CssClass.LbTextSmall);
        deliveryOptionsElement.append(deliveryOptionDispatch);
    }

    deliveryOptionNotAvailable(deliveryOptions: DeliveryOption[], deliveryOptionsElement: Element){
        const deliveryOptionGridElement = document.createElement('p');
        deliveryOptionGridElement.classList.add(StringEnums.CssClass.LbText);
        deliveryOptionGridElement.innerHTML = this.getDeliveryOptionIsSelectableFalseDescription(deliveryOptions);
        deliveryOptionsElement.append(deliveryOptionGridElement);
    }

    checkIfDeliveryOptionIsSelectable(deliveryOptions: DeliveryOption[]):boolean{
        for (let x in deliveryOptions) {
            if(deliveryOptions[x].isSelectable === false){
                return false;
            }
        }
        return true;
    }

    getDeliveryOptionIsSelectableFalseDescription(deliveryOptions: DeliveryOption[]):string{
        for (let x in deliveryOptions) {
            if(deliveryOptions[x].isSelectable === false){
                return deliveryOptions[x].description;
            }
        }
        return '';
    }

    validatePostcode(postcode: string): boolean {
        return ValidationUtilities.isPostcode(postcode);
    }
    
    //------ Clean Up Processes Start -----//
    dispose(): void {
        console.log('ProductDeliveryPanel: dispose');
        this.removePostcodeOnKeyUpEvent();
        this.removePostcodeChangeOnClickEvent();
        this.removeProductPriceUpdatedEvent();
        this.delete();
    }

    delete(): void {
        console.log('ProductDeliveryPanel: productDeliveryPanelDelete');

        if ((<any>window)[this.htmlElement.getAttribute(AttributeEnums.ProductDelivery.ProductDeliveryPanel) + 'ProductDeliveryPanel']) {
            console.log('ProductDeliveryPanel: delete - has a ProductDeliveryPanel');
            delete (<any>window)[this.htmlElement.getAttribute(AttributeEnums.ProductDelivery.ProductDeliveryPanel) + 'ProductDeliveryPanel'];
            console.log('ProductDeliveryPanel: delete - reference deleted');
        }
        else {
            console.log('ProductDeliveryPanel: delete - ProductDeliveryPanel Reference Not Found');
        }
    }
    //------ Clean Up Processes End -----//
}