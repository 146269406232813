import { HttpRequest } from "../../shared/ajax";
import { initialise } from "../../shared/initialiser";
import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { ResponsiveState } from "../../shared/responsiveState";
import { CookieUtils } from "../../shared/utilities/cookieUtils";
import { CustomEvents } from "../../shared/utilities/customEvents";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CookieEnums } from "../../shared/utilities/enums/cookieEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { SessionStorageEnums } from "../../shared/utilities/enums/sessionStorageEnums";
import { StringEnums } from "../../shared/utilities/enums/stringEnums";
import { UrlEnums } from "../../shared/utilities/enums/urlEnums";
import { UtilityEnums } from "../../shared/utilities/enums/utillityEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";

const urlPaths = require('../../config/urlPaths.json');
const toggleFeatures = require('../../config/toggleFeatures.json');

export interface INotification {
    timeout: number;
    delay: number;
    placeHolderElement: HTMLElement;
    notificationElement: HTMLElement;
    chatButtonElement?: HTMLElement;
    phoneButtonElement?: HTMLElement;
}

export class Notification implements INotification {
    public timeout: number;
    public delay: number;
    public chatButtonElement?: HTMLElement;
    public phoneButtonElement?: HTMLElement;
    private delayTimeoutId: NodeJS.Timeout;

    private readonly _handleCloseButtonClick;
    private readonly _handleChatButtonClick;
    private readonly _handlePhoneButtonClick;
    private readonly _handleShowOverlayEvent;
    private readonly _handleHideOverlayEvent;
    private readonly _handleLiveChatLoadComplete;
    

    constructor(public placeHolderElement: HTMLElement, public notificationElement: HTMLElement) {
        try {
            this._handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
            this._handleChatButtonClick = this.handleChatButtonClick.bind(this);
            this._handlePhoneButtonClick = this.handlePhoneButtonClick.bind(this);
            this._handleShowOverlayEvent = this.handleShowOverlayEvent.bind(this);
            this._handleHideOverlayEvent = this.handleHideOverlayEvent.bind(this);
            this._handleLiveChatLoadComplete = this.handleLiveChatLoadComplete.bind(this);

            this.initialise();
        } catch (error) {
            console.log('Notification: constructor ERROR', error);
            this.dispose();
        }
    }

    attachCloseClickEventListener(): void {
        try {
            let closeButtonElement = this.notificationElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Notification.CloseButton));
            if (closeButtonElement) {
            closeButtonElement.addEventListener('click', this._handleCloseButtonClick);
            }
        } catch (error) {
            console.log('Notification: attachCloseClickEventListener ERROR', error);
            throw new Error();
        }
    }

    removeCloseClickEventListener(): void {
        try {
            let closeButtonElement = this.notificationElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Notification.CloseButton));
            if (closeButtonElement) {
                closeButtonElement.removeEventListener('click', this._handleCloseButtonClick);
            }
        } catch (error) {
            console.log('Notification: removeCloseClickEventListener ERROR', error);
        }
    }

    attachChatButtonClickEventListener(): void {
        try {
            if (this.chatButtonElement) {
                this.chatButtonElement.addEventListener('click', this._handleChatButtonClick);
            }
        } catch (error) {
            console.log('Notification: attachChatButtonClickEventListener ERROR', error);
            throw new Error();
        }
    }
    removeChatButtonClickEventListener(): void {
        try {
            if (this.chatButtonElement) {
                this.chatButtonElement.removeEventListener('click', this._handleChatButtonClick);
            }
        } catch (error) {
            console.log('Notification: removeChatButtonClickEventListener ERROR', error);
        }
    }

    attachPhoneButtonClickEventListener(): void {
        try {
            if (this.phoneButtonElement) {
                this.phoneButtonElement.addEventListener('click', this._handlePhoneButtonClick);
            }
        } catch (error) {
            console.log('Notification: attachPhoneButtonClickEventListener ERROR', error);
            throw new Error();
        }
    }
    removePhoneButtonClickEventListener(): void {
        try {
            if (this.phoneButtonElement) {
                this.phoneButtonElement.removeEventListener('click', this._handlePhoneButtonClick);
            }
        } catch (error) {
            console.log('Notification: removePhoneButtonClickEventListener ERROR', error);
        }
    }

    attachLiveChatLoadCompleteEventListener(): void{
        try {
            (<any>window).addEventListener(CustomEventEnums.LiveChatLoadComplete, this._handleLiveChatLoadComplete);
        } catch (error) {
            console.log('Notification: attachLiveChatLoadCompleteEventListener ERROR', error);
            throw new Error();
        }
    }
    removeLiveChatLoadCompleteEventListener(): void{
        try {
            (<any>window).removeEventListener(CustomEventEnums.LiveChatLoadComplete, this._handleLiveChatLoadComplete);
        } catch (error) {
            console.log('Notification: removeLiveChatLoadCompleteEventListener ERROR', error);
        }
    }

    attachShowOverlayEventListener(): void{
        try {
            (<any>window).addEventListener(CustomEventEnums.ShowOverlay, this._handleShowOverlayEvent);
        } catch (error) {
            console.log('Notification: attachShowOverlayEventListener ERROR', error);
            throw new Error();
        }
    }
    removeShowOverlayEventListener(): void{
        try {
            (<any>window).removeEventListener(CustomEventEnums.ShowOverlay, this._handleShowOverlayEvent);
        } catch (error) {
            console.log('Notification: removeShowOverlayEventListener ERROR', error);
        }
    }
    attachHideOverlayEventListener(): void{
        try {
            (<any>window).addEventListener(CustomEventEnums.HideOverlay, this._handleHideOverlayEvent);
        } catch (error) {
            console.log('Notification: attachHideOverlayEventListener ERROR', error);
            throw new Error();
        }
    }
    removeHideOverlayEventListener(): void{
        try {
            (<any>window).removeEventListener(CustomEventEnums.HideOverlay, this._handleHideOverlayEvent);
        } catch (error) {
            console.log('Notification: removeHideOverlayEventListener ERROR', error);
        }
    }
   
    initialise(): void {
        try {
            this.setTimeout();
            this.setDelay();
            this.attachCloseClickEventListener();
            this.attachShowOverlayEventListener();
            this.attachHideOverlayEventListener();
            if(sessionStorage.getItem(SessionStorageEnums.LiveChatCached) === 'true')
            {
                console.log('Notification: initialise Live Chat Cache Loaded');
                this.openNotificationPanelOnDelay();
            }else{
                console.log('Notification: initialise Live Chat Cache Not Loaded - attachLiveChatLoadCompleteEventListener');
                this.attachLiveChatLoadCompleteEventListener();
            }
            this.setChatButtonElementAndAttachEventListener();
            this.setPhoneButtonElementAndAttachEventListener();

        } catch (error) {
            console.log('Notification: initialise ERROR', error);
            this.dispose();
        }
    }

    handleCloseButtonClick(): void {
        this.closeNotificationPanel();
        CookieUtils.setCookie(CookieEnums.NotificationStatus, StringEnums.Notification.ConsentDismiss, 0, 1);
    }

    handleChatButtonClick(event: Event): void {
        console.log('Notification: handleChatButtonClick');
        this.closeNotificationPanel();
        (<any>window).dispatchEvent(CustomEvents.openLiveChatEvent());
    }

    handlePhoneButtonClick(): void {
        console.log('Notification: handlePhoneButtonClick');
        this.closeNotificationPanel();
    }

    handleLiveChatLoadComplete(): void{
        console.log('Notification: handleLiveChatLoadComplete');
        this.openNotificationPanelOnDelay();
        this.removeLiveChatLoadCompleteEventListener();
    }
    
    handleShowOverlayEvent():void{
        console.log('Notification: handleShowOverlayEvent');
        
        this.clearTimeout(this.delayTimeoutId);
    }

    handleHideOverlayEvent():void{
        console.log('Notification: handleHideOverlayEvent');

        this.clearTimeout(this.delayTimeoutId);

        this.openNotificationPanelOnDelay();
    }

    openNotificationPanelOnDelay():void{
        try {
            console.log('Notification: openNotificationPanelOnDelay');
            this.delayTimeoutId = setTimeout(() => {
                this.openNotificationPanel();
            }, this.delay);
        } catch (error) {
            console.log('Notification: openNotificationPanelOnDelay ERROR', error);
        }
    }

    openNotificationPanel():void{
        try {
            console.log('Notification: openNotificationPanel');

            let timeoutSeconds:Number = this.convertMilisecondsToSeconds(this.timeout);
            let progressBarTimerElement: HTMLElement = this.notificationElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Notification.ProgressBarTimer)) as HTMLElement;
            progressBarTimerElement.setAttribute("style", `transition:width ${timeoutSeconds}s linear`);
            
            this.notificationElement.classList.add(StringEnums.CssClass.LbActive);

            (<any>window).dispatchEvent(CustomEvents.notificationPanelIsOpenEvent());
            (<any>window).dispatchEvent(CustomEvents.hideLiveChatEvent());

            setTimeout(() => {
                this.closeNotificationPanel();
            }, this.timeout);

            this.incrementCookieTriggerCount();    
            
            this.removeHideOverlayEventListener();
            this.removeShowOverlayEventListener();
            
        } catch (error) {
            console.log('Notification: openNotificationPanel ERROR', error);
        }
    }

    clearTimeout(timeoutID:NodeJS.Timeout):void{
        try {
            if(this.delayTimeoutId !== null){
                console.log('Notification: clearTimeout');
                clearTimeout(timeoutID);
            }
        } catch (error) {
            console.log('Notification: clearTimeout ERROR', error);
        } 
    }

    convertMilisecondsToSeconds(timeInMiliseconds:number):Number{
        return timeInMiliseconds * .001;
    }

    closeNotificationPanel():void{
        try {
            console.log('Notification: closeNotification');
            this.notificationElement.classList.remove(StringEnums.CssClass.LbActive);
            (<any>window).dispatchEvent(CustomEvents.notificationPanelIsClosedEvent());
            (<any>window).dispatchEvent(CustomEvents.showLiveChatEvent());
            this.dispose();
        } catch (error) {
            console.log('Notification: closeNotificationPanel ERROR', error);
        }
    }

    setTimeout(): void {
        try {
            let timeoutElement = this.notificationElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Notification.Placeholder)).querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Notification.Timeout)) as HTMLElement;
            if (timeoutElement) {
                this.timeout = +timeoutElement.getAttribute(AttributeEnums.Notification.Timeout);
            }
        } catch (error) {
            console.log('Notification: setTimeout - Failed to initialise ERROR', error);
            throw new Error('Notification: setTimeout - Failed to initialise ERROR');
        }
    }

    setDelay(): void {
        try {
            let delayElement = this.notificationElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Notification.Placeholder)).querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Notification.Delay)) as HTMLElement;
            if (delayElement) {
                this.delay = +delayElement.getAttribute(AttributeEnums.Notification.Delay);
            }
        } catch (error) {
            console.log('Notification: setDelay- Failed to initialise ERROR', error);
            throw new Error('Notification: setDelay- Failed to initialise ERROR');
        }
    }

    setChatButtonElementAndAttachEventListener(): void {
        try {
            this.chatButtonElement = this.notificationElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Notification.Button, UtilityEnums.NotificationButtonTypes.Chat)) as HTMLElement;
            if (this.chatButtonElement) {
                this.attachChatButtonClickEventListener();
            }
        } catch (error) {
            console.log('Notification: setChatButtonElementAndAttachEventListener ERROR', error);
            throw new Error();
        }
    }

    setPhoneButtonElementAndAttachEventListener(): void {
        try {
            this.phoneButtonElement = this.notificationElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Notification.Button, UtilityEnums.NotificationButtonTypes.Phone)) as HTMLElement;
            if (this.phoneButtonElement) {
                this.updateDisplayPhoneButton();
                this.attachPhoneButtonClickEventListener();
            }
        } catch (error) {
            console.log('Notification: setPhoneButtonElementAndAttachEventListener ERROR', error);
            throw new Error();
        }
    }

    updateDisplayPhoneButton():void{
        try {
            let responsiveState:StringEnums.Selectors = ResponsiveState.getStateAsEnum();
            if(responsiveState === StringEnums.Selectors.Desktop){
                this.phoneButtonElement.setAttribute(AttributeEnums.Notification.ButtonShow, 'false');
            }
        } catch (error) {
            console.log('Notification: updateDisplayPhoneButton ERROR', error);
        }
    }

    incrementCookieTriggerCount():void{
        try {
            console.log('Notification: incrementCookieTriggerCount');
            let triggerCount = 1;
            let notificationTriggerCount: string | null = CookieUtils.getCookie(CookieEnums.NotificationTriggerCount);
            if (notificationTriggerCount) {
                triggerCount = Number(notificationTriggerCount);
                triggerCount += 1;
                CookieUtils.setCookie(CookieEnums.NotificationTriggerCount, triggerCount.toString(), 0, 1);
            } 
        } catch (error) {
            console.log('Notification: incrementCookieTriggerCount ERROR', error);
        }
    }

    static checkCookieTriggerCount():boolean{
         try {
            console.log('Notification: checkCookieTriggerCount');
             let notificationTriggerCount: string | null = CookieUtils.getCookie(CookieEnums.NotificationTriggerCount);
            if (notificationTriggerCount) {
                if (Number(notificationTriggerCount) < 5) {
                    return true;
                } else{
                    return false;
                }
            } else {
                let triggerCount = 1;
                CookieUtils.setCookie(CookieEnums.NotificationTriggerCount, triggerCount.toString(), 0, 1);
                return true;
            }
        } catch (error) {
            console.log('Notification: checkCookieTriggerCount ERROR', error);
            return false;
        }
    }

    static checkCookieStatus():boolean{
        try {
            console.log('Notification: checkCookieStatus');
            let notificationStatus: string | null = CookieUtils.getCookie(CookieEnums.NotificationStatus);
            if (notificationStatus) {
                if (notificationStatus === StringEnums.Notification.ConsentEnable) {
                    return true;
                } 
                else if(notificationStatus === StringEnums.Notification.ConsentDismiss){
                    return false;
                }
            } else {
                CookieUtils.setCookie(CookieEnums.NotificationStatus, StringEnums.Notification.ConsentEnable, 0, 1);
                return true;
            }
        } catch (error) {
            console.log('Notification: checkCookieStatus ERROR', error);
            return false;
        }
        return true;
    }

    public static async notificationFactory() {
        try {
            if(toggleFeatures.Notification){

                if(Notification.checkCookieStatus() && Notification.checkCookieTriggerCount()){
                    let placeHolderElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Notification.Placeholder)) as HTMLElement;
                    if (placeHolderElement) {
                        await Notification.requestNotification(placeHolderElement);
                        if(Notification.hasNotificationHtml()){
                            let notificationElement = placeHolderElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Notification.Notification)) as HTMLElement;
                            let newNotification = new Notification(placeHolderElement, notificationElement);
                            (<any>window).notification = newNotification;
                        }else{
                            console.log('Notification: no notification found');
                        }
                    }
                }
            }

        } catch (error) {
            console.log('Notification: no notification placeholder ERROR', error);
        }
    }

    static async requestNotification(placeHolderElement: HTMLElement) {
        try {
            let url:string = location.href; 
            let requestUrl:string = `${UrlEnums.NotificationUrls.Notification}?url=${url}`; 
            let notificationHtml: string = await HttpRequest.get(requestUrl);
            placeHolderElement.innerHTML = notificationHtml;
        } catch (error) {
            console.log(`Notification: requestNotification failed requesting ajax for ${location.href} ERROR`, error);
            throw new Error();
        }
    }

    static hasNotificationHtml():boolean {
        let notificationElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Notification.Notification));
        if(notificationElement === null){
            return false;
        }else{
            return true;
        }
    }

    dispose(): void {
        try {
            this.removeCloseClickEventListener();
            this.removeChatButtonClickEventListener();
            this.removePhoneButtonClickEventListener();
            this.removeLiveChatLoadCompleteEventListener();
            this.removeShowOverlayEventListener();
            this.removeHideOverlayEventListener();
        } catch (error) {
            console.log('Notification: dispose ERROR', error);
        }
    }

    delete():void {
        try {
            console.log('Notification: delete');
            if ((<any>window)['notification']) {
                console.log('Notification: delete - has a notification');
                delete (<any>window)['notification'];
                console.log('Notification: delete - reference notification');
            }
            else {
                console.log('Notification: delete - notification Reference Not Found');
            }
        } catch (error) {
            console.log('Notification: delete ERROR', error);
        }
    }
}