import { PopOutController } from "../../shared/popOutController";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { StringEnums } from "../../shared/utilities/enums/stringEnums";
import { UtilityEnums } from "../../shared/utilities/enums/utillityEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { IProductDropDown, IProductSelector, ProductDropDown } from "./productDropDown";

const urlPaths = require('../../config/urlPaths.json');

export interface IProductSizeDropDown extends IProductDropDown, IProductSizeSelector {
    productSizeSelectorSetSelected: () => void;
}

export interface IProductSizeSelector extends IProductSelector {
    productSizeId: number | undefined;
}

export class ProductSizeDropDown extends ProductDropDown implements IProductSizeDropDown {

    productSizeId: number | undefined;
    private readonly _sizeSelectorClick;

    private readonly _sizePickerElement: HTMLElement;
    private readonly _sizePickerHandheldElement: HTMLElement;
    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);
        this._sizePickerElement = this.getSizePickerElement();
        this._sizePickerHandheldElement = this.getSizePickerHandheldElement();
        this.intialiseSizeProperties();
        //----- Event Listener Bindings Start -----//
        this._sizeSelectorClick = this.sizeSelectorClick.bind(this);
        //----- Event Listener Bindings End -----//

        //----- Initialise Event Listeners Start -----//
        this.attachSizeSelectorClickEvent();
        //----- Initialise Event Listeners End -----//
    }

    //------ Add Event Listeners Start -----//
    attachSizeSelectorClickEvent() {

        this._sizePickerElement.addEventListener('click', this._sizeSelectorClick);
        this._sizePickerHandheldElement.addEventListener('click', this._sizeSelectorClick);
    }
    //------ Add Event Listeners End -----//

    //------ Remove Event Listeners Start -----//

    removeSizeSelectorClickEvent() {

        this._sizePickerElement.removeEventListener('click', this._sizeSelectorClick);
        this._sizePickerHandheldElement.removeEventListener('click', this._sizeSelectorClick);
    }
    //------ Remove Event Listeners End -----//

    //------ Event Listener Methods Start -----//
    sizeSelectorClick(event: MouseEvent) {
        console.log('Product: productSelectorClick');
        let clickElement = event.target;

        this.ifClickElementIsOfProductSelectorTypeUpdateAttributesForOptionChange(clickElement);


    }

    ifClickElementIsOfProductSelectorTypeUpdateAttributesForOptionChange(clickElement: EventTarget): void {
        console.log('Product: ifClickElementIsOfProductSelectorTypeUpdateAttributesForOptionChange');
        if (clickElement instanceof HTMLElement && clickElement.hasAttribute(AttributeEnums.ProductSelector.ProductSelectorItem)) {
            this.updateDataAttributesForProductSizeChange(clickElement);
        }
    }

    productSizeSelectorSetSelected(): void {
        console.log('Product: productSizeSelectorSetSelected');
        let dataProductElement = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product));
        if (this.value !== undefined) {
            let dataProductSizeHandheldElement = dataProductElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.SizePicker.ProductSizePickerHandheld));
            let dataProductSizeHandheldSelectedElement = dataProductSizeHandheldElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelectorType, 'size') + HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelectorSizeId, this.value.toString()));
            dataProductSizeHandheldSelectedElement.setAttribute(AttributeEnums.ProductSelector.ProductSelectorIsSelected, 'true');
            dataProductSizeHandheldSelectedElement.classList.add(StringEnums.CssClass.LbSelected);
            if (dataProductSizeHandheldSelectedElement.hasAttribute(AttributeEnums.Product.ProductScrollIntoView)) {
                this.productSizeSelectorScrollToView(dataProductSizeHandheldElement, dataProductSizeHandheldSelectedElement);
            }
            let dataProductSizeElement = dataProductElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.SizePicker.ProductSizePicker));
            let dataProductSizeHSelectedElement = dataProductSizeElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelectorType, 'size') + HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelectorSizeId, this.value.toString()));
            dataProductSizeHSelectedElement.setAttribute(AttributeEnums.ProductSelector.ProductSelectorIsSelected, 'true');
            dataProductSizeHSelectedElement.classList.add(StringEnums.CssClass.LbSelected);
        }
    }

    productSizeSelectorScrollToView(dataProductSizeHandheldElement:Element, dataProductSizeHandheldSelectedElement:Element) {
        console.log('ProductSizeDropDown: productSizeSelectorScrollToView');
        if(dataProductSizeHandheldSelectedElement.parentElement.previousElementSibling !== null && dataProductSizeHandheldSelectedElement.parentElement.previousElementSibling !== undefined){
            let elementPositionInScroll = dataProductSizeHandheldSelectedElement.parentElement.previousElementSibling.children[0].getBoundingClientRect().right;
            let scrollOffset = dataProductSizeHandheldElement.querySelector('ul').scrollLeft;
            let scrollElementOffset = dataProductSizeHandheldElement.getBoundingClientRect().left;
            let newPosition = elementPositionInScroll + scrollOffset - scrollElementOffset;
            dataProductSizeHandheldElement.querySelector('ul').scrollTo(newPosition, 0);
        }
    }

    compareLineSizeAndReloadProductWithNewSize(productSelectorSizeId: string, productSelectorFullSizeUrl:string): void {
        console.log('ProductSizeDropDown: compareLineSizeAndReloadProductWithNewSize');
        let dataProductUrlElement = this.getProductContainerElement().querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.DropDowns.SelectedProductUrl));
        let dataProductUrl: string | null;
        if (dataProductUrlElement !== null && dataProductUrlElement !== undefined) {
            dataProductUrl = dataProductUrlElement.getAttribute(AttributeEnums.DropDowns.SelectedProductUrl);
        } else {
            dataProductUrl = null;
        }
        let hiddenInputFieldForSize = this.getProductContainerElement().querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelector) + HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductSizeId));
        let productLineId: string | null = hiddenInputFieldForSize.getAttribute(AttributeEnums.Product.ProductLineId);
        let productSizeId: string | null = hiddenInputFieldForSize.getAttribute(AttributeEnums.Product.ProductSizeId);

        let dataProductUrlNew: string = this.checkLineSizeThenCreateUpdatedProductUrl(productLineId, productSizeId, dataProductUrl, productSelectorSizeId, productSelectorFullSizeUrl);

        this.getProductData(dataProductUrlNew);
    }

    getProductData(dataProductUrlNew: string) {
        if (dataProductUrlNew.length > 0) {
            //LOAD NEW PRODUCT DATA
            let productType: string = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).getAttribute(AttributeEnums.Product.Product);
            if (productType === UtilityEnums.ProductTypeValue.BasketPopOut) {
                (<any>window).basketPopOut.initialiseBasketPopOutProduct(dataProductUrlNew);
            } else {
                //Add Product Code
                window.location.href = dataProductUrlNew;
            }
        }
    }

    checkLineSizeThenCreateUpdatedProductUrl(productLineId: string, productSizeId: string, dataProductUrl: string, productSelectorSizeId: string, productSelectorFullSizeUrl:string): string {

        console.log('ProductSizeDropDown: checkLineSizeThenCreateUpdatedProductUrl');
        let dataProductUrlNew: string = "";
        let productLineSizeNew: string = productLineId + "-" + productSelectorSizeId;
        if ((productSizeId === null) || (productSizeId === undefined) || (productSizeId === '')) {
            return this.replaceLineAndSizeIdInProductUrl(dataProductUrl, productSelectorFullSizeUrl);
        }

        if ((productLineId != null) && (productLineId.length > 0) && (productSizeId != null) && (productSizeId.length > 0)) {
            let productLineSize: string = productLineId + "-" + productSizeId;


            if (productLineSize != productLineSizeNew) {
                dataProductUrlNew = this.replaceLineAndSizeIdInProductUrl(dataProductUrl, productSelectorFullSizeUrl);
            }
            else {
                //Error needs Id and Family values that work with correct product
                this.closeOptionsPickerPopout();
            }
        }
        return dataProductUrlNew;
    }

    replaceLineAndSizeIdInProductUrl(url: string, nameUrl:string) {
        let startCharacterForName = url.lastIndexOf('product/') + 8;
        let stringBeforeName: string = url.substring(0, startCharacterForName);

        let endCharacterForIds = url.lastIndexOf('?');
        let stringAfterLineId: string = "";
        if (endCharacterForIds > 0) {
            stringAfterLineId = url.substring(endCharacterForIds, url.length);
        }

        let newUrl = stringBeforeName + nameUrl + stringAfterLineId;
        return newUrl;
    }

    updateDataAttributesForProductSizeChange(clickElement: HTMLElement): void {
        console.log('SelectorSize: updateDataAttributesForProductSizeChange');
        let productSelectorSizeId: string;
        let productSelectorFullSizeUrl: string;
        if (clickElement.hasAttribute(AttributeEnums.ProductSelector.ProductSelectorSizeId)) {
            productSelectorSizeId = clickElement.getAttribute(AttributeEnums.ProductSelector.ProductSelectorSizeId);
            productSelectorFullSizeUrl = clickElement.getAttribute(AttributeEnums.ProductSelector.ProductSelectorFullsizeUrl);
        } else {
            productSelectorSizeId = clickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelectorSizeId)).getAttribute(AttributeEnums.ProductSelector.ProductSelectorSizeId);
            productSelectorFullSizeUrl = clickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelectorFullsizeUrl)).getAttribute(AttributeEnums.ProductSelector.ProductSelectorFullsizeUrl);
        }
        this.compareLineSizeAndReloadProductWithNewSize(productSelectorSizeId, productSelectorFullSizeUrl);
    }
    //------ Event Listener Methods End -----//

    setProductSizeId(): number {
        let sizeIdElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductSizeId)) as HTMLElement;
        try {
            return parseInt(this.setPropertyFromDataAttribute(sizeIdElement, AttributeEnums.Product.ProductSizeId));
        } catch (error) {
            console.log('Critical Error: setProductSizeId Type Missmatch Error', sizeIdElement);
            throw 'Critical Error: setProductSizeId Type Missmatch Error';
        }
    }

    refreshProperties(): void {
        this.productSizeId = this.setProductSizeId();
        super.refreshProperties();
    }
    intialiseSizeProperties(): void {
        this.productSizeId = this.setProductSizeId();

    }
    updateValidationStateUsingValue():void {
        this.setSizeHandheldValidation();
        super.updateValidationStateUsingValue();
    }

    setSizeHandheldValidation(): void{
        try{
        let addToCartAttempted: boolean = (this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductAddToCartAttempted)).getAttribute(AttributeEnums.Product.ProductAddToCartAttempted) === 'true');
        let handheldElement = this.getSizePickerHandheldElement();
        if (this.value === undefined) {
            if (addToCartAttempted) {
                handheldElement.classList.remove(StringEnums.CssClass.LbSuccess);
                handheldElement.classList.add(StringEnums.CssClass.LbWarning);
            }
        } else {
            handheldElement.classList.remove(StringEnums.CssClass.LbWarning);
            handheldElement.classList.add(StringEnums.CssClass.LbSuccess);
        }
        } catch(error) {
            console.log("ProductSizeDropDown: setSizeHandheldValidation ERROR setting validation", error);
        }
    }

    getSizePickerElement(): HTMLElement {
        return this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.SizePicker.ProductSizePicker));
    }

    getSizePickerHandheldElement(): HTMLElement {
        return this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.SizePicker.ProductSizePickerHandheld));
    }
    //------ Custom Event Creation Start -----//
    //------ Custom Event Creation End -----//

    //------ Clean Up Processes Start -----//

    dispose(): void {
        this.removeSizeSelectorClickEvent();
        super.dispose();
    }
    //------ Clean Up Processes End -----//
}