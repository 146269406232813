import { IPromotionGtmDataObject } from "../../shared/googleTagManager/gtmDataObject";
import { GoogleTagManager } from "../../shared/googleTagManager/gtmService";
import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AnalyticEnums } from "../../shared/utilities/enums/analyticsEnums";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";

export interface IBannerPromotion extends IInstanceComponentBase {
    id: string;
    href: string;
    name: string;
    position: number;
    impressionLogged: boolean;
    htmlElement: HTMLElement;
    updateImpressionLogged: (isLogged: boolean) => void;
    getPromotionGtmDataObject: () => IPromotionGtmDataObject;
}

export class BannerPromotion extends InstanceComponentBase implements IBannerPromotion {
    readonly _handleBannerPromotionClickEvent;
    public id: string;
    public href: string;
    public name: string;
    public impressionLogged: boolean;

    constructor(public htmlElement: HTMLElement, public position: number) {
        super(htmlElement);
        this._handleBannerPromotionClickEvent = this.handleBannerPromotionClickEvent.bind(this);

        this.setHref();
        this.setName();
        this.setId();
        this.setImpressionLogged();

        this.attachBannerPromotionClickEventListener();
    }

    attachBannerPromotionClickEventListener(): void {
        console.log('BannerPromotion: attachBannerPromotionClickEventListener');
        this.htmlElement.addEventListener('click', this._handleBannerPromotionClickEvent);
    }
    removeBannerPromotionClickEventListener(): void {
        console.log('BannerPromotion: removeBannerPromotionClickEventListener');
        this.htmlElement.removeEventListener('click', this._handleBannerPromotionClickEvent);
    }

    setHref(): void {
        try {
            this.href = this.htmlElement.getAttribute('href');
        } catch (error) {
            console.log('BannerPromotion: setHref ERROR', error);
            this.dispose();
            throw new Error();
        }
    }

    setName(): void {
        try {
            this.name = this.htmlElement.getAttribute(AttributeEnums.BannerPromotion.BannerPromotionName);
        } catch (error) {
            console.log('BannerPromotion: setName ERROR', error);
            this.dispose();
            throw new Error();
        }
    }

    setId(): void {
        try {
            this.id = this.htmlElement.getAttribute(AttributeEnums.BannerPromotion.BannerPromotion);
        } catch (error) {
            console.log('BannerPromotion: setId ERROR', error);
            this.dispose();
            throw new Error();
        }
    }
    setImpressionLogged(): void {
        try {
            this.impressionLogged = (this.htmlElement.getAttribute(AnalyticEnums.InteractionState.ImpressionLogged) === 'true') ? true : false;
        } catch (error) {
            console.log('BannerPromotion: setImpressionLogged Not Set');
            this.impressionLogged = true;
        }
    }
    writeToImpressionLogged(isLogged: boolean): void {
        try {
            this.htmlElement.setAttribute(AnalyticEnums.InteractionState.ImpressionLogged, isLogged ? 'true' : 'false');
        } catch (error) {
            console.log('BannerPromotion: writeToImpressionLogged Not Set');
        }
    }

    updateImpressionLogged(isLogged: boolean): void {
        this.writeToImpressionLogged(isLogged);
        this.setImpressionLogged();
    }

    handleBannerPromotionClickEvent(): void {
        console.log('BannerPromotion: handleBannerPromotionClickEvent');
        let promotionGtmDataObject: IPromotionGtmDataObject = this.getPromotionGtmDataObject();
        GoogleTagManager.sendPromotionsGtmDataObjectAndNavigate(promotionGtmDataObject, this.href);
    }
    getPromotionGtmDataObject(): IPromotionGtmDataObject {
        let promotionGtmDataObject: IPromotionGtmDataObject = {
            id: this.id,
            url: this.href,
            name: this.name,
            position: this.position,
            creative: 'banner'
        };
        return promotionGtmDataObject;
    }

    dispose(): void {
        try {
            this.removeBannerPromotionClickEventListener();
        } catch (error) {
            console.log('BannerPromotion: dispose ERROR', error);
        }
    }

}