import { IAddToRemoveFromCartGtmDataObject, IProductViewGtmDataObject } from "../../shared/googleTagManager/gtmDataObject";
import { GoogleTagManager } from "../../shared/googleTagManager/gtmService";
import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { BasketService } from "../basket/basketService";

export interface IProductGtm extends IInstanceComponentBase {
    id: number;
    groupSku: string;
    sku: string;
    name: string;
    price: number;
    brand: string;
    variant: string;
    category: string;
    getAddToRemoveFromCartGtmDataObject: () => IAddToRemoveFromCartGtmDataObject;
}

export class ProductGtm extends InstanceComponentBase implements IProductGtm {
    public id: number;
    public groupSku: string;
    public sku: string;
    public name: string;
    public price: number;
    public brand: string;
    public variant: string;
    public category: string;

    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);

        this.setGtmValues();
        this.sendGtmProductView();
    }

    setGtmValues(): void {
        try {
            this.id = +this.htmlElement.getAttribute(AttributeEnums.ProductGtm.GtmProductId);
            this.groupSku = this.htmlElement.getAttribute(AttributeEnums.ProductGtm.GtmProductGroupSku);
            this.sku = this.htmlElement.getAttribute(AttributeEnums.ProductGtm.GtmProductSku);
            this.name = this.htmlElement.getAttribute(AttributeEnums.ProductGtm.GtmProductName);
            this.price = +this.htmlElement.getAttribute(AttributeEnums.ProductGtm.GtmProductPrice);
            this.brand = this.htmlElement.getAttribute(AttributeEnums.ProductGtm.GtmProductBrand);
            this.variant = this.htmlElement.getAttribute(AttributeEnums.ProductGtm.GtmProductSize);
            this.category = this.htmlElement.getAttribute(AttributeEnums.ProductGtm.GtmProductCategory);
        } catch (error) {
            console.log('ProductGtm: setGtmValues ERROR', error);
        }
    }

    sendGtmProductView(): void {
        console.log('ProductGtm: sendGtmProductView');
        let productViewGtmDataObject: IProductViewGtmDataObject = this.getProductViewGtmDataObject();
        GoogleTagManager.sendProductViewGtmDataObject(productViewGtmDataObject);
    }

    getProductViewGtmDataObject(): IProductViewGtmDataObject {
        let productGtmDataObject: IProductViewGtmDataObject = {
            id: this.id,
            groupSku: this.groupSku,
            sku: this.sku,
            name: this.name,
            price: this.price,
            brand: this.brand,
            variant: this.variant,
            category: this.category,
            quantity: 1,

        };
        return productGtmDataObject;
    }

    getAddToRemoveFromCartGtmDataObject(): IAddToRemoveFromCartGtmDataObject {
        let dataObject = BasketService.mapAddToRemoveFromCartGtmDataObject(this.id, this.name, this.price, this.brand, this.variant, this.category, 1);

        return dataObject;
    }

    dispose(): void {

    }

}