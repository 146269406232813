import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { Breadcrumb } from "./productListingPage";
const urlPaths = require('../../config/urlPaths.json');
export interface IProductListingPageBreadcrumb extends IInstanceComponentBase {
    updateBreadcrumb: (breadcrumbs: Breadcrumb[]) => void;
}

export class ProductListingPageBreadcrumb extends InstanceComponentBase implements IProductListingPageBreadcrumb {

    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);

    }

    updateBreadcrumb(breadcrumbs: Breadcrumb[]) {
        var breadcrumbElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Breadcrumb.Breadcrumb));
        breadcrumbElement.innerHTML =  this.createBreadcrumbsHtml(breadcrumbs);
    }

    public createBreadcrumbsHtml(breadcrumbs: Breadcrumb[]) : string {
        let breadCrumbsHtml :string = '';

        //breadcrumbs.forEach((breadcrumb: Breadcrumb
        //    ) => {
            
        //        breadCrumbsHtml += this.createBreadcrumbHtml(breadcrumb);
        //});


        breadcrumbs.forEach((breadcrumb: Breadcrumb, index: number, breadcrumbArray: Array<Breadcrumb>
        ) => {

            breadCrumbsHtml += this.createBreadcrumbHtml(breadcrumb, index, breadcrumbArray);

        });


        return breadCrumbsHtml;
    }

    //createBreadcrumbHtml(breadcrumb: Breadcrumb): string{
    //    let breadcrumbHtml = `<li itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem"><a itemscope="" itemtype="https://schema.org/WebPage" itemprop="item" itemid="${urlPaths.websiteUrl}${breadcrumb.url}" href="${breadcrumb.url}"><span itemprop="name">${breadcrumb.name}</span></a><meta itemprop="position" content="${breadcrumb.position}"></li>`;
    //    return breadcrumbHtml;
    //}

    createBreadcrumbHtml(breadcrumb: Breadcrumb, index: number, breadcrumbArray: Array<Breadcrumb>): string {
        let breadcrumbHtml: string;
        if (index + 1 < breadcrumbArray.length) {
            breadcrumbHtml = `<li itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem"><a itemscope="" itemtype="https://schema.org/WebPage" itemprop="item" itemid="${urlPaths.websiteUrl}${breadcrumb.url}" href="${breadcrumb.url}"><span itemprop="name">${breadcrumb.name}</span></a><meta itemprop="position" content="${breadcrumb.position}"></li>`;
        } else {
            breadcrumbHtml = `<li itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem"><span itemprop="name">${breadcrumb.name}</span><meta itemprop="position" content="${breadcrumb.position}"></li>`;

        }
        return breadcrumbHtml;
    }

    dispose(): void {
        
    }
}