import { MegaMenu } from './megaMenu';
import { Overlay } from './overlay';
import { AttributeEnums } from './utilities/enums/attributeEnums';
import { HelperUtils } from './utilities/helperUtils';

export function initialiseMegaMenu() {
    let doesObjectExistOnWindow = () => { return typeof (<any>window).megaMenu }
    if (doesObjectExistOnWindow()) {

        let htmlElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.MegaMenu.MegaMenu)) as HTMLElement;
        if (htmlElement) {
            createMegaMenu(htmlElement);
        }

        function createMegaMenu(htmlElement: HTMLElement) {
            let megaMenu = new MegaMenu(htmlElement);
            (<any>window).megaMenu = megaMenu;
        }
    }
}