import { ProductListingPage } from "../components/productListingPage/productListingPage";
import { IPopOutController, IPopOutState } from "./popOutController";
import { ResponsiveState } from "./responsiveState";
import { AttributeEnums } from "./utilities/enums/attributeEnums";
import { CustomEventEnums } from "./utilities/enums/customEventEnums";
import { StringEnums } from "./utilities/enums/stringEnums";
import { UtilityEnums } from "./utilities/enums/utillityEnums";
import { TimeUtils } from "./utilities/timeUtils";
const urlPaths = require('../config/urlPaths.json');
export interface IHistoryController {
    pushProductListingPageHistoryToStack: (pageUrl: string, title: string) => void;   
}

export interface IHistoryState {
    title: string;
    type: UtilityEnums.HistoryStateType;
}

export interface IHistoryStatePopOut extends IHistoryState {
    popOutState: IPopOutState;
}

export interface IHistoryStateProductListingPage extends IHistoryState {
    querySelector: string;
}

export class HistoryController {

    private readonly popoutController: IPopOutController;
    private readonly _handlePopState;


    constructor() {
        this.popoutController = (<any>window).popOutController;

        this._handlePopState = this.handlePopState.bind(this);
        this.attachHistoryControllerPopStateEventListener();

    }
    attachHistoryControllerPopStateEventListener(): void {
        console.log('HistoryController: attachHistoryControllerPopStateEventListener');
        (<any>window).addEventListener("popstate", this._handlePopState);
    }

    removeHistoryControllerPopStateEventListener(): void {
        console.log('HistoryController: removeHistoryControllerPopStateEventListener');
        (<any>window).removeEventListener('popstate', this._handlePopState);
    }


    handleBeforeUnload() {

        console.log('HistoryController: handleBeforeUnload hashchange FIRED');
        
        HistoryController.removeAllPopOutHistory();
    }


    handlePopState(e: PopStateEvent) {
        console.log('HistoryController: handlePopState  POPPED Event Started');

        let state = e.state as IHistoryState;
        if (state !== null && state !== undefined) {

            if (state.type === UtilityEnums.HistoryStateType.PopOut) {
                if (location.href.includes('/browse')) {
                    console.log('HistoryController: handlePopState - Pop Out PLP ', state.title);
                    let historyStatePopOut = e.state as IHistoryStatePopOut;
                    this.handlePopOutStateChangeForProductListingPage(historyStatePopOut.popOutState);
                } else {
                    console.log('HistoryController: handlePopState - Pop Out', state.title);
                    let historyStatePopOut = e.state as IHistoryStatePopOut;
                    this.handlePopOutStateChange(historyStatePopOut.popOutState);
                }
            } else if (state.type === UtilityEnums.HistoryStateType.ProductListingPageUpdate && ProductListingPage.hasDifferentQuerySelector(location.href.split('/browse/')[1])) {
                let historyStateProductListingPage = e.state as IHistoryStateProductListingPage;
                historyStateProductListingPage.querySelector = historyStateProductListingPage.querySelector.replace('browse/', '');
                    console.log(`HistoryController: handlePopState - PLP ${historyStateProductListingPage.querySelector}`);

                    this.handleProductListingPageStateChange(historyStateProductListingPage.querySelector);

            } else if (state.type === UtilityEnums.HistoryStateType.BasketUpdate) {
                this.handleBasketUpdated();
            } else {
                console.log(`HistoryController: handlePopState - location.href ${location.href}`);
              
                this.handleOther();
            }

            document.title = state.title;
        } else if (location.href.includes('/browse/') && ProductListingPage.hasDifferentQuerySelector(location.href.split('/browse/')[1])) {
            let querySelector = location.href.split('/browse/')[1];
            console.log(`HistoryController: handlePopState - PLP Browse(Initial Page) ${querySelector}`);
            this.handleProductListingPageStateChange(querySelector);
        } else if (location.href.includes('/browse') && !location.href.includes('/browse/') && !location.href.includes('#')) {
            console.log(`HistoryController: handlePopState - PLP Browse(Initial Page) plp home (page--1)`);
            this.handleProductListingPageStateChange('page--1');
        }
        else {
            console.log(`HistoryController: handlePopState - Other`);
            this.handleOther();
        }
    }

    handleOther() {
        console.log('HistoryController: handleOther');
        this.popoutController.closeAllMenus();
    }

    handlePopOutStateChange(popOutState: IPopOutState) {
        console.log('HistoryController: handlePopOutStateChange');
        this.popoutController.openByIdAndFamilyNoHistoryPush(popOutState.popOutId, popOutState.popOutFamily);
    }

    handlePopOutStateChangeForProductListingPage(popOutState: IPopOutState) {
        console.log('HistoryController: handlePopOutStateChange');
        this.popoutController.openByIdAndFamilyNoHistoryPush(popOutState.popOutId, popOutState.popOutFamily);
    }

    handleProductListingPageStateChange(querySelector: string) {
        console.log('HistoryController: handlePopOutStateChange');
        document.dispatchEvent(HistoryController.productListingPageHistoryBackCustomEvent(querySelector));
    }

    handleBasketUpdated() {
        console.log('HistoryController: handleBasketUpdated');
        location.reload();
    }



    static productListingPageHistoryBackCustomEvent(querySelector: string) {
        console.log(`ProductListingPage: productListingPageHistoryBackCustomEvent querySelector: ${querySelector}`);
        return new CustomEvent(CustomEventEnums.ProductListingPageHistoryBack, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                querySelector: querySelector
            }
        });
    }

    static pushPopOutHistoryToStack(popOutFamily: string, popOutId: string, pageUrl: string) {
        console.log(`HistoryController: pushPopOutHistoryToStack HISTORY PUSHED - ${pageUrl}`);
        let popoutHistoryLength: number = HistoryController.getPopoutHistoryLength();
        let urlWithoutHash: string = pageUrl.split('#')[0];
        let urlWithHash: string = `${urlWithoutHash}#${popOutFamily}${popOutId}`;
        let popOutState: IPopOutState = { popOutFamily: popOutFamily, popOutId: popOutId, popOutHistoryLength: popoutHistoryLength + 1 } as IPopOutState;
        let historyStatePopOut: IHistoryStatePopOut = { title: document.title, type: UtilityEnums.HistoryStateType.PopOut, popOutState: popOutState } as IHistoryStatePopOut;

        history.pushState(historyStatePopOut, document.title, urlWithHash);

    }

    static getPopoutHistoryLength(): number {
        console.log('HistoryController: getPopoutHistoryLength');
        try {
            let state = history.state as IHistoryState;
            if (state !== null && state !== undefined) {
                if (state.type === UtilityEnums.HistoryStateType.PopOut) {
                    let historyStatePopOut = history.state as IHistoryStatePopOut;
                    return historyStatePopOut.popOutState.popOutHistoryLength;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        } catch (error) {
            console.log('HistoryController: getPopoutHistoryLength ERROR', error);

            return 0;
        }

    }

    async pushProductListingPageHistoryToStack(pageUrl: string, title: string) {
        console.log(`HistoryController: pushProductListingPageHistoryToStack Start - pageUrl ${pageUrl} title ${title}`);
        this.removeHistoryControllerPopStateEventListener();
        this.popoutController.active =false;
        let currentHistoryHasPopout: boolean = (HistoryController.getPopoutHistoryLength() > 0) ? true : false;
        if (currentHistoryHasPopout) {
            HistoryController.removeAllPopOutHistory();
            console.log('HistoryController: pushProductListingPageHistoryToStack - POP OUT HISTORY REMOVED');

        }

        let historyState: IHistoryStateProductListingPage;
        let currentUrl = window.location.href;
        if (currentUrl === urlPaths.websiteUrl + '/browse') {
            pageUrl = 'browse/' + pageUrl;
            
        } 
        historyState = { title: title, type: UtilityEnums.HistoryStateType.ProductListingPageUpdate, querySelector: pageUrl } as IHistoryStateProductListingPage;
        let querySelector: string = historyState.querySelector;


        if (currentHistoryHasPopout) {
            //await this.pushPopOutHistoryToStackOnPollIsTrue(querySelector, pageUrl);
            await this.pushStateForProductListingPageOnPollIsTrue(historyState, title, pageUrl, querySelector);
        } else {

            history.pushState(historyState, title, pageUrl);
           
            document.title = title;
            this.attachHistoryControllerPopStateEventListener();
            this.popoutController.active = true;

        }
        console.log(`HistoryController: pushProductListingPageHistoryToStack - finished`);
        // if (currentHistoryHasPopout) {
        //     setTimeout(() => {
        //         HistoryController.pushPopOutHistoryToStack(AttributeEnums.ProductListingPageOptions.ProductListingPageOptionsPopOutValue, '1', `${pageUrl}#${AttributeEnums.ProductListingPageOptions.ProductListingPageOptionsPopOutValue}1`, `${title}#${AttributeEnums.ProductListingPageOptions.ProductListingPageOptionsPopOutValue}1`);
        //     },1000);
        // }




    }

    async pushStateForProductListingPageOnPollIsTrue(historyState: IHistoryStateProductListingPage, title: string, pageUrl: string, querySelector: string) {
        let maxTime = 0
        let that = this;
        let intervalId1 = await setInterval(async function () {

            if (history.state === null || UtilityEnums.HistoryStateType.ProductListingPageUpdate === history.state.type) {

                history.pushState(historyState, title, pageUrl);
                document.title = title;
                console.log(`HistoryController: pushProductListingPageHistoryToStack - PLP HISTORY PUSHED AFTER POPOUT STATE REMOVED ${pageUrl}`);
                //await this.pushPopOutHistoryToStackOnPollIsTrue(querySelector, pageUrl);
                await (async function () {
                    let maxTime = 0

                    let intervalId2 = await setInterval(async function () {

                        if (querySelector === history.state.querySelector) {
                            let popOutUrl = `${pageUrl}#${AttributeEnums.ProductListingPageOptions.ProductListingPageOptionsPopOutValue}1`
                            HistoryController.pushPopOutHistoryToStack(AttributeEnums.ProductListingPageOptions.ProductListingPageOptionsPopOutValue, '1', popOutUrl);
                            console.log(`HistoryController: pushProductListingPageHistoryToStack - POP OUT HISTORY PUSHED AFTER POPOUT STATE REMOVED - ${popOutUrl}`);
                            that.attachHistoryControllerPopStateEventListener();
                            that.popoutController.active = true;
                            clearInterval(intervalId2);
                        }
                        if (maxTime > 1000) {
                            clearInterval(intervalId2);
                            that.attachHistoryControllerPopStateEventListener();
                            that.popoutController.active = true;
                        }
                        maxTime += 100;
                    }, 100);
                })();
                clearInterval(intervalId1);
            }

            if (maxTime > 1000) {
                clearInterval(intervalId1);
                that.attachHistoryControllerPopStateEventListener();
                that.popoutController.active = true;
            }

            maxTime += 100;
        }, 100);
    }

    async pushPopOutHistoryToStackOnPollIsTrue(querySelector: string, pageUrl: string) {
        let timeCounter = 0

        let intervalId2 = setInterval(async function () {

            if (querySelector === history.state.querySelector) {
                let popOutUrl = `${pageUrl}#${AttributeEnums.ProductListingPageOptions.ProductListingPageOptionsPopOutValue}1`
                HistoryController.pushPopOutHistoryToStack(AttributeEnums.ProductListingPageOptions.ProductListingPageOptionsPopOutValue, '1', popOutUrl);
                console.log(`HistoryController: pushProductListingPageHistoryToStack - POP OUT HISTORY PUSHED AFTER POPOUT STATE REMOVED - ${popOutUrl}`);
                clearInterval(intervalId2);
            }
            if (timeCounter > 1000) {
                clearInterval(intervalId2);
            }
            timeCounter += 100;
        }, 100);
    }

    static replaceProductListingPageHistoryOnStack(pageUrl: string, title: string) {
        let currentHistoryIsPopout: boolean = (this.getPopoutHistoryLength() > 0) ? true : false;
        if (currentHistoryIsPopout) {

            HistoryController.removeAllPopOutHistory();

        }
        console.log('HistoryController: replaceProductListingPageHistoryOnStack HISTORY REPLACED');
        let historyState: IHistoryState = { title: title, type: UtilityEnums.HistoryStateType.ProductListingPageUpdate } as IHistoryState;

        history.replaceState(historyState, title, pageUrl);
        document.title = title;

    }



    static replaceBasketHistoryToStack(pageUrl: string) {
        let title: string = 'Basket Update | Land of Beds';
        let historyState: IHistoryState = { title: title, type: UtilityEnums.HistoryStateType.BasketUpdate } as IHistoryState;

        history.replaceState(historyState, title, pageUrl);
        document.title = title;
    }

    static async removeAllPopOutHistory() {

        console.log('HistoryController: removeAllPopOutHistory');
        if (history.state !== null && history.state.type !== null && history.state.type === UtilityEnums.HistoryStateType.PopOut) {

            let stepsToGoBack: number = 0 - HistoryController.getPopoutHistoryLength();
            history.go(stepsToGoBack);
            console.log(`HistoryController: removeAllPopOutHistory - History.go(${stepsToGoBack}) Called`);
        }

    }


    static async historyBackOneIfPopOut() {
        let state = history.state as IHistoryState
        if (state.type === UtilityEnums.HistoryStateType.PopOut) {
            console.log('HistoryController: historyBackOneIfPopOut - History.Back() Called');
            history.back();
        }
    }

    static initialiseHistoryController() {
        let historyController = new HistoryController();
        (<any>window).historyController = historyController;
    }

    static getHistoryControllerClassReference(): IHistoryController {
        return ((<any>window).historyController as IHistoryController);
    }

    dispose() {
        this.removeHistoryControllerPopStateEventListener();
    }
}