export namespace OneTrustEnums {

    export enum OneTrustCookie {
        OneTrustCookie = 'OptanonConsent'
    }
    export enum CategoryConsentTrue {
        StrictlyNecessary = 'C0001:1',
        Performance = 'C0002:1',
        Functional = 'C0003:1',
        Targeting = 'C0004:1',
        SocialMedia = 'C0005:1'
    }
    export enum Events {
        OTConsentApplied = 'OTConsentApplied',
        OneTrustPCLoaded = 'OneTrustPCLoaded',
        ConsentOnetrust = 'consent.onetrust',
        OneTrustGroupsUpdated = 'OneTrustGroupsUpdated'
    }
}