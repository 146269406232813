import { IMenuClickGtmDataObject } from "./googleTagManager/gtmDataObject";
import { GoogleTagManager } from "./googleTagManager/gtmService";
import { IInstanceComponentBase, InstanceComponentBase } from "./instanceComponentBase";
import { AttributeEnums } from "./utilities/enums/attributeEnums";

export interface IMegaMenuItem extends IInstanceComponentBase {
    name: string;
    href: string;
}

export class MegaMenuItem extends InstanceComponentBase implements IMegaMenuItem {
    public name: string;
    public href: string;

    private readonly _handleClick;
    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);

        this._handleClick = this.handleClick.bind(this);

        this.setName();
        this.setHref();

        this.attachClickEventListener();
    }

    attachClickEventListener() {
        this.htmlElement.addEventListener('click', this._handleClick);
    }

    removeClickEventListener() {
        this.htmlElement.removeEventListener('click', this._handleClick);
    }

    handleClick(): void {
        try {
            let dataObject: IMenuClickGtmDataObject = {
                name: this.name,
                url: this.href,
                id: ''
            } as IMenuClickGtmDataObject;
            GoogleTagManager.sendMenuClickGtmDataObjectAndNavigate(dataObject, this.href);

        } catch (error) {
            document.location = this.href;
        }

    }


    setName(): void {
        this.name = this.htmlElement.getAttribute(AttributeEnums.MegaMenuGtm.GtmMegaMenuName);
    }

    setHref(): void {
        this.href = this.htmlElement.getAttribute('href');
    }

    dispose(): void {
        this.removeClickEventListener();
    }
}