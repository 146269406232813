import { AjaxGetAndReplaceHtml, AjaxGetAndReplaceHtmlAndOuterElement } from "../../shared/ajaxGetAndReplaceHtml";
import { initialise } from "../../shared/initialiser";
import { InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { UrlEnums } from "../../shared/utilities/enums/urlEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { LoadingTemplates } from "../loading/loadingTemplates";
const urlPaths = require('../../config/urlPaths.json');

export interface IProductFinancePanel {
    dispose: () => Promise<void>;
}

export class ProductFinancePanel extends InstanceComponentBase implements IProductFinancePanel {
    private readonly _recalculateFinancePanelFromCustomEvent;

    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);

        this._recalculateFinancePanelFromCustomEvent = this.recalculateFinancePanelFromCustomEvent.bind(this);

        this.attachProductPriceUpdatedEventListener();
    }


    async attachProductPriceUpdatedEventListener() {
        this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).addEventListener(CustomEventEnums.ProductPriceUpdated, this._recalculateFinancePanelFromCustomEvent);
    }

    removeProductPriceUpdatedEventListener() {
        this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).removeEventListener(CustomEventEnums.ProductPriceUpdated, this._recalculateFinancePanelFromCustomEvent);
    }

    async recalculateProductFinancePanel(price: number) {
        try {
            console.log('ProductFinancePanel: recalculateProductFinancePanel Started');
            console.log('ProductFinancePanel: recalculateProductFinancePanel - dataProductFinancePanelElement ', this.htmlElement);
            if (this.htmlElement !== null) {
                this.htmlElement.innerHTML = LoadingTemplates.productLoadingTemplate;
                let url: string = urlPaths.websiteUrl + `${UrlEnums.PartialUrls.ProductFinancePanel}${price}`;
                console.log('ProductFinancePanel: recalculateProductFinancePanel URL: ' + url);
                await AjaxGetAndReplaceHtml(this.htmlElement, url);
                console.log('ProductFinancePanel: recalculateProductFinancePanel AjaxGetAndReplaceHtml Finished');

                initialise();
            }
        } catch (error) {
            console.log('ProductFinancePanel: recalculateProductFinancePanel', error);
        }
    }

    recalculateFinancePanelFromCustomEvent(event: CustomEvent): void {
        console.log('ProductFinancePanel: recalculateFinancePanelFromCustomEvent - Beginning');
        let eventDetail = event.detail;
        let type: string = eventDetail.type as string;
        let totalPrice: number = +eventDetail.totalPrice;
        if (type === this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).getAttribute(AttributeEnums.Product.Product)) {
            console.log('ProductFinancePanel: recalculateFinancePanelFromCustomEvent - totalPrice' + totalPrice);
            this.recalculateProductFinancePanel(totalPrice);
        }
    }

    async dispose(): Promise<void> {
        this.removeProductPriceUpdatedEventListener();
    }

    delete(): void {
    }
}