import { InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { UtilityEnums } from "../../shared/utilities/enums/utillityEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { CarouselInfiniteLoad, ICarouselInfiniteLoad } from "../carousel/carouselInfiniteLoad";
import { CarouselTileLoad, ICarouselTileLoad } from "../carousel/carouselTileLoad";

export interface IProductCarouselOrchestrator {
}

export class ProductCarouselOrchestrator extends InstanceComponentBase implements IProductCarouselOrchestrator {

    private readonly _productCarouselOneScrollInteraction;
    private readonly _productCarouselTwoScrollInteraction;
    private readonly _productCarouselThreeScrollInteraction;
    private readonly _productCarouselFourScrollInteraction;
    private readonly _productCarouselFiveScrollInteraction;
    private readonly _productCarouselSixScrollInteraction;
    private readonly _productCarouselSevenScrollInteraction;

    private readonly offsetTollerance: number = 800;

    private productCarouselOne: ICarouselInfiniteLoad;
    private productCarouselTwo: ICarouselInfiniteLoad;
    private productCarouselThree: ICarouselInfiniteLoad;
    private productCarouselFour: ICarouselInfiniteLoad;
    private productCarouselFive: ICarouselInfiniteLoad;
    private productCarouselSix: ICarouselInfiniteLoad;
    private productCarouselSeven: ICarouselTileLoad;

    constructor(public htmlElement: HTMLElement) {
        console.log('ProductCarouselOrchestrator instantiate')
        super(htmlElement);

        this._productCarouselOneScrollInteraction = this.productCarouselOneScrollInteraction.bind(this);
        this._productCarouselTwoScrollInteraction = this.productCarouselTwoScrollInteraction.bind(this);
        this._productCarouselThreeScrollInteraction = this.productCarouselThreeScrollInteraction.bind(this);
        this._productCarouselFourScrollInteraction = this.productCarouselFourScrollInteraction.bind(this);
        this._productCarouselFiveScrollInteraction = this.productCarouselFiveScrollInteraction.bind(this);
        this._productCarouselSixScrollInteraction = this.productCarouselSixScrollInteraction.bind(this);
        this._productCarouselSevenScrollInteraction = this.productCarouselSevenScrollInteraction.bind(this);

        this.attachProductCarouselOneScrollEventListener();
        this.attachProductCarouselTwoScrollEventListener();
        this.attachProductCarouselThreeScrollEventListener();
        this.attachProductCarouselFourScrollEventListener();
        this.attachProductCarouselFiveScrollEventListener();
        this.attachProductCarouselSixScrollEventListener();
        this.attachProductCarouselSevenScrollEventListener();
    }

    attachProductCarouselOneScrollEventListener(): void {
        let productCarouselOneElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselInfiniteLoad.CarouselInfiniteLoad, UtilityEnums.ProductCarouselNames.ProductCarouselOne));

        if (productCarouselOneElement !== null) {
            document.addEventListener('scroll', this._productCarouselOneScrollInteraction);
        }
    }
    attachProductCarouselTwoScrollEventListener(): void {
        let productCarouselTwoElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselInfiniteLoad.CarouselInfiniteLoad, UtilityEnums.ProductCarouselNames.ProductCarouselTwo));

        if (productCarouselTwoElement !== null) {
            document.addEventListener('scroll', this._productCarouselTwoScrollInteraction);
        }
    }
    attachProductCarouselThreeScrollEventListener(): void {
        let productCarouselThreeElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselInfiniteLoad.CarouselInfiniteLoad, UtilityEnums.ProductCarouselNames.ProductCarouselThree));

        if (productCarouselThreeElement !== null) {
            document.addEventListener('scroll', this._productCarouselThreeScrollInteraction);
        }
    }
    attachProductCarouselFourScrollEventListener(): void {
        let productCarouselFourElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselInfiniteLoad.CarouselInfiniteLoad, UtilityEnums.ProductCarouselNames.ProductCarouselFour));

        if (productCarouselFourElement !== null) {
            document.addEventListener('scroll', this._productCarouselFourScrollInteraction);
        }
    }

    attachProductCarouselFiveScrollEventListener(): void {
        let productCarouselFiveElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselInfiniteLoad.CarouselInfiniteLoad, UtilityEnums.ProductCarouselNames.ProductCarouselFive));

        if (productCarouselFiveElement !== null) {
            document.addEventListener('scroll', this._productCarouselFiveScrollInteraction);
        }
    }
    attachProductCarouselSixScrollEventListener(): void {
        let productCarouselFiveElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselInfiniteLoad.CarouselInfiniteLoad, UtilityEnums.ProductCarouselNames.ProductCarouselSix));

        if (productCarouselFiveElement !== null) {
            document.addEventListener('scroll', this._productCarouselSixScrollInteraction);
        }
    }
    attachProductCarouselSevenScrollEventListener(): void {
        let productCarouselSixElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselTileLoad.CarouselTileLoad, UtilityEnums.ProductCarouselNames.ProductCarouselSeven));

        if (productCarouselSixElement !== null) {
            document.addEventListener('scroll', this._productCarouselSevenScrollInteraction);
        }
    }

    removeProductCarouselOneScrollEventListener(): void {
        document.removeEventListener('scroll', this._productCarouselOneScrollInteraction);
    }
    removeProductCarouselTwoScrollEventListener(): void {
        document.removeEventListener('scroll', this._productCarouselTwoScrollInteraction);
    }
    removeProductCarouselThreeScrollEventListener(): void {
        document.removeEventListener('scroll', this._productCarouselThreeScrollInteraction);
    }
    removeProductCarouselFourScrollEventListener(): void {
        document.removeEventListener('scroll', this._productCarouselFourScrollInteraction);
    }
    removeProductCarouselFiveScrollEventListener(): void {
        document.removeEventListener('scroll', this._productCarouselFiveScrollInteraction);
    }
    removeProductCarouselSixScrollEventListener(): void {
        document.removeEventListener('scroll', this._productCarouselSixScrollInteraction);
    }
    removeProductCarouselSevenScrollEventListener(): void {
        document.removeEventListener('scroll', this._productCarouselSevenScrollInteraction);
    }

    productCarouselOneScrollInteraction(): void {
        try {
            let productCarouselOneElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselInfiniteLoad.CarouselInfiniteLoad, UtilityEnums.ProductCarouselNames.ProductCarouselOne));

            if ((this.getElementTop(productCarouselOneElement) < this.getWindowTollerance())) {
                this.productCarouselOneFactory(productCarouselOneElement);
                this.removeProductCarouselOneScrollEventListener();
            }

        } catch (error) {
            console.log('ProductCarouselOrchestrator: productCarouselOneScrollInteraction ERROR', error);
        }
    }
    productCarouselTwoScrollInteraction(): void {
        try {
            let productCarouselTwoElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselInfiniteLoad.CarouselInfiniteLoad, UtilityEnums.ProductCarouselNames.ProductCarouselTwo));

            if ((this.getElementTop(productCarouselTwoElement) < this.getWindowTollerance())) {
                this.productCarouselTwoFactory(productCarouselTwoElement);
                this.removeProductCarouselTwoScrollEventListener();
            }

        } catch (error) {
            console.log('ProductCarouselOrchestrator: productCarouselTwoScrollInteraction ERROR', error);
        }
    }
    productCarouselThreeScrollInteraction(): void {
        try {
            let productCarouselThreeElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselInfiniteLoad.CarouselInfiniteLoad, UtilityEnums.ProductCarouselNames.ProductCarouselThree));

            if ((this.getElementTop(productCarouselThreeElement) < this.getWindowTollerance())) {
                this.productCarouselThreeFactory(productCarouselThreeElement);
                this.removeProductCarouselThreeScrollEventListener();
            }

        } catch (error) {
            console.log('ProductCarouselOrchestrator: productCarouselThreeScrollInteraction ERROR', error);
        }
    }
    productCarouselFourScrollInteraction(): void {
        try {
            let productCarouselFourElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselInfiniteLoad.CarouselInfiniteLoad, UtilityEnums.ProductCarouselNames.ProductCarouselFour));

            if ((this.getElementTop(productCarouselFourElement) < this.getWindowTollerance())) {
                this.productCarouselFourFactory(productCarouselFourElement);
                this.removeProductCarouselFourScrollEventListener();
            }

        } catch (error) {
            console.log('ProductCarouselOrchestrator: productCarouselFourScrollInteraction ERROR', error);
        }
    }
    productCarouselFiveScrollInteraction(): void {
        try {
            let productCarouselFiveElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselInfiniteLoad.CarouselInfiniteLoad, UtilityEnums.ProductCarouselNames.ProductCarouselFive));

            if ((this.getElementTop(productCarouselFiveElement) < this.getWindowTollerance())) {
                this.productCarouselFiveFactory(productCarouselFiveElement);
                this.removeProductCarouselFiveScrollEventListener();
            }

        } catch (error) {
            console.log('ProductCarouselOrchestrator: productCarouselFiveScrollInteraction ERROR', error);
        }
    }
    productCarouselSixScrollInteraction(): void {
        try {
            let productCarouselSixElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselInfiniteLoad.CarouselInfiniteLoad, UtilityEnums.ProductCarouselNames.ProductCarouselSix));

            if ((this.getElementTop(productCarouselSixElement) < this.getWindowTollerance())) {
                this.productCarouselSixFactory(productCarouselSixElement);
                this.removeProductCarouselSixScrollEventListener();
            }

        } catch (error) {
            console.log('ProductCarouselOrchestrator: productCarouselSixScrollInteraction ERROR', error);
        }
    }
    productCarouselSevenScrollInteraction(): void {
        try {
            let productCarouselSevenElement: HTMLElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CarouselTileLoad.CarouselTileLoad, UtilityEnums.ProductCarouselNames.ProductCarouselSeven));

            if ((this.getElementTop(productCarouselSevenElement) < this.getWindowTollerance())) {
                this.productCarouselSevenFactory(productCarouselSevenElement);
                this.removeProductCarouselSevenScrollEventListener();
            }

        } catch (error) {
            console.log('ProductCarouselOrchestrator: productCarouselSevenScrollInteraction ERROR', error);
        }
    }

    productCarouselOneFactory(productCarouselElement: HTMLElement): void {
        console.log('ProductCarouselOrchestrator: productCarouselOneFactory');

        try {
            this.productCarouselOne = new CarouselInfiniteLoad(productCarouselElement, productCarouselElement.getAttribute(AttributeEnums.CarouselInfiniteLoad.Url), true);
        } catch (error) {
            console.log('ProductCarouselOrchestrator: productCarouselOneFactory ERROR', error);
        }
    }

    productCarouselTwoFactory(productCarouselElement: HTMLElement): void {
        console.log('ProductCarouselOrchestrator: productCarouselTwoFactory');

        try {
            this.productCarouselTwo = new CarouselInfiniteLoad(productCarouselElement, productCarouselElement.getAttribute(AttributeEnums.CarouselInfiniteLoad.Url), true);
        } catch (error) {
            console.log('ProductCarouselOrchestrator: productCarouselTwoFactory ERROR', error);
        }
    }

    productCarouselThreeFactory(productCarouselElement: HTMLElement): void {
        console.log('ProductCarouselOrchestrator: productCarouselThreeFactory');

        try {
            this.productCarouselThree = new CarouselInfiniteLoad(productCarouselElement, productCarouselElement.getAttribute(AttributeEnums.CarouselInfiniteLoad.Url), true);
        } catch (error) {
            console.log('ProductCarouselOrchestrator: productCarouselThreeFactory ERROR', error);
        }
    }

    productCarouselFourFactory(productCarouselElement: HTMLElement): void {
        console.log('ProductCarouselOrchestrator: productCarouselFourFactory');

        try {
            this.productCarouselFour = new CarouselInfiniteLoad(productCarouselElement, productCarouselElement.getAttribute(AttributeEnums.CarouselInfiniteLoad.Url), true);
        } catch (error) {
            console.log('ProductCarouselOrchestrator: productCarouselFourFactory ERROR', error);
        }
    }

    productCarouselFiveFactory(productCarouselElement: HTMLElement): void {
        console.log('ProductCarouselOrchestrator: productCarouselFiveFactory');

        try {
            this.productCarouselFive = new CarouselInfiniteLoad(productCarouselElement, productCarouselElement.getAttribute(AttributeEnums.CarouselInfiniteLoad.Url), true);
        } catch (error) {
            console.log('ProductCarouselOrchestrator: productCarouselFiveFactory ERROR', error);
        }
    }

    productCarouselSixFactory(productCarouselElement: HTMLElement): void {
        console.log('ProductCarouselOrchestrator: productCarouselSixFactory');

        try {
            this.productCarouselSix = new CarouselInfiniteLoad(productCarouselElement, productCarouselElement.getAttribute(AttributeEnums.CarouselInfiniteLoad.Url), true);
        } catch (error) {
            console.log('ProductCarouselOrchestrator: productCarouselSixFactory ERROR', error);
        }
    }

    productCarouselSevenFactory(productCarouselElement: HTMLElement): void {
        console.log('ProductCarouselOrchestrator: productCarouselSevenFactory');

        try {
            this.productCarouselSeven = new CarouselTileLoad(productCarouselElement);
        } catch (error) {
            console.log('ProductCarouselOrchestrator: productCarouselSevenFactory ERROR', error);
        }
        //as it is the last element
        this.dispose();
    }

    getElementTop(productCarouselElement: HTMLElement): number {
        let elementViewportOffset = productCarouselElement.getBoundingClientRect();
        let elementTop: number = elementViewportOffset.top;
        return elementTop;
    }
    getWindowTollerance(): number {
        let viewportHeight: number = visualViewport.height;
        let totalHeight: number = viewportHeight + this.offsetTollerance;
        return totalHeight;
    }

    dispose(): void {
    }

}