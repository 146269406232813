export namespace AsyncUtils {
    export async function asyncFuncBeforAfter(func: Function, doBefore?: Function, doAfter?: Function) {

        if (doBefore != null || doBefore != undefined) {
            doBefore();
        }

        let result = await func();

        if (doAfter != null || doAfter != undefined) {
            doAfter();
        }

        return result;
    }

    export const delay = (delayInms:number) => {
        return new Promise(resolve => setTimeout(resolve, delayInms));
      }
      
      
}