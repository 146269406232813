import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";


export interface IProductListingPageQuickLink extends IInstanceComponentBase {
    name: string;
    href: string;
}

export class ProductListingPageQuickLink extends InstanceComponentBase implements IProductListingPageQuickLink {
    
    public name: string;
    public href: string;

    private readonly _handleProductListingPageQuickLinkClick;
    
    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);
               
        this._handleProductListingPageQuickLinkClick = this.handleProductListingPageQuickLinkClick.bind(this);

        this.setName();
        this.setHref();

        this.attachProductListingPageQuickLinkClickEventListener();
    }

    attachProductListingPageQuickLinkClickEventListener(): void {
        console.log('ProductListingPageQuickLink: attachProductListingPageQuickLinkClickEventListener');
        this.htmlElement.addEventListener("click", this._handleProductListingPageQuickLinkClick);
     }

    removeProductListingPageQuickLinkClickEventListener(): void {
        console.log('ProductListingPageQuickLink: removeProductListingPageQuickLinkClickEventListener');
        this.htmlElement.removeEventListener("click", this._handleProductListingPageQuickLinkClick);
    }

    handleProductListingPageQuickLinkClick(e: Event) {
        console.log('ProductListingPageQuickLink: handleProductListingPageQuickLinkClick');       
         this.htmlElement.dispatchEvent(this.productListingPageQuickLinkCustomEvent(this.name, this.href)); 
     }

     productListingPageQuickLinkCustomEvent(type: string, value: string) {
        console.log(`ProductListingPageQuickLink: productListingPageQuickLinkCustomEvent type: ${type}  value: ${value}`);
        return new CustomEvent(CustomEventEnums.ProductListingPageHeaderQuickLink, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                type: type,
                value: value
            }
        });
    }


    setName(): void {
        this.name = this.htmlElement.getAttribute(AttributeEnums.ProductListingPageHeader.ProductListingPageHeaderQuickLinkName);
    }
    setHref(): void {
        this.href = this.htmlElement.getAttribute('href');
    }
   

    dispose(): void {
        this.removeProductListingPageQuickLinkClickEventListener();
    }
    
    delete(): void {

    }
}