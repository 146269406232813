import { InstanceComponentBase } from "../../shared/instanceComponentBase";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";

export interface IProductPrice {
    updateProductPrice: (additionalPriceTotals: number) => void;
    dispose: () => void;
    totalPrice: number;
}

export class ProductPrice extends InstanceComponentBase implements IProductPrice {
    private productType: string;
    public totalPrice: number;
    private baseNowPrice: number;
    private baseWasPrice: number;
    private baseRrpPrice: number;
    private baseSavePrice: number;

    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);

        this.productType = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).getAttribute(AttributeEnums.Product.Product);
        this.setBaseNowPrice();
        this.setBaseWasPrice();
        this.setBaseRrpPrice();
        this.setBaseSavePrice();
        this.setTotalPrice();
    }

    setBaseNowPrice(): void {
        console.log("ProductPrice: setBaseNowPrice");
        this.baseNowPrice = this.getPriceFromAttribute(AttributeEnums.ProductPrice.ProductBaseNowPrice);
    }
    setBaseWasPrice(): void {
        console.log("ProductPrice: setBaseWasPrice");
        this.baseWasPrice = this.getPriceFromAttribute(AttributeEnums.ProductPrice.ProductBaseWasPrice);
    }
    setBaseRrpPrice(): void {
        console.log("ProductPrice: setBaseRrpPrice");
        this.baseRrpPrice = this.getPriceFromAttribute(AttributeEnums.ProductPrice.ProductBaseRrpPrice);
    }
    setBaseSavePrice(): void {
        console.log("ProductPrice: setBaseSavePrice");
        this.baseSavePrice = this.getPriceFromAttribute(AttributeEnums.ProductPrice.ProductBaseSavePrice);
    }

    setTotalPrice(): void {
        console.log("ProductPrice: setTotalPrice");
        this.totalPrice = this.getPriceFromAttribute(AttributeEnums.ProductPrice.ProductNowPrice);
    }

    updateProductPrice(additionalPriceTotals: number): void {
        console.log('ProductPrice: updateProductPrice');

        this.updateProductPrefixAndPrice('RRP', AttributeEnums.ProductPrice.ProductRrpPrice, (this.baseRrpPrice + additionalPriceTotals));
        this.updateProductPrefixAndPrice('WAS', AttributeEnums.ProductPrice.ProductWasPrice, (this.baseWasPrice + additionalPriceTotals));
        this.updateProductPrefixAndPrice('', AttributeEnums.ProductPrice.ProductNowPrice, (this.baseNowPrice + additionalPriceTotals));

        this.setTotalPrice();
        //needs updating to allow multiple products
        this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).dispatchEvent(this.productPriceUpdatesEvent(this.productType, this.totalPrice));
    }

    updateProductPrefixAndPrice(pricePrefix: string, priceAttribute: string, newPrice: number): void {
        let dataProductPriceElement: HTMLElement = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).querySelector('[' + priceAttribute + ']');
        if (dataProductPriceElement != null) {
            dataProductPriceElement.setAttribute(priceAttribute, newPrice.toString());
            let prefix: string = '£';
            if (pricePrefix.length > 0) {
                prefix = pricePrefix + ' ' + prefix;
            }
            dataProductPriceElement.innerHTML = prefix + newPrice.toLocaleString();
        }
    }

    getPriceFromAttribute(priceAttribute: string): number {
        let dataProductPriceElement: Element = this.htmlElement.querySelector('[' + priceAttribute + ']');
        if (dataProductPriceElement != null) {
            let dataProductPriceAsString: string = dataProductPriceElement.getAttribute(priceAttribute);
            let dataProductPrice: number = parseFloat(dataProductPriceAsString.replace(',', ''));
            return dataProductPrice;
        }
        else {
            return 0.00;
        }
    }

    productPriceUpdatesEvent(type: string, totalPrice: number) {
        console.log("ProductPrice: productPriceUpdatesEvent - CustomEvent product-price-updated Dispatched");
        return new CustomEvent(CustomEventEnums.ProductPriceUpdated, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                'type': type,
                'totalPrice': totalPrice
            }
        });
    }

    dispose(): void {

    }
    delete(): void {

    }
}