import { InstanceComponentBase } from '../../shared/instanceComponentBase';
import { AttributeEnums } from '../../shared/utilities/enums/attributeEnums';
import { StringEnums } from '../../shared/utilities/enums/stringEnums';
import { HelperUtils } from '../../shared/utilities/helperUtils';

export interface GalleryInterface {
  htmlElement: Element;
}

export class Gallery extends InstanceComponentBase {

  private mainArea = <HTMLElement>this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbGalleryMainArea));
  public selectedElement: Element;
  public placeholder: Element;

  private readonly _resizeController;
  private readonly _setMainAreaBackgroundWithEvent;
  private readonly _setMainAreaBackgroundWithSelectedElement;
  private readonly _setMainAreaBackgroundAndTileToSelect;

  constructor(public htmlElement: Element) {
    super(htmlElement);

    //----- Event Listener Bindings Start -----//
    this._resizeController = this.resizeController.bind(this);
    this._setMainAreaBackgroundWithEvent = this.setMainAreaBackgroundWithEvent.bind(this);
    this._setMainAreaBackgroundWithSelectedElement = this.setMainAreaBackgroundWithSelectedElement.bind(this);
    this._setMainAreaBackgroundAndTileToSelect = this.setMainAreaBackgroundAndTileToSelect.bind(this);
    //----- Event Listener Bindings End -----//

    this.initialise();

    //----- Initialise Event Listeners Start -----//
    this.attachCellEventListener();
    this.attachResizeControllerEvent();
    //----- Initialise Event Listeners End -----//
  }

  initialise() {
    let clone = <Element>this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbGalleryMainArea)).cloneNode(true);
    this.selectedElement = clone;
    this.placeholder = clone;
  }

  getElementToMoveIntoView() {
    let scrollableArea: HTMLElement = this.htmlElement.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbGalleryChooserTiles));
    let scrollPosition = scrollableArea.scrollLeft;
    let scrollableAreaWidth = scrollableArea.getBoundingClientRect().width;
    let scrollCount = Math.round(scrollPosition / scrollableAreaWidth) + 1;
    return scrollCount;
  }

  //------ Add Event Listeners Start -----//
  attachResizeControllerEvent(): void {
    window.addEventListener("resize", this._resizeController);
  }

  attachCellEventListener() {
    let cells = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.Gallery.GallerySelectableCell));
    for (let i = 0; i < cells.length; i++) {
      this.attachHoverEventListener(cells[i]);
      this.attachLeaveEventListener(cells[i]);
      this.attachClickEventListener(cells[i]);
    }
  }

  attachHoverEventListener(cell: Element) {
    cell.addEventListener('mouseenter', this._setMainAreaBackgroundWithEvent);
  }

  attachLeaveEventListener(cell: Element) {
    cell.addEventListener('mouseleave', this._setMainAreaBackgroundWithSelectedElement);
  }

  attachClickEventListener(cell: Element) {
    cell.addEventListener('click', this._setMainAreaBackgroundAndTileToSelect);
  }

  //------ Add Event Listeners End -----//

  //------ Remove Event Listeners Start -----//
  removeResizeControllerEvent(): void {
    window.removeEventListener("resize", this._resizeController);
  }

  removeCellEventListener() {
    let cells = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.Gallery.GallerySelectableCell));
    for (let i = 0; i < cells.length; i++) {
      this.removeHoverEventListener(cells[i]);
      this.removeLeaveEventListener(cells[i]);
      this.removeClickEventListener(cells[i]);
    }
  }

  removeHoverEventListener(cell: Element) {
    cell.removeEventListener('mouseenter', this._setMainAreaBackgroundWithEvent);
  }

  removeLeaveEventListener(cell: Element) {
    cell.removeEventListener('mouseleave', this._setMainAreaBackgroundWithSelectedElement);
  }

  removeClickEventListener(cell: Element) {
    cell.removeEventListener('click', this._setMainAreaBackgroundAndTileToSelect);
  }
  //------ Remove Event Listeners End -----//

  //------ Event Listener Methods Start -----//
  resizeController(): void {
    console.log('Gallery: resizeController - Beginning');
    this.mainArea.style.height = 'auto';
  }

  setMainAreaBackgroundWithEvent(e: Event) {
    console.log('Gallery: setMainAreaBackgroundWithEvent - Beginning');
    let tagertElement = e.target as Element;
    this.setMainAreaBackground(tagertElement);
  }

  setMainAreaBackgroundWithSelectedElement(): void {
    console.log('Gallery: setMainAreaBackgroundWithSelectedElement - Beginning');
    this.setMainAreaBackground(this.selectedElement);
  }

  setMainAreaBackgroundAndTileToSelect(e: Event): void {
    console.log('Gallery: setMainAreaBackgroundAndTileToSelect - Beginning');
    let tagertElement = e.target as Element;
    let cell = tagertElement.closest(HelperUtils.addCssClassDot(StringEnums.CssClass.LbGalleryChooserTilesCell));
    this.setMainAreaBackground(cell);
    this.setTileToSelected(cell);
    this.selectedElement = cell;
  }

  setMainAreaBackground(element: Element) {
    console.log('Gallery: setMainAreaBackground - Beginning');
    //this.mainArea.style.height = this.mainArea.firstElementChild.clientHeight + 'px';
    if (element != null) { 
      let updatedInnerHTML = element.innerHTML.replace('extrasmallsquarecropped', 'mediumletterboxcropped').replace(AttributeEnums.ProductSelector.ProductSelectorSwatchSrc, AttributeEnums.ProductSelector.ProductSelectorSwatchPreviewSrc);

      this.mainArea.innerHTML = updatedInnerHTML;
    }
  }

  setTileToSelected(element: Element) {
    console.log('Gallery: setTileToSelected - Beginning');
    this.reset();
    element.classList.add(StringEnums.CssClass.LbGalleryChooserTilesCellSelected);
  }

  //------ Event Listener Methods End -----//

  //------ Custom Event Creation Start -----//
  //------ Custom Event Creation End -----//

  setMainBackgroundAreaById(id: number) {
    let selectedCell = this.getSelectedCellById(id);
    this.setMainAreaBackground(selectedCell);
  }

  setTileToSelectedById(id: number) {
    this.reset();
    let selectedCellById: HTMLElement = this.getSelectedCellById(id);
    if (selectedCellById != null) {
      selectedCellById.classList.add(StringEnums.CssClass.LbGalleryChooserTilesCellSelected);
    }
  }

  getSelectedCellById(id: number) {
    return this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Gallery.GalleryId, id.toString())) as HTMLElement;
  }

  reset() {
    let cells = this.htmlElement.querySelectorAll(HelperUtils.addCssClassDot(StringEnums.CssClass.LbGalleryChooserTilesCellSelected));
    for (let i = 0; i < cells.length; i++) {
      cells[i].classList.remove(StringEnums.CssClass.LbGalleryChooserTilesCellSelected);
    }
  }

  //------ Clean Up Processes Start -----//
  dispose(): void {
    this.removeResizeControllerEvent();
    this.removeCellEventListener();
    this.delete();
  }

  delete(): void {
    if ((<any>window)[this.htmlElement.getAttribute(AttributeEnums.Gallery.Gallery) + 'Gallery']) {
      console.log('GalleryController: Delete - Deleting Gallery Reference');
      delete (<any>window)[this.htmlElement.getAttribute(AttributeEnums.Gallery.Gallery) + 'Gallery'];
    }
    else {
      console.log('GalleryController: Delete - Gallery Reference Not Found');
    }
  }
  //------ Clean Up Processes End -----//
}