import { InstanceComponentBase } from "./instanceComponentBase";
import { AttributeEnums } from "./utilities/enums/attributeEnums";
import { HelperUtils } from "./utilities/helperUtils";

export function initialiseInstanceComponents<Type extends InstanceComponentBase>(arg:Function, dataAttributeName:string, instanceComponentFactory:Function) {
    let instanceComponentsOfType:Type[] = getAllComponentsOfType<Type>(arg);
    let componentElements = document.querySelectorAll(dataAttributeName+ ':not(' + HelperUtils.addDataAttributeTags(AttributeEnums.InstanceComponentInitialiser.Ignore,'true')+')');
    createNewInstanceComponentBaseForAllNonExistant(instanceComponentsOfType, componentElements, instanceComponentFactory);
}

function getAllComponentsOfType<Type extends InstanceComponentBase>(arg:Function):Type[]{
    let instanceComponentBaseList: Type[] = [];
    for (let member in window) {
        if (window[member] instanceof arg) {
            let currentComponent = (window[member] as unknown) as Type;      
            instanceComponentBaseList.push(currentComponent);
        }
    }
    return instanceComponentBaseList;
}

function createNewInstanceComponentBaseForAllNonExistant(instanceComponentBaseList:InstanceComponentBase[], componentElements:NodeListOf<Element>, instanceComponentFactory:Function){
    for (let i = 0; i < componentElements.length; i += 1) {
        let componentElement = componentElements[i];
        createInstanceComponentBaseForElementIfNonExistant(instanceComponentBaseList, componentElement, instanceComponentFactory);
    }
}

function createInstanceComponentBaseForElementIfNonExistant(instanceComponentBaseList:InstanceComponentBase[], componentElement:Element, instanceComponentFactory:Function) {
    if (instanceComponentBaseList.length === 0) {
        instanceComponentFactory(componentElement);
    } else {
        let componentExists = instanceComponentBaseList.some((c:InstanceComponentBase) => { return(componentElement === c.getHtmlElement()) });
        if (!componentExists) {
            instanceComponentFactory(componentElement);
        }
    }
}