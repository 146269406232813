import { AttributeEnums } from "../utilities/enums/attributeEnums";
import { GoogleTagManagerEnums } from "../utilities/enums/googleTagManagerEnums";
import { IAddToRemoveFromCartGtmDataObject, IFilterClickGtmDataObject, IItemClickGtmDataObject, IMenuClickGtmDataObject, IProductGtmDataObject, IProductViewGtmDataObject, IPromotionGtmDataObject, IQuickLinkClickGtmDataObject, ISearchProductAndPageClickGtmDataObject, ISortClickGtmDataObject, IViewCartGtmDataObject } from "./gtmDataObject";
import { IGtmAddToCartAdd, IGtmAddToCartEcommerce, IGtmPostAddToCartObject } from "./gtmPostAddToCartObject";
import { IGtmFilterClickEcommerce, IGtmPostFilterClickObject } from "./gtmPostFilterClickObject";
import { IGtmItemEcommerce, IGtmPostItemClickObject } from "./gtmPostItemClickObject";
import { IGtmMenuEcommerce, IGtmPostMenuClickObject } from "./gtmPostMenuClickObject";
import { IGtmProductTileActionField, IGtmProductTileEcommerce, IGtmProductTileEcommerceClick, IGtmPostProductTileClickObject } from "./gtmPostProductTileClickObject";
import { IGtmPostProductTileImpressionsObject, IGtmProductTileImpressionsEcommerce } from "./gtmPostProductTileImpressionsObject";
import { IGtmPostProductViewObject, IGtmProductViewDetail, IGtmProductViewEcommerce } from "./gtmPostProductViewObject";
import { IGtmPromotionEcommerce, IGtmPromotionEcommerceClick, IGtmPostPromotionClickObject } from "./gtmPostPromotionClickObject";
import { IGtmPostPromotionImpressionsObject, IGtmPromotionImpressionsEcommerce, IGtmPromotionImpressionsPromoView } from "./gtmPostPromotionImpressionsObject";
import { IGtmPostQuickLinkClickObject, IGtmQuickLinkClickEcommerce } from "./gtmPostQuickLinkClickObject";
import { IGtmPostRemoveFromCartObject, IGtmRemoveFromCartEcommerce, IGtmRemoveFromCartRemove } from "./gtmPostRemoveFromCartObject";
import { IGtmPostSearchProductAndPageClickObject, IGtmSearchProductAndPageEcommerce } from "./gtmPostSearchProductAndPageClickObject";
import { IGtmPostSortClickObject, IGtmSortClickEcommerce } from "./gtmPostSortClickObject";
import { IGtmPostViewCartEcommerce, IGtmPostViewCartObject } from "./gtmPostViewCartObject";

const ids = require('../../config/ids.json');

export namespace GoogleTagManager {

    export function sendProductGtmDataObjectAndNavigate(productGtmDataObject: IProductGtmDataObject, navigationUrl: string): void {
        let products = new Array<IProductGtmDataObject>();
        products.push(productGtmDataObject);

        this.sendProductsGtmDataObjectAndNavigate(products, navigationUrl, productGtmDataObject.list);
    }

    export function sendProductsGtmDataObjectAndNavigate(productGtmDataObjects: IProductGtmDataObject[], navigationUrl: string, list: string): void {
        try {
            if (checkIfWindowHasGtm()) {
                if (productGtmDataObjects !== null && productGtmDataObjects.length > 0) {
                    let gtmProductTileActionField: IGtmProductTileActionField = {
                        list: list
                    };
                    let gtmProductTileEcommerceClick: IGtmProductTileEcommerceClick = {
                        actionField: gtmProductTileActionField,
                        products: productGtmDataObjects
                    };
                    let gtmProductTileEcommerce: IGtmProductTileEcommerce = {
                        click: gtmProductTileEcommerceClick
                    };
                    let gtmProductTilePostObject: IGtmPostProductTileClickObject = {
                        event: GoogleTagManagerEnums.EventTypes.ProductClick,
                        ecommerce: gtmProductTileEcommerce,
                        eventCallback: (containerId) => { if (containerIdChecker(containerId)) { document.location = navigationUrl } }
                    };

                    let dataLayer = (<any>window).dataLayer;
                    dataLayer.push({ ecommerce: null });
                    dataLayer.push(gtmProductTilePostObject);
                }
                else {
                    document.location = navigationUrl;
                }
            }
            else {
                document.location = navigationUrl;
            }
        }

        catch {
            document.location = navigationUrl;
        }
    }

    export function sendPromotionsGtmDataObjectAndNavigate(promotionGtmDataObjects: IPromotionGtmDataObject, navigationUrl: string): void {
        try {
            if (checkIfWindowHasGtm()) {
                let promotionsArray = new Array<IPromotionGtmDataObject>();
                promotionsArray.push(promotionGtmDataObjects);
                let gtmPromotionEcommerceClick: IGtmPromotionEcommerceClick = {
                    promotions: promotionsArray
                };
                let gtmPromotionEcommerce: IGtmPromotionEcommerce = {
                    promoClick: gtmPromotionEcommerceClick
                };
                let gtmPromotionPostObject: IGtmPostPromotionClickObject = {
                    event: GoogleTagManagerEnums.EventTypes.PromotionClick,
                    ecommerce: gtmPromotionEcommerce,
                    eventCallback: (containerId) => { if (containerIdChecker(containerId)) { document.location = navigationUrl } }
                };

                let dataLayer = (<any>window).dataLayer;
                dataLayer.push({ ecommerce: null });
                dataLayer.push(gtmPromotionPostObject);
            }
            else {
                document.location = navigationUrl;
            }
        }

        catch {
            document.location = navigationUrl;
        }
    }

    export function sendProductTileImpressionsGtmDataObject(productGtmDataObjects: IProductGtmDataObject[]): boolean {
        try {
            if (productGtmDataObjects !== null && productGtmDataObjects.length > 0) {
                let gtmProductTileImpressionsEcommerce: IGtmProductTileImpressionsEcommerce = {
                    currencyCode: 'GBP',
                    impressions: productGtmDataObjects
                }
                let gtmPostProductTileImpressionsObject: IGtmPostProductTileImpressionsObject = {
                    event: GoogleTagManagerEnums.EventTypes.ProductImpressions,
                    ecommerce: gtmProductTileImpressionsEcommerce
                }
                let dataLayer = (<any>window).dataLayer;
                dataLayer.push({ ecommerce: null });
                dataLayer.push(gtmPostProductTileImpressionsObject);

                return true;
            }
            else {
                return false;
            }
        } catch {
            return false;
        }
    }

    export function sendPromotionImpressionsGtmDataObject(promotionGtmDataObject: IPromotionGtmDataObject[]): boolean {
        try {
            if (promotionGtmDataObject !== null && promotionGtmDataObject.length > 0) {
                let gtmPromotionImpressionsPromoView: IGtmPromotionImpressionsPromoView = {
                    promotions: promotionGtmDataObject
                }

                let gtmPromotionImpressionsEcommerce: IGtmPromotionImpressionsEcommerce = {
                    promoView: gtmPromotionImpressionsPromoView

                }
                let gtmPostPromotionImpressionsObject: IGtmPostPromotionImpressionsObject = {
                    event: GoogleTagManagerEnums.EventTypes.PromotionImpressions,
                    ecommerce: gtmPromotionImpressionsEcommerce
                }
                let dataLayer = (<any>window).dataLayer;
                dataLayer.push({ ecommerce: null });
                dataLayer.push(gtmPostPromotionImpressionsObject);

                return true;
            }
            else {
                return false;
            }
        } catch {
            return false;
        }
    }

    export function sendProductViewGtmDataObjectList(productViewGtmDataObjectList: IProductViewGtmDataObject[]) {
        try {
            let gtmProductViewDetail: IGtmProductViewDetail = {
                products: productViewGtmDataObjectList
            };
            let gtmProductViewEcommerce: IGtmProductViewEcommerce = {
                detail: gtmProductViewDetail
            };
            let gtmPostProductViewObject: IGtmPostProductViewObject = {
                event: GoogleTagManagerEnums.EventTypes.ProductView,
                ecommerce: gtmProductViewEcommerce
            };

            let dataLayer = (<any>window).dataLayer;
            dataLayer.push({ ecommerce: null });
            dataLayer.push(gtmPostProductViewObject);
        } catch (error) {

        }
    }
    export function sendProductViewGtmDataObject(productViewGtmDataObject: IProductViewGtmDataObject) {
        try {
            let productViewList = new Array<IProductViewGtmDataObject>();
            productViewList.push(productViewGtmDataObject);

            this.sendProductViewGtmDataObjectList(productViewList);
        } catch (error) {

        }
    }

    export function sendAddToCartGtmDataObjectList(addToRemoveFromCartGtmDataObjectList: IAddToRemoveFromCartGtmDataObject[]) {
        try {

            let gtmAddToCartAdd: IGtmAddToCartAdd = {
                products: addToRemoveFromCartGtmDataObjectList
            };
            let gtmAddToCartEcommerce: IGtmAddToCartEcommerce = {
                currencyCode: 'GBP',
                add: gtmAddToCartAdd
            }
            let gtmPostAddToCartObject: IGtmPostAddToCartObject = {
                event: GoogleTagManagerEnums.EventTypes.AddToCart,
                ecommerce: gtmAddToCartEcommerce
            }

            let dataLayer = (<any>window).dataLayer;
            dataLayer.push({ ecommerce: null });
            dataLayer.push(gtmPostAddToCartObject);
        } catch (error) {

        }
    }

    export function sendAddToCartGtmDataObject(addToRemoveFromCartGtmDataObject: IAddToRemoveFromCartGtmDataObject) {
        try {
            let addToRemoveFromCartList = new Array<IAddToRemoveFromCartGtmDataObject>();
            addToRemoveFromCartList.push(addToRemoveFromCartGtmDataObject);

            this.sendAddToCartGtmDataObjectList(addToRemoveFromCartList);
        } catch (error) {

        }
    }

    export function sendRemoveFromCartGtmDataObjectList(addToRemoveFromCartGtmDataObjectList: IAddToRemoveFromCartGtmDataObject[]) {
        try {

            let gtmRemoveFromCartRemove: IGtmRemoveFromCartRemove = {
                products: addToRemoveFromCartGtmDataObjectList
            };
            let gtmRemoveFromCartEcommerce: IGtmRemoveFromCartEcommerce = {
                currencyCode: 'GBP',
                remove: gtmRemoveFromCartRemove
            }
            let gtmPostRemoveFromCartObject: IGtmPostRemoveFromCartObject = {
                event: GoogleTagManagerEnums.EventTypes.RemoveFromCart,
                ecommerce: gtmRemoveFromCartEcommerce
            }

            let dataLayer = (<any>window).dataLayer;
            dataLayer.push({ ecommerce: null });
            dataLayer.push(gtmPostRemoveFromCartObject);
        } catch (error) {

        }
    }

    export function sendRemoveFromCartGtmDataObject(addToRemoveFromCartGtmDataObject: IAddToRemoveFromCartGtmDataObject) {
        try {
            let addToRemoveFromCartList = new Array<IAddToRemoveFromCartGtmDataObject>();
            addToRemoveFromCartList.push(addToRemoveFromCartGtmDataObject);

            this.sendRemoveFromCartGtmDataObjectList(addToRemoveFromCartList);
        } catch (error) {

        }
    }

    export function sendViewCartGtmDataObjectList(viewCartGtmDataObjectList: IViewCartGtmDataObject[]): void {
        try {
            let gtmPostViewCartEcommerce: IGtmPostViewCartEcommerce = {
                items: viewCartGtmDataObjectList
            };
            let gtmPostViewCartObject: IGtmPostViewCartObject = {
                event: GoogleTagManagerEnums.EventTypes.ViewCart,
                ecommerce: gtmPostViewCartEcommerce
            };

            let dataLayer = (<any>window).dataLayer;
            dataLayer.push({ ecommerce: null });
            dataLayer.push(gtmPostViewCartObject);

        } catch (error) {

        }
    }

    export function sendSearchProductAndPageClickGtmDataObjectAndNavigate(searchClickGtmDataObject: ISearchProductAndPageClickGtmDataObject, eventTypes: GoogleTagManagerEnums.EventTypes, navigationUrl: string): void {
        try {
            if (checkIfWindowHasGtm()) {
                let gtmSearchProductAndPageEcommerce: IGtmSearchProductAndPageEcommerce = {
                    click: searchClickGtmDataObject
                };
                let gtmPostSearchProductAndPageClickObject: IGtmPostSearchProductAndPageClickObject = {
                    event: eventTypes,
                    ecommerce: gtmSearchProductAndPageEcommerce,
                    eventCallback: (containerId) => { if (containerIdChecker(containerId)) { document.location = navigationUrl } }
                };

                let dataLayer = (<any>window).dataLayer;
                dataLayer.push({ ecommerce: null });
                dataLayer.push(gtmPostSearchProductAndPageClickObject);
            }
            else {
                document.location = navigationUrl
            }
        }

        catch {
            document.location = navigationUrl;
        }
    }

    export function sendMenuClickGtmDataObjectAndNavigate(menuClickGtmDataObject: IMenuClickGtmDataObject, navigationUrl: string): void {
        try {
            if (checkIfWindowHasGtm()) {
                let gtmMenuEcommerce: IGtmMenuEcommerce = {
                    click: menuClickGtmDataObject
                };
                let gtmPostMenuPageClickObject: IGtmPostMenuClickObject = {
                    event: GoogleTagManagerEnums.EventTypes.MenuClick,
                    ecommerce: gtmMenuEcommerce,
                    eventCallback: (containerId) => { if (containerIdChecker(containerId)) { document.location = navigationUrl } }
                };

                let dataLayer = (<any>window).dataLayer;
                dataLayer.push({ ecommerce: null });
                dataLayer.push(gtmPostMenuPageClickObject);
            }
            else {
                document.location = navigationUrl
            }
        }

        catch {
            document.location = navigationUrl;
        }
    }

    export function sendItemClickGtmDataObjectAndNavigate(itemClickGtmDataObject: IItemClickGtmDataObject, navigationUrl: string): void {
        try {
            if (checkIfWindowHasGtm()) {
                let gtmItemEcommerce: IGtmItemEcommerce = {
                    click: itemClickGtmDataObject
                };
                let gtmPostItemClickObject: IGtmPostItemClickObject = {
                    event: GoogleTagManagerEnums.EventTypes.ItemClick,
                    ecommerce: gtmItemEcommerce,
                    eventCallback: (containerId) => { if (containerIdChecker(containerId)) { document.location = navigationUrl } }
                };

                let dataLayer = (<any>window).dataLayer;
                dataLayer.push({ ecommerce: null });
                dataLayer.push(gtmPostItemClickObject);
            }
            else {
                document.location = navigationUrl
            }
        }

        catch {
            document.location = navigationUrl;
        }
    }

    export function sendItemClickGtmDataObject(itemClickGtmDataObject: IItemClickGtmDataObject): void {
        try {
            let gtmItemEcommerce: IGtmItemEcommerce = {
                click: itemClickGtmDataObject
            };
            let gtmPostItemClickObject: IGtmPostItemClickObject = {
                event: GoogleTagManagerEnums.EventTypes.ItemClick,
                ecommerce: gtmItemEcommerce
            };

            let dataLayer = (<any>window).dataLayer;
            dataLayer.push({ ecommerce: null });
            dataLayer.push(gtmPostItemClickObject);
        }
        catch {

        }
    }

    export function sendSortClickGtmDataObject(sortClickGtmDataObject: ISortClickGtmDataObject): void {
        try {
            let gtmSortClickEcommerce: IGtmSortClickEcommerce = {
                click: sortClickGtmDataObject
            };
            let gtmPostSortClickObject: IGtmPostSortClickObject = {
                event: GoogleTagManagerEnums.EventTypes.SortClick,
                ecommerce: gtmSortClickEcommerce
            };

            let dataLayer = (<any>window).dataLayer;
            dataLayer.push({ ecommerce: null });
            dataLayer.push(gtmPostSortClickObject);
        }
        catch {

        }
    }

    export function sendFilterClickGtmDataObject(filterClickGtmDataObject: IFilterClickGtmDataObject): void {
        try {
            let gtmFilterClickEcommerce: IGtmFilterClickEcommerce = {
                click: filterClickGtmDataObject
            };
            let gtmPostFilterClickObject: IGtmPostFilterClickObject = {
                event: GoogleTagManagerEnums.EventTypes.FilterClick,
                ecommerce: gtmFilterClickEcommerce
            };

            let dataLayer = (<any>window).dataLayer;
            dataLayer.push({ ecommerce: null });
            dataLayer.push(gtmPostFilterClickObject);
        }
        catch {

        }
    }

    export function sendQuickLinkClickGtmDataObjectAndNavigate(quickLinkClickGtmDataObject: IQuickLinkClickGtmDataObject, navigationUrl: string): void {
        try {
            if (checkIfWindowHasGtm()) {

                let gtmQuickLinkClickEcommerce: IGtmQuickLinkClickEcommerce = {
                    click: quickLinkClickGtmDataObject
                };
                let gtmPostQuickLinkClickObject: IGtmPostQuickLinkClickObject = {
                    event: GoogleTagManagerEnums.EventTypes.QuickLinkClick,
                    ecommerce: gtmQuickLinkClickEcommerce,
                    eventCallback: (containerId) => { if (containerIdChecker(containerId)) { document.location = navigationUrl } }
                };

                let dataLayer = (<any>window).dataLayer;
                dataLayer.push({ ecommerce: null });
                dataLayer.push(gtmPostQuickLinkClickObject);
            }
            else {
                document.location = navigationUrl
            }

        }
        catch {
            document.location = navigationUrl;
        }
    }

    function containerIdChecker(containerId: string): boolean {
        if (containerId !== ids.GoogleContainerIdToIgnore) {
            return true;
        } else {
            return false;
        }
    }

    function checkIfWindowHasGtm(): boolean {
        try {
            if ((<any>window).google_tag_manager) {
                console.log('Google Tag Manager is loaded');
                return true;
            }
            else {
                console.log('Google Tag Manager is not loaded');
                return false;
            }
        } catch (error) {
            console.log(`GoogleTagManager: checkIfWindowHasGtm ERROR`, error);
            return false;
        }
    }
}