import { DomUtils } from "./utilities/domUtils";
import { InstantiationUtils } from "./utilities/instantiationUtils";
import { initialisePopOutController } from './popOutControllerInitialiser';
import { initialiseBasketPopOut } from "../components/basketPopOut/basketPopOutInitialiser";
import { ResponsiveState } from "./responsiveState";
import { initialiseOverlay } from "./overlayInitialiser";
import { initialiseMegaMenu } from "./megaMenuInitialiser";
import { swipedEvents } from "./utilities/swipeEvents";
import { FirstInteraction } from "./firstInteraction";
import { HistoryController } from "./historyController";
import { ScrollService } from "./scrollService";
import { GlobalComponents } from "../components/globalComponents/globalComponents";
import { LiveChat } from "./liveChat";
import { Notification } from "../components/notification/notification";
import { LbCookies } from "./lbCookies";
import { OneTrustCookieConsent } from "./oneTrust/oneTrustCookieConsent";
const initialiseationConfigData = require('../config/initialisationConfig.json');
const toggleFeatures = require('../config/toggleFeatures.json');
//const urlPaths = global.require('../../config/urlPaths.json');

window.addEventListener('DOMContentLoaded', () => {
  pageLoad();
});

async function pageLoad() {
    ResponsiveState.responsiveStateInitialiser();
    swipedEvents(<any>window, document);
    initialisePopOutController();
    HistoryController.initialiseHistoryController();
    initialiseOverlay();
    initialiseMegaMenu();
    await initialise();
    initialiseBasketPopOut();
    if (toggleFeatures.LiveChat) {
        Notification.notificationFactory();
        LiveChat.initialise();
    }
    FirstInteraction.initialise();
    ScrollService.initialise();
    GlobalComponents.initialiseGlobalComponents();
    HistoryController.removeAllPopOutHistory();
    if (toggleFeatures.CookieConsent) {
        LbCookies.getOrInitialiseCookieConsent();
    }
    LbCookies.setAffiliateClickRefCookie(OneTrustCookieConsent.getTargetingConsent());
    OneTrustCookieConsent.addOneTrustEventListeners();
}

export async function initialise() {
  let allDataAttributeNames = DomUtils.createArrayOfDataElement();
  let uniqueAttributeNames = Array.from(new Set(allDataAttributeNames));
  await matchInitialiseationConfigWithDomElement(uniqueAttributeNames);
}

async function matchInitialiseationConfigWithDomElement(uniqueAttributeNames: string[]) {
  for (var items in initialiseationConfigData.bundles) {
    let dataAttributeName: string = initialiseationConfigData.bundles[items][0].dataAttributeName;
    let functionName: string = initialiseationConfigData.bundles[items][0].functionName;
    let moduleUrl: string = initialiseationConfigData.bundles[items][0].moduleUrl;
    if (uniqueAttributeNames.includes(dataAttributeName)) {
      await InstantiationUtils.importAndInstatiate(moduleUrl, functionName);
    }
  }
}