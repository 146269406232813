import { LbCookies } from "../lbCookies";
import { CookieUtils } from "../utilities/cookieUtils";
import { OneTrustEnums } from "../utilities/enums/oneTrustEnums";

export namespace OneTrustCookieConsent {

    export function addOneTrustEventListeners(): void {
        window.addEventListener(OneTrustEnums.Events.OneTrustGroupsUpdated, OneTrustCookieConsent.OT_OneTrustGroupsUpdated);
    }
    export function OT_OneTrustGroupsUpdated() {
        let necessaryConsent: boolean = OneTrustCookieConsent.getStrictlyNecessaryConsent();
        let performanceConsent: boolean = OneTrustCookieConsent.getPerformanceConsent();
        let functionalConsent: boolean = OneTrustCookieConsent.getFunctionalConsent();
        let targetingConsent: boolean = OneTrustCookieConsent.getTargetingConsent();
        let socialMediaConsent: boolean = OneTrustCookieConsent.getSocialMediaConsent();

        LbCookies.setAffiliateClickRefCookie(targetingConsent);

        console.log(`OneTrustCookieConsent: OT_OneTrustGroupsUpdated necessaryConsent-${necessaryConsent} performanceConsent-${performanceConsent} functionalConsent-${functionalConsent} targetingConsent-${targetingConsent} socialMediaConsent-${socialMediaConsent}`);
    }


    export function getOneTrustCookie(): string {
        let consentCookie: string = '';

        try {
            let oneTrustCookie: string | null = CookieUtils.getCookie(OneTrustEnums.OneTrustCookie.OneTrustCookie);

            if (oneTrustCookie != null) {
                consentCookie = oneTrustCookie;
            }
        }
        catch {
            console.log('OneTrustCookieConsent: getOneTrustCookie ERROR');
            return consentCookie;
        }

        return consentCookie;
    }


    export function getStrictlyNecessaryConsent(): boolean {
        try {
            let consentCookie = OneTrustCookieConsent.getOneTrustCookie();

            if ((consentCookie.length > 0) && consentCookie.includes(OneTrustEnums.CategoryConsentTrue.StrictlyNecessary)) {
                return true;
            }
            return false;
        }
        catch {
            console.log('OneTrustCookieConsent: getStrictlyNecessaryConsent ERROR');
            return false;
        }
    }
    export function getPerformanceConsent(): boolean {
        try {
            let consentCookie = OneTrustCookieConsent.getOneTrustCookie();

            if ((consentCookie.length > 0) && consentCookie.includes(OneTrustEnums.CategoryConsentTrue.Performance)) {
                return true;
            }
            return false;
        }
        catch {
            console.log('OneTrustCookieConsent: getPerformanceConsent ERROR');
            return false;
        }
    }

    export function getFunctionalConsent(): boolean {
        try {
            let consentCookie = OneTrustCookieConsent.getOneTrustCookie();

            if ((consentCookie.length > 0) && consentCookie.includes(OneTrustEnums.CategoryConsentTrue.Functional)) {
                return true;
            }
            return false;
        }
        catch {
            console.log('OneTrustCookieConsent: getFunctionalConsent ERROR');
            return false;
        }
    }

    export function getTargetingConsent(): boolean {
        try {
            let consentCookie = OneTrustCookieConsent.getOneTrustCookie();

            if ((consentCookie.length > 0) && consentCookie.includes(OneTrustEnums.CategoryConsentTrue.Targeting)) {
                return true;
            }
            return false;
        }
        catch {
            console.log('OneTrustCookieConsent: getTargetingConsent ERROR');
            return false;
        }
    }

    export function getSocialMediaConsent(): boolean {
        try {
            let consentCookie = OneTrustCookieConsent.getOneTrustCookie();

            if ((consentCookie.length > 0) && consentCookie.includes(OneTrustEnums.CategoryConsentTrue.SocialMedia)) {
                return true;
            }
            return false;
        }
        catch {
            console.log('OneTrustCookieConsent: getSocialMediaConsent ERROR');
            return false;
        }
    }

}