import { IPopOutController, PopOutController } from "./popOutController";
import { AttributeEnums } from "./utilities/enums/attributeEnums";
import { HelperUtils } from "./utilities/helperUtils";
import { CustomEventEnums } from "./utilities/enums/customEventEnums";
import { StringEnums } from "./utilities/enums/stringEnums";
import { AjaxGetAndReplaceHtml } from "./ajaxGetAndReplaceHtml";
import { UrlEnums } from "./utilities/enums/urlEnums";
import { HttpRequest } from "./ajax";
import { initialise } from "./initialiser";
import { IMegaMenuDesktop, MegaMenuDesktop } from "./megaMenuDesktop";
import { IMegaMenuHandheld, MegaMenuHandheld } from "./megaMenuHandheld";
const urlPaths = require('../config/urlPaths.json');
export class MegaMenu {

  private readonly _closeMobilePopOutOnDesktop;
  private readonly _setMegaMenuZIndex;
  private readonly _unsetMegaMenuZIndex;
  private readonly _handleFirstInteraction;
  private readonly _handleHandHeldMenuOpen;
  private megaMenuContainer: HTMLElement;
  private desktopMenuLoaded: boolean = false;
  private handHeldMenuLoaded: boolean = false;
  private megaMenuDesktop: IMegaMenuDesktop;
  private megaMenuHandheld: IMegaMenuHandheld;
  private timeOutHandler:ReturnType<typeof setTimeout>;

  constructor(public htmlElement: HTMLElement) {
    this.setMegaMenuContainer();

    this._closeMobilePopOutOnDesktop = this.closeMobilePopOutOnDesktop.bind(this);
    this._setMegaMenuZIndex = this.setMegaMenuZIndex.bind(this);
    this._unsetMegaMenuZIndex = this.unsetMegaMenuZIndex.bind(this);

    this._handleFirstInteraction = this.handleFirstInteraction.bind(this);
    this._handleHandHeldMenuOpen = this.handleHandHeldMenuOpen.bind(this);
    this.attachFirstInteractionChangeEventListener();
    this.attachOpenHandHeldMenuClickEventListener();

    this.megaMenuDesktopFactory();

  }
  attachOpenHandHeldMenuClickEventListener(): void {
    console.log('megaMenu: attachOpenHandHeldMenuClickEventListener');
    document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.MegaMenu.MegaMenuHandHeldButton)).addEventListener('click', this._handleHandHeldMenuOpen);
  }

  removeOpenHandHeldMenuClickEventListener(): void {
    console.log('megaMenu: removeOpenHandHeldMenuClickEventListener');
    document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.MegaMenu.MegaMenuHandHeldButton)).addEventListener('click', this._handleHandHeldMenuOpen);
  }

  attachFirstInteractionChangeEventListener(): void {
    console.log('megaMenu: attachFirstInteractionChangeEventListener');
    document.addEventListener(CustomEventEnums.FirstInteraction, this._handleFirstInteraction);
  }

  removeFirstInteractionChangeEventListener(): void {
    console.log('megaMenu: removeFirstInteractionChangeEventListener');
    document.removeEventListener(CustomEventEnums.FirstInteraction, this._handleFirstInteraction);
  }

  attachDeviceTypeChangeEventListener(): void {
    console.log('megaMenu: attachViewportDimensionsChangedEventListener');
    document.addEventListener(CustomEventEnums.DeviceTypeChangeEvent, this._closeMobilePopOutOnDesktop);
  }

  removeDeviceTypeChangeEventListener(): void {
    console.log('megaMenu: removeDeviceTypeChangeEventListener');
    document.removeEventListener(CustomEventEnums.DeviceTypeChangeEvent, this._closeMobilePopOutOnDesktop);
  }

  attachMegaMouseOverEventListener(): void {
    console.log('megaMenu: attachMegaMouseOverEventListener');
    this.htmlElement.addEventListener('mouseover', this._setMegaMenuZIndex);
  }

  removeMegaMenuMouseOverEventListener(): void {
    console.log('megaMenu: removeMegaMenuMouseOverEventListener');
    this.htmlElement.removeEventListener('mouseover', this._setMegaMenuZIndex);
  }

  attachMegaMouseOutEventListener(): void {
    console.log('megaMenu: attachMegaMouseOutEventListener');
    this.htmlElement.addEventListener('mouseout', this._unsetMegaMenuZIndex);
  }

  removeMegaMenuMouseOutEventListener(): void {
    console.log('megaMenu: removeMegaMenuMouseOutEventListener');
    this.htmlElement.removeEventListener('mouseover', this._unsetMegaMenuZIndex);
  }

  megaMenuDesktopFactory(): void {
    try {
      this.megaMenuDesktop = new MegaMenuDesktop(this.htmlElement);
    } catch (error) {
      console.log('MegaMenu: megaMenuDesktopFactory Error', error);
    }
  }

  megaMenuHandheldFactory(): void {
    try {
      let megaMenuHandheldElement: HTMLElement = this.megaMenuContainer.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.MegaMenuHandheld.MegaMenuHandheld));
      this.megaMenuHandheld = new MegaMenuHandheld(megaMenuHandheldElement);
    } catch (error) {
      console.log('MegaMenu: megaMenuHandheldFactory Error', error);
    }
  }

  setMegaMenuContainer(): void {
    try {
      this.megaMenuContainer = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.MegaMenu.MegaMenuContainer)) as HTMLElement;
    } catch (error) {
      console.log('MegaMenu: setMegaMenuContainer ERROR', error);
    }
  }


  reinitialiseMegaMenuDesktop(): void {
    if (this.megaMenuDesktop) {
      this.megaMenuDesktop.dispose();
      this.megaMenuDesktop = null;
    }
    this.megaMenuDesktopFactory();
  }

  async handleFirstInteraction() {
    console.log('MegaMenu: handleFirstInteraction');

    await this.loadDesktopMenu();
    await initialise();
    this.reinitialiseMegaMenuDesktop();
    this.htmlElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.MegaMenu.MegaMenu));

    this.megaMenuHoverDelay();

    this.megaMenuOverlay();

    this.attachDeviceTypeChangeEventListener();
    this.attachMegaMouseOverEventListener();
    this.attachMegaMouseOutEventListener();
    this.desktopMenuLoaded = true;
  }

  async handleHandHeldMenuOpen() {
    if (!this.handHeldMenuLoaded) {
      await this.loadHandHeldMenu();
      await initialise();
      this.megaMenuHandheldFactory();
      this.handHeldMenuLoaded = true;
    }
    ((<any>window).popOutController as IPopOutController).openByIdAndFamily('1', 'megaMenu');
  }

  megaMenuHoverDelay() {
    console.log('MegaMenu: megaMenuHoverDelay ');
    let menuEnterTimer: NodeJS.Timeout;
    let showDelay = 250;

    let categoryItems = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.MegaMenu.MegaMenuHover));

    if (this.isElement(categoryItems[0])) {
      for (let i = 0; i < categoryItems.length; i++) {
        categoryItems[i].addEventListener('mouseenter', (e: any) => {
          let thisItem = e.target;
          let thisItemParent = (thisItem as Element).closest(HelperUtils.addDataAttributeTags(AttributeEnums.MegaMenu.MegaMenuPopOut));
          let thisItemParentItems = thisItemParent.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.MegaMenu.MegaMenuHover));
          clearTimeout(menuEnterTimer);
          menuEnterTimer = setTimeout(function () {

            for (let j = 0; j < thisItemParentItems.length; j++) {
              thisItemParentItems[j].classList.remove('lb-active');
            }
            thisItem.classList.add('lb-active');
          }, showDelay);
        });
      }
    }
  }

  async loadDesktopMenu(): Promise<void> {
    console.log('MegaMenu: loadDesktopMenu - Started');
    //let desktopElement= this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.MegaMenu.MegaMenu));
    await AjaxGetAndReplaceHtml(this.htmlElement, urlPaths.websiteUrl + UrlEnums.PartialUrls.MegaMenuDesktop);
    console.log('MegaMenu: loadDesktopMenu - Finished');
    this.desktopMenuLoaded = true;
  }

  async loadHandHeldMenu(): Promise<void> {
    let handHeldlement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.PopOut.PopOutFamily, 'megaMenu'));
    let megaMenuHandHeldHtmlString: string = await HttpRequest.get(urlPaths.websiteUrl + UrlEnums.PartialUrls.MegaMenuMobile);
    handHeldlement.innerHTML = megaMenuHandHeldHtmlString;
  }

  async loadMenu(): Promise<void> {
    console.log('MegaMenu: loadMenu - Started');
    let element = this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.MegaMenu.MegaMenuContainer));
    let megaMenuHtmlString: string = await HttpRequest.get(urlPaths.websiteUrl + UrlEnums.PartialUrls.MegaMenu);
    element.innerHTML = megaMenuHtmlString;
    console.log('MegaMenu: loadMenu - Finished');
  }

  setMegaMenuZIndex(): void {
    console.log('megaMenu: setmegaMenuZIndex');
    let megaMenuRibbon: HTMLElement = document.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbMainMenuRibbon));
    megaMenuRibbon.style.zIndex = '30';

    let megaMenu: HTMLElement = document.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbMegaMenu));
    megaMenu.style.zIndex = '20';

    let headerMenu: HTMLElement = document.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbMainMenuHeader));
    headerMenu.style.zIndex = '20';

    clearTimeout(this.timeOutHandler);

  };

  unsetMegaMenuZIndex(): void {
    console.log('megaMenu: unsetmegaMenuZIndex');
    this.timeOutHandler = setTimeout(function () {
      let megaMenuRibbon: HTMLElement = document.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbMainMenuRibbon));
      megaMenuRibbon.style.zIndex = 'unset';

      let megaMenu: HTMLElement = document.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbMegaMenu));
      megaMenu.style.zIndex = 'unset';

      let headerMenu: HTMLElement = document.querySelector(HelperUtils.addCssClassDot(StringEnums.CssClass.LbMainMenuHeader));
      headerMenu.style.zIndex = 'unset';
    }, 250);
  };
  
  isElement(element: any) {
    return element instanceof Element || element instanceof HTMLDocument;
  }

  toggleModifier(element: string, modifier: string, state: boolean) {
    let obj = document.querySelectorAll(element)[0];
    if (this.isElement(obj)) {
      if (state == true) {
        obj.classList.add("lb-" + modifier);
      } else {
        obj.classList.remove("lb-" + modifier);
      }
    }
  }

  megaMenuOverlay() {
    console.log('MegaMenu: megaMenuOverlay');
    let categoryItems = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.MegaMenu.MegaMenuPopOut));
    categoryItems.forEach(item => {
      item.addEventListener('mouseenter', this.displayOverlay);
      item.addEventListener('mouseleave', this.hideOverlay);
    });
  }

  displayOverlay() {
    (<any>window).overlay.showOverlay();
  }

  hideOverlay() {
    (<any>window).overlay.hideOverlay();
  }


  closeMobilePopOutOnDesktop(e: CustomEvent) {
    console.log('megaMenu:close', e.detail.deviceType);
    if (e.detail.deviceType == "desktop") {
      ((<any>window).popOutController as PopOutController).closeAllMenus();
    }
  }

  dispose(): void {
    this.removeFirstInteractionChangeEventListener();
    this.removeOpenHandHeldMenuClickEventListener();
    this.removeDeviceTypeChangeEventListener();
    this.removeMegaMenuMouseOverEventListener();
    this.removeMegaMenuMouseOutEventListener();
  }

  delete(): void {

  }
}