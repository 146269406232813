import { AjaxGetAndReplaceHtml, AjaxGetAndReplaceHtmlAndOuterElement } from "../../shared/ajaxGetAndReplaceHtml";
import { initialise } from "../../shared/initialiser";
import { initialiseInstanceComponents } from "../../shared/instanceComponentInitialiser";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { LoadingTemplates } from "../loading/loadingTemplates";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { Product } from "./product";
import { UrlEnums } from "../../shared/utilities/enums/urlEnums";
import { UtilityEnums } from "../../shared/utilities/enums/utillityEnums";
import { BasketPopOut } from "../basketPopOut/basketPopOut";
import { StringEnums } from "../../shared/utilities/enums/stringEnums";
const urlPaths = require('../../config/urlPaths.json');
export function initialiseProduct() {
    console.log('ProductInitialiser - initialiseProduct');
    initialiseInstanceComponents<Product>(Product, HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product), productFactory);
}

function productFactory(currentElement: Element): void {
    let newProduct = new Product(currentElement);
    (<any>window)[currentElement.getAttribute(AttributeEnums.Product.Product) + UtilityEnums.WindowObjectName.Product] = newProduct;
    console.log('ProductInitialiser - productFactory - Finished');
}


export function getProductWithTypeName(productType: string): Product {
    if (productType == UtilityEnums.ProductTypeValue.BasketPopOut) {
        var basketPopOut: BasketPopOut = (<any>window)[UtilityEnums.WindowObjectName.BasketPopOut];
        var product = basketPopOut.product;
        return product;
    } else {
        return (<any>window)[productType + UtilityEnums.WindowObjectName.Product];
    }
}

export async function reitialiseMainProductAndProductOptionsPanel(product: Product) {
    try {
        console.log('ProductInitialiser: reitialiseMainProductAndProductOptionsPanel Started');
        let lineId: number = product.lineId;
        let sizeId: number = product.sizeId;
        let dataProductSelectionPanelElement = product.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductSelectionPanel)) as HTMLElement;
        product.dispose();
        clearFinancePanelElements(product.htmlElement as HTMLElement);
        dataProductSelectionPanelElement.innerHTML = LoadingTemplates.productLoadingTemplate;
        let url: string = urlPaths.websiteUrl + `${UrlEnums.PartialUrls.ProductSelectionPanel}${lineId}-${sizeId}`;
        console.log('ProductInitialiser: reitialiseMainProductAndProductOptionsPanel URL: ' + url);
        await AjaxGetAndReplaceHtmlAndOuterElement(dataProductSelectionPanelElement, url);
        console.log('ProductInitialiser: reitialiseMainProductAndProductOptionsPanel AjaxGetAndReplaceHtml Finished');

        console.log('ProductInitialiser: reitialiseMainProductAndProductOptionsPanel FinancePanel Started');
        let productElement = document.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product, UtilityEnums.ProductTypeValue.Product)) as HTMLElement;
        let productPrice = productElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductPrice.ProductNowPrice)).getAttribute(AttributeEnums.ProductPrice.ProductNowPrice);
        let urlFinance: string = urlPaths.websiteUrl + `${UrlEnums.PartialUrls.ProductFinancePanel}${productPrice}`;
        console.log('ProductInitialiser: reitialiseMainProductAndProductOptionsPanel urlFinance: ' + urlFinance);
        let financePanelElement = productElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductFinancePanel.ProductFinancePanel)) as HTMLElement;
        await AjaxGetAndReplaceHtml(financePanelElement, urlFinance);
        console.log('ProductInitialiser: reitialiseMainProductAndProductOptionsPanel FinancePanel Finished');

        if (!(<any>window).productProduct) {
            console.log('ProductInitialiser: reitialiseMainProductAndProductOptionsPanel - Product Disposed');
        } else {
            console.log('ProductInitialiser: reitialiseMainProductAndProductOptionsPanel - Product Not Disposed Error');
        }
        await initialise();
        console.log('ProductInitialiser: reitialiseMainProductAndProductOptionsPanel - executed initialise');
        console.log('ProductInitialiser: reitialiseMainProductAndProductOptionsPanel - Finished', (<any>window).productProduct);
    } catch (error) {
        console.log('ProductInitialiser: reitialiseMainProductAndProductOptionsPanel', error);
    }
}

export function clearFinancePanelElements(htmlElement: HTMLElement): void {
    try {
        console.log('Product: clearFinancePanelElements Started');
        let dataProductFinancePanelElement = htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductFinancePanel.ProductFinancePanel)) as HTMLElement;
        console.log('Product: clearFinancePanelElements - dataProductFinancePanelElement ', dataProductFinancePanelElement);
        if (dataProductFinancePanelElement !== null) {
            dataProductFinancePanelElement.innerHTML = LoadingTemplates.productLoadingTemplate;

        }
    } catch (error) {
        console.log('Product: clearFinancePanelElements', error);
    }
}

