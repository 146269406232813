export namespace SchemaDataEnums {
    export enum ItemList {
        ItemType = 'itemtype',
        ItemTypeValue = 'https://schema.org/ItemList',
        ItemListElement = 'itemListElement',
        ItemProp = 'itemprop',
        Content = 'content',
        Name = 'name',
        Size = 'size',
        Brand = 'brand',
        Category = 'category',
        Model = 'model',
        Position = 'position'
    }


}