import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { ResponsiveState } from "../../shared/responsiveState";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { StringEnums } from "../../shared/utilities/enums/stringEnums";
import { UtilityEnums } from "../../shared/utilities/enums/utillityEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";

export interface IYouTubeVideo extends IInstanceComponentBase {

}

export class YouTubeVideo extends InstanceComponentBase implements IYouTubeVideo {

    private lastResponsiveState: string;
    private videoSource: string;
    private videoTitle: string;
    private imageFileName: string;
    private imageWidth: number;
    private imageHeight: number;
    private readonly _creatorForIFrame;
    private readonly _visibilityState;
    private readonly _scrollInteraction;
    private readonly _clickInteraction;

    constructor(htmlElement: Element) {
        super(htmlElement);

        this._creatorForIFrame = this.creatorForIFrame.bind(this);

        //----- Event Listener Bindings Start -----//
        this._visibilityState = this.visibilityState.bind(this);
        this._scrollInteraction = this.scrollInteraction.bind(this);
        this._clickInteraction = this.clickInteraction.bind(this);
        //----- Event Listener Bindings End -----//

        this.lastResponsiveState = ResponsiveState.getState();
        this.setVideoSource();
        this.setVideoTitle();
        this.setImageDetails();

        this.createPlaceholderImage();
    }

    //------ Add Event Listeners Start -----//
    attachPlaceholderClickEventListener(): void {
        this.htmlElement.addEventListener('click', this._creatorForIFrame);
    }

    attachVisibilityChangeEventListener(): void {
        document.addEventListener('visibilitychange', this._visibilityState);
    }

    attachScrollEventListener(): void {
        document.addEventListener('scroll', this._scrollInteraction);
    }

    attachClickEventListener(): void {
        document.addEventListener('click', this._clickInteraction);
    }
    //------ Add Event Listeners End -----//

    //------ Remove Event Listeners Start -----//
    removePlaceholderClickEventListener(): void {
        this.htmlElement.removeEventListener('click', this._creatorForIFrame);
    }
    removeVisibilityChangeEventListener(): void {
        document.removeEventListener('visibilitychange', this._visibilityState);
    }
    removeScrollEventListener(): void {
        document.removeEventListener('scroll', this._scrollInteraction);
    }
    removeClickEventListener(): void {
        document.removeEventListener('click', this._clickInteraction);
    }
    //------ Remove Event Listeners End -----//

    setVideoSource(): void {
        this.videoSource = this.htmlElement.getAttribute(AttributeEnums.Video.VideoSource);
    }

    setVideoTitle(): void {
        this.videoTitle = this.htmlElement.getAttribute(AttributeEnums.Video.VideoTitle);
    }

    setImageDetails(): void {
        let imageSize: string = this.htmlElement.getAttribute(AttributeEnums.Video.VideoPlaceholderSize);

        if (imageSize === UtilityEnums.YouTubeImageSizes.Default) {
            this.imageFileName = UtilityEnums.YouTubeImageFileNames.Default;
            this.imageWidth = UtilityEnums.YouTubeImageWidths.Default;
            this.imageHeight = UtilityEnums.YouTubeImageHeights.Default;
        } else if (imageSize === UtilityEnums.YouTubeImageSizes.Medium) {
            this.imageFileName = UtilityEnums.YouTubeImageFileNames.Medium;
            this.imageWidth = UtilityEnums.YouTubeImageWidths.Medium;
            this.imageHeight = UtilityEnums.YouTubeImageHeights.Medium;
        } else if (imageSize === UtilityEnums.YouTubeImageSizes.High) {
            this.imageFileName = UtilityEnums.YouTubeImageFileNames.High;
            this.imageWidth = UtilityEnums.YouTubeImageWidths.High;
            this.imageHeight = UtilityEnums.YouTubeImageHeights.High;
        } else if (imageSize === UtilityEnums.YouTubeImageSizes.Standard) {
            this.imageFileName = UtilityEnums.YouTubeImageFileNames.Standard;
            this.imageWidth = UtilityEnums.YouTubeImageWidths.Standard;
            this.imageHeight = UtilityEnums.YouTubeImageHeights.Standard;
        } else if (imageSize === UtilityEnums.YouTubeImageSizes.MaxRes) {
            this.imageFileName = UtilityEnums.YouTubeImageFileNames.MaxRes;
            this.imageWidth = UtilityEnums.YouTubeImageWidths.MaxRes;
            this.imageHeight = UtilityEnums.YouTubeImageHeights.MaxRes;
        } else {
            this.imageFileName = UtilityEnums.YouTubeImageFileNames.Standard;
            this.imageWidth = UtilityEnums.YouTubeImageWidths.Standard;
            this.imageHeight = UtilityEnums.YouTubeImageHeights.Standard;
        }
    }


    //------ Event Listener Methods Start -----//
    creatorForIFrame(): void {
        console.log('YouTubeVideo: creatorForIFrame');
        this.removePlaceholderClickEventListener();
        this.createYouTubeVideo();
    }

    visibilityState(): void {
        console.log('YouTubeVideo: visibilityState');
        if (document.visibilityState === "hidden") {
            this.pauseVideo();
        }
    }
    scrollInteraction(): void {
        console.log('YouTubeVideo: scrollInteraction');

        let myElement: HTMLElement = this.htmlElement as HTMLElement;
        let bounding: DOMRect = myElement.getBoundingClientRect();
        let myElementHeight: number = myElement.offsetHeight;
        let myElementWidth: number = myElement.offsetWidth;

        if (bounding.top >= -myElementHeight
            && bounding.left >= -myElementWidth
            && bounding.right <= (window.innerWidth || document.documentElement.clientWidth) + myElementWidth
            && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + myElementHeight) {
            //console.log('Element is in the viewport!');
        } else {
            //console.log('Element is NOT in the viewport!');
            this.pauseVideo();
        }
    }
    clickInteraction(event: MouseEvent): void {
        console.log('YouTubeVideo: clickInteraction');
        let clickElement: EventTarget = event.target;
        this.ifClickElementNotOnSelf(clickElement);
    }

    createPlaceholderImage(): void {
        console.log('YouTubeVideo: createPlaceholderImage');
        let imageSource: string = this.createPlaceholderImageSource();

        let image: HTMLImageElement = new Image();
        image.src = imageSource;
        image.alt = this.videoTitle;
        image.width = this.imageWidth;
        image.height = this.imageHeight;
        image.loading = 'lazy';

        this.htmlElement.appendChild(image);

        this.attachPlaceholderClickEventListener();
    }

    createYouTubeVideo(): void {
        console.log('YouTubeVideo: createYouTubeVideo');
        let iframe: HTMLElement = this.createIFrame();
        this.htmlElement.innerHTML = '';
        this.htmlElement.appendChild(iframe);
        this.attachVisibilityChangeEventListener();
        this.attachScrollEventListener();
        this.attachClickEventListener();
    }

    createIFrame(): HTMLElement {
        console.log('YouTubeVideo: createIFrame');
        let iframe: HTMLElement = document.createElement('iframe');

        iframe.setAttribute('id', `${this.videoSource}`);
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allowfullscreen', '');
        iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
        iframe.setAttribute('title', this.videoTitle);
        iframe.setAttribute('src', `https://www.youtube.com/embed/${this.videoSource}${this.createUrlParameters()}`);

        return iframe;
    }

    ifClickElementNotOnSelf(clickElement: EventTarget): void {
        console.log('YouTubeVideo: ifClickElementNotOnSelf' + clickElement);
        if (clickElement instanceof HTMLElement && clickElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Video.VideoSource, this.videoSource))) {
            //console.log('true');
        } else {
            //console.log('false');
            this.pauseVideo();
        }
    }
    //------ Event Listener Methods End -----//

    pauseVideo(): void {
        console.log('YouTubeVideo: pauseVideo');
        let video: HTMLIFrameElement = this.htmlElement.querySelector(`#${this.videoSource}`) as HTMLIFrameElement;
        video.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }

    createPlaceholderImageSource(): string {
        return `https://i.ytimg.com/vi/${this.videoSource}/${this.imageFileName}`;
    }

    createUrlParameters(): string {
        let parameters: string = `?rel=0&showinfo=0&autoplay=1`;
        parameters += `&enablejsapi=1&version=3&playerapiid=ytplayer`;
        if (ResponsiveState.getState() === StringEnums.Selectors.Desktop) {

        }
        if (ResponsiveState.getState() === StringEnums.Selectors.Mobile) {
            parameters += `&cc_load_policy=1&cc_lang_pref=en`;
        }
        return parameters;
    }

    //------ Clean Up Processes Start -----//
    dispose(): void {
        this.removePlaceholderClickEventListener();
        this.removeVisibilityChangeEventListener()
        this.removeScrollEventListener();
        this.removeClickEventListener();
    }
    //------ Clean Up Processes End -----//
}