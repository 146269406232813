import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { UtilityEnums } from "../../shared/utilities/enums/utillityEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { ButtonImageMediaTile } from "../mediaTile/buttonImageMediaTile";
import { IPagingSelectableCarousel, PagingSelectableCarousel } from "../selectableCarousel/pagingSelectableCarousel";
import { IProductListingPageOption } from "./productListingPageOption";
const urlPaths = require('../../config/urlPaths.json');
export interface ICategoryPagingSelectableCarousel extends IPagingSelectableCarousel {

    displayLoading: () => void;
    getCellIdFromValue: (value: string) => number;
    setCurrentSelectedIndexAndStyleToNone: () => void;
}

export class CategoryPagingSelectableCarousel extends PagingSelectableCarousel implements ICategoryPagingSelectableCarousel {

    private readonly categoryTypeString = "category";
    private isLoading: boolean = false;
    constructor(public htmlElement: Element, carouselOrientation: UtilityEnums.ComponentOrientation, topLevelHtmlElement?: HTMLElement) {
        super(htmlElement, carouselOrientation, topLevelHtmlElement);
        //this.totalOccupiedCells = this.occupiedCellsElements.length;
        //this.cellsPerPage = this.getCellsPerPage();
        //this.initialiseCarouselCurrentSelectedIndex();
    }

    setCarouselCurrentSelectedIndex() {
        console.log('categoryPagingSelectableCarousel: setCarouselCurrentSelectedIndex, HTMLelement', this.htmlElement);
        console.log('categoryPagingSelectableCarousel: setCarouselCurrentSelectedIndex HTML selected cell', this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.SelectableCarousel.SelectedCell)));
        let selectedCellElement: HTMLElement | null = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.SelectableCarousel.SelectedCell));

        if (selectedCellElement !== null) {
            try {
                let selectedIndex: number = +selectedCellElement.getAttribute(AttributeEnums.SelectableCarousel.Cell);
                if (selectedIndex !== null && selectedCellElement !== undefined) {
                    this.carouselCurrentSelectedIndex = selectedIndex;
                    this.resetMediaTileActiveState();
                    this.setCurrentPage();
                    console.log(`categoryPagingSelectableCarousel: setCarouselCurrentSelectedIndex - SelectedCell ${this.selectedCell}`);
                    console.log(`categoryPagingSelectableCarousel: setCarouselCurrentSelectedIndex - currentPage ${this.currentPage}`);
                    this.moveCarouselToPage(this.currentPage);
                    this.setCarouselShowHideArrows();
                } else{
                    this.removeSelectedAttributesAndCss();
                }
            } catch {
            }

        } else {
            this.removeSelectedAttributesAndCss();
        }
    }

    initialiseCarouselCurrentSelectedIndex(){
        this.setCarouselCurrentSelectedIndex();
    }

    setCurrentSelectedIndexAndStyleToNone(){
        this.carouselCurrentSelectedIndex = undefined;
        this.removeSelectedAttributesAndCss();
    }

    dispatchSelectedCarouselCellSelectedEvent(e: Event): void {
        console.log(`CategoryPagingSelectableCarousel: dispatchSelectedCarouselCellSelectedEvent`);

        let targetElement = e.target as HTMLElement;
        let cell = this.getSelectedCellForEvent(targetElement);

        if (cell !== null && this.isLoading === false) {
            let selectedIndex: number = + cell.getAttribute(AttributeEnums.SelectableCarousel.Cell);
            let querySelectorValue: string = cell.getAttribute(AttributeEnums.CategoryPagingSelectableCarousel.CategoryPagingSelectableCarouselValue);
            console.log(`CategoryPagingSelectableCarousel: dispatchSelectedCarouselCellSelectedEvent Cell ${cell}, `);
            this.htmlElement.dispatchEvent(this.productListingPageOptionSelectedCustomEvent(this.categoryTypeString, querySelectorValue));
        }
    }

    displayLoading() {
        this.mediaTiles.forEach((mediaTiles: ButtonImageMediaTile) => { mediaTiles.htmlElement.classList.add('lb-loadingTile') });
        this.isLoading = true;
    }

    public static reinitializeCategoryCarouselHtml(categoryOptions: IProductListingPageOption[], htmlElement: HTMLElement) {

        htmlElement.innerHTML = CategoryPagingSelectableCarousel.staticSelectableCarouselSliderAndContentHtml(categoryOptions);
        
    }

    private static createCellHtmlForCategoryOptions(categoryOptions: IProductListingPageOption[]) {
        let cellsHtml: string = '';

        for (let i = 0; i < categoryOptions.length; i += 1) {
            cellsHtml += CategoryPagingSelectableCarousel.createCellHtmlForCategoryOption(categoryOptions[i], i);
        }
        return cellsHtml;
    }

    private static createCellHtmlForCategoryOption(categoryOption: IProductListingPageOption, cellNumber: number) {
        let cellHtml: string = '';
        cellHtml += `<div data-selectable-carousel-cell="${cellNumber}" class="lb_selectableCarousel_cells_cell" data-category-paging-selectable-carousel-value="${categoryOption.optionValue}">`;
        cellHtml += CategoryPagingSelectableCarousel.createTileHtmlForCategoryOption(categoryOption);
        cellHtml += `</div>`;
        return cellHtml;
    }

    private static createTileHtmlForCategoryOption(categoryOptions: IProductListingPageOption): string {
        let url: string = CategoryPagingSelectableCarousel.getImageUrlFromOptionValue(categoryOptions);
        let baseImagePath: string = urlPaths.baseImagePath;
        let imagePath = `${baseImagePath}/images/managed/category/${url}/mediumlargesquare/${url}.jpg`;
        let tileHtml = `<div data-media-tile="true" data-button-image-media-tile="true" class="lb_carouselTile lb_carouselTile-imageAndText"><img width="276" height="276" src="${imagePath}" alt="${categoryOptions.optionTitle}"/><div class="lb_carouselTile_name">${categoryOptions.optionTitle}</div></div>`;
        return tileHtml;
    }

    private static staticSelectableCarouselSliderAndContentHtml(categoryOptions: IProductListingPageOption[]) {
        let html: string = '';
        html += CategoryPagingSelectableCarousel.createPrevHtmlForCategoryOptions();
        html += `<div data-selectable-carousel-slider="true" class="lb_selectableCarousel_cells" style="flex:0 0 1045px">`;
        html += CategoryPagingSelectableCarousel.createCellHtmlForCategoryOptions(categoryOptions);
        html += `</div>`;
        html += CategoryPagingSelectableCarousel.createNextHtmlForCategoryOptions();
        return html;
    }

    private static createPrevHtmlForCategoryOptions() {
        let prevHtml: string = `<div data-paging-selectable-carousel-prev="true" class="lb_selectableCarousel_prev lb_selectableCarousel_prev-small lb-hidden"></div>`;
        return prevHtml;
    }

    private static createNextHtmlForCategoryOptions() {
        let nextHtml: string = `<div data-paging-selectable-carousel-next="true" class="lb_selectableCarousel_next lb_selectableCarousel_next-small"></div>`;
        return nextHtml;
    }

    public static getImageUrlFromOptionValue(categoryOptions: IProductListingPageOption): string {
        let url = categoryOptions.optionValue.split('_')[1];
        return url;
    }

    getCellIdFromValue(value: string): number {
        let id = value.split('_')[0];
        let element = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.CategoryPagingSelectableCarousel.CategoryPagingSelectableCarouselValue, value));
        let cellId: number = +element.getAttribute(AttributeEnums.SelectableCarousel.Cell);
        return cellId;
    }

    productListingPageOptionSelectedCustomEvent(type: string, value: string) {
        console.log(`ProductListingPageOption: productListingPageOptionSelectedCustomEvent type: ${type}  value: ${value}`);
        return new CustomEvent(CustomEventEnums.ProductListingPageOptionSelected, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                type: type,
                value: value
            }
        });
    }

    dispose(): void {
        super.dispose();
    }
}