import { BaseMediaTile, IBaseMediaTile } from "./baseMediaTile";

export interface IButtonImageMediaTile extends IBaseMediaTile {

}

export class ButtonImageMediaTile extends BaseMediaTile implements IButtonImageMediaTile {
    
    constructor(public htmlElement: Element) {

        super(htmlElement);
    }

    dispose(): void {
        
    }
    
}