export namespace StringEnums {
    export enum CssClass {
        LbActive = 'lb-active',
        LbDisabled = 'lb-disabled',
        LbCarouselCell = 'lb_carousel_cell',
        LbCarouselCells = 'lb_carousel_cells',
        LbCarouselNext = 'lb_carousel_next',
        LbCarouselPrevious = 'lb_carousel_previous',
        LbColourPickerTile = 'lb_colourPickerTile',
        LbCookieConsent = 'lb_cookieConsent',
        LbCookieConsentBtn = 'lb_cookieConsent_btn',
        LbCookieConsentText = 'lb_cookieConsent_text',
        LbDisableCssTransitions = 'lb-disableCssTransitions',
        LbFormElement = 'lb_formElement',
        LbFormElementDropDown = 'lb_formElement-dropDown',
        LbFormElementInput = 'lb_formElement_input',
        LbFormElementTextBox = 'lb_formElement-textBox',
        LbGalleryMainArea = 'lb_gallery_mainArea',
        LbGalleryChooserTiles = 'lb_gallery_chooser_tiles',
        LbGalleryChooserTilesCell = 'lb_gallery_chooser_tiles_cell',
        LbGalleryChooserTilesCellSelected = 'lb-selected',
        LbHeading = 'lb_heading',
        LbHeadingDelivery = 'lb_heading-delivery',
        LbHidden = 'lb-hidden',
        LbIcon = 'lb_icon',
        LbIconButton = 'lb_icon-button',
        LbIconDelivery = 'lb_icon-delivery',
        LbIconSmall = 'lb_icon-small',
        LbLink = 'lb_link',
        LbLinkWordWrap = 'lb_link-wordWrap',
        LbMainMenuHeader = 'lb_mainMenuHeader',
        ClearanceDot = 'lb_megaMenu_list_item-clearance',
        LbMainMenuRibbon = 'lb_mainMenuRibbon',
        LbMegaMenu = 'lb_megaMenu',
        LbNoScroll = 'lb_noScroll',
        LbOverlay = 'lb_overlay',
        LbOptionsGrid = 'lb_optionsGrid',
        LbOptionsGridOption = 'lb_optionsGrid_option',
        LbOptionsGridPrice = 'lb_optionsGrid_price',
        LbPopOut = 'lb_popOut',
        LbPopOutControl = 'lb_popOut_control',
        LbPopOutTransition = 'lb_popOut-transition',
        LbProductPageLayoutGallery = 'lb_productPageLayout_gallery',
        LbReadMoreFade = 'lb-readMoreFade',
        LbSelectableCarouselZoomHidden = "lb_selectableCarousel_zoom-hidden",
        LbSelected = 'lb-selected',
        LbSuccess = 'lb-success',
        LbText = 'lb_text',
        LbTextItalic = 'lb_text-italic',
        LbTextSmall = 'lb_text-small',
        LbWarning = 'lb-warning'
    }
    export enum Content {
        ReadLess = 'read less',
        ReadMore = 'read more',
        PleaseSelectAColour = 'Please select a colour.',
        PleaseSelectAllProductOptions = 'Please select all product options',
        SorryThisSelectionIsNoLongerAvailable = 'Sorry! This selection is no longer available. Please try a different selection',
        SorryThisSelectionIsNoLongerAvailableTitle = 'Sorry! This selection is no longer available | Land of Beds',

    }

    export enum Selectors {
        Desktop = 'Desktop',
        Tablet = 'Tablet',
        Mobile = 'Mobile'
    }
    export enum Delivery {
        DefaultPostCode = 'LS18 4SF',
        InputPlaceholderPostCode = 'Enter your postcode'
    }

    export enum Cookie {
        ConsentEnable = 'enable',
        ConsentDismiss = 'dismiss',
        ConsentText = 'To give you the best experience our website uses cookies. You can learn more by ',
        ConsentPrivacy = 'clicking here',
        ConsentButton = 'OK'
    }
    export enum Notification {
        ConsentEnable = 'enable',
        ConsentDismiss = 'dismiss'
    }
    export enum QueryStringParameters {
        AffiliateClickRef = 'clickref'
    }
}