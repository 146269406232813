import { HistoryController } from "../../shared/historyController";
import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { PopOutController } from "../../shared/popOutController";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { StringEnums } from "../../shared/utilities/enums/stringEnums";
import { ValidationState } from "../../shared/utilities/enums/validationStateEnum";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { TypeUtils } from "../../shared/utilities/typeUtils";

export interface IProductDropDown extends IProductSelector, IInstanceComponentBase {
    htmlElement: HTMLElement;
    popOutIdOpen: string;
    popOutFamilyOpen: string;
    productDisplayText: string;
    productDisplayName: string;
    refreshProperties: () => void;
    updateProductDisplayText: (newDisplayText: string) => void;
    updateProductDisplayName: (newDisplayName: string) => void;
    updateValue: (newValue: number | undefined) => void;
    updateProductDropDown: (newDisplayText: string, newDisplayName: string, newValue: number | undefined) => void;
    dispose: () => void;
}

export interface IProductSelector {
    productSelectorElement: HTMLElement;
    productLineId: number;
    value?: number;
}

export abstract class ProductDropDown extends InstanceComponentBase implements IProductDropDown {
    popOutIdOpen: string;
    popOutFamilyOpen: string;
    productDisplayText: string;
    productDisplayName: string;
    productSelectorElement: HTMLElement;
    productLineId: number;
    value?: number;
   

    constructor(public htmlElement: HTMLElement) {
        super(htmlElement);

      

        this.htmlElement = htmlElement;
        this.refreshProperties();

    
    }

   
  

    setPropertyFromDataAttribute(element: HTMLElement, attributeName: string): string {
        if (element.hasAttribute(attributeName)) {
            return element.getAttribute(attributeName);
        }
        else {
            console.log(`Critical Error: ProductDropDown setPropertyFromDataAttribute Attribute:  ${attributeName}`, element);
            throw `Critical Error: ProductDropDown setPropertyFromDataAttribute Attribute: ${attributeName}`;
        }
    }
    setPropertyFromDataAttributeValidUndefined(element: HTMLElement, attributeName: string): string | undefined {
        if (element.hasAttribute(attributeName)) {
            return element.getAttribute(attributeName);
        }
        else {
            return undefined;
        }
    }
    setInnerTextFromDataAttribute(element: HTMLElement, attributeName: string): string {
        if (element.hasAttribute(attributeName)) {
            return element.innerText;
        }
        else {
            console.log(`Critical Error: ProductDropDown setInnerTextFromDataAttribute Attribute:  ${attributeName}`, element);
            throw `Critical Error: ProductDropDown setInnerTextFromDataAttribute Attribute: ${attributeName}`;
        }
    }

    setPopOutIdOpen(): string {
        return this.setPropertyFromDataAttribute(this.htmlElement, AttributeEnums.PopOut.PopOutIdOpen);
    }

    setPopOutFamilyOpen(): string {
        return this.setPropertyFromDataAttribute(this.htmlElement, AttributeEnums.PopOut.PopOutFamilyOpen);
    }

    setProductDisplayText(): string {
        let displayTextElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.DropDowns.ProductDisplayText)) as HTMLElement;
        return this.setInnerTextFromDataAttribute(displayTextElement, AttributeEnums.DropDowns.ProductDisplayText);
    }

    setProductDisplayName(): string {
        let displayNameElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.DropDowns.ProductDisplayName)) as HTMLElement;
        return this.setInnerTextFromDataAttribute(displayNameElement, AttributeEnums.DropDowns.ProductDisplayName);
    }

    setProductLineId(): number {
        let lineIdElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductLineId)) as HTMLElement;
        try {
            return parseInt(this.setPropertyFromDataAttribute(lineIdElement, AttributeEnums.Product.ProductLineId));
        } catch (error) {
            console.log('Critical Error: setProductLineId Type Missmatch Error', lineIdElement);
            throw 'Critical Error: setProductLineId Type Missmatch Error';
        }
    }

    setProductSelectorElement(): HTMLElement {
        let productSelectorElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelector));
        if (productSelectorElement.hasAttribute(AttributeEnums.ProductSelector.ProductSelector)) {
            return productSelectorElement as HTMLElement;
        }
        else {
            console.log('Critical Error: ProductDropDown Attribute data-product-selector', this.htmlElement);
            throw 'Critical Error: ProductDropDown Attribute data-product-selector';
        }
    }
    updateValidationState(validationState: ValidationState) {
        if (ValidationState.Warning) {
            this.htmlElement.classList.remove(StringEnums.CssClass.LbSuccess);
            this.htmlElement.classList.add(StringEnums.CssClass.LbWarning);
        } else if (ValidationState.Success) {
            this.htmlElement.classList.remove(StringEnums.CssClass.LbWarning);
            this.htmlElement.classList.add(StringEnums.CssClass.LbSuccess);
        } else if (ValidationState.None) {
            this.htmlElement.classList.remove(StringEnums.CssClass.LbWarning);
            this.htmlElement.classList.remove(StringEnums.CssClass.LbSuccess);
        }
    }
    updateValidationStateUsingValue(): void {
        let addToCartAttempted: boolean = (this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product)).querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductAddToCartAttempted)).getAttribute(AttributeEnums.Product.ProductAddToCartAttempted) === 'true');
        if (this.value === undefined) {
            if (addToCartAttempted) {
                this.htmlElement.classList.remove(StringEnums.CssClass.LbSuccess);
                this.htmlElement.classList.add(StringEnums.CssClass.LbWarning);
            }
        } else {
            this.htmlElement.classList.remove(StringEnums.CssClass.LbWarning);
            this.htmlElement.classList.add(StringEnums.CssClass.LbSuccess);
        }
    }

    setValue(): number | undefined {

        let productSelectorElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelector)) as HTMLElement;

        let valueAsString: string | undefined = this.setPropertyFromDataAttributeValidUndefined(productSelectorElement, 'value');

        if (valueAsString === undefined || valueAsString === '') {
            return undefined;
        }
        else {
            return +valueAsString;
        }
    }

    writeToDataAttributeValue(element: HTMLElement, attributeName: string, value: string): void {
        element.setAttribute(attributeName, value);
    }

    writeToInnerText(element: HTMLElement, attributeName: string, value: string): void {
        if (element.hasAttribute(attributeName)) {
            element.innerText = value;
        }
        else {
            console.log(`Critical Error: ProductDropDown writeToInnerText Attribute: ${attributeName} Value: ${value}`, element);
            throw `Critical Error: ProductDropDown writeToInnerText Attribute: ${attributeName} Value: ${value}`;
        }
    }

    writeToProductDisplayText(newDisplayText: string): void {
        let displayTextElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.DropDowns.ProductDisplayText)) as HTMLElement;
        this.writeToInnerText(displayTextElement, AttributeEnums.DropDowns.ProductDisplayText, newDisplayText);
    }

    writeToProductDisplayName(newDisplayName: string): void {
        let displayTextElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.DropDowns.ProductDisplayName)) as HTMLElement;
        this.writeToInnerText(displayTextElement, AttributeEnums.DropDowns.ProductDisplayName, newDisplayName);
    }

    writeToValue(newValue: number): void {
        let displayTextElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.ProductSelector.ProductSelector)) as HTMLElement;
        this.writeToDataAttributeValue(displayTextElement, 'value', newValue.toString());
    }

    updateProductDropDown(newDisplayText: string, newDisplayName: string, newValue: number | undefined): void {
        this.updateProductDisplayText(newDisplayText);
        this.updateProductDisplayName(newDisplayName);
        this.updateValue(newValue);
    }

    updateProductDisplayText(newDisplayText: string): void {
        this.writeToProductDisplayText(newDisplayText);
        this.productDisplayText = this.setProductDisplayText();
    }

    updateProductDisplayName(newDisplayName: string): void {
        this.writeToProductDisplayName(newDisplayName);
        this.productDisplayName = this.setProductDisplayName();
    }

    updateValue(newValue: number | undefined): void {
        this.writeToValue(newValue);
        this.value = this.setValue();
    }

    refreshProperties(): void {
        this.popOutIdOpen = this.setPopOutIdOpen();
        this.popOutFamilyOpen = this.setPopOutFamilyOpen();
        this.productDisplayText = this.setProductDisplayText();
        this.productDisplayName = this.setProductDisplayName();
        this.productSelectorElement = this.setProductSelectorElement();
        this.productLineId = this.setProductLineId();
        this.value = this.setValue();
    }

    closeOptionsPickerPopout() {
        if (PopOutController.hasIdLowerLevel(this.popOutIdOpen)){
            HistoryController.historyBackOneIfPopOut();
            ((<any>window).popOutController as PopOutController).openByIdAndFamilyNoHistoryPush(PopOutController.getIdLowerLevel(this.popOutIdOpen), this.popOutFamilyOpen);
        }  
        else {
            ((<any>window).popOutController as PopOutController).closeAllMenus();
        }
    }

    getProductContainerElement() {
        return this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.Product));
    }
    getProductOptionsPanelContainerElement() {
        return this.htmlElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.Product.ProductOptionsPanel));
    }

    getSelectedProductUrl() {
        return this.getProductContainerElement().querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.DropDowns.SelectedProductUrl)).getAttribute(AttributeEnums.DropDowns.SelectedProductUrl);
    }

    //------ Custom Event Creation Start -----//
    //------ Custom Event Creation End -----//

    //------ Clean Up Processes Start -----//
    dispose(): void {
        
        this.delete();
    }
    delete(): void {
        console.log('ProductDropDown: delete');
    }
    //------ Clean Up Processes End -----//

}