
export namespace HttpRequest {

  export async function getBeforAfter(route: string, doBefore?: Function, doAfter?: Function) {
    doBefore();
    let result = await get(route);
    doAfter();
    return result;
  }

  export async function getBeforAfterFailed(route: string, doBefore?: Function, doAfter?: Function, onFailed?: Function) {
    doBefore();
    let result = await get(route);
    if(result === null) {
      onFailed();
    }
    doAfter();
    return result;
  }

  export async function get(route: string) {
    return await fetch(route, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        'Access-Control-Allow-Headers': 'Content-Type'
      }
    })
      .then<string>(res => {
        if (!res.ok) {
          console.log('Critical error on Get route: ' + route);
          throw new Error('Get Error For: ' + route);
        }
        var text = res.text();
        //console.log("getFinished");
        return text;
      })
      .catch(error => {
        console.log(error);
        return null;
      });
  }

  export async function post(route: string, body: string) {
    return await fetch(route, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        'Access-Control-Allow-Headers': 'Content-Type'
      },
      body: body
    })
      .then<string>(res => {
        if (!res.ok) {
          console.log('Critical error on Post route: ' + route);
          throw new Error('Post Error For: ' + route);
        }
        var text = res.text();
        return text;
      })
      .catch(error => {
        console.log(error);
        throw new Error('Post Error For: ' + route);
      });
  }

  export async function put(route: string, body: string) {
    return await fetch(route, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        'Access-Control-Allow-Headers': 'Content-Type'
      },
      body: body
    })
      .then<string>(res => {
        if (!res.ok) {
          console.log('Critical error on Put route: ' + route);
          throw new Error('Put Error For: ' + route);
        }
        var text = res.text();
        return text;
      })
      .catch(error => {
        console.log(error);
        throw new Error('Put Error For: ' + route);
      });
  }

  export async function deleteCall(route: string, body: string) {
    return await fetch(route, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        'Access-Control-Allow-Headers': 'Content-Type'
      },
      body: body
    })
      .then<string>(res => {
        if (!res.ok) {
          console.log('Critical error on Delete route: ' + route);
          throw new Error('Delete Error For: ' + route);
        }
        var text = res.text();
        return text;
      })
      .catch(error => {
        console.log(error);
        throw new Error('Delete Error For: ' + route);
      });
  }

}