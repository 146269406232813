import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { YouTubeVideo } from "../video/youTubeVideo";
import { BaseMediaTile, IBaseMediaTile } from "./baseMediaTile";

export interface ILazyYouTubeMediaTile extends IBaseMediaTile {
}

export class LazyYouTubeMediaTile extends BaseMediaTile implements ILazyYouTubeMediaTile {

    public youTubeVideo: YouTubeVideo;

    constructor(public htmlElement: Element) {
        super(htmlElement);

        this.createYouTubeVideoFactory();
    }

    createYouTubeVideoFactory(): void {
        let videoAttr: string = this.htmlElement.getAttribute(AttributeEnums.Video.Video);
        if (videoAttr !== null) {
            this.youTubeVideo = new YouTubeVideo(this.htmlElement);
        }
    }

    dispose(): void {

    }

}