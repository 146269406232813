import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { ContentItems, IContentItems } from "../contentItems/contentItems";
import { BannerPromotions, IBannerPromotions } from "../promotions/bannerPromotions";

export interface IGlobalComponents {
    bannerPromotions: IBannerPromotions;
    dispose: () => void;
}

export class GlobalComponents implements IGlobalComponents {
    public bannerPromotions: IBannerPromotions;
    public contentItems: IContentItems;

    constructor() {

        this.bannerPromotions = BannerPromotions.bannerPromotionsFactory();
        this.contentItems = ContentItems.contentItemsFactory();
    }

    public static initialiseGlobalComponents(): void {
        try {
            (<any>window).GlobalComponents = new GlobalComponents();
        } catch (error) {
            console.log('GlobalComponents.initialiseGlobalComponents ERROR', error);
        }
    }

    public static getGlobalComponents(): GlobalComponents {
        try {
            return (<any>window).GlobalComponents;
        } catch (error) {
            return null;
        }
    }

    dispose(): void {
        try {
            this.bannerPromotions.dispose();
        } catch (error) {
            console.log('Static: dispose ERROR', error);
        }
    }
}