export namespace DomUtils {
  export function createArrayOfDataElement(): string[] {
    let allDataAttributeNames: string[] = [];
    let allElements = document.querySelectorAll('*');
    for (let i = 0; i < allElements.length; i++) {
      for (let x = 0; x < allElements[i].attributes.length; x++) {
        if (allElements[i].attributes[x].name.includes("data-")) {
          allDataAttributeNames.push(allElements[i].attributes[x].name);
        }
      }
    }
    return allDataAttributeNames;
  }

  // export function removeChildren(element: HTMLElement): void {
  //   while (element.firstChild) {
  //     element.removeChild(element.firstChild);

  //   }
  //}
}
