import { AsyncUtils } from "../../shared/utilities/asyncUtils";
import { IInstanceComponentBase, InstanceComponentBase } from "../../shared/instanceComponentBase";
import { ResponsiveState } from "../../shared/responsiveState";
import { AttributeEnums } from "../../shared/utilities/enums/attributeEnums";
import { CustomEventEnums } from "../../shared/utilities/enums/customEventEnums";
import { StringEnums } from "../../shared/utilities/enums/stringEnums";
import { UtilityEnums } from "../../shared/utilities/enums/utillityEnums";
import { HelperUtils } from "../../shared/utilities/helperUtils";
import { BaseMediaTile } from "../mediaTile/baseMediaTile";
import { mediaTileFactory } from "../mediaTile/mediaTileFactory";
import { NoEmitOnErrorsPlugin } from "webpack";

export interface ISelectableCarousel extends IInstanceComponentBase {

    carouselCurrentSelectedIndex: number | undefined;
    updateCarouselCurrentSelectedIndex: (index: number) => void;
    carouselOrientation: UtilityEnums.ComponentOrientation;
    deviceOrientation: UtilityEnums.DeviceOrientation;
    totalOccupiedCells: number;
    mediaTiles: BaseMediaTile[];
    topLevelHtmlElement: HTMLElement;
}

export class SelectableCarousel extends InstanceComponentBase implements ISelectableCarousel {

    carouselCurrentSelectedIndex: number;

    protected carouselSliderElement: HTMLElement = this.getCarouselSliderElement();
    protected selectedCell: HTMLElement | null = this.getSelectedCellElement();

    protected occupiedCellsElements: NodeListOf<HTMLElement> = this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.SelectableCarousel.Cell));
    private readonly _dispatchSelectedCarouselCellSelectedEvent;
    private readonly _updateCarouselCurrentSelectedIndexFromEvent;
    private readonly _updateDeviceOrientation;
    public carouselOrientation: UtilityEnums.ComponentOrientation;
    public deviceOrientation: UtilityEnums.DeviceOrientation;
    public totalOccupiedCells: number;

    public mediaTiles:BaseMediaTile[] = [];
    public topLevelHtmlElement: HTMLElement; 


    constructor(public htmlElement: Element, carouselOrientation: UtilityEnums.ComponentOrientation, topLevelHtmlElement?: HTMLElement) {
        super(htmlElement);

        if (topLevelHtmlElement !== undefined) {
            this.topLevelHtmlElement = topLevelHtmlElement;
        } else {
            this.topLevelHtmlElement = this.htmlElement as HTMLElement;
        }
        this.totalOccupiedCells = this.occupiedCellsElements.length;
        this.mediaTilesFactory();

        this._dispatchSelectedCarouselCellSelectedEvent = this.dispatchSelectedCarouselCellSelectedEvent.bind(this);
        this._updateCarouselCurrentSelectedIndexFromEvent = this.updateCarouselCurrentSelectedIndexFromEvent.bind(this);
        this._updateDeviceOrientation = this.updateDeviceOrientation.bind(this);

        this.attachCarouselClickEventListener();
        this.attachDeviceOrientationChangedEventListener();
        this.attachSelectedCarouselCellSelectedEventListener();

        this.initialiseCarouselCurrentSelectedIndex();  
           
        this.updateCarouselOrientation(carouselOrientation);
        
        if(this.selectedCell!= null) {
            this.writeToCarouselCurrentSelectedIndex(this.carouselCurrentSelectedIndex);
        }
        this.deviceOrientation = ResponsiveState.getOrientation();
    }

    mediaTilesFactory() {
        console.log('SelectableCarousel: mediaTilesFactory', this.htmlElement);
        this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.BaseMediaTile.MediaTile)).forEach((element: HTMLElement) => {
            try {
                this.mediaTiles.push(mediaTileFactory(element));
            }
            catch (error) {
                console.log('SelectableCarousel: mediaTilesFactory Error', error);
            }
        });
    }

    attachCarouselClickEventListener(): void {
        console.log('SelectableCarousel: attachCarouselClickEventListener');
        let sliderElement = this.getCarouselSliderElement();
        sliderElement.addEventListener('click', this._dispatchSelectedCarouselCellSelectedEvent);
    }

    removeCarouselClickEventListener(): void {
        console.log('SelectableCarousel: removeCarouselClickEventListener');
        let sliderElement = this.getCarouselSliderElement();
        sliderElement.removeEventListener('click', this._dispatchSelectedCarouselCellSelectedEvent);
    }

    attachDeviceOrientationChangedEventListener(): void {
        console.log('SelectableCarousel: attachDeviceOrientationChangedEventListener');
        document.addEventListener(CustomEventEnums.DeviceOrientationChangeEvent, this._updateDeviceOrientation);
    }

    removeDeviceOrientationChangedEventListener(): void {
        console.log('SelectableCarousel: removeDeviceOrientationChangedEventListener');
        this.htmlElement.removeEventListener(CustomEventEnums.DeviceOrientationChangeEvent, this._updateDeviceOrientation);
    }

    attachSelectedCarouselCellSelectedEventListener(): void {
        console.log('SelectableCarousel: attachSelectedCarouselCellSelectedEventListener');
        this.topLevelHtmlElement.addEventListener(CustomEventEnums.SelectedCarouselCellSelected, this._updateCarouselCurrentSelectedIndexFromEvent);
    }

    removeSelectedCarouselCellSelectedEventListener(): void {
        console.log('SelectableCarousel: removeSelectedCarouselCellSelectedEventListener');
        this.topLevelHtmlElement.removeEventListener(CustomEventEnums.SelectedCarouselCellSelected, this._updateCarouselCurrentSelectedIndexFromEvent);
    }

    writeToMainDisplayArea(htmlElement: HTMLElement): void {

    }

    resetMediaTileActiveState() {
        console.log('SelectableCarousel: resetMediaTileActiveState');
        this.setAllMediaTilesToInactive();
        this.setCurrentlySelectedMediaTileToActive();
    }

    setAllMediaTilesToInactive() {
        this.mediaTiles.forEach((tile: BaseMediaTile) => { tile.setTileActiveState(false); });
    }

    setCurrentlySelectedMediaTileToActive() {
        console.log(`SelectableCarousel: setCurrentlySelectedMediaTileToActive, mediaTiles length ${this.mediaTiles.length}`);
        if (this.mediaTiles.length > 0) {
            this.mediaTiles[this.carouselCurrentSelectedIndex].setTileActiveState(true);
        }
    }

    setCarouselCurrentSelectedIndex() {
        console.log('SelectableCarousel: setCarouselCurrentSelectedIndex, HTMLelement', this.htmlElement);
        console.log('SelectableCarousel: setCarouselCurrentSelectedIndex HTML selected cell', this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.SelectableCarousel.SelectedCell)));
        let selectedCell: HTMLElement | null = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.SelectableCarousel.SelectedCell));

        if (selectedCell !== null) {
            try {
                let selectedIndex: number = +selectedCell.getAttribute(AttributeEnums.SelectableCarousel.Cell);
                this.carouselCurrentSelectedIndex = selectedIndex;
            } catch {
                this.carouselCurrentSelectedIndex = 0;
            }
        } else {
            this.carouselCurrentSelectedIndex = 0;
        }

        this.resetMediaTileActiveState();
    }

    hasCellSelectedAttribute() {
        if (this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.SelectableCarousel.SelectedCell)) !== null) {
            return true;
        } else {
            return false
        };
    }

    initialiseCarouselCurrentSelectedIndex() {
        console.log('SelectableCarousel: initialiseCarouselCurrentSelectedIndex');
        let initialSelection = this.htmlElement.getAttribute(AttributeEnums.SelectableCarousel.InitialSelection);
        if (this.hasCellSelectedAttribute()) {
            this.setCarouselCurrentSelectedIndex();
        } else if(initialSelection !== "false") {
            this.updateCarouselCurrentSelectedIndex(0);
        } 
    }

    updateCarouselCurrentSelectedIndex(index: number) {
        console.log('SelectableCarousel: updateCarouselCurrentSelectedIndex - index ' + index.toString());
        this.writeToCarouselCurrentSelectedIndex(index);
        this.setCarouselCurrentSelectedIndex();
    }

    updateCarouselCurrentSelectedIndexFromEvent(event: CustomEvent) {
        console.log('SelectableCarousel: updateCarouselCurrentSelectedIndexFromEvent - event', event);
        const selectedIndex: number = event.detail.selectedIndex as number;

        this.updateCarouselCurrentSelectedIndex(selectedIndex);
    }

    writeToCarouselCurrentSelectedIndex(index: number) {
        console.log('SelectableCarousel: writeToCarouselCurrentSelectedIndex - index' + index.toString());
        this.removeSelectedAttributesAndCss();
        let selectedElement = this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.SelectableCarousel.Cell, index.toString()));

        if (selectedElement !== null) {
            selectedElement.setAttribute(AttributeEnums.SelectableCarousel.SelectedCell, '');
            selectedElement.classList.add(StringEnums.CssClass.LbGalleryChooserTilesCellSelected);
        }
    }

    updateCarouselOrientation(carouselOrientation: UtilityEnums.ComponentOrientation) {
        this.writeToCarouselOrientation(carouselOrientation);
        this.setCarouselOrientation();
    }

    setCarouselOrientation() {
        let carouselOrientationString: string = this.htmlElement.getAttribute(AttributeEnums.SelectableCarousel.ComponentOrientation);
        this.carouselOrientation = (UtilityEnums.ComponentOrientation.Horizontal === carouselOrientationString) ? UtilityEnums.ComponentOrientation.Horizontal : UtilityEnums.ComponentOrientation.Vertical;
    }

    writeToCarouselOrientation(carouselOrientation: UtilityEnums.ComponentOrientation) {
        this.htmlElement.setAttribute(AttributeEnums.SelectableCarousel.ComponentOrientation, carouselOrientation);
    }

    removeSelectedAttributesAndCss() {
        console.log('SelectableCarousel: removeSelectedAttributesAndCss');
        this.htmlElement.querySelectorAll(HelperUtils.addDataAttributeTags(AttributeEnums.SelectableCarousel.SelectedCell))
            .forEach((element: HTMLElement) => {
                element.classList.remove(StringEnums.CssClass.LbGalleryChooserTilesCellSelected);
                element.removeAttribute(AttributeEnums.SelectableCarousel.SelectedCell);
            });
    }

    updateDeviceOrientation(event: CustomEvent): void {
        console.log('SelectableCarousel: updateDeviceOrientation');
        let target: EventTarget = event.target;
        let deviceOrientation = event.detail.deviceOrientation as UtilityEnums.DeviceOrientation;
        this.deviceOrientation = deviceOrientation;
        console.log('SelectableCarousel: updateDeviceOrientation - deviceOrientation: ' + deviceOrientation.toString());
    }

    selectedCarouselCellSelectedEvent(selectedIndex: number) {
        console.log('SelectableCarousel: selectedCarouselCellSelectedEvent', selectedIndex);
        return new CustomEvent(CustomEventEnums.SelectedCarouselCellSelected, {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: {
                'selectedIndex': selectedIndex
            }
        });
    }

    dispatchSelectedCarouselCellSelectedEvent(e: Event): void {
        console.log('SelectableCarousel: dispatchSelectedCarouselCellSelectedEvent', this.carouselSliderElement);

        let targetElement = e.target as HTMLElement;
        let cell = this.getSelectedCellForEvent(targetElement);
        if (cell !== null) {
            let selectedIndex: number = + cell.getAttribute(AttributeEnums.SelectableCarousel.Cell);
            this.htmlElement.dispatchEvent(this.selectedCarouselCellSelectedEvent(selectedIndex));
        }
    }

    getSelectedCellForEvent(targetElement: HTMLElement){
        let cell = targetElement.closest(HelperUtils.addDataAttributeTags(AttributeEnums.SelectableCarousel.Cell));
        return cell;
    }

    getCarouselSliderElement(): HTMLElement{
        return this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.SelectableCarousel.Slider)) as HTMLElement;
    }

    getSelectedCellElement(): HTMLElement{
        return this.htmlElement.querySelector(HelperUtils.addDataAttributeTags(AttributeEnums.SelectableCarousel.SelectedCell)) as HTMLElement;
    }
    dispose(): void {
        this.removeCarouselClickEventListener();
        this.removeDeviceOrientationChangedEventListener();
        this.removeSelectedCarouselCellSelectedEventListener();
    }

    delete(): void {

    }
}